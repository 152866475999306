import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { FormWrapper } from 'app/components/Forms/FormWrapper';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { useContextModuleNavigator } from 'app/hooks';
import { paths } from 'app/routes/paths';

import { messages } from './messages';
import { useForm, useSelectors } from './hooks';
import { Form } from './Form';
import { Header } from './components/Header';
import styled from 'styled-components/macro';

export const CreateSubscriptionPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { currentInstituteId: instituteId } = useSelectors();

  const { popToRoot } = useContextModuleNavigator({
    context: instituteId,
    rootEndpoint: paths.subscriptions.list.cases.pending,
  });

  const onFailure = (message: string) => {
    alert(message);
  };

  const { canSubmit, isSubmitting, methods, onSubmit } = useForm({
    instituteId,
    onSuccess: popToRoot,
    onFailure: onFailure,
  });

  const pageTitle = t(messages.pageTitle());
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageWrapper header={<Header backBehaviour label={pageTitle} />}>
        <FormWrapper
          id="customer-edit-form"
          methods={methods}
          onSubmit={onSubmit}
        >
          <Form
            canSubmit={canSubmit}
            isSubmitting={isSubmitting}
            methods={methods}
          />
        </FormWrapper>
      </PageWrapper>
    </>
  );
};

const MandatButton = styled.input`
  background: #e462a1;
  border: none;
  padding: 20px;
  border-radius: 20px;
  color: white;
  margin: 30px;
`;

export const FormSectionTitle = styled.h3`
  margin-block: 0 1rem;
  color: ${p => p.theme.text.title}; ;
`;