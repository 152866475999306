import { useCallback, useMemo, useState } from 'react';

import { useFuse } from 'app/hooks/fuse/useFuse';

import { SubscriptionTab } from '../types';
import { FuseSearchEvent } from 'app/hooks/fuse/types';
import { get } from 'lodash';
import { debounce } from 'throttle-debounce';
import { useOldQueries } from './useOldQueries';
import { useOldOptions } from './useOldOptions';

export const useOldData = ({
  instituteId,
  tab,
}: {
  instituteId: number;
  tab: SubscriptionTab;
}) => {
  const [query, updateQuery] = useState('');

  // eslint-disable-next-line
  const setQuery = useCallback(debounce(100, updateQuery), []);

  const { subscribersOldQueryState } = useOldQueries({
    instituteId,
    searchQuery: query,
    tab,
  });

  const subscribersQueryState = useMemo(
    () => subscribersOldQueryState,
    [subscribersOldQueryState],
  );

  const { fuseOptions } = useOldOptions();

  const {
    hits: filteredSubscribers,
    isActive: isSearchActive,
    onSearch: onFuseSearch,
  } = useFuse({
    list: subscribersQueryState.data?.hits.hits.map(hit => hit.source),
    options: fuseOptions,
  });

  const filteredSubscribersQueryState = useMemo(
    () => ({ ...subscribersQueryState, data: filteredSubscribers }),
    [filteredSubscribers, subscribersQueryState],
  );

  const onSearch = useCallback(
    (event: FuseSearchEvent) => {
      onFuseSearch(event);
      const value = get(event.target, 'value', '');
      setQuery(value.trim());
    },
    [onFuseSearch, setQuery],
  );
  return {
    subscribersOldQueryState,
    subscribersQueryState: filteredSubscribersQueryState,
    isSearchActive,
    onSearch,
  };
};
