import { Helmet } from 'react-helmet-async';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState, useRef } from 'react';

import { List } from 'app/components/List';
import { LoadableContent } from 'app/components/LoadableContent';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { paths } from 'app/routes/paths';
import { EmptyState } from 'app/components/EmptyState';

import { SubscriptionItem } from './components/SubscriptionItem';
import { ExternalSubscriptions } from './components/ExternalSubscriptions';
import { messages } from './messages';
import { useData, useSelectors } from './hooks';
import { Header } from './components/Header';
import { SubscriptionTab } from './types';
import { locationFromTab, tabFromURLParam } from './utils';
import { request } from 'utils/request';
import { Subscription } from 'types/API/subscriptions';
import style from 'styled-components/macro';
import Dropdown from 'react-dropdown';
import ScrollToTop from 'app/components/ScrollToTop/ScrollToTop';
import { useInstituteSelector } from 'app/atoms/selectors';
import ReactToPrint from 'react-to-print';
import { OldDBView } from './components/OldDBView';
import { sub } from 'date-fns';

interface Option {
  value: string;
  label: string;
}

export const SubscriptionsPage = () => {
  const { currentInstituteId } = useSelectors();
  const { t } = useTranslation();
  const history = useHistory();
  const { kind }: { kind?: string } = useParams();
  const { institute } = useInstituteSelector();
  const [tab, setTab] = useState(tabFromURLParam(kind));

  const [isInteractionEnabled, setInteractionEnabled] = useState<boolean>(true);

  useEffect(() => {
    history.push(locationFromTab(tab));
  }, [history, tab]);

  const {
    //externalSubscribersQueryState,
    subscribersQueryState,
    isSearchActive,
    onSearch,
  } = useData({
    instituteId: currentInstituteId,
    tab,
  });

  const onTabChange = useCallback((_, newValue: SubscriptionTab) => {
    //reset selected categories first
    onSubSelect(0);
    setTab(newValue);
    console.log(
      `tabChanged, subSelected:${subSelected} selectectedCat:${getSubName(
        subSelected,
      )}, subs.valid?:${subs !== undefined}`,
    );
  }, []);

  const onSubscriptionSelection = useCallback(
    (customerId: number) => {
      const location = generatePath(paths.subscriptions.update, {
        id: customerId,
      });
      history.push(location);
    },
    [history],
  );

  const onInteractionEnabled = useCallback((enabled: boolean) => {
    setInteractionEnabled(enabled);
  }, []);

  const isContentEmpty = subscribersQueryState.data?.length === 0; //&& !externalSubscribersQueryState.data;

  const getEmptyContentMessage = () => {
    console.log(`reload getEmptyContentMessage getEmptyContentMessage`);
    if (isSearchActive) {
      return t(messages.emptySearchLabel());
    } else {
      var tabKey = messages.tabs.pendings;
      if (tab === SubscriptionTab.PENDING) {
        tabKey = messages.tabs.pendings;
      } else if (tab === SubscriptionTab.SUBSCRIBERS) {
        tabKey = messages.tabs.subscriptions;
      } else {
        tabKey = messages.tabs.oldBd;
      }
      return t(tabKey.emptyLabel());
    }
  };

  const searchEnabled = tab !== SubscriptionTab.OLDDB;
  const filterByCategoryEnabled = tab !== SubscriptionTab.PENDING && tab !== SubscriptionTab.OLDDB;
  const isOldDBTabSelected = tab === SubscriptionTab.OLDDB;
  const [subs, setSubs] = useState<Option[]>();
  const [subSelected, setSubSelected] = useState(0);
  var subscriptionsMapNamesAndAbreviations: Option[] = [
    { label: 'Abonnements actifs', value: 'AA' },
    { label: 'Abonnements en rejet', value: 'R' },
    { label: 'Abonnements résiliés', value: 'X' },
    { label: 'Supplément skin', value: 'SSK' },
    { label: 'Prélèvement jeune + supp. skin', value: 'PJSSK' },
    { label: 'Anticipation jeune + supp. skin', value: 'AJSSK' },
    { label: 'Prélévement adulte', value: 'PA' },
    { label: 'Prélévement jeune', value: 'PJ' },
    { label: 'Passage', value: 'P' },
    { label: 'Anticipation adulte', value: '' },
    { label: 'Anticipation jeune', value: 'AJ' },
    { label: 'CE', value: '' },
    { label: 'VIP', value: '' },
    { label: 'Prélévement skin', value: 'PS' },
    { label: 'Antip skin', value: 'AS' },
  ];

  useEffect(() => {
    fetchSubscriptionTypesRequest().then(data => {
      var tmpSub: Option[] = [
        { value: String('0'), label: subscriptionsMapNamesAndAbreviations[0].label/* + ' ' + subscriptionsMapNamesAndAbreviations[0].value*/ },
        { value: String('1'), label: subscriptionsMapNamesAndAbreviations[1].label/* + ' ' + subscriptionsMapNamesAndAbreviations[1].value*/ },
        { value: String('2'), label: subscriptionsMapNamesAndAbreviations[2].label/* + ' ' + subscriptionsMapNamesAndAbreviations[2].value*/ },
        { value: String('3'), label: subscriptionsMapNamesAndAbreviations[3].label/* + ' ' + subscriptionsMapNamesAndAbreviations[3].value*/ },
        { value: String('4'), label: subscriptionsMapNamesAndAbreviations[4].label/* + ' ' + subscriptionsMapNamesAndAbreviations[4].value*/ },
        { value: String('5'), label: subscriptionsMapNamesAndAbreviations[5].label/* + ' ' + subscriptionsMapNamesAndAbreviations[5].value*/ },
      ];
      // eslint-disable-next-line array-callback-return
      /*
      data.map(sub => {
        let subId = String(sub.id);
        let subName = sub.name;
        console.log(`subId1:${subId}, subName1:${subName}`);
        if (subName === 'SKIN') {
          tmpSub.push({ value: subId, label: 'Prélévement skin PS' });
        } else if (subName !== 'prélévement adulte') {
          tmpSub.push({ value: subId, label: capitalizeFirstLetter(subName) });
        }
      });*/
      /*
      data.map(sub => {
        let subId = String(sub.id + 5);
        let subName = sub.name;
        console.log(`subId1:${subId}, subName1:${subName}`);
        if (subId === '6') { // prélévement adulte
          tmpSub.push({ value:  subId, label: subscriptionsMapNames[6] + ' ' + subscriptionsMapAbreviations[6] });
        } else if (subId === '7') { //prélévement jeune
          tmpSub.push({ value:  subId, label: subscriptionsMapNames[7] + ' ' + subscriptionsMapAbreviations[7] });
        } else if (subId === '8') { //passage
          tmpSub.push({ value: subId, label: subscriptionsMapNames[8] + ' ' + subscriptionsMapAbreviations[8] });
        } else if (subId === '9') { //anticipation adulte
          tmpSub.push({ value: subId, label: subscriptionsMapNames[9] + ' ' + subscriptionsMapAbreviations[9] });
        } else if (subId === '10') { //Anticipation jeune
          tmpSub.push({ value: subId, label: subscriptionsMapNames[10] + ' ' + subscriptionsMapAbreviations[10] });
        } else if (subId === '11') { //CE
          tmpSub.push({ value: subId, label: subscriptionsMapNames[11] + ' ' + subscriptionsMapAbreviations[11] });
        } else if (subId === '12') { //VIP
          tmpSub.push({ value: subId, label: subscriptionsMapNames[12] + ' ' + subscriptionsMapAbreviations[12] });
        } else if (subId === '13') { //skin
          tmpSub.push({ value: subId, label: subscriptionsMapNames[13] + ' ' + subscriptionsMapAbreviations[13] });
        } else if (subId === '14') { //Antip SKIN
          tmpSub.push({ value: subId, label: subscriptionsMapNames[14] + ' ' + subscriptionsMapAbreviations[14] });
        }
      });*/
      data.map(sub => {
        let subId = sub.id + 5;
        tmpSub.push({ value:  String(subId), label: subscriptionsMapNamesAndAbreviations[subId].label/* + ' ' + subscriptionsMapNamesAndAbreviations[subId].value*/ });
      });
      setSubs(tmpSub);
    });
  }, []);

  const getSubName = (value: number) => {
    console.log(`getSubName value?:${value}`);
    if (subs === undefined) {
      return undefined;
    } else if (tab === SubscriptionTab.PENDING) {
      return 'tous les abonements en attente';
    } else {
      return subscriptionsMapNamesAndAbreviations[value].label;
      /*
      let category = subs?.filter(sub => {
        return sub.value === String(value);
      });
      if (category === undefined || category![0] === undefined) {
        //console.log(`value:${value}, name:${name}`);
        return undefined;
      }
      //console.log(`value:${value}, name:${name![0].label}`);
      return category![0].label;
      */
    }
  };

  const onSubSelect = (option) => {
    // setCategorieSelected(Number(option.value))
    console.log('onSubSelect : ' + option?.value);
    if (option.value === undefined) {
      setSubSelected(option);
    } else {
      setSubSelected(option.value);
    }
  };

  const capitalizeFirstLetter = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const componentRef = useRef<HTMLDivElement>(null);

  const getSubscribersAccordingToSelectedFilters = (subSelected: number) => {
    let res = subscribersQueryState.data
      ?.filter(sub => {
        var showSub = false;
        let end = sub.subscriptions[0].subscriptionDates.endDate ?? undefined;
        var currentDate = new Date();
        var endDate = end !== undefined ? new Date(end!) : undefined;

        const expired = endDate !== undefined ? currentDate > endDate : false;
        let rejection = sub.subscriptions[0].rejection;
        const hasSuppSkin =  sub.subscriptions[0].subscriptionType.skin !== undefined ? sub.subscriptions[0].subscriptionType.skin : false;

        /*
        console.log(
          `list, subSelected:${subSelected}, subSelectedName:${getSubName(subSelected)}
          , date_debut: ${start}, 
          date_fin: ${endDate},
          currentDate:${currentDate}, startDate:${startDate},
          expired:${expired}`,
        );
        */
        if (String(subSelected) == '0') {
          showSub = !rejection && !expired;//&& !sub.subscriptions[0].resiliate && sub.subscriptions[0].status;
          //showSub = true;
        } else if (String(subSelected) == '1') {
          let curCat = rejection;
          showSub = curCat ?? false;
        } else if (String(subSelected) == '2') {
          showSub = expired;//sub.subscriptions[0].resiliate;
        } else if (String(subSelected) == '3') {
          showSub = sub.subscriptions[0].subscriptionType.id === 3 && !rejection && !expired && hasSuppSkin;
        } else if (String(subSelected) == '4') {
          showSub = sub.subscriptions[0].subscriptionType.id === 2 && !rejection && !expired  && hasSuppSkin;
        } else if (String(subSelected) == '5') {
          showSub = sub.subscriptions[0].subscriptionType.id === 5 && !rejection && !expired  && hasSuppSkin;
        } else {
          let curCat = sub.subscriptions[0].subscriptionType.id !== undefined ? sub.subscriptions[0].subscriptionType.id + 5 : sub.subscriptions[0].subscriptionType.id;
          showSub = curCat == subSelected ? true : false;
          console.log(`type de prelev, curCat:${curCat}, subSelected:${subSelected}`);
          if (rejection === true || expired) {
            return false;
          }
        }
        return showSub;
      })
      .sort((a, b) => {
        return a.lastname > b.lastname ? 1 : -1;
      });
    return res;
  };

  return (
    <>
      <Helmet>
        <title>
          {t(messages.pageTitle())} : {institute.name}
        </title>
      </Helmet>
      <PageWrapper
        disabled={!isInteractionEnabled}
        header={
          <Header
            isLoading={subscribersQueryState.isLoading}
            label={t(messages.pageTitle()) + ' : ' + institute.name}
            onSearch={onSearch}
            onTabChange={onTabChange}
            searchEnabled={searchEnabled}
            tab={tab}
          />
        }
      >
        <PrintButtonRow>
          <ReactToPrint
            trigger={() => <PrintButton type="submit" value="Imprimer" />}
            content={() => componentRef.current}
          />
        </PrintButtonRow>

        <div ref={componentRef}>
          <Wrapper2>
            {getSubName(subSelected) !== undefined
              ? `Nombre de: ${getSubName(subSelected)}: ${
                  getSubscribersAccordingToSelectedFilters(subSelected)?.length ?? 0
                }`
              : undefined}
          </Wrapper2>

          {!isOldDBTabSelected && (
            <LoadableContent
              error={subscribersQueryState.error}
              isLoading={subscribersQueryState.isLoading}
              isEmpty={isContentEmpty}
              emptyNodeRenderer={() => (
                <EmptyState label={getEmptyContentMessage()} />
              )}
            >
              {filterByCategoryEnabled && (
                <DropdownWrapper>
                  <Dropdown
                    options={subs || []}
                    onChange={onSubSelect.bind(this)}
                    placeholder={
                      subs !== undefined
                        ? subs[0].label
                        : 'Sélectionnez une catégorie'
                    }
                  />
                </DropdownWrapper>
              )}

              <List>
                {getSubscribersAccordingToSelectedFilters(subSelected) &&
                  (getSubscribersAccordingToSelectedFilters(subSelected)!.map(
                    subscriber => (
                      <SubscriptionItem
                        customer={subscriber}
                        isPending={
                          tab === SubscriptionTab.PENDING || subscriber.pending
                        }
                        key={subscriber.id}
                        onClick={() => onSubscriptionSelection(subscriber.id)}
                      />
                )))
                }
                {/*
                <ExternalSubscriptions
                  externalSubscribers={externalSubscribersQueryState.data}
                  instituteId={currentInstituteId}
                  onInteractionEnabled={onInteractionEnabled}
                  onSubscriptionSelection={onSubscriptionSelection}
              />*/}
              </List>
              <ScrollToTop />
            </LoadableContent>
          )}

          {isOldDBTabSelected && <OldDBView></OldDBView>}
        </div>
      </PageWrapper>
    </>
  );
};

export const fetchSubscriptionTypesRequest = () =>
  request<[Subscription.TypeList]>(`api/subcription_types`, {
    method: 'GET',
  });

const DropdownWrapper = style.div`
  margin-top: 20px;
  margin-bottom: 20px;

  > *a > * > * {
    color: blue;
  }

  > *a > * > * > .menuClassName {
    color: red;
    }

  > *a > * > * > .menuClassName {
    color: green;
  }

  > *a > * > .className {
    color: yellow;
  }

  > *a > * > .placeholderClassName {
    color: orange;
  }

  > *a > * > .baseClassNamel {
    color: green;
  }
}



`;

const PrintButtonRow = style.div`
  position: absolute;
  top: 70px;
  padding-right: -30px;
  left: -30px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

const PrintButton = style.input`
  z-index: auto;
  background: #e462a1;
  border: none;
  padding: 20px;
  border-radius: 20px;
  color: white;
`;

const Wrapper2 = style.div`
  margin-left: auto;
  color: red;
  background-color1: green;
  align-items1: center;
  color: ${p => p.theme.text.title};
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.9rem;
  text-align: right;
`;
/*
const Wrapper = style.DropdownWrapper`
  display: flex;
  flex-direction: column;

  > .disconnect-button {
    margin-top: 1.5rem;
  }
  > * {
    margin-bottom: 1rem;
  }
`;
*/