export namespace Objectives {
  export type Metric = 'total_sales' /* | 'products_sales' | 'services_count'*/;

  export const Metrics = {
    TOTAL_SALES: 'total_sales' as Metric,
  };

  export const metrics = [
    Metrics.TOTAL_SALES,
    // Metrics.PRODUCTS_SALES,
    // Metrics.SERVICES_COUNT,
  ];

  export type Monthly = Daily[];

  export interface Daily {
    total_sales: number;
  }
}
