import { BackdropProps, Dialog } from '@material-ui/core';
import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

import { Content } from './Content';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalMaxWidth } from './types';

export interface CommonModalProps {
  closeModal: () => void;
}

interface Props extends CommonModalProps {
  BackdropProps?: Partial<BackdropProps>;
  children: (props: any) => ReactNode;
  fullWidth?: boolean;
  maxWidth?: ModalMaxWidth;
  onClose?: () => void;
}

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 15px;
    box-shadow: 0px 16px 45px rgba(0, 0, 0, 0.1);
    background-color: ${p => p.theme.pageBackground};
    padding: 1rem;
  }
  & .MuiDialogContent-root {
    overflow: hidden;
  }
`;

export const Modal = ({ children, closeModal, ...rest }: Props) => (
  <StyledDialog open={true} {...rest}>
    {children({ Content, Footer, Header })}
  </StyledDialog>
);
