import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectInputOption } from 'app/components/Inputs/SelectInput';
import { Institute } from 'types/API/institutes';
import { entityEndpoint } from 'utils/entities/identifier';
import { User } from 'types/API/users';

import { messages } from '../messages';
import { first } from 'lodash';
import { useRecoilValue } from 'recoil';
import { meState } from 'app/atoms';

export const useFormData = ({
  institutes
}: {
    institutes: Institute.Normal[];
}) => {
  const { t } = useTranslation();

  const institutesOptions: SelectInputOption[] = useMemo(
    () =>
      institutes.map(institute => ({
        id: entityEndpoint({ id: institute.id, module: 'institutes' }),
        label: institute.name,
      })),
    [institutes],
  );

  const availableRoles: User.Role[] = useMemo(
    () => {
      // const me = useRecoilValue(meState);
      // if (first(me?.roles) === User.Roles.ROLE_SUPER_ADMIN) {
        return [User.Roles.ROLE_USER, User.Roles.ROLE_SUPER_USER, User.Roles.ROLE_COMPTA, User.Roles.ROLE_ANIMATRICE, User.Roles.ROLE_FORMATRICE] //User.Roles.ROLE_SUBSCRIPTIONS
      // } else {
        // return [User.Roles.ROLE_USER, User.Roles.ROLE_SUPER_USER]
      // } 
    },
    [],
  );

  const rolesOptions: SelectInputOption[] = useMemo(
    () =>
      availableRoles.map(role => ({
        id: role,
        label: t(messages.role.options(role)),
      })),
    [availableRoles, t],
  );

  return {
    institutesOptions,
    rolesOptions,
  };
};
