import {
  CircularProgressProps,
  CircularProgress as MUICircularProgress,
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

interface Props extends CircularProgressProps {}

export const CircularProgress = (props: Props) => (
  <InternalCircularProgress {...props} />
);

const InternalCircularProgress = styled(MUICircularProgress)`
  left: 50%;
  margin-left: -12px;
  margin-top: -12px;
  position: absolute;
  top: 50%;
`;
