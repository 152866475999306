import { useCallback } from 'react';

import { Optional } from 'types/misc';

import { FormValues } from './useForm/types';
import { Institute } from 'types/API/institutes';
import { omit } from 'lodash';

export const useTransformData = () => {
  const getFormValues = useCallback(
    (institute: Optional<Institute.Normal>): Optional<FormValues> => {
      if (!institute) {
        return undefined;
      }
      return omit(institute, ['caisses', 'country', 'type']);
    },
    [],
  );

  const getUpdatePayload = useCallback(
    ({ formValues }: { formValues: FormValues }): Institute.Payload => {
      return formValues;
    },
    [],
  );

  return {
    getFormValues,
    getUpdatePayload,
  };
};
