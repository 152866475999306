import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

type CustomInfoEntryProps = PropsWithChildren<{
  label: string;
}>;

const CustomInfoEntry = ({ children, label }: CustomInfoEntryProps) => (
  <EntryWrapper>
    <Label>{label}</Label>
    {children}
  </EntryWrapper>
);

interface InfoEntryProps {
  label: string;
  value: string;
}

export const InfoEntry = ({ label, value }: InfoEntryProps) => (
  <CustomInfoEntry label={label}>
    <ValueLabel
      dangerouslySetInnerHTML={{
        __html: value,
      }}
    />
  </CustomInfoEntry>
);


const CustomInfoEntryHorizontal = ({ children, label }: CustomInfoEntryProps) => (
  <EntryWrapperHorizontal>
    <LabelHorizontal>{label}</LabelHorizontal>
    {children}
  </EntryWrapperHorizontal>
);

export const InfoEntryHorizontal = ({ label, value }: InfoEntryProps) => (
  <CustomInfoEntryHorizontal label={label}>
    <ValueLabelHorizontal
      dangerouslySetInnerHTML={{
        __html: value,
      }}
    />
  </CustomInfoEntryHorizontal>
);

const Label = styled.span`
  font-family: 'Gabriel Sans Cond';
  font-weight: 400;
  font-size: 0.75rem;
  color: ${p => p.theme.text.body};
`;

const ValueLabel = styled.span`
  font-family: 'Gabriel Sans Cond';
  font-weight: 700;
  font-size: 0.75rem;
  > span {
    font-weight: 400;
    font-size: 0.75rem;
    color: ${p => p.theme.text.body};
  }
`;

const EntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
`;

export const EntriesWrapper = styled.div`
  display: flex;
  flex-direction: row;

  > div:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export const InfoEntrySeparator = styled.span`
  width: 1px;
  background-color: ${p => p.theme.text.body};
  margin-right: 0.5rem;
`;



const LabelHorizontal = styled.span`
  font-family: 'Gabriel Sans Cond';
  font-weight: 400;
  font-size: 0.75rem;
  color: ${p => p.theme.text.body};
  margin-right: 1rem;
`;

const ValueLabelHorizontal = styled.span`
  font-family: 'Gabriel Sans Cond';
  font-weight: 700;
  font-size: 0.75rem;
  width: 100%;
  margin-right: 10px;

  > span {
    font-weight: 400;
    font-size: 0.75rem;
    color: ${p => p.theme.text.body};
  }
`;

const EntryWrapperHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 5px;
  text-wrap: nowrap;
  text-align: end;
  // align-items: center;
`;