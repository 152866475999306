import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { messages } from 'app/i18n/messages';

export const useFormValidation = ({
  isPasswordUpdateEnabled,
}: {
  isPasswordUpdateEnabled: boolean;
}) => {
  const { t } = useTranslation();

  const schema = useMemo(() => {
    let definition: any = {
      firstname: yup.string().required(t(messages.forms.requiredFieldError())),
      lastname: yup.string().required(t(messages.forms.requiredFieldError())),
      institute: yup
        .array(yup.string())
        .min(1, t(messages.forms.requiredFieldError()))
        .required(t(messages.forms.requiredFieldError())),
    };

    if (isPasswordUpdateEnabled) {
      definition = {
        updatePassword: yup.boolean(),
        password: yup.string().when('updatePassword', {
          is: true,
          then: yup.string().required(t(messages.forms.requiredFieldError())),
          otherwise: yup.string().optional(),
        }),
        ...definition,
      };
    }
    return yup.object(definition);
  }, [t, isPasswordUpdateEnabled]);

  return { schema };
};
