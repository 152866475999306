import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  pageTitle: () => _t(translations.orders.page_title),
  add: () => _t(translations.orders.add),
  id: () => _t(translations.orders.id),
  date: () => _t(translations.orders.date),
  price: () => _t(translations.orders.price),
  salePrice: () => _t(translations.orders.sale_price),
  margin: () => _t(translations.orders.margin),
  emptyLabel: () => _t(translations.orders.empty_label),
  dateFormat: () => _t(translations.orders.date_format),
  status: {
    closed: () => _t(translations.orders.status.closed),
    pending: () => _t(translations.orders.status.pending),
  },
};
