export enum ReceiptTab {
  PENDING = 0,
  PAID,
  PAID_PRODUCTS,
}

export enum ReceiptKind {
  PENDING = 'pending',
  PAID = 'paid',
  PAID_PRODUCTS = 'paid-products',
}
