import { Route } from 'types/route';
import { paths } from '../../paths';

import { UserPage } from 'app/pages/Users/UserPage';
import { UsersPage } from 'app/pages/Users/UsersPage';

const users: Route = {
  authenticated: true,
  content: UsersPage,
  routerPath: paths.users.list,
};

const createUser: Route = {
  authenticated: true,
  content: UserPage,
  routerPath: paths.users.create,
};

const updateUser: Route = {
  authenticated: true,
  content: UserPage,
  routerPath: paths.users.update,
};

export const routes = [createUser, updateUser, users];
