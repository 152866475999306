import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { LoadableContent } from 'app/components/LoadableContent';
import { request } from 'utils/request';
import { EmptyState } from 'app/components/EmptyState';
import { makeStyles } from '@material-ui/core/styles';



export const ManualPage = () => {

  return (
    <>     <Helmet>
      <title>Manual</title>
    </Helmet>
      <PageWrapper
        header={<PageHeader label={"Manuels"} />}>
        
        <p>Vous trouverez ci-dessous des lien pour ouvrir les manuels utilisateurs :</p>
        <RowButton>
          <a href='https://backrec.institut-bodyminute.com/portail.pdf' target='_blank' rel='noopener noreferrer'>
            <ManualButton>Portail</ManualButton>
          </a>

          <a href='https://backrec.institut-bodyminute.com/caisse.pdf' target='_blank' rel='noopener noreferrer'><ManualButton>Caisse</ManualButton></a>
        </RowButton>
        

        {/* <LoadableContent isLoading={loading}
          emptyNodeRenderer={() => (
            <EmptyState label="Liste vide" />
          )}>
          {data &&
            <div>
              <p>Liste des questions fréquement posées:</p>
              <div>
                {data.map(faq => (
                  <div className={classes.cell}>
                    <div className={classes.question}>{faq.question}?</div>
                    <div>{faq.response}</div>
                  </div>
                ))
                }
              </div>
            </div>
          }
        </LoadableContent> */}
      </PageWrapper></>
  );
}

const RowButton = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  flex-grow: 1;
`;

const ManualButton = styled.a`
  background: #e462a1;
  border: none;
  padding: 20px;
  border-radius: 20px;
  color: white;
  margin: 30px;

`;


/*
import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { LoadableContent } from 'app/components/LoadableContent';
import { EmptyState } from 'app/components/EmptyState';
import { messages } from './messages';
import { Header } from './components/Header';
import { useData } from './hooks';
import { locationFromTab, tabFromURLParam } from './utils';
import { ManualsTab } from './types';
import { List } from 'app/components/List';
import { ManualItem } from './components/ManualItem';
import ScrollToTop from 'app/components/ScrollToTop/ScrollToTop';


export const ManualPage = () => {
  const { kind }: { kind?: string } = useParams();
  const [tab, setTab] = useState(tabFromURLParam(kind));
  const [ManualSelected, setManualSelected] = useState(0);
  const history = useHistory();
  const { t } = useTranslation();
  const {
    manualQueryState,
    isSearchActive,
    onSearch,
  } = useData({
    tab,
  });


  useEffect(() => {
    history.push(locationFromTab(tab));
  }, [history, tab]);

  const onTabChange = useCallback((_, newValue: ManualsTab) => {
    onSubSelect(0);
    setTab(newValue);
  }, []);


  const onSubSelect = (option) => {
    if (option.value === undefined) {
      setManualSelected(option);
    } else {
      setManualSelected(option.value);
    }
  };


  const getSubName = (value: number) => {
    if (tab === ManualsTab.PORTAL) {
      return "tous les Portails";
    } else {
      return "tous les Caisses";
    }
  };

  const getEmptyContentMessage = () => {
    if (isSearchActive) {
      return t(messages.emptySearchLabel());
    } else {
      const tabKey =
        tab === ManualsTab.PORTAL
          ? messages.tabs.portal
          : messages.tabs.box;
      return t(tabKey.emptyLabel());
    }
  };

  const getManualAccordingToSelectedFilters = () => {
    let res = manualQueryState.data?.sort((a, b) => {
      return a.createdAt > b.createdAt ? -1 : 1;
    })
    return res;
  };


  const openURLInNewWindow = (url: string) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.opener = null; // Prevent the opened window from having a reference to the current window
    } else {
      alert('Pop-up blocked. Please allow pop-ups for this site and try again.');
    }
  };


  return (
    <>      <Helmet>
      <title>
        {t(messages.pageTitle())}
      </title>
    </Helmet>
      <PageWrapper
        header={
          <Header
            isLoading={manualQueryState.isLoading}
            label={t(messages.pageTitle())}
            onSearch={onSearch}
            onTabChange={onTabChange}
            searchEnabled={true}
            tab={tab}
          />
        }

      >
        <Wrapper2>
          {getSubName(ManualSelected) !== undefined
            ? `Nombre de ${getSubName(ManualSelected)}: ${getManualAccordingToSelectedFilters()?.length ?? 0
            }`
            : undefined}
        </Wrapper2>
        <LoadableContent
          error={manualQueryState.error}
          isLoading={manualQueryState.isLoading}
          emptyNodeRenderer={() => (
            <EmptyState label={getEmptyContentMessage()} />
          )}
        >
          <List>
            {getManualAccordingToSelectedFilters() &&
              (getManualAccordingToSelectedFilters()!.map(
                (manual, index) => (
                  <ManualItem
                    manual={manual}
                    isPending={
                      tab === ManualsTab.PORTAL
                    }
                    key={index}
                    onClick={() => openURLInNewWindow(manual[`${t(messages.urlLang())}`])
                    }
                  />
                )))
            }
          </List>
          <ScrollToTop />
        </LoadableContent>
      </PageWrapper >
    </>
  );
}


const Wrapper2 = styled.div`
  margin-left: auto;
  color: red;
  background-color1: green;
  align-items1: center;
  color: ${p => p.theme.text.title};
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.9rem;
  text-align: right;
`;
*/