import { useQuery } from 'app/hooks';
import { Month } from 'types/month';
import moment from 'moment';
import { genericQueryKey } from 'utils/cache/keys';
import { Objectives } from 'types/API/objectives';
import { sleep } from 'utils/time';

/// [START TMP]

const randomFromInterval = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const fetchObjectivesRequest = ({
  instituteId,
  month: formattedMonth,
}: {
  instituteId: number;
  month: string;
}): Promise<Objectives.Monthly> =>
  new Promise(async (resolve, reject) => {
    await sleep(0);
    const month = Month.fromDate(moment(formattedMonth, 'YYYY-MM').toDate());
    const hasResult = month.index <= Month.current().index;
    let values: Objectives.Monthly = [];
    if (hasResult) {
      values = month.days().map(date => {
        if (date.getDay() === 0) {
          return { total_sales: 0 };
        }
        const total_sales = randomFromInterval(700, 1600);

        return { total_sales };
      });
    }
    resolve(values);
  });
/// [END TMP]

export const useQueries = ({
  instituteId,
  month,
}: {
  instituteId: number;
  month: Month;
}) => {
  const parameters = { instituteId, month: month.toString() };
  const {
    data: objectives,
    error,
    isLoading,
  } = useQuery(
    genericQueryKey({
      identifier: 'objectives',
      parameters: parameters,
    }),
    () => fetchObjectivesRequest(parameters),
    // { enabled: !!customerId },
  );

  return { objectives, error, isLoading };
};
