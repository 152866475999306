import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

import { CircularProgress } from '../CircularProgress';

interface ButtonProps extends MUIButtonProps {
  isSpinning?: boolean;
}

interface ButtonContainerProps {
  isVisible: boolean;
}

export const Button = (props: ButtonProps) => {
  const {
    color = 'secondary',
    children,
    disabled,
    isSpinning,
    variant = 'contained',
    ...rest
  } = props;

  return (
    <StyledButton
      color={color}
      disabled={!!isSpinning || !!disabled}
      variant={variant}
      {...rest}
      style={{ borderRadius: 50 }}
    >
      <ButtonContainer isVisible={!isSpinning}>{children}</ButtonContainer>
      {isSpinning && <CircularProgress size={24} color="secondary" />}
    </StyledButton>
  );
};

const StyledButton = styled(MUIButton)`
  &.MuiButton-contained {
    padding: 0.75rem 4rem;
    font-family: 'Gabriel Sans Cond';
    font-weight: 500;
    text-transform: inherit;
  }
  &.MuiButton-outlined {
    padding: 0.75rem 4rem;
    font-family: 'Gabriel Sans Cond';
    font-weight: 500;
    text-transform: inherit;
  }
  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.4;
  }
`;

// eslint-disable-next-line prettier/prettier
const ButtonContainer = styled('div')<ButtonContainerProps>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;
