import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'app/components/Button';
import { Modal } from 'app/components/Modal';
import { Objectives } from 'types/API/objectives';
import { useInstituteSelector } from 'app/atoms/selectors';
import { FormWrapper } from 'app/components/Forms/FormWrapper';
import { getCurrencySymbol } from 'utils/currency';

import { Form } from './Form';
import { messages } from './messages';
import { useForm } from './hooks';

interface Props {
  close: () => void;
  validate: (objectives: any) => void;
}

export const ObjectivesWeeklyModal = (props: Props) => {
  const { close, validate } = props;

  const { t } = useTranslation();

  const { institute } = useInstituteSelector();
  const currency = institute.country.currency;

  const metricInfos = useMemo(
    () =>
      Objectives.metrics.map(metric => ({
        metric,
        label: t(messages.metrics[metric](), {
          currency: getCurrencySymbol(currency),
        }),
      })),
    [currency, t],
  );

  const { methods, onSubmit: onFormSubmit } = useForm(validate);

  return (
    <Modal closeModal={close} fullWidth maxWidth="md" onClose={close}>
      {({ Content, Footer, Header }) => (
        <FormWrapper methods={methods} onSubmit={onFormSubmit}>
          <Header title={t(messages.modalTitle())} />

          <Content>
            {/* <StyledDialogContentText>{description}</StyledDialogContentText> */}
            <Form metricInfos={metricInfos} />
          </Content>

          <Footer>
            <Button color="secondary" type="submit">
              {t(messages.submit())}
            </Button>
          </Footer>
        </FormWrapper>
      )}
    </Modal>
  );
};
