import { useTranslation } from 'react-i18next';
import React, { PropsWithChildren } from 'react';

import { Confirmation } from 'app/components/Modals/Confirmation';
import { ModalLoader } from 'app/hoc/ModalLoader';
import { useMutationOptions } from 'app/hooks/modal/useMutationsOptions';

import { UserModalStates } from '../../hooks/useModals';
import { messages } from '../../messages';
import { useMutations } from '../../hooks';

type Props = PropsWithChildren<
  UserModalStates & {
    instituteId: number;
  }
>;

export const ModalsProvider = (props: Props) => {
  const { children, deleteConfirmation, instituteId } = props;

  const { t } = useTranslation();

  const { deleteMutation, isDeleting } = useMutations(instituteId);

  const mutationOptions = useMutationOptions({
    successCallback: () => {
      deleteConfirmation.toggleOpenState();
    },
    successMessage: t(messages.deleteAlertSuccessDescription()),
  });

  const renderDeleteUser = () => (
    <ModalLoader shouldRender={deleteConfirmation.open}>
      <Confirmation
        cancelAction={deleteConfirmation.toggleOpenState}
        confirmAction={() => {
          if (deleteConfirmation.context) {
            deleteMutation(deleteConfirmation.context, mutationOptions);
          }
        }}
        description={t(messages.deleteConfirmationDescritption())}
        isConfirmActionLoading={isDeleting}
        title={t(messages.deleteConfirmationTitle())}
      />
    </ModalLoader>
  );

  return (
    <>
      {children}
      {renderDeleteUser()}
    </>
  );
};
