import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { Checkbox } from 'app/components/Inputs/Checkbox';
import { fieldName } from 'utils/form';

import { messages } from '../../../messages';

export const Institute = () => {
  const { t } = useTranslation();
  const namePrefix = 'customer';

  return (
    <Grid container spacing={4}>
      <Grid item md={4}>
        <FormInputGrid item sm={12}>
          <Checkbox
            label={t(messages.noMailing())}
            name={fieldName('noMailing', namePrefix)}
          />
        </FormInputGrid>
        <FormInputGrid item sm={12}>
          <Checkbox
            label={t(messages.noSMS())}
            name={fieldName('noSms', namePrefix)}
          />
        </FormInputGrid>
      </Grid>
    </Grid>
  );
};