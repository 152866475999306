import { useRecoilState, useRecoilValue } from 'recoil';

import { institutesState, meState } from 'app/atoms';

export const useSelectors = () => {
  const [institutes, setInstitutes] = useRecoilState(institutesState);
  const me = useRecoilValue(meState);

  const setInstitute = (instituteId: number) => {
    let newState = { ...institutes };
    newState.currentInstituteId = instituteId;
    setInstitutes(newState);
  };

  return {
    currentInstituteId: institutes?.currentInstituteId ?? -1,
    institutes: institutes.userInstitutes,
    me,
    setInstitute,
  };
};
