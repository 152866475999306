import React, { PropsWithChildren } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';

import styled from 'styled-components/macro';
import { useMemo } from 'react';

interface Mapping {
  lg: number;
  md: number;
  sm: number;
  xs: number;
}

interface DefaultProps {
  fontSize: number;
  mapping?: Mapping;
}

type Props = PropsWithChildren<DefaultProps>;

export const DynamicLabel = (props: Props) => {
  const defaultMapping = useMemo(
    () => ({
      lg: 1,
      md: 1,
      sm: 0.8,
      xs: 0.6,
    }),
    [],
  );

  const { children, fontSize, mapping = defaultMapping, ...rest } = props;

  const theme = useTheme();
  const lgAndAbove = useMediaQuery(theme.breakpoints.up('lg'), {
    noSsr: true,
  });
  const mdAndAbove = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const smAndAbove = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const xsAndAbove = useMediaQuery(theme.breakpoints.up('xs'), {
    noSsr: true,
  });

  let ratio = 1;

  if (lgAndAbove) {
    ratio = mapping.lg;
  } else if (mdAndAbove) {
    ratio = mapping.md;
  } else if (smAndAbove) {
    ratio = mapping.sm;
  } else if (xsAndAbove) {
    ratio = mapping.xs;
  }

  const scaledFontSize = (fontSize * ratio).roundOff(3);
  return (
    <Label $fontSize={scaledFontSize.roundOff(3)} {...rest}>
      {children}
    </Label>
  );
};

interface LabelProps {
  $fontSize: number;
}

const Label = styled.span<LabelProps>`
  font-size: ${p => `${p.$fontSize}rem`};
`;
