import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { TextInput } from 'app/components/Inputs/TextInput';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { Switch } from 'app/components/Inputs/Switch';
import { Disableable } from 'app/components/Disableable';

import { messages } from '../../../../messages';

interface Props {
  disabled: boolean;
  isPasswordUpdateEnabled: boolean;
  isPasswordUpdateOptional: boolean;
}

export const General = (props: Props) => {
  const { disabled, isPasswordUpdateEnabled, isPasswordUpdateOptional } = props;
  const { t } = useTranslation();

  const { watch } = useFormContext();

  const shouldUpdatePassword = watch('updatePassword');

  return (
    <Grid container spacing={1}>
      <FormInputGrid item sm={12}>
        <FormLabel>{t(messages.firstname.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name="firstname"
          placeholder={t(messages.firstname.placeholder())}
          required
        />
      </FormInputGrid>
      <FormInputGrid item sm={12}>
        <FormLabel>{t(messages.lastname.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name="lastname"
          placeholder={t(messages.lastname.placeholder())}
          required
        />
      </FormInputGrid>

      <Disableable enabled={isPasswordUpdateEnabled}>
        <Disableable enabled={isPasswordUpdateOptional}>
          <FormInputGrid item sm={12}>
            <Switch
              label={t(messages.updatePassword())}
              name="updatePassword"
            />
          </FormInputGrid>
        </Disableable>
        <Disableable enabled={shouldUpdatePassword}>
          <FormInputGrid item sm={12}>
            <FormLabel>{t(messages.password.label())}</FormLabel>
            <TextInput
              disabled={disabled}
              name="password"
              placeholder={t(messages.password.placeholder())}
              required
              type="password"
            />
          </FormInputGrid>
        </Disableable>
      </Disableable>
    </Grid>
  );
};
