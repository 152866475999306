import React from 'react';
import { useTranslation } from 'react-i18next';

import { SalesSummary } from 'app/components/SalesSummary';

import { messages } from '../../messages';

interface Props {
  currency: string;
  debitsAmount: number;
  debitsCount: number;
  nbAdult: number;
  nbJeune: number;
  nbSkin: number;
}

export const Summary = (props: Props) => {
  const { currency, debitsAmount, debitsCount, nbAdult, nbJeune, nbSkin } = props;

  const { t } = useTranslation();

  const title = t(messages.receiptCount(), { count: debitsCount });
  const total = {
    label: t(messages.total()),
    price: debitsAmount,
  };

  return <SalesSummary currency={currency} title={title} total={total} nbAdult={nbAdult} nbJeune={nbJeune} nbSkin={nbSkin} />;
};