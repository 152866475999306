import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Use consistent styling
import './index.css';
import 'sanitize.css/sanitize.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import reportWebVitals from 'reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';

import { App } from 'app';
import { ThemeProvider } from 'styles/theme/ThemeProvider';

// Type extensions
import 'utils/extensions';

// Initialize languages
import './locales/i18n';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <ThemeProvider>
    <HelmetProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </HelmetProvider>
  </ThemeProvider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// on(event: 'languageChanged', callback: (lng: string) => void): void;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
