import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { TextInput } from 'app/components/Inputs/TextInput';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { SelectInput } from 'app/components/Inputs/SelectInput';

import { messages } from '../../../messages';
import { Disableable } from 'app/components/Disableable';

import { Checkbox } from 'app/components/Inputs/Checkbox';

interface Props {
  disabled: boolean;
}

export const Contract = ({ disabled }: Props) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const endDate = watch('subscription.endDate');
  const endSuppSkinDate = watch('subscription.suppSkinEndDate');

  const paymentDayValue = watch('subscription.paymentDay');

  const namePrefix = 'customer';

  return (
    <Grid container spacing={1}>
      <FormInputGrid item sm={12}>
        <FormLabel>{t(messages.rumCode.label())}</FormLabel>
        <TextInput disabled={disabled} name="subscription.rum" required />
      </FormInputGrid>
      <FormInputGrid item sm={12}>
        <FormLabel>{t(messages.subscriptionType.label())}</FormLabel>
        <TextInput disabled={disabled} name="subscription.type" required />
      </FormInputGrid>
      <FormInputGrid item sm={12}>
        <FormLabel>{t(messages.startDate.label())}</FormLabel>
        <TextInput disabled={disabled} name="subscription.startDate" required />
      </FormInputGrid>
      <Disableable enabled={!!endDate}>
        <FormInputGrid item sm={12}>
          <FormLabel>{t(messages.endDate.label())}</FormLabel>
          <TextInput disabled={disabled} name="subscription.endDate" required />
        </FormInputGrid>
      </Disableable>
      <Disableable enabled={endSuppSkinDate !== undefined && endSuppSkinDate !== '' }>
        <FormInputGrid item sm={12}>
          <FormLabel>{t(messages.endDate.label())}</FormLabel>
          <TextInput disabled={disabled} name="subscription.suppSkinEndDate" required />
        </FormInputGrid>
      </Disableable>
      <FormInputGrid item sm={12}>
        <FormLabel>{t(messages.dueDay.label())}</FormLabel>
        {/* <TextInput
          name="subscription.paymentDay"
          required
        /> */}
        <SelectInput
          name="subscription.paymentDay"
          options={[{ id: "5", label: "5" }, { id: "20", label: "20"}]}
          fullWidth
          placeholder={paymentDayValue}
          required
        />

      </FormInputGrid>
      <FormInputGrid item sm={12}>
        <Checkbox
          label="Résilier contrat"
          name="subscription.resiliate"
        />
      </FormInputGrid>
    </Grid>
  );
};
