declare global {
  interface URLSearchParams {
    stringSearchParams(): string;
  }
}

URLSearchParams.prototype.stringSearchParams = function () {
  const value = this.toString();
  return value === '' ? value : `?${value}`;
};

export {};
