import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core';
import styled from 'styled-components/macro';

export interface CheckboxProps extends MuiCheckboxProps {
  label: string;
}

export const Checkbox = (props: CheckboxProps) => {
  const { label, ...rest } = props;
  return <FormControlLabel control={<MuiCheckbox {...rest} />} label={label} />;
};

const FormControlLabel = styled(MuiFormControlLabel)`
  & .MuiTypography-body1 {
    color: ${p => p.theme.input.text.default};
  }
`;
