import { envs } from 'env-config';

export const getEnv = (name: string): string => {
  const value = envs[name];
  if (!value) {
    throw new Error(
      `No ${name} environment variable set. Please check your environment file.`,
    );
  }
  return value;
};
