import { Helmet } from 'react-helmet-async';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { List } from 'app/components/List';
import { paths } from 'app/routes/paths';

import { messages } from './messages';
import { allStatKinds, StatKind } from '../StatsPage/types';
import { StatItem } from './components/StatItem';
import { parameterFromStatKind } from '../StatsPage/utils';
import { useInstituteSelector } from 'app/atoms/selectors';

export const StatsMenu = () => {
  const { t } = useTranslation();
  const pageTitle = t(messages.pageTitle());

  const history = useHistory();
  const onClick = useCallback((kind: StatKind) => {
    const location = generatePath(paths.stats.pattern, {
      kind: parameterFromStatKind(kind),
    });
    history.push(location);
  }, []);

  const { institute } = useInstituteSelector();

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageWrapper header={<PageHeader label={pageTitle + " : " + institute.name } />}>
        <List>
          {allStatKinds.map(kind => (
            <StatItem
              key={kind}
              label={t(messages.itemLabel(kind))}
              onClick={() => onClick(kind)}
            />
          ))}
        </List>
      </PageWrapper>
    </>
  );
};
