import React, { PropsWithChildren, ReactNode } from 'react';

import { Nullable } from 'types/misc';

import { CircularProgress } from '../CircularProgress';
import { Conditional } from '../Conditional';
import { ContentError } from '../ContentError';

type Props = PropsWithChildren<{
  isLoading: boolean;
  error?: Nullable<Error>;
  isEmpty?: boolean;
  emptyNodeRenderer?: () => ReactNode;
}>;

export const LoadableContent = ({
  children,
  emptyNodeRenderer,
  error,
  isEmpty,
  isLoading,
}: Props) => (
  <Conditional
    condition={isLoading}
    trueConditionNode={<CircularProgress color="primary" />}
    falseConditionNode={
      <Conditional
        condition={!!error}
        trueConditionNode={<ContentError label={error?.message} />}
        falseConditionNode={
          <Conditional
            condition={!!isEmpty && !!emptyNodeRenderer}
            // condition={true}
            trueConditionNode={emptyNodeRenderer?.()}
            falseConditionNode={children}
          />
        }
      />
    }
  />
);
