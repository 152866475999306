import moment from 'moment';
import { useCallback, useMemo } from 'react';

export enum ClusteringMode {
  WEEKLY,
  MONTHLY,
}

interface Options {
  values: any[];
}

interface Result {
  clusteringMode?: ClusteringMode;
  values: any[];
}

export const useClustering = (options: Options) => {
  const { values: rawValues } = options;

  const getCluseringMode = useCallback((values: any[]) => {
    const datasetSize = values.length;
    console.log('datasetSize', datasetSize);
    // ~6 months
    if (datasetSize > 180) {
      return ClusteringMode.MONTHLY;
      // ~3 months
    } else if (datasetSize > 90) {
      return ClusteringMode.WEEKLY;
    }
    return undefined;
  }, []);

  const clusteringKey = (mode: ClusteringMode, date: string) => {
    switch (mode) {
      case ClusteringMode.WEEKLY:
        return `${moment(date).year()}-${moment(date).week()}`;
      case ClusteringMode.MONTHLY:
        return `${moment(date).year()}-${moment(date).month()}`;
    }
  };

  const groupValues = useCallback((values: any[], mode: ClusteringMode) => {
    // Group values by cluster mode
    const groupObject = values.reduce((acc, value) => {
      const date = value['dateday']; // @TODO use dynamoic property
      const key = clusteringKey(mode, date);
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(value);
      return acc;
    }, {});
    return Object.entries(groupObject).map(([_, group]) => group) as any[];
  }, []);

  const sumObjectProperties = useCallback(
    (clusteringMode: ClusteringMode) => (prev: any, next: any) => {
      const obj = {};
      Object.entries(next).forEach(([key, value]) => {
        // @TODO use dynamoic property
        if (key !== 'dateday') {
          const prevCasted = parseFloat(prev[key]);
          const valueCasted = parseFloat(value as string);
          obj[key] = prevCasted + valueCasted;
        } else {
          // obj['dateday'] = clusteringKey(clusteringMode, value as string);
          obj['dateday'] = value;
        }
      });
      return obj;
    },
    [],
  );

  const clusterValues = useCallback(
    (values: any[], mode: ClusteringMode) =>
      groupValues(values, mode).map(group =>
        group.reduce(sumObjectProperties(mode)),
      ),
    [],
  );

  const result: Result = useMemo(() => {
    const clusteringMode = getCluseringMode(rawValues);
    let values: any[];
    if (clusteringMode !== undefined) {
      values = clusterValues(rawValues, clusteringMode);
    } else {
      values = rawValues;
    }
    return { values, clusteringMode };
  }, [rawValues]);

  return result;
};
