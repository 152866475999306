import { useState, useEffect } from 'react';
import style from 'styled-components/macro';
import { useTopServiceQueries } from './hooks';
import { LoadableContent } from 'app/components/LoadableContent';
import { EmptyState } from 'app/components/EmptyState';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import randomColor from 'randomcolor'; //https://medium.com/officialrajdeepsingh/best-way-to-generate-a-random-color-in-react-js-and-build-a-color-project-222e3dff3571
import { InlineDatePicker } from './components/StatsContent/components/inlineDatePicker';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { TopServiceInterface } from 'types/API/topservice';
import { fetchTopServiceRequest } from 'app/services/stats';

import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

interface PieValue {
  title: string;
  value: number;
  color: string;
}

interface BarValue {
  label: string;
  data: number;
  backgroundColor: string;
}

interface StatsValue {
  title: string;
  price: number;
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Visualisation',
    },
  },
};

export const TopService = props => {
  const { institute } = props;

  let lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 30);
  var date = new Date();
  let firstDayOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  const [startDate, setStartDate] = useState(firstDayOfTheMonth);
  const [endDate, setEndDate] = useState(new Date());

  const [barData, setBarData] = useState<{ labels: string[]; datasets: any[] }>(
    { labels: [], datasets: [] },
  );

  // var { statsQueryState } = useTopServiceQueries({ instituteId: institute.id, start: startDate, end: endDate });

  const DateWrapper = style.div`
  `;

  const DateWrapperLabel = style.div`
  `;

  const DropdownWrapper = style.div`
    margin-top: 20px;
    margin-bottom: 20px;
  `;

  const PieDiv = style.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; 
    height: 100%;
    margin-top: 50px;
  `;

  const [pieValues, setPieValues] = useState<Array<PieValue>>([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState<TopServiceInterface[]>();
  const [categories, setCategories] = useState(['all']);
  const [selectedCat, setSelectedCat] = useState('Sélectionnez une catégorie');

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#cbcffe',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const loadData = async (start, end) => {
    setStartDate(start);
    setEndDate(end);
    if (!loading) {
      setLoading(true);
      fetchTopServiceRequest({
        instituteId: institute.id,
        start: start,
        end: end,
      })
        .then(data => {
          setLoading(false);
          setData(data);
          var categories: string[] = ['Sélectionnez une catégorie'];
          data.map(service => {
            const found = categories.find(obj => {
              return obj === service.statCategorie;
            });

            if (!found) {
              categories.push(service.statCategorie);
            }
            setBarDataForSelectedFilter();
          });
          setCategories(categories);
        })
        .catch(error => {
          setLoading(false);
          setError(error);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    // setPieDataForSelectedFilter();
    setBarDataForSelectedFilter();
  }, [data, selectedCat]);

  const onSelect = option => {
    console.log('You selected ', option.label);
    setSelectedCat(option.value);

    // setPieDataForSelectedFilter();
    setBarDataForSelectedFilter();
  };

  const setTotals = () => {
    var calculatedTotal = 0;
    var calculatedTotalNb = 0;

    if (data) {
      data.map(service => {
        var addNumbers = false;
        if (selectedCat == 'Sélectionnez une catégorie') {
          addNumbers = true;
        } else if (selectedCat === service.statCategorie) {
          addNumbers = true;
        }

        if (addNumbers) {
          calculatedTotalNb += Number(service.nb);
          calculatedTotal += Number(service.sum);
        }
      });

      setTotal(calculatedTotal);
      setTotalNb(calculatedTotalNb);
    }
  };

  const setBarDataForSelectedFilter = () => {
    if (data) {
      let filteredServices = data;
      const barVal: BarValue[] = data
        .filter(service => {
          var showService = false;
          if (selectedCat == 'Sélectionnez une catégorie') {
            showService = true;
          } else {
            showService = service.statCategorie == selectedCat ? true : false;
          }
          return showService;
        })
        .map(ser => {
          const result: BarValue = {
            label: ser.title,
            data: Math.round(Number(ser.nb)),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          };
          return result;
        });

      var fluxNames = barVal.map(val => {
        return val.label;
      });

      var values = barVal.map(val => {
        return val.data;
      });

      const barData = {
        labels: fluxNames,
        datasets: [
          {
            label: 'Nombre de prestations réalisées par catégorie',
            data: values,
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
        ],
      };

      setBarData(barData);
      setTotals();
    }
  };

  const setPieDataForSelectedFilter = () => {
    if (data) {
      let filteredServices = data;
      const pieVal: PieValue[] = data
        .filter(service => {
          var showService = false;
          if (selectedCat == 'Sélectionnez une catégorie') {
            showService = true;
          } else {
            showService = service.statCategorie == selectedCat ? true : false;
          }
          return showService;
        })
        .map(ser => {
          const result: PieValue = {
            title: ser.title,
            value: Math.round(Number(ser.sum)),
            color: randomColor(),
          };
          return result;
        });

      setPieValues(pieVal);
    }
  };

  const [total, setTotal] = useState(0);
  const [totalNb, setTotalNb] = useState(0);

  useEffect(() => {
    loadData(startDate, endDate);
  }, []);

  return (
    <div>
      {/* <PieChart description={pieDesc} propertyMapping={pieMapping} values={pieValues}></PieChart> */}
      <InlineDatePicker
        start={startDate}
        end={endDate}
        max={Date()}
        action={loadData}
      ></InlineDatePicker>

      <DropdownWrapper>
        <Dropdown
          options={categories}
          value={selectedCat}
          onChange={onSelect.bind(this)}
          placeholder="Sélectionnez un mois"
        />
      </DropdownWrapper>

      {
        <LoadableContent
          error={error}
          isLoading={loading}
          isEmpty={data?.length === 0}
          emptyNodeRenderer={() => <EmptyState label="Aucun résultat" />}
        />
      }

      {data && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Services</StyledTableCell>
                <StyledTableCell align="right">Catégorie</StyledTableCell>
                <StyledTableCell align="right">Nb ({totalNb})</StyledTableCell>
                <StyledTableCell align="right">
                  CA ({total.toFixed(2)}€)
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .filter(service => {
                  var showService = false;
                  if (selectedCat == 'Sélectionnez une catégorie') {
                    showService = true;
                  } else {
                    showService =
                      service.statCategorie == selectedCat ? true : false;
                  }
                  return showService;
                })
                .sort((a, b) => (Number(a.sum) > Number(b.sum) ? -1 : 1))
                .map(row => (
                  <StyledTableRow key={row.title.toLowerCase()}>
                    <StyledTableCell component="th" scope="row">
                      {row.title.toLowerCase().charAt(0).toUpperCase() +
                        row.title.toLowerCase().slice(1)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.statCategorie.charAt(0).toUpperCase() +
                        row.statCategorie.slice(1)}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.nb}</StyledTableCell>
                    <StyledTableCell align="right">
                      {Number(row.sum).toFixed(2)}€
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* 
        {total > 0 &&
          <p>Total {total}€</p>
        } */}

      {/* {selectedCat != "Sélectionnez une catégorie" &&
        <PieDiv>
          <PieChart style={{ width: 650, height: 650 }}
            data={pieValues}
            label={({ dataEntry }) => dataEntry.title}
            labelStyle={{
              fontSize: '3px',
              fontFamily: 'sans-serif',
              color: '#fff',
            }}
          />
        </PieDiv>
      } */}

      <Bar options={options} data={barData} />

      {/* </LoadableContent> */}
    </div>
  );
};