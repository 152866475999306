import { Controller, useFormContext } from 'react-hook-form';

import { Checkbox as UncontrolledCheckbox } from '../Uncontrolled/Checkbox';

interface Props {
  disabled?: boolean;
  label: string;
  name: string;
}

export const Checkbox = ({ disabled, label, name }: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur, onChange, value } }) => (
        <UncontrolledCheckbox
          checked={value ?? false}
          color="primary"
          disabled={disabled}
          label={label}
          onBlur={onBlur}
          onChange={e => onChange(e.target.checked)}
          size="small"
        />
      )}
    />
  );
};
