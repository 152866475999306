import { useState } from 'react';
import style from 'styled-components/macro';
import DatePicker from "react-datepicker";
import { useBookQueries } from './hooks';
import { LoadableContent } from 'app/components/LoadableContent';
import { EmptyState } from 'app/components/EmptyState';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import randomColor from "randomcolor"; //https://medium.com/officialrajdeepsingh/best-way-to-generate-a-random-color-in-react-js-and-build-a-color-project-222e3dff3571

import { PieChart } from 'react-minimal-pie-chart';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

interface PieValue {
  title: string;
  value: number;
  color: string;
}

interface StatsValue {
  title: string;
  price: number;
}

export const Statistics = (props) => { 

  const { institute } = props;

  let lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 30);
  var date = new Date();
  let firstDayOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  const [startDate, setStartDate] = useState(firstDayOfTheMonth);
  const [endDate, setEndDate] = useState(new Date());

  var { statsQueryState } = useBookQueries({ instituteId: institute.id, start: startDate, end: endDate });

  const options = statsQueryState.data?.map(user => { return user.userCreated }) || []
  
  const DateWrapper = style.div`
  `;

  const DateWrapperLabel = style.div`
  `;

  const DropdownWrapper = style.div`
    margin-top: 20px;
    margin-bottom: 20px;
  `;

  const PieDiv = style.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; 
    height: 100%;
  `;

  const [userSelected, setSelectedUser] = useState()
  const [prestas, setPrestas] = useState<Array<StatsValue>>([])

  const [total, setTotal] = useState(0)
  const [pieValues, setPieValues] = useState<Array<PieValue>>([])
  
  const propertyMapping = { value: 'Prestation', price: 'CA'}
  
  const onSelect = (option) => {
    console.log('You selected ', option.label)
    setSelectedUser(option.label)

    
    if (statsQueryState.data) {
      var user: any = statsQueryState.data.filter(stats => {
        return stats.userCreated === option.label
      })

      const services = user[0].service;

      if (services.length > 1) {
        const total = services.reduce((total, num) => total + num.price, 0)
        setTotal(total)
      } else {
        setTotal(services[0].price)
      }
      

      const pieVal: [PieValue] = services.map(ser => {
        const result = { title: ser.title, value: Math.round(ser.price), color: randomColor() }
        return result
      })

      setPieValues(pieVal)
      setPrestas(services)
    }    
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#cbcffe',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  return (
    <div>
      {/* <PieChart description={pieDesc} propertyMapping={pieMapping} values={pieValues}></PieChart> */}
      <DateWrapper>
        <DateWrapperLabel>Du</DateWrapperLabel>
        <DatePicker locale="fr" dateFormat="dd/MM/yyyy" selected={startDate} onChange={(date) => setStartDate(date)} />
        <DateWrapperLabel>au</DateWrapperLabel>
        <DatePicker locale="fr" dateFormat="dd/MM/yyyy" selected={endDate} onChange={(date) => setEndDate(date)} />
      </DateWrapper>

      <LoadableContent
        error={statsQueryState.error}
        isLoading={statsQueryState.isLoading}
        isEmpty={statsQueryState.data?.length === 0}
        emptyNodeRenderer={() => (
          <EmptyState label="Aucun résultat" />
        )}
      >
        <DropdownWrapper>
          <Dropdown options={options} onChange={onSelect.bind(this)} placeholder="Choisissez une esthet" />
        </DropdownWrapper>

        {prestas.length > 0 &&
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Prestations</StyledTableCell>
                  <StyledTableCell align="right">prix</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prestas.map((row) => (
                  <StyledTableRow key={row.title.toLowerCase()}>
                    <StyledTableCell component="th" scope="row">
                      {row.title.toLowerCase()}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.price}€</StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow key="total">
                  <StyledTableCell component="th" scope="row">
                    <b>Total</b>
                  </StyledTableCell>
                  <StyledTableCell align="right">{total}€</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        }

{/* 
        {total > 0 &&
          <p>Total {total}€</p>
        } */}

        
        <PieDiv>
          <PieChart style={{ width: 650, height: 650 }}
            data={pieValues}
            label={({ dataEntry }) => dataEntry.title}
            labelStyle={{
              fontSize: '3px',
              fontFamily: 'sans-serif',
              color: '#fff',
            }}
          />
        </PieDiv>


        
      </LoadableContent>
    </div>

    
  );
}