import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components/macro';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { DayPicker } from 'app/components/Inputs/DayPicker';
import { FormButton } from 'app/pages/InstitutePage/Form/components/FormButton';

import { messages } from '../../../../messages';
import { DeleteButton } from '../../../components/DeleteButton';

interface Props {
  disabled: boolean;
}

const maxAdditionalPeriods = 3;

export const FiscalDates = ({ disabled }: Props) => {
  const { t } = useTranslation();

  const [additionalPeriods, setAdditionalPeriods] = useState<string[]>([]);

  const onAddMarker = () => {
    setAdditionalPeriods([...additionalPeriods, uuid()]);
  };

  const onRemoveMarker = (identifier: string) => {
    setAdditionalPeriods(
      additionalPeriods.filter(candidate => candidate !== identifier),
    );
  };

  const addAdditionalPeriodVisible = useMemo(
    () => additionalPeriods.length < maxAdditionalPeriods,
    [additionalPeriods],
  );

  return (
    <>
      <FormInputGrid item sm={6} md={3}>
        <FormLabel>{t(messages.fiscalYearStart.label())}</FormLabel>
        <DayPicker
          name="periodeStart"
          dayPlaceholder={t(messages.dayPicker.dayPlaceholder())}
          disabled={disabled}
          monthPlaceholder={t(messages.dayPicker.monthPlaceholder())}
        />
      </FormInputGrid>
      <FormInputGrid item sm={6} md={3}>
        <FormLabel>{t(messages.fiscalYearEnd.label())}</FormLabel>
        <DayPicker
          name="periodeEnd"
          dayPlaceholder={t(messages.dayPicker.dayPlaceholder())}
          disabled={disabled}
          monthPlaceholder={t(messages.dayPicker.monthPlaceholder())}
        />
      </FormInputGrid>
      {/* <FormInputGrid item xs={0} sm={6}></FormInputGrid> */}
      {additionalPeriods.map((identifier, index) => (
        <PeriodicClosingWrapper key={identifier}>
          <PeriodicClosingGrid item sm={6} md={3}>
            <FormLabel>
              {t(messages.additionalPeriodicClosing.label(), {
                identifier: index + 1,
              })}
            </FormLabel>
            <DayPicker
              name={`additionalPeriods[${identifier}]`}
              dayPlaceholder={t(messages.dayPicker.dayPlaceholder())}
              disabled={disabled}
              monthPlaceholder={t(messages.dayPicker.monthPlaceholder())}
            />
          </PeriodicClosingGrid>
          <DeleteFormInputGrid item sm={6} md={9}>
            <DeleteWrapper>
              <DeleteButton onClick={() => onRemoveMarker(identifier)} />
            </DeleteWrapper>
          </DeleteFormInputGrid>
        </PeriodicClosingWrapper>
      ))}
      {addAdditionalPeriodVisible && (
        <FormInputGrid item sm={12}>
          <FormButton disabled={disabled} onClick={onAddMarker}>
            {t(messages.addPeriodicClosing())}
          </FormButton>
        </FormInputGrid>
      )}
    </>
  );
};

const DeleteFormInputGrid = styled(FormInputGrid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const DeleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 3rem;
`;

const PeriodicClosingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const PeriodicClosingGrid = styled(FormInputGrid)`
  padding: 0 0.25rem;
`;
