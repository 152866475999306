import styled from 'styled-components/macro';
import {
  IconButton as RawIconButton,
  IconButtonProps,
} from '@material-ui/core';

import { ReactComponent as TrashIcon } from 'app/assets/trash.svg';

export const DeleteButton = (props: IconButtonProps) => (
  <StyledIconButton {...props}>
    <TrashIcon />
  </StyledIconButton>
);

const StyledIconButton = styled(RawIconButton)`
  &.MuiIconButton-root {
    background-color: ${({ theme }) => theme.danger};
  }
  &.MuiIconButton-root:hover {
    background-color: ${({ theme }) => theme.danger};
    opacity: 0.8;
  }
  width: 2.5rem;
  height: 2.5rem;
`;
