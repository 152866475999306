import { FormLabel } from 'app/components/Forms/FormLabel';
import { SelectInput } from 'app/components/Inputs';
import { SelectInputOption } from 'app/components/Inputs/SelectInput';

interface Props {
  disabled: boolean;
  label: string;
  name: string;
  options: SelectInputOption[];
  placeholder: string;
  required?: boolean;
  onDiscountChange?: (...event: any[]) => void;
}

export const PriceInput = (props: Props) => {
  const { disabled, label, name, options, placeholder, required, onDiscountChange } = props;

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <SelectInput
        disabled={disabled}
        name={name}
        options={options}
        onChange1={onDiscountChange}
        placeholder={placeholder}
        required={required}
      />
    </>
  );
};
