import React from 'react';

import { ListItem, ListItemProps } from 'app/components/ListItem';

interface Props extends ListItemProps {
  label: string;
}

export const StatItem = (props: Props) => {
  const { label, ...rest } = props;

  return <ListItem {...rest}>{label}</ListItem>;
};
