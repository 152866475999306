import { SellOffPage } from 'app/pages/SellOff/SellOffPage';
import { SellOffsPage } from 'app/pages/SellOff/SellOffsPage';
import { Route } from 'types/route';

import { paths } from '../../paths';

const sellOffs: Route = {
    authenticated: true,
    content: SellOffsPage,
    routerPath: paths.selloffs.list,
};

const createSellOff: Route = {
    authenticated: true,
    content: SellOffPage,
    routerPath: paths.selloffs.create,
  };
  
  const updateSellOff: Route = {
    authenticated: true,
    content: SellOffPage,
    routerPath: paths.selloffs.update,
  };

export const routes = [createSellOff, updateSellOff, sellOffs];