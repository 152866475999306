import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  discountPercentFormat: () => _t(translations.common.discount_percent_format),
  discountPriceFormat: () => _t(translations.common.discount_price_format),
  priceFormat: () => _t(translations.common.price_format),
  unsubscribedUser: () => _t(translations.common.users.unsubscribed_user),
  monthDateFormat: () => _t(translations.common.month_date_format),

  forms: {
    dateMustBeInTheFuture: () =>
      _t(translations.common.forms.date_must_be_in_the_future),
    endDateMustBeLaterThanStartDate: () =>
      _t(translations.common.forms.end_date_must_be_later_than_start_date),
    requiredFieldError: () =>
      _t(translations.common.forms.required_field_error),
    invalidEmailError: () => _t(translations.common.forms.invalid_email_error),
    invalidIBANError: () => _t(translations.common.forms.invalid_iban_error),
  },
  errors: {
    generic: () => _t(translations.common.errors.generic),
  },
};
