import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { TextInput } from 'app/components/Inputs/TextInput';
import { Checkbox } from 'app/components/Inputs/Checkbox';
import { fieldName } from 'utils/form';

import { messages } from '../../../messages';
import { formatDateInput } from 'utils/formatters/date';
import { SelectInput } from 'app/components/Inputs/SelectInput';
import { useFormContext } from 'react-hook-form';
import { translations } from 'locales/translations';

interface Props {
  disabled: boolean;
  displayRejectedDebit: boolean;
  rejectionDate: string;
  RejectionMotif?: string;
}

export const Institute = ({ disabled, displayRejectedDebit, rejectionDate, RejectionMotif }: Props) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const namePrefix = 'customer';
  const namePrefixSubscription = 'subscription';
  const rejection = watch(fieldName('rejection', namePrefixSubscription));

  return (
    <Grid container spacing={4}>
      {/*}
      <Grid item md={8}>
        //{ <FormInputGrid item sm={6}></FormInputGrid> }
        <FormLabel>{t(messages.subscriptionComments.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          multiline
          name={fieldName('commentSubscriber', namePrefix)}
          placeholder={t(messages.subscriptionComments.placeholder())}
        />
        <FormLabel>{t(messages.instituteComments.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          multiline
          name={fieldName('commentInstitute', namePrefix)}
          placeholder={t(messages.instituteComments.placeholder())}
        />
      </Grid>
      */}
      <Grid item md={4}>
        {displayRejectedDebit && (
          <FormInputGrid item sm={12}>
              <Checkbox
                label={rejectionDate !== undefined && rejectionDate !== "" ? (t(messages.rejectedDebit()) + ` le: ` + formatDateInput({date: rejectionDate})) : (t(messages.rejectedDebit()))}
                name={fieldName('rejection', namePrefixSubscription)}
              />
              {rejection &&
                <SelectInput
                  name="subscription.RejectionMotif"
                  options={[
                    { id: "AC01" , label: t(messages.rejectedDebitReason.AC01())},
                    { id: "AC04" , label: t(messages.rejectedDebitReason.AC04())},
                    { id: "BE05" , label: t(messages.rejectedDebitReason.BE05())},
                    { id: "AM04" , label: t(messages.rejectedDebitReason.AM04())},  
                    { id: "AC13 (3)" , label: t(messages.rejectedDebitReason.AC13())},  
                    { id: "MD01" , label: t(messages.rejectedDebitReason.MD01())},  
                    { id: "AC06" , label: t(messages.rejectedDebitReason.AC06())},  
                    { id: "MD07" , label: t(messages.rejectedDebitReason.MD07())},  
                    { id: "AG02" , label: t(messages.rejectedDebitReason.AG02())},  
                    { id: "FF01" , label: t(messages.rejectedDebitReason.FF01())},  
                    { id: "MS02" , label: t(messages.rejectedDebitReason.MS02())},  
                    { id: "MS03" , label: t(messages.rejectedDebitReason.MS03())},  
                    { id: "RC01" , label: t(messages.rejectedDebitReason.RC01())},  
                    { id: "RR01" , label: t(messages.rejectedDebitReason.RR01())},  
                    { id: "SL01" , label: t(messages.rejectedDebitReason.SL01())},  
                    { id: "AM05" , label: t(messages.rejectedDebitReason.AM05())},  
                    { id: "MD02" , label: t(messages.rejectedDebitReason.MD02())},  
                    { id: "MD06 (4)" , label: t(messages.rejectedDebitReason.MD06())},  
                    { id: "CNOR" , label: t(messages.rejectedDebitReason.CNOR())},  
                    { id: "DNOR" , label: t(messages.rejectedDebitReason.DNOR())},  
                    { id: "AG01" , label: t(messages.rejectedDebitReason.AG01())},  
                    { id: "BE07" , label: t(messages.rejectedDebitReason.BE07())}]}
                  fullWidth
                  placeholder={RejectionMotif ?? t(messages.rejectedDebitReason.title())}
                  required
                />
              }

          </FormInputGrid>
        )}
        <FormInputGrid item sm={12}>
          <Checkbox
            label={t(messages.wrongAddress())}
            name={fieldName('wrongAddress', namePrefix)}
          />
        </FormInputGrid>
        <FormInputGrid item sm={12}>
          <Checkbox
            label={t(messages.wrongPhone())}
            name={fieldName('wrongPhone', namePrefix)}
          />
        </FormInputGrid>
        <FormInputGrid item sm={12}>
          <Checkbox
            label={t(messages.wrongEmail())}
            name={fieldName('wrongMail', namePrefix)}
          />
        </FormInputGrid>
        <FormInputGrid item sm={12}>
          <Checkbox
            label={t(messages.noMailing())}
            name={fieldName('noMailing', namePrefix)}
          />
        </FormInputGrid>
        <FormInputGrid item sm={12}>
          <Checkbox
            label={t(messages.noSMS())}
            name={fieldName('noSms', namePrefix)}
          />
        </FormInputGrid>
        <FormInputGrid item sm={12}>
          <Checkbox
            label={t(messages.noEmail())}
            name={fieldName('noMail', namePrefix)}
          />
        </FormInputGrid>
      </Grid>
    </Grid>
  );
};
