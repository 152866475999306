import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  createPageTitle: () => _t(translations.order.create.page_title),
  updatePageTitle: () => _t(translations.order.update.page_title),
  save: () => _t(translations.order.save),
  send: () => _t(translations.order.send),
  products: {
    emptyLabel: () => _t(translations.order.products.empty_label),
    addProducts: () => _t(translations.order.products.add),
  },
};
