import { useRecoilValue } from 'recoil';

import { institutesState, meState } from 'app/atoms';

export const useSelectors = () => {
  const state = useRecoilValue(institutesState);
  const me = useRecoilValue(meState);
  return {
    currentInstituteId: state?.currentInstituteId ?? -1,
    institutes: state.userInstitutes,
    me,
  };
};
