import moment from 'moment';

import { useQuery } from 'app/hooks';
import { Month } from 'types/month';
import { genericQueryKey } from 'utils/cache/keys';
import { sleep } from 'utils/time';
import { Stats } from 'types/API/stats';

/// [START TMP]
const randomFromInterval = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const fetchStatsRequest = ({
  instituteId,
  month: formattedMonth,
}: {
  instituteId: number;
  month: string;
}): Promise<Stats.Monthly> =>
  new Promise(async (resolve, reject) => {
    await sleep(0);
    const month = Month.fromDate(moment(formattedMonth, 'YYYY-MM').toDate());
    const hasResult = month.index <= Month.current().index;
    let values: Stats.Monthly = [];
    if (hasResult) {
      values = month.days().map(date => {
        if (
          date.getDay() === 0 ||
          (month.index === Month.current().index &&
            date.getDate() > new Date().getDate())
        ) {
          return { total_sales: 0, product_sales: 0, services: 0 };
        }
        const total_sales = randomFromInterval(700, 1600);
        const product_sales = (randomFromInterval(10, 30) / 100) * total_sales;
        const services = randomFromInterval(10, 30);
        return { total_sales, product_sales, services };
      });
    }
    resolve(values);
  });

/// [END TMP]

export const useQueries = ({
  instituteId,
  month,
}: {
  instituteId: number;
  month: Month;
}) => {
  const parameters = { instituteId, month: month.toString() };
  const {
    data: stats,
    error,
    isLoading,
  } = useQuery(
    genericQueryKey({
      identifier: 'stats',
      parameters: parameters,
    }),
    () => fetchStatsRequest(parameters),
    // { enabled: !!customerId },
  );

  return { stats, error, isLoading };
};
