import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Switch } from 'app/components/Inputs/Switch';
import { useFormContext } from 'react-hook-form';
import { fieldName } from 'utils/form';

import { Button } from 'app/components/Button';
import {
  DefaultFormSection,
  FormSection,
  FormSectionTitle,
} from 'app/components/Forms/FormSection';

import { Contract } from './sections/Contract';
import { BankAccount } from './sections/BankAccount';
import { Customer } from './sections/Customer';
import { Institute } from './sections/Institute';
import { messages } from '../messages';
import { SubscriptionStatus } from '../components/SubscriptionStatus';

interface Props {
  canSubmit: boolean;
  displayBankAccount: boolean;
  displayRejectedDebit: boolean;
  rejectionDate: string;
  RejectionMotif?: string;
  isSubmitting: boolean;
  isSubscriptionActive: boolean;
}

export const Form = (props: Props) => {
  const { canSubmit, displayBankAccount, displayRejectedDebit, rejectionDate, RejectionMotif, isSubmitting } = props;
  const { watch } = useFormContext();
  const namePrefix = 'subscriptionPayment';
  const displayBankInfo = watch(fieldName('displayBankInfo', namePrefix));

  const { t } = useTranslation();

  const location = useLocation();

  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => {
    if (hasRendered) {
      // Called after the first render loop (this could be extracted inside a custom hook if used more than once)
      if (location.hash) {
        document.getElementById(location.hash.slice(1))?.scrollIntoView();
      }
    } else {
      setHasRendered(true);
    }
  }, [hasRendered, location.hash]);

  return (
    <Wrapper>
      <Button
        className="submit"
        disabled={!canSubmit}
        isSpinning={isSubmitting}
        type="submit"
      >
        {t(messages.submit())}
      </Button>
      <Grid container spacing={4}>
        <Grid item md={8}>
          <FormSection title={t(messages.sections.customer())}>
            <Customer disabled={isSubmitting} />
          </FormSection>
        </Grid>
        <Grid item md={4}>
          <DefaultFormSection
            id="contract-section"
            title={
              <SectionTitleWrapper>
                <FormSectionTitle>
                  {t(messages.sections.contract())}
                </FormSectionTitle>

                {/* Information not provided by the backend for now */
                /* <SubscriptionStatus
                  isActive={isSubscriptionActive}
                  label={t(
                    isSubscriptionActive
                      ? messages.subscriptionStatus.active()
                      : messages.subscriptionStatus.inactive(),
                  )}
                /> */}
              </SectionTitleWrapper>
            }
          >
            <Contract disabled />
          </DefaultFormSection>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <FormSection title={t(messages.sections.institute())}>
            <Institute
              disabled={isSubmitting}
              displayRejectedDebit={displayRejectedDebit}
              rejectionDate={rejectionDate}
              RejectionMotif={RejectionMotif}
            />
          </FormSection>
        </Grid>
      </Grid>
      {displayBankAccount && (
        <Grid container spacing={1}>
          <Grid item sm={8}>
            <FormSection
              id="bank-account"
              title={t(messages.sections.bankAccount())}
            >
              <Switch
                label="Voir/Modifier IBAN"
                name={fieldName('displayBankInfo', namePrefix)}
              />
              {displayBankInfo && <BankAccount disabled={isSubmitting} />}
            </FormSection>
          </Grid>
        </Grid>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
  .submit {
    float: right;
  }
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
`;
