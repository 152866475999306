import { FiscalArchivesList } from 'app/pages/FiscalArchivesPage/types';
import { format } from 'date-fns';
import { request } from 'utils/request';

export const generateFiscalArchives = async ({
	instituteId,
	start,
	end,
}: {
	instituteId: number;
	start: Date;
	end: Date;
}) => {
	const formattedStartDate = format(start, 'yyyyMMddHHmmss');
	const formattedEndDate = format(end, 'yyyyMMddHHmmss');
	const response = await request<Blob>(`api/fiscal-archive/generate`, {
		method: 'POST',
		data: {
			startDate: formattedStartDate,
			endDate: formattedEndDate,
			instituteId: instituteId,
		},
		responseType: 'blob',
	});
	const href = URL.createObjectURL(response);

	// create "a" HTML element with href to file & click
	const link = document.createElement('a');
	link.href = href;
	link.setAttribute(
		'download',
		`archive_${formattedStartDate}_${formattedEndDate}.zip`,
	); //or any other extension
	document.body.appendChild(link);
	link.click();

	// clean up "a" element & remove ObjectURL
	document.body.removeChild(link);
	URL.revokeObjectURL(href);
};

export const downloadFiscalArchive = async ({
	archiveId,
	fileName,
}: {
	archiveId: number;
	fileName: string;
}) => {
	const response = await request<Blob>(
		`api/fiscal-archive/download/${archiveId}`,
		{
			method: 'GET',
			responseType: 'blob',
		},
	);
	console.log(response);
	const href = URL.createObjectURL(response);

	// create "a" HTML element with href to file & click
	const link = document.createElement('a');
	link.href = href;
	link.setAttribute('download', `${fileName}`); //or any other extension
	document.body.appendChild(link);
	link.click();

	// clean up "a" element & remove ObjectURL
	document.body.removeChild(link);
	URL.revokeObjectURL(href);

	return response;
};

export const fetchFiscalArchivesList = async ({
	instituteId,
}: {
	instituteId: number;
}): Promise<FiscalArchivesList> => {
	const response = await request<FiscalArchivesList>(
		`api/fiscal-archive/list/${instituteId}`,
		{
			method: 'GET',
		},
	);
	return response;
};
