import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  pageTitle: () => _t(translations.special_offers.list.page_title),
  dateFormat: () => _t(translations.special_offers.list.date_format),
  maxDiscountAmountReached: {
    title: () =>
      _t(translations.special_offers.list.max_discount_amount_reached.title),
    descritption: () =>
      _t(
        translations.special_offers.list.max_discount_amount_reached
          .description,
      ),
    primaryActionLabel: () =>
      _t(
        translations.special_offers.list.max_discount_amount_reached
          .primary_action_label,
      ),
  },
  cures: {
    sectionTitle: () =>
      _t(translations.special_offers.list.cures.section_title),
    create: () => _t(translations.special_offers.list.cures.create),
    sessions: () => _t(translations.special_offers.list.cures.sessions),
    products: () => _t(translations.special_offers.list.cures.products),
    deleteConfirmationTitle: () =>
      _t(translations.special_offers.list.cures.delete.confirmation.title),
    deleteConfirmationDescritption: () =>
      _t(
        translations.special_offers.list.cures.delete.confirmation.description,
      ),
    deleteAlertSuccessDescription: () =>
      _t(
        translations.special_offers.list.cures.delete.alert.success.description,
      ),
    emptyLabel: () => _t(translations.special_offers.list.cures.empty_label),
  },
  discounts: {
    sectionTitle: () =>
      _t(translations.special_offers.list.discounts.section_title),
    create: () => _t(translations.special_offers.list.discounts.create),
    products: () => _t(translations.special_offers.list.discounts.products),
    availability: () =>
      _t(translations.special_offers.list.discounts.availability),
    deleteConfirmationTitle: () =>
      _t(translations.special_offers.list.discounts.delete.confirmation.title),
    deleteConfirmationDescritption: () =>
      _t(
        translations.special_offers.list.discounts.delete.confirmation
          .description,
      ),
    deleteAlertSuccessDescription: () =>
      _t(
        translations.special_offers.list.discounts.delete.alert.success
          .description,
      ),
    emptyLabel: () =>
      _t(translations.special_offers.list.discounts.empty_label),
  },
};
