import React, { useMemo } from 'react';
import { ListItem, ListItemProps } from 'app/components/ListItem';
import { SellOff } from 'types/API/selloffs';
import { useTranslation } from 'react-i18next';
import { messages } from '../../messages';
import { EntriesWrapper, InfoEntry } from 'app/components/ListItem/InfoEntry';

interface Props extends ListItemProps {
  sellOff: SellOff.Normal;
}

export const SellOffItem = (props: Props) => {
  const { sellOff, ...rest } = props

  const { t } = useTranslation();

  const entries = useMemo(() => {
    const startDate = new Date(sellOff.startDate);
    const endDate = new Date(sellOff.endDate);

    const entries: { key: string; label: string; value: string }[] = [
      {
        key: 'id',
        label: t(messages.id()),
        value: sellOff.id.toString(),
      },
      {
        key: 'name',
        label: t(messages.name()),
        value: sellOff.name,
      },
      {
        key: 'startDate',
        label: t(messages.startDate()),
        value: t(messages.dateFormat(), {
          date: startDate,
        }),
      },
      {
        key: 'sendDate',
        label: t(messages.endDate()),
        value: t(messages.dateFormat(), {
          date: endDate,
        }),
      }
    ];
    return entries;
  }, [sellOff, t]);

  return (
    <ListItem {...rest}>
      <EntriesWrapper>
        {entries?.map(entry => (
          <InfoEntry key={entry.key} label={entry.label} value={entry.value} />
        ))}
      </EntriesWrapper>
    </ListItem>
  );
};