import { Popover } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { Avatar } from 'app/components/Avatar';

import { AccountPanel } from '../AccountPanel';

interface Props {
  label: string;
}

export const AccountButton = ({ label }: Props) => {
  const buttonRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopoverOpen = Boolean(anchorEl);

  const handleClick = () => {
    setAnchorEl(buttonRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledPopover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <AccountPanel container="popover" onInstituteChange={handleClose} />
      </StyledPopover>
      <Content ref={buttonRef} onClick={handleClick}>
        <StyledAvatar fallbackLabel={label} size="2rem" />
      </Content>
    </>
  );
};

const Content = styled.div`
  width: 3rem;
  height: 4rem;
  background-color: ${({ theme }) => theme.secondary};
  border-radius: 1.5rem 1.5rem 0 0;
  padding: 0.5rem;
  cursor: pointer;
`;

const StyledPopover = styled(Popover)`
  & .MuiPopover-paper {
    background-color: ${({ theme }) => theme.itemBackground};
    border-radius: 15px;
    padding: 1rem;
  }
`;

const StyledAvatar = styled(Avatar)`
  && {
    font-weight: 800;
    font-size: 1rem;
  }
`;
