import moment from 'moment';
import { includes, indexOf } from 'lodash';
import i18next from 'i18next';
import {
  CalendarDigit,
  Day,
  DayValue,
} from '@hassanmojab/react-modern-calendar-datepicker';

import { messages } from './messages';

export const calendarLocale = () => {
  const months = moment.months();
  const defaultShortWeekdays = moment.weekdaysShort();
  const localeShortWeekdays = moment.weekdaysShort(true);
  const localeWeekdays = moment.weekdays(true);

  const weekdayIndices = localeShortWeekdays.map(weekday =>
    indexOf(defaultShortWeekdays, weekday),
  );

  return {
    months,
    weekDays: localeWeekdays.map((weekday, index) => ({
      name: weekday,
      short: localeShortWeekdays[index],
      isWeekend: includes([0, 6], weekdayIndices[index]),
    })),
    // For an unknown reason index from moment and this lib does not match
    // So we have to offset. For ex 1 (monday) correspond to 6 in this lib
    weekStartingIndex: (weekdayIndices[0] + 5) % 7,
    getToday(gregorainTodayObject: Day) {
      return gregorainTodayObject;
    },
    toNativeDate(date: Day) {
      return new Date(date.year, date.month - 1, date.day);
    },
    getMonthLength(date: Day) {
      return new Date(date.year, date.month, 0).getDate();
    },
    transformDigit(digit: CalendarDigit) {
      return digit;
    },
    nextMonth: i18next.t(messages.nextMonth()),
    previousMonth: i18next.t(messages.previousMonth()),
    openMonthSelector: i18next.t(messages.openMonthSelector()),
    openYearSelector: i18next.t(messages.openYearSelector()),
    closeMonthSelector: i18next.t(messages.closeMonthSelector()),
    closeYearSelector: i18next.t(messages.closeYearSelector()),
    defaultPlaceholder: i18next.t(messages.defaultPlaceholder()),
    from: i18next.t(messages.from()),
    to: i18next.t(messages.to()),
    digitSeparator: ',',
    yearLetterSkip: 0,
    isRtl: false,
  };
};

export const momentFromDay = (value: DayValue) => {
  if (!value) return undefined;
  const formattedDate = `${value.day}/${value.month}/${value.year}`;
  return moment(formattedDate, 'D/M/YYYY');
};

export const formattedDay = (value: DayValue, format: string) =>
  momentFromDay(value)?.format(format);

export const dateFromDay = (value: DayValue) => momentFromDay(value)?.toDate();

export const dayFromDate = (input: Date | string) => {
  const date = moment(input);
  return {
    year: date.year(),
    month: date.month() + 1,
    day: date.date(),
  };
};
