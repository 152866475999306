import { Discount } from 'types/API/discounts';
import { request } from 'utils/request';

export enum FetchMode {
  ALL = 0, // All special offers (active & outdated)
  ACTIVE, // Only active special offers
}

export const fetchDiscountsRequest = ({
  fetchMode,
  instituteId,
}: {
  fetchMode: FetchMode;
  instituteId: number;
}) => {
  const endPoint = `api/promotions/portal/${instituteId}`;
  return request<Discount.Normal[]>(endPoint, {
    method: 'GET',
  });
};

export const createDiscountRequest = (data: Discount.CreatePayload) =>
  request<Discount.Normal>(`api/promotions`, {
    method: 'POST',
    data,
  });

export const updateDiscountRequest = ({
  discountId,
  data,
}: {
  discountId: number;
  data: Discount.UpdatePayload;
}) =>
  request<Discount.Normal>(`api/promotions/${discountId}`, {
    method: 'PUT',
    data,
  });

export const deleteDiscountRequest = ({ id }: { id: number }) =>
  request<Discount.Normal>(`api/promotions/${id}`, {
    method: 'PUT',
    data: { deleted: true },
  });
