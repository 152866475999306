import {
  ToggleButtonGroup as MUIToggleButtonGroup,
  ToggleButton as MUIToggleButton,
} from '@material-ui/lab';
import styled from 'styled-components/macro';

export const ToggleButtonGroup = styled(MUIToggleButtonGroup)``;

export const ToggleButton = styled(MUIToggleButton)`
  &.MuiToggleButton-root {
    text-transform: inherit;
    word-break: normal;
  }

  &.MuiToggleButton-root {
    background-color: ${p => p.theme.input.background};
    color: ${p => p.theme.input.text.placeholder};
    font-family: 'Gabriel Sans Cond';
    padding: 0.5rem 1.5rem;
    font-weight: 500;
    font-size: 1rem;
    > span {
      opacity: 0.5;
    }
  }

  &.MuiToggleButton-root.Mui-selected,
  &.MuiToggleButton-root.Mui-selected:hover {
    background-color: ${p => p.theme.primary};
    color: ${p => p.theme.text.bright};
    > span {
      opacity: 1;
    }
  }
`;
