import { useCallback, useEffect, useMemo, useState } from 'react';

import { useQueries as useObjectivesQueries } from 'app/hooks/queries/objectives/useQueries';
import { Optional } from 'types/misc';
import { Month } from 'types/month';

import { monthlyFromWeeklyObjectives, rowsFromDailyObjectives } from '../utils';
import { Objectives } from 'types/API/objectives';

// Endpoints à prévoir
// GET objectives [month=YYYY-MM] => MonthlyObjectives (array of days with objectives)
// POST objectives [month=YYYY-MM, payload]
// PUT/PATCH objectives [month=YYYY-MM, payload]

export const useData = (options: { instituteId: number; month: Month }) => {
  const { month } = options;
  const { objectives: savedMonthlyObjectives, isLoading } =
    useObjectivesQueries(options);

  const [monthlyObjectives, setMonthlyObjectives] = useState<
    Optional<Objectives.Monthly>
  >(savedMonthlyObjectives);

  useEffect(() => {
    setMonthlyObjectives(savedMonthlyObjectives);
  }, [savedMonthlyObjectives]);

  //update local version
  const updateDailyObjective = useCallback(
    (day: number, value: number) => {
      const index = day - 1;
      if (monthlyObjectives) {
        const copy = [...monthlyObjectives];
        copy[index] = { total_sales: value };
        setMonthlyObjectives(copy);
      }
    },
    [monthlyObjectives, setMonthlyObjectives],
  );

  const setWeeklyObjectives = useCallback(
    (weeklyObjectives: any) => {
      const objectives = monthlyFromWeeklyObjectives({
        month,
        weeklyObjectives,
      });
      setMonthlyObjectives(objectives);
    },
    [month, setMonthlyObjectives],
  );

  const rows = useMemo(() => {
    return monthlyObjectives && monthlyObjectives.length > 0
      ? rowsFromDailyObjectives({ month, objectives: monthlyObjectives })
      : undefined;
  }, [monthlyObjectives, month]);

  return { isLoading, rows, setWeeklyObjectives, updateDailyObjective };
};
