import { useState, useEffect } from 'react';
import style from 'styled-components/macro';
import { request } from 'utils/request';
import { LoadableContent } from 'app/components/LoadableContent';
import { EmptyState } from 'app/components/EmptyState';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { EsthetStats, EsthetStatsStats } from 'types/API/stats';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { PieChart } from 'react-minimal-pie-chart';
import randomColor from 'randomcolor';
import Dropdown from 'react-dropdown';
import { makeStyles } from '@material-ui/core/styles';
import { InlineDatePicker } from './components/StatsContent/components/inlineDatePicker';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Visualisation',
    },
  },
};

registerLocale('fr', fr);

interface PieValue {
  title: string;
  value: number;
  color: string;
}

const keyTitle = {
  total: 'Total',
  care: 'Soins',
  sales: 'Ventes',
  contract: 'Contrats',
  avg: 'Moyenne',
  tips: 'Pourboires',
  epilation: 'Epilations',
  soinVisage: 'Soins visage',
  soinCorps: 'Soins corps',
  manucure: 'Manucure',
  promo: 'Promo',
  nbTicket: 'Nb Tickets',
  avgTicket: 'Ticket Moyen',
  ipl: 'IPL',
  cure: 'Cure',
};

export const EsthetSummary = props => {
  const { institute } = props;

  var date = new Date();
  let firstDayOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  const [startDate, setStartDate] = useState(firstDayOfTheMonth);
  const [endDate, setEndDate] = useState(new Date());

  const DateWrapper = style.div`
    margin-top: 20px;
    margin-bottom: 20px;
  `;

  const DateWrapperLabel = style.div`
  `;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState<EsthetStats[]>();

  const [visibleCamembert, setVisibleCamembert] = useState(false);
  const [pieValues, setPieValues] = useState<Array<PieValue>>([]);
  const [selectedEsthet, setSelectedEsthet] = useState<EsthetStats>();

  var statsTotaux = {
    Ventes: 0,
    Epilations: 0,
    'Soins visage': 0,
    'Soins corps': 0,
    Manucure: 0,
    IPL: 0,
    Cure: 0,
    Promo: 0,
    Total: 0,
  };

  const [barData, setBarData] = useState<{ labels: string[]; datasets: any[] }>(
    { labels: [], datasets: [] },
  );

  const [totaux, setTotaux] = useState<EsthetStatsStats>({
    total: 0,
    care: 0,
    sales: 0,
    contract: 0,
    avg: 0,
    tips: 0,
    epilation: 0,
    soinVisage: 0,
    soinCorps: 0,
    manucure: 0,
    promo: 0,
    nbTicket: 0,
    avgTicket: 0,
    ipl: 0,
    cures: 0,
  });

  const loadData = async (start, end) => {
    setStartDate(start);
    setEndDate(end);
    if (!loading) {
      setLoading(true);
      fetchEsthetStats({ instituteId: institute.id, start: start, end: end })
        .then(data => {
          setLoading(false);
          setData(data);
        })
        .catch(error => {
          setLoading(false);
          setError(error);
          console.log(error);
        });
    }
  };

  const calculateTotaux = () => {
    if (data) {
      var palmade: EsthetStatsStats = {
        total: 0,
        care: 0,
        sales: 0,
        contract: 0,
        avg: 0,
        tips: 0,
        epilation: 0,
        soinVisage: 0,
        soinCorps: 0,
        manucure: 0,
        promo: 0,
        nbTicket: 0,
        avgTicket: 0,
        ipl: 0,
        cures: 0,
      };

      for (var i = 0; i < data.length; i++) {
        let values = data[i].stats;
        Object.keys(values).forEach((elt, index) => {
          palmade[elt] += values[elt];
        });
      }

      setTotaux(palmade);
    }
  };

  const updateBarData = () => {
    if (selectedEsthet != undefined) {
      let names = Object.keys(selectedEsthet.stats).filter(
        t =>
          t != 'total' &&
          t != 'avg' &&
          t != 'care' &&
          t != 'nbTicket' &&
          t != 'tips' &&
          t != 'avgTicket',
      );
      var values: Array<number> = [];
      Object.keys(selectedEsthet.stats)
        .filter(
          t =>
            t != 'total' &&
            t != 'avg' &&
            t != 'care' &&
            t != 'nbTicket' &&
            t != 'tips' &&
            t != 'avgTicket',
        )
        .map(key => {
          values.push(selectedEsthet.stats[key]);
        });
      const barData = {
        labels: names.map(key => keyTitle[key]),
        datasets: [
          {
            label: "chiffre d'affaire",
            data: values,
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
        ],
      };

      setBarData(barData);
    }
  };

  useEffect(() => {
    calculateTotaux();
    return () => {};
  }, [data]);

  useEffect(() => {
    updateBarData();
    return () => {};
  }, [selectedEsthet]);

  const onEsthetSelection = (esthet: EsthetStats) => {
    console.log(esthet.user.id);
    setVisibleCamembert(!visibleCamembert);
    setSelectedEsthet(esthet);

    const keys = Object.keys(esthet.stats).filter(t => t !== 'total');

    const pieVal: PieValue[] = keys
      .filter(
        t =>
          t != 'total' &&
          t != 'avg' &&
          t != 'care' &&
          t != 'nbTicket' &&
          t != 'tips' &&
          t != 'avgTicket',
      )
      .filter(t => esthet.stats[t] > 0)
      .map(key => {
        const value: number = Math.round(esthet.stats[key]);
        const result: PieValue = {
          title: keyTitle[key],
          value: value,
          color: randomColor(),
        };
        return result;
      });
    setPieValues(pieVal);
  };

  const [sortFilter, setSortFilter] = useState('Total');
  var filters = [
    'Ventes',
    'Epilations',
    'Soins visage',
    'Soins corps',
    'Manucure',
    'IPL',
    'Cure',
    'Promo',
    'Total',
  ];
  const onSelect = option => {
    console.log('You selected ', option.label);
    setSortFilter(option.value);
  };

  let sortOrder = [
    'total',
    'care',
    'sales',
    'contract',
    'avg',
    'epilation',
    'soinVisage',
    'soinCorps',
    'manucure',
    'promo',
    'nbTicket',
    'avgTicket',
    'tips',
  ];

  const useStyles = makeStyles({
    colCell: {
      whiteSpace: 'nowrap',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
    },
  });

  const classes = useStyles();

  useEffect(() => {
    loadData(startDate, endDate);
  }, []);

  return (
    <div>
      <InlineDatePicker
        start={startDate}
        end={endDate}
        max={Date()}
        action={loadData}
      ></InlineDatePicker>

      <LoadableContent
        error={error}
        isLoading={loading}
        isEmpty={data === undefined}
        emptyNodeRenderer={() => (
          <EmptyState label={loading ? 'Chargement' : 'Pas de résultats'} />
        )}
      >
        {data && !visibleCamembert && (
          <div>
            <DropdownWrapper>
              Classer les résultats par:{' '}
              <Dropdown
                options={filters}
                value={sortFilter}
                onChange={onSelect.bind(this)}
                placeholder="Classer les résultats par:"
              />
            </DropdownWrapper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Esthéticienne</StyledTableCell>
                    {/* <StyledTableCell align="right">{keyTitle.care}</StyledTableCell> */}
                    <StyledTableCell align="right">
                      <span className={classes.colCell}>
                        <span>{keyTitle.sales}</span>
                        <span>{totaux.sales.toFixed(2)}</span>
                      </span>
                    </StyledTableCell>
                    {/* <StyledTableCell align="right">{keyTitle.contract}</StyledTableCell> */}
                    {/* <StyledTableCell align="right">{keyTitle.avg}</StyledTableCell> */}
                    {/* <StyledTableCell align="right">{keyTitle.tips}</StyledTableCell> */}
                    <StyledTableCell align="right">
                      <span className={classes.colCell}>
                        <span>{keyTitle.epilation}</span>
                        <span>{totaux.epilation.toFixed(2)}</span>
                      </span>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <span className={classes.colCell}>
                        <span>{keyTitle.soinVisage}</span>
                        <span>{totaux.soinVisage.toFixed(2)}</span>
                      </span>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <span className={classes.colCell}>
                        <span>{keyTitle.soinCorps}</span>
                        <span>{totaux.soinCorps.toFixed(2)}</span>
                      </span>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <span className={classes.colCell}>
                        <span>{keyTitle.manucure}</span>
                        <span>{totaux.manucure.toFixed(2)}</span>
                      </span>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <span className={classes.colCell}>
                        <span>{keyTitle.promo}</span>
                        <span>{totaux.promo.toFixed(2)}</span>
                      </span>
                    </StyledTableCell>
                    {/* <StyledTableCell align="right">{keyTitle.nbTicket}</StyledTableCell> */}
                    {/* <StyledTableCell align="right">{keyTitle.avgTicket}</StyledTableCell> */}
                    <StyledTableCell align="right">
                      <b>
                        <span className={classes.colCell}>
                          <span>{keyTitle.ipl}</span>
                          <span>{totaux.ipl.toFixed(2)}</span>
                        </span>
                      </b>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <b>
                        <span className={classes.colCell}>
                          <span>{keyTitle.cure}</span>
                          <span>{totaux.cures.toFixed(2)}</span>
                        </span>
                      </b>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <b>
                        <span className={classes.colCell}>
                          <span>{keyTitle.tips}</span>
                          <span>{totaux.tips.toFixed(2)}</span>
                        </span>
                      </b>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <b>
                        <span className={classes.colCell}>
                          <span>{keyTitle.total}</span>
                          <span>{totaux.total.toFixed(2)}</span>
                        </span>
                      </b>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .sort((a, b) => {
                      // ["", "", "", "", "", "IPL", "Cure", "", "Total"]
                      switch (sortFilter) {
                        case 'Ventes': {
                          return a.stats.sales > b.stats.sales ? -1 : 1;
                        }
                        case 'Epilations': {
                          return a.stats.epilation > b.stats.epilation ? -1 : 1;
                        }
                        case 'Soins visage': {
                          return a.stats.soinVisage > b.stats.soinVisage
                            ? -1
                            : 1;
                        }
                        case 'Soins corps': {
                          return a.stats.soinCorps > b.stats.soinCorps ? -1 : 1;
                        }
                        case 'Manucure': {
                          return a.stats.manucure > b.stats.manucure ? -1 : 1;
                        }
                        case 'Promo': {
                          return a.stats.promo > b.stats.promo ? -1 : 1;
                        }
                        default: {
                          return a.stats.total > b.stats.total ? -1 : 1;
                        }
                      }
                    }) //a.stats.total > b.stats.total ? -1 : 1)
                    .filter(item => {
                      return item.stats.total > 0;
                    })
                    .map(esthet => {
                      return (
                        <StyledTableRow
                          key="test"
                          onClick={() => onEsthetSelection(esthet)}
                        >
                          <StyledTableCell component="th" scope="row">
                            {esthet.user.firstname} {esthet.user.lastname}
                          </StyledTableCell>
                          {/* <StyledTableCell align="right">{esthet.stats.care.toFixed(2)}</StyledTableCell> */}
                          <StyledTableCell align="right">
                            {esthet.stats.sales.toFixed(2)}
                          </StyledTableCell>
                          {/* <StyledTableCell align="right">{esthet.stats.contract.toFixed(2)}</StyledTableCell> */}
                          {/* <StyledTableCell align="right">{esthet.stats.avg.toFixed(2)}</StyledTableCell> */}
                          {/* <StyledTableCell align="right">{esthet.stats.tips.toFixed(2)}</StyledTableCell> */}
                          <StyledTableCell align="right">
                            {esthet.stats.epilation.toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {esthet.stats.soinVisage.toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {esthet.stats.soinCorps.toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {esthet.stats.manucure.toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {esthet.stats.promo.toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {esthet.stats.ipl.toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {esthet.stats.cures.toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {esthet.stats.tips.toFixed(2)}
                          </StyledTableCell>
                          {/* <StyledTableCell align="right">{esthet.stats.nbTicket.toFixed(2)}</StyledTableCell> */}
                          <StyledTableCell align="right">
                            <b>{esthet.stats.total.toFixed(2)}</b>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        {visibleCamembert && (
          <div>
            <PieTitleRow>
              <b>
                {selectedEsthet?.user.firstname} {selectedEsthet?.user.lastname}
                : Total = {selectedEsthet?.stats.total.toFixed(2)}€
              </b>
              <CloseButton onClick={() => setVisibleCamembert(false)}>
                Retour au tableau
              </CloseButton>
            </PieTitleRow>
            <PieDataRow>
              {selectedEsthet &&
                Object.keys(selectedEsthet.stats)
                  .filter(t => t != 'contract' && t != 'avg' && t != 'care')
                  .sort((a, b) => {
                    return sortOrder.indexOf(a) - sortOrder.indexOf(b);
                  })
                  .map(key => {
                    return (
                      <div>
                        <b>{keyTitle[key]}:</b>
                        {key != 'nbTicket' && (
                          <p>{selectedEsthet.stats[key].toFixed(2)}€</p>
                        )}
                        {key == 'nbTicket' && (
                          <p>{selectedEsthet.stats[key]}</p>
                        )}

                        {/* <p>({selectedEsthet.stats[key] > 0 ? (selectedEsthet.stats[key] * 100 / selectedEsthet.stats.total).toFixed(0) : "0"}%)</p>   */}
                      </div>
                    );
                  })}
            </PieDataRow>
            {/* <PieDiv>
              <PieChart style={{ width: 650, height: 650 }}
                data={pieValues}
                label={({ dataEntry }) => dataEntry.title}
                labelStyle={{
                  fontSize: '3px',
                  fontFamily: 'sans-serif',
                  color: '#fff',
                }}
              />
            </PieDiv> */}

            <Bar options={options} data={barData} />
          </div>
        )}
      </LoadableContent>
    </div>
  );
};

const fetchEsthetStats = async ({
  instituteId,
  start,
  end,
}: {
  instituteId: number;
  start: Date;
  end: Date;
}) => {
  const startHourDate = start.setHours(0, 0, 0, 0);
  const startDateString = start.toISOString();
  const endHourDate = end.setHours(23, 59, 0, 0);
  const endDateString = end.toISOString();
  const response = request<EsthetStats[]>(`api/stat/user/institute`, {
    method: 'POST',
    data: {
      instituteId: instituteId,
      startDate: startDateString,
      endDate: endDateString,
    },
  });
  return response;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#cbcffe',
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'center',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PieDiv = style.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; 
    height: 100%;
  `;

const PieTitleRow = style.div`
    display: flex;
    justify-content: space-between;
    width: 100%; 
    margin-bottom: 20px;
    margin-top: 50px;
  `;

const PieDataRow = style.div`
    display: flex;
    justify-content: space-between;
    width: 100%; 
    margin-bottom: 20px;
    margin-top: 50px;
    background: white;
    border: none;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
  `;

const CloseButton = style.button`
  background: #e462a1;
  border: none;
  padding: 20px;
  border-radius: 20px;
  color: white;
`;

const DropdownWrapper = style.div`
    margin-top: 20px;
    margin-bottom: 20px;
  `;