import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { TextInput } from 'app/components/Inputs/TextInput';
import { fieldName } from 'utils/form';
import { FeatureFlip } from 'utils/feature-flip';

import { messages } from '../../../messages';
import { FiscalDates } from './FiscalDates';
import { Disableable } from 'app/components/Disableable';

interface Props {
  disabled: boolean;
}

export const Legal = ({ disabled }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <FormInputGrid item sm={6}>
        <FormLabel>{t(messages.siret.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name={fieldName('siret')}
          placeholder={t(messages.siret.placeholder())}
          required
        />
      </FormInputGrid>
      <FormInputGrid item sm={6}>
        <FormLabel>{t(messages.ape.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name={fieldName('ape')}
          placeholder={t(messages.ape.placeholder())}
          required
        />
      </FormInputGrid>
      <FormInputGrid item sm={6}>
        <FormLabel>{t(messages.vat.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name={fieldName('codetva')}
          placeholder={t(messages.vat.placeholder())}
          required
        />
      </FormInputGrid>
      <FormInputGrid item sm={6}></FormInputGrid>
      <Disableable enabled={FeatureFlip.institute.fiscalYearPeriod.isAvailable}>
        <FiscalDates disabled={disabled} />
      </Disableable>
    </Grid>
  );
};
