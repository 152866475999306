import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Optional } from 'types/misc';

import {
  DatePicker as UncontrolledDatePicker,
  Day,
  DayValue,
} from '../Uncontrolled/DatePicker';
import { dateFromDay, dayFromDate } from '../Uncontrolled/DatePicker/utils';

interface Props {
  disabled?: boolean;
  format?: string;
  minimumDate?: Day;
  maximumDate?: Day;
  name: string;
  placeholder?: string;
  required?: boolean;
}

const defaultFormat = 'DD/MM/YYYY';

export const DatePicker = (props: Props) => {
  const {
    disabled,
    format = defaultFormat,
    minimumDate,
    maximumDate,
    name,
    placeholder = defaultFormat,
  } = props;

  const { control } = useFormContext();

  const deserialize = useCallback(
    (serialized: Optional<string>): Optional<DayValue> => {
      if (!serialized) return undefined;
      return dayFromDate(serialized);
    },
    [],
  );

  const serialize = useCallback(
    (value: Optional<DayValue>) => dateFromDay(value),
    [],
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur, onChange, value } }) => {
        return (
          <UncontrolledDatePicker
            disabled={disabled}
            format={format}
            minimumDate={minimumDate}
            maximumDate={maximumDate}
            onBlur={onBlur}
            onChange={(value: DayValue) => {
              onChange(serialize(value));
            }}
            placeholder={placeholder}
            value={deserialize(value)}
          />
        );
      }}
    />
  );
};