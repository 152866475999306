export const isDiscountEditable = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  const now = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);
  return start < end && start > now;
};
