import moment from 'moment';

export type MonthIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export class Month {
  index: MonthIndex;

  year: number;

  constructor(index: MonthIndex, year: number) {
    this.index = index;
    this.year = year;
  }

  static current() {
    return Month.fromDate(new Date());
  }

  static fromDate(date: Date) {
    const derived = moment(date);
    return new Month(derived.month() as MonthIndex, derived.year());
  }

  toDate() {
    return moment(`${this.year}-${this.index + 1}`, 'YYYY-M').toDate();
  }

  toString() {
    return moment(this.toDate()).format('YYYY-MM');
  }

  byAddingMonths(number: number) {
    return Month.fromDate(moment(this.toDate()).add(number, 'M').toDate());
  }

  byAddingMonth() {
    return this.byAddingMonths(1);
  }

  bySubstractingMonths(number: number) {
    return Month.fromDate(moment(this.toDate()).subtract(number, 'M').toDate());
  }

  bySubstractingMonth() {
    return this.bySubstractingMonths(1);
  }

  days() {
    let dates: Date[] = [];
    const reference = this.toDate();
    const startOfMonth = new Date(
      reference.getFullYear(),
      reference.getMonth(),
      1,
    );
    let offset = startOfMonth;
    while (offset.getMonth() === this.index) {
      dates.push(new Date(offset));
      offset.setDate(offset.getDate() + 1);
    }
    return dates;
  }
}
