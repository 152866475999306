import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { ListItem, ListItemProps } from 'app/components/ListItem';
import { EntriesWrapper, InfoEntry } from 'app/components/ListItem/InfoEntry';
import { usePriceFormatter } from 'utils/formatters/hooks/price';
import { Receipt } from 'types/API/receipts';

import { messages } from './messages';

interface Props extends ListItemProps {
  currency: string;
  product: Receipt.DisplayableProduct;
}

export const ProductItem = (props: Props) => {
  const { currency, product, ...rest } = props;

  const { t } = useTranslation();

  const { formatPrice } = usePriceFormatter({ currency });

  const entries = useMemo(() => {
    const entries: { key: string; label: string; value: string }[] = [
      {
        key: 'unit_price',
        label: t(messages.unitPrice()),
        value: formatPrice(product.unitPrice),
      },
      {
        key: 'quantity',
        label: t(messages.quantity()),
        value: product.quantity,
      },
      {
        key: 'price',
        label: t(messages.price()),
        value: formatPrice(product.totalPrice),
      },
    ];
    return entries;
  }, [product, formatPrice, t]);

  return (
    <ListItem {...rest}>
      <NameLabel>{product.name}</NameLabel>
      <EntriesWrapper>
        {entries?.map(entry => (
          <InfoEntry key={entry.key} label={entry.label} value={entry.value} />
        ))}
      </EntriesWrapper>
    </ListItem>
  );
};

const NameLabel = styled.span`
  font-weight: 600;
  font-size: 1rem;
  color: ${p => p.theme.text.title};
`;
