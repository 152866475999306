import { DefaultValue } from 'recoil';

export const storageEffect =
  (key: string) =>
  ({ setSelf, onSet, trigger }) => {
    if (trigger === 'get') {
      const savedValue = localStorage.getItem(key);
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }
    }

    onSet((newValue: any) => {
      if (newValue instanceof DefaultValue) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };
