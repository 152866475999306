import { first } from 'lodash';
import { Customer } from 'types/API/customers';

export const isCustomerDebtor = (customer: Customer.Normal) => {
  const subscription = first(customer.subscriptions);
  const payment = first(subscription?.subscriptionPayments);
  if (payment) {
    const sameNames =
      payment.firstname === customer.firstname &&
      payment.lastname === customer.lastname;
    const sameAddress =
      payment.address === customer.address &&
      payment.city === customer.city &&
      payment.zipcode === customer.zipcode &&
      (payment.complement ?? '') === customer.complement;
    return sameNames && sameAddress;
  }
  // Default value is no payment is filled
  return true;
};
