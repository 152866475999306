import { MutateOptions } from 'react-query';
import { useSetRecoilState } from 'recoil';

import { snackbarState } from 'app/atoms';

type SuccessCallbackType<TData, TVariables, TContext> = (
  data: TData,
  variables: TVariables,
  context: TContext,
) => void;

type MutationOptionsType<TData, TVariables, TContext, TError extends Error> = {
  successCallback?: SuccessCallbackType<TData, TVariables, TContext>;
  successMessage?: string;
  errorCallback?: (error: TError) => void;
  errorMessage?: (error: TError) => string;
};

export function useMutationOptions<
  TData = unknown,
  TError extends Error = Error,
  TVariables = unknown,
  TContext = unknown,
>({
  successCallback,
  successMessage,
  errorCallback,
  errorMessage,
}: MutationOptionsType<TData, TVariables, TContext, TError>): MutateOptions<
  TData,
  TError,
  TVariables,
  TContext
> {
  const setSnackbarState = useSetRecoilState(snackbarState);

  const handleError = (error: TError) => {
    const message = errorMessage?.(error) ?? error.message;
    setSnackbarState({
      label: message,
      open: true,
      severity: 'error',
    });

    errorCallback?.(error);
  };

  const handleSuccess = (
    data: TData,
    variables: TVariables,
    context: TContext,
  ) => {
    if (successMessage) {
      setSnackbarState({
        label: successMessage,
        open: true,
        severity: 'success',
      });
    }

    successCallback?.(data, variables, context);
  };

  return {
    onSuccess(data, variables, context) {
      handleSuccess(data, variables, context);
    },
    onError(error) {
      handleError(error);
    },
  };
}
