import React from 'react';
import styled from 'styled-components/macro';

import {
  PageHeader,
  PageHeaderProps,
} from 'app/components/PageHeaders/PageHeader';
import { enabledIfSet } from 'utils/components/enabledIfSet';
import { Receipt } from 'types/API/receipts';
import { Optional } from 'types/misc';

import { Summary } from '../Summary';

interface Props extends PageHeaderProps {
  currency: string;
  summary: Optional<{
    count: number;
    total: Receipt.Total;
  }>;
}

export const Header = (props: Props) => {
  const { currency, summary, ...rest } = props;

  return (
    <TitleWrapper>
      <PageHeader {...rest} />
      {enabledIfSet(summary, summary => (
        <Summary currency={currency} {...summary} />
      ))}
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
