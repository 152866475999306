import { find, includes } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';

import { paths } from 'app/routes/paths';

import { ReactComponent as DebitsIcon } from 'app/assets/menu/default/debits.svg';
import { ReactComponent as DebitsIconSelected } from 'app/assets/menu/selected/debits.svg';

import { ReactComponent as DiscountsIcon } from 'app/assets/menu/default/discounts.svg';
import { ReactComponent as DiscountsIconSelected } from 'app/assets/menu/selected/discounts.svg';

import { ReactComponent as InstituteIcon } from 'app/assets/menu/default/institute.svg';
import { ReactComponent as InstituteIconSelected } from 'app/assets/menu/selected/institute.svg';

import { ReactComponent as ReceiptsIcon } from 'app/assets/menu/default/receipts.svg';
import { ReactComponent as ReceiptsIconSelected } from 'app/assets/menu/selected/receipts.svg';

import { ReactComponent as SubscriptionsIcon } from 'app/assets/menu/default/subscriptions.svg';
import { ReactComponent as SubscriptionsIconSelected } from 'app/assets/menu/selected/subscriptions.svg';

import { ReactComponent as UsersIcon } from 'app/assets/menu/default/users.svg';
import { ReactComponent as UsersIconSelected } from 'app/assets/menu/selected/users.svg';

import { ReactComponent as SellOffIcon } from 'app/assets/menu/default/institute.svg';
import { ReactComponent as SellOffIconSelected } from 'app/assets/menu/selected/institute.svg';

import {
  TrendingUpOutlined as ObjectivesIcon,
  ShoppingCartOutlined as OrdersIcon,
  BarChartOutlined as StatsIcon,
  HelpOutline as HelpIcon,
  MenuBook as MenuBook,
  Archive,
} from '@material-ui/icons';

import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

import { MenuItem, MenuItemConfiguration } from '../types';
import { messages } from '../messages';
import { matchPath } from 'react-router-dom';
import { FeatureFlip } from 'utils/feature-flip';
import { useSelectors } from './useSelectors';
import { hasEntitlement } from 'utils/roles/hasEntitlement';

export const useData = () => {
  const { t } = useTranslation();

  const { me } = useSelectors();

  const pathsPredicate = useCallback(
    (path: string, paths: string[]) =>
      !!find(paths, candidate =>
        matchPath(path, {
          path: candidate,
          exact: true,
        }),
      ),
    [],
  );

  const icon = useCallback((item: MenuItem) => {
    return (selected: boolean) => {
      switch (item) {
        case MenuItem.RECEIPTS:
          return selected ? <ReceiptsIconSelected /> : <ReceiptsIcon />;
        case MenuItem.STATS:
          return selected ? <StatsIcon color="secondary" /> : <StatsIcon />;
        case MenuItem.SUBCRIPTIONS:
          return selected ? (
            <SubscriptionsIconSelected />
          ) : (
            <SubscriptionsIcon />
          );
        case MenuItem.DEBITS:
          return selected ? <DebitsIconSelected /> : <DebitsIcon />;
        case MenuItem.DISCOUNTS:
          return selected ? <DiscountsIconSelected /> : <DiscountsIcon />;
        case MenuItem.USERS:
          return selected ? <UsersIconSelected /> : <UsersIcon />;
        case MenuItem.INSTITUTE:
          return selected ? <InstituteIconSelected /> : <InstituteIcon />;
        case MenuItem.OBJECTIVES:
          return selected ? (
            <ObjectivesIcon color="secondary" />
          ) : (
            <ObjectivesIcon />
          );
        case MenuItem.ORDERS:
          return selected ? <OrdersIcon color="secondary" /> : <OrdersIcon />;
        case MenuItem.SELLOFF:
          return selected ? <LoyaltyIcon /> : <LoyaltyIcon />;
        case MenuItem.ADDINSTITUTES:
          return selected ? <InstituteIconSelected /> : <InstituteIcon />;
        case MenuItem.TILLS:
          return selected ? <PointOfSaleIcon /> : <PointOfSaleIcon />;
        case MenuItem.FAQ:
          return selected ? <HelpIcon color="secondary" /> : <HelpIcon />;
        case MenuItem.MANUAL:
          return selected ? <MenuBook color="secondary" /> : <MenuBook />;
        case MenuItem.FISCAL_ARCHIVES:
          return selected ? <Archive color="secondary" /> : <Archive />;
      }
    };
  }, []);

  const restrictedItems: MenuItem[] = useMemo(() => {
    let items: MenuItem[] = [];
    if (!FeatureFlip.objectives.isAvailable) {
      items.push(MenuItem.OBJECTIVES);
    }
    // removing restriction for orders
    // if (!FeatureFlip.orders.isAvailable) {
    //   items.push(MenuItem.ORDERS);
    //   console.log("########################### order not available")
    // } else {
    //   console.log("########################### order Available")
    // }
    if (!FeatureFlip.stats.isAvailable) {
      items.push(MenuItem.STATS);
    }
    if (!hasEntitlement('debits_management', me)) {
      items.push(MenuItem.DEBITS);
    }
    if (!hasEntitlement('institute_management', me)) {
      items.push(MenuItem.INSTITUTE);
    }
    if (!hasEntitlement('add_institute_management', me)) {
      items.push(MenuItem.ADDINSTITUTES);
    }
    if (!hasEntitlement('institute_management', me)) {
      items.push(MenuItem.TILLS);
    }
    return items;
  }, [me]);

  const menuItems: MenuItemConfiguration[] = useMemo(() => {
    const items: MenuItemConfiguration[] = [
      {
        icon: icon(MenuItem.RECEIPTS),
        id: MenuItem.RECEIPTS,
        title: t(messages.receipts()),
        path: paths.receipts.list.cases.pending,
        selectionPredicate: (path: string) =>
          pathsPredicate(path, [
            paths.receipts.list.pattern,
            paths.receipts.detail,
          ]),
      },
      {
        icon: icon(MenuItem.STATS),
        id: MenuItem.STATS,
        title: t(messages.stats()),
        path: paths.stats.index,
        selectionPredicate: (path: string) =>
          pathsPredicate(path, [paths.stats.index, paths.stats.pattern]),
      },
      {
        icon: icon(MenuItem.SUBCRIPTIONS),
        id: MenuItem.SUBCRIPTIONS,
        title: t(messages.subscriptions()),
        path: paths.subscriptions.list.cases.pending,
        selectionPredicate: (path: string) =>
          pathsPredicate(path, [
            paths.subscriptions.list.pattern,
            paths.subscriptions.update,
          ]),
      },
      {
        icon: icon(MenuItem.DISCOUNTS),
        id: MenuItem.DISCOUNTS,
        title: t(messages.specialOffers()),
        path: paths.specialOffers.list,
        selectionPredicate: (path: string) =>
          pathsPredicate(path, [
            paths.specialOffers.list,
            paths.specialOffers.cures.create,
            paths.specialOffers.cures.update,
            paths.specialOffers.discounts.create,
            paths.specialOffers.discounts.update,
          ]),
      },
      {
        icon: icon(MenuItem.DEBITS),
        id: MenuItem.DEBITS,
        title: t(messages.debits()),
        path: paths.debits.list.cases.auto,
        selectionPredicate: (path: string) =>
          pathsPredicate(path, paths.debits.list.patterns),
      },
      {
        icon: icon(MenuItem.USERS),
        id: MenuItem.USERS,
        title: t(messages.users()),
        path: paths.users.list,
        selectionPredicate: (path: string) =>
          pathsPredicate(path, [
            paths.users.list,
            paths.users.create,
            paths.users.update,
          ]),
      },
      {
        icon: icon(MenuItem.INSTITUTE),
        id: MenuItem.INSTITUTE,
        title: t(messages.institute()),
        path: paths.institutes.update,
        selectionPredicate: (path: string) =>
          pathsPredicate(path, [paths.institutes.update]),
      },
      {
        icon: icon(MenuItem.OBJECTIVES),
        id: MenuItem.OBJECTIVES,
        title: t(messages.objectives()),
        path: paths.objectives.index,
        selectionPredicate: (path: string) =>
          pathsPredicate(path, [
            paths.objectives.index,
            paths.objectives.editObjectives,
          ]),
      },
      {
        icon: icon(MenuItem.ORDERS),
        id: MenuItem.ORDERS,
        title: t(messages.orders()),
        path: paths.orders.list,
        selectionPredicate: (path: string) =>
          pathsPredicate(path, [
            paths.orders.list,
            paths.orders.create,
            paths.orders.update,
          ]),
      },
      {
        icon: icon(MenuItem.SELLOFF),
        id: MenuItem.SELLOFF,
        title: t(messages.selloff()),
        path: paths.selloffs.list,
        selectionPredicate: (path: string) =>
          pathsPredicate(path, [paths.selloffs.list]),
      },
      {
        icon: icon(MenuItem.TILLS),
        id: MenuItem.TILLS,
        title: t(messages.tills()),
        path: paths.tills.index,
        selectionPredicate: (path: string) =>
          pathsPredicate(path, [paths.tills.index]),
      },
      {
        icon: icon(MenuItem.FAQ),
        id: MenuItem.FAQ,
        title: t(messages.faq()),
        path: paths.faq.index,
        selectionPredicate: (path: string) =>
          pathsPredicate(path, [paths.faq.index]),
      },
      {
        icon: icon(MenuItem.ADDINSTITUTES),
        id: MenuItem.ADDINSTITUTES,
        title: t(messages.addInstitutes()),
        path: paths.addInstitutes.index,
        selectionPredicate: (path: string) =>
          pathsPredicate(path, [paths.addInstitutes.index]),
      },
      {
        icon: icon(MenuItem.MANUAL),
        id: MenuItem.MANUAL,
        title: t(messages.manual()),
        path: paths.manual.index,
        selectionPredicate: (path: string) =>
          pathsPredicate(path, [paths.manual.index]),
      },
      {
        icon: icon(MenuItem.FISCAL_ARCHIVES),
        id: MenuItem.FISCAL_ARCHIVES,
        title: t(messages.fiscalArchives()),
        path: paths.fiscalArchives.index,
        selectionPredicate: (path: string) =>
          pathsPredicate(path, [paths.fiscalArchives.index]),
      },
    ];
    return items.filter(item => !includes(restrictedItems, item.id));
  }, [icon, pathsPredicate, restrictedItems, t]);

  return { menuItems };
};
