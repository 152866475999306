import { DialogActions } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

const StyledDialogActions = styled(DialogActions)`
  justify-content: 'flex-end';
  padding: 0 40px 30px;

  & > :last-child {
    margin-left: 16px;
  }
`;

export const Footer = ({ children }: PropsWithChildren<{}>) => (
  <StyledDialogActions>{children}</StyledDialogActions>
);
