import React from 'react';
import { Grid } from '@material-ui/core';

import { InputConfiguration } from '../../../../types/inputs';
import { Input } from './inputs/Input';

interface Props {
  canSubmit: boolean;
  isSubmitting: boolean;
  configurations: InputConfiguration[];
}

export const Form = (props: Props) => {
  const { canSubmit, configurations, isSubmitting } = props;

  return (
    <Grid container direction="row-reverse" spacing={1}>
      {configurations
        .slice()
        .reverse()
        .map((configuration, index) => (
          <Input
            configuration={configuration}
            disabled={isSubmitting}
            // Index never change between two render loop, should be fine to use it as the component key.
            key={index}
          />
        ))}
    </Grid>
  );
};
