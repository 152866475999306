import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';

import { FormWrapper } from 'app/components/Forms/FormWrapper';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';

import { paths } from 'app/routes/paths';
import { LoadableContent } from 'app/components/LoadableContent';
import { Mode } from 'types/forms';
import { useContextModuleNavigator } from 'app/hooks';
import { useInstituteSelector } from 'app/atoms/selectors';

import { messages } from './messages';
import { useForm, useFormData, useQueries } from './hooks';
import { Form } from './Form';

export const CurePage = () => {
  const { institute } = useInstituteSelector();

  const { id }: { id?: string } = useParams();

  const currency = institute.country.currency;

  const { popToRoot } = useContextModuleNavigator({
    context: institute.id,
    rootEndpoint: paths.specialOffers.list,
  });

  const { t } = useTranslation();

  const { mode, cureId } = useMemo(() => {
    const m = id ? Mode.Update : Mode.Create;
    return {
      mode: m,
      cureId: id ? parseInt(id) : undefined,
    };
  }, [id]);

  const { cure, error, isLoading, products } = useQueries({
    cureId,
    instituteId: institute.id,
  });

  const formData = useFormData({
    currency,
    products: products ?? [],
  });

  const { canSubmit, isSubmitting, methods, onSubmit } = useForm({
    cure,
    mode,
    instituteId: institute.id,
    onSuccess: popToRoot,
  });

  const pageTitle = useMemo(() => {
    switch (mode) {
      case Mode.Create:
        return t(messages.createPageTitle());
      case Mode.Update:
        return t(messages.updatePageTitle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageWrapper header={<PageHeader backBehaviour label={pageTitle} />}>
        <LoadableContent error={error} isLoading={isLoading}>
          <FormWrapper
            id="cure-edit-form"
            methods={methods}
            onSubmit={onSubmit}
          >
            <Form
              canSubmit={canSubmit}
              currency={currency}
              isSubmitting={isSubmitting}
              {...formData}
            />
          </FormWrapper>
        </LoadableContent>
      </PageWrapper>
    </>
  );
};
