import { User } from 'types/API/users';

export interface JWTContent {
  exp: number;
  iat: number;
  roles: User.Role[];
  userid: number;
  username: string;
}
export interface AuthorizationState {
  readonly refresh_token?: string;
  readonly token?: string;
}

export const defaultState: AuthorizationState = {
  refresh_token: undefined,
  token: undefined,
};
