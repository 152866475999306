import { Subscription } from 'types/API/subscriptions';
import { request } from 'utils/request';

export const updateSubscriptionRequest = ({
  subscriptionId,
  data,
}: {
  subscriptionId: number;
  data: Subscription.Payload;
}) =>
  request<any>(`api/subscriptions/${subscriptionId}`, {
    method: 'PUT',
    data,
  });

export const createSubscriptionRequest = (data: any) =>
  request<any>(`api/customers`, {
    method: 'POST',
    data,
  });

export const resiliateSubscriptionRequest = ({
  subscriptionId,
}: {
  subscriptionId: number;
}) => {
  console.log(`resiliate debit resiliateSubscriptionRequest`);
  // const endDate = new Date().toLocaleString("fr-FR")
  const endDate = new Date().toISOString();
  //.replace(',', '')
  const a = new Date().toDateString();
  const b = new Date().toString();
  const data = { endDate: endDate };
  // "2022-01-17T17:26:36.649Z" 503
  // const data = {"endDate":"25/05/2021 15:00"}
  return request<any>(`api/subscription_dates/${subscriptionId}`, {
    method: 'PUT',
    data,
  });
};

export const resiliateOldSubscriptionRequest = ({
  subscriptionId,
  instituteId,
  idabo,
}: {
  subscriptionId: number;
  instituteId: number;
  idabo: string;
}) => {
  console.log(`resiliate debit resiliateSubscriptionRequest`);
  const data = {
    subscriptionid: subscriptionId,
    instituteId: instituteId,
    idabo: idabo,
  };
  return request<any>(`api/prelevement/old/terminate`, {
    method: 'POST',
    data,
  });
};
