import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

import { StatKind } from './types';

export const messages = {
  pageTitle: (kind: StatKind) => _t(translations.stats.pages[kind].page_title),
  dailyDateFormat: () => _t(translations.stats.daily_date_format),
  weeklyDateFormat: () => _t(translations.stats.weekly_date_format),
  dropdown: {
    service: {
      label: () => _t(translations.stats.inputs.dropdown.service.label),
      placeholder: () =>
        _t(translations.stats.inputs.dropdown.service.placeholder),
    },
    user: {
      label: () => _t(translations.stats.inputs.dropdown.user.label),
      placeholder: () =>
        _t(translations.stats.inputs.dropdown.user.placeholder),
    },
  },
  metrics: {
    date: () => _t(translations.stats.metrics.date),
    turnover: () => _t(translations.stats.metrics.turnover),
    card: () => _t(translations.stats.metrics.card),
    cheque: () => _t(translations.stats.metrics.cheque),
    cash: () => _t(translations.stats.metrics.cash),
    subscribers_turnover: () =>
      _t(translations.stats.metrics.subscribers_turnover),
    passing_turnover: () => _t(translations.stats.metrics.passing_turnover),
    debits_turnover: () => _t(translations.stats.metrics.debits_turnover),
    debits_young_turnover: () =>
      _t(translations.stats.metrics.debits_young_turnover),
    anticipation_turnover: () =>
      _t(translations.stats.metrics.anticipation_turnover),
    anticipation_young_turnover: () =>
      _t(translations.stats.metrics.anticipation_young_turnover),
    ce_turnover: () => _t(translations.stats.metrics.ce_turnover),
  },
};
