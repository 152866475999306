import React, { useCallback, useMemo } from 'react';
// See https://github.com/Kiarash-Z/react-modern-calendar-datepicker/issues/204
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import RawDatePicker, {
  Day,
  DayValue,
  RenderInputProps,
} from '@hassanmojab/react-modern-calendar-datepicker';

import './styles.css';
import { TextInput as RawTextInput } from '../TextInput';
import { theme } from 'styles/theme/themes';
import { calendarLocale, dayFromDate, formattedDay } from './utils';
import styled from 'styled-components/macro';

export type { Day, DayValue };

interface Props {
  disabled?: boolean;
  format: string;
  minimumDate?: Day;
  maximumDate?: Day;
  onBlur?: () => void;
  onChange?: (value: DayValue) => void;
  placeholder?: string;
  required?: boolean;
  value?: DayValue;
}

export const DatePicker = (props: Props) => {
  const {
    disabled,
    format,
    minimumDate = dayFromDate(new Date()),
    maximumDate,
    onBlur,
    onChange,
    placeholder,
    required,
    value,
  } = props;

  const locale = useMemo(() => calendarLocale(), []);

  const renderCustomInput = useCallback(
    ({ ref }: RenderInputProps) => (
      <TextInput
        aria-readonly={true}
        disabled={disabled}
        onBlur={onBlur}
        placeholder={placeholder}
        inputRef={ref}
        fullWidth
        autoComplete="off"
        margin="none"
        value={formattedDay(value, format)}
        variant="outlined"
        required={required}
      />
    ),
    [disabled, format, onBlur, placeholder, required, value],
  );

  return (
    <RawDatePicker
      colorPrimary={theme.primary}
      locale={locale}
      minimumDate={minimumDate}
      maximumDate={maximumDate}
      onChange={onChange}
      renderInput={renderCustomInput}
      shouldHighlightWeekends
      value={value}
    />
  );
};

const TextInput = styled(RawTextInput)`
  & .MuiInputBase-input {
    cursor: pointer;
  }
`;
