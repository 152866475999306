import { Auth } from 'types/API/auth';
import { User } from 'types/API/users';
import { request } from 'utils/request';

export const signinEndpoint = 'login_check';

export const sendSigninRequest = (data: Auth.SigninPayload) =>
  request<Auth.SigninResponse>(signinEndpoint, {
    method: 'POST',
    data,
  });

export const registerUserRequest = (data: Auth.RegisterPayload) =>
  request<User.DetailFormat>('register', {
    method: 'POST',
    data,
  });
