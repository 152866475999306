import {
  Cell,
  Pie,
  PieChart as DefaultPieChart,
  ResponsiveContainer,
  Sector,
} from 'recharts';
import { useCallback, useState } from 'react';

import { PieChartDescription } from 'app/pages/Stats/StatsPage/types/charts';
import { PropertyMapping } from 'app/pages/Stats/StatsPage/types';

interface Props {
  description: PieChartDescription;
  propertyMapping: PropertyMapping;
  values: any[];
}

export const PieChart = (props: Props) => {
  const { description, values } = props;

  const { dataProperty, nameProperty } = description;

  // const { propertyLabel } = usePropertyMapping({ propertyMapping });

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const renderActiveShape = useCallback(props => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      name,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  }, []);

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <ResponsiveContainer>
      <DefaultPieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={values}
          dataKey={dataProperty}
          nameKey={nameProperty}
          cx="50%"
          cy="50%"
          onMouseEnter={onPieEnter}
          innerRadius="40%"
          outerRadius="80%"
        >
          {values.map((_, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </DefaultPieChart>
    </ResponsiveContainer>
  );
};
