import Moment from 'moment';
import { useState, useEffect } from 'react';
import style from 'styled-components/macro';
import { request } from 'utils/request';
import { BonusStats } from 'types/API/stats';
import { LoadableContent } from 'app/components/LoadableContent';
import { EmptyState } from 'app/components/EmptyState';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useInstituteSelector } from 'app/atoms/selectors';
import { InlineDatePicker } from './components/StatsContent/components/inlineDatePicker';


export const Bonus = (props) => { 

  const { institute } = useInstituteSelector();

  let lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);

  var date = new Date();
  let firstDayOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  const [startDate, setStartDate] = useState(firstDayOfTheMonth);
  const [endDate, setEndDate] = useState(new Date());

  const DateWrapper = style.div`
    margin-top: 20px;
    margin-bottom: 20px;
  `;

  const DateWrapperLabel = style.div`
  `;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState<BonusStats[]>();
  const [totaux, setTotaux] = useState([0, 0, 0, 0, 0]);

  const loadData = async (start: Date, end: Date) => {
    setStartDate(start)
    setEndDate(end)
    if (!loading) {
      setLoading(true)
      fetchBonusStats({ instituteId: institute.id, start: start, end: end }).then(data => {
        setLoading(false)
        setData(data)
      }).catch(error => {
        setLoading(false)
        setError(error)
      })
    }
  }

  function calcultateTotaux() {
    if (data) {
      let array = [0, 0, 0, 0, 0];
      for (let i = 0; i < data.length; i++) {
        array[0] += data[i].ca;
        array[1] += data[i].passage;
        array[2] += data[i].antip;
        array[3] += data[i].vente;
        array[4] += data[i].basePrime;
      }
      setTotaux(array);
    }
  }

  useEffect(() => {
    loadData(startDate, endDate);
    return () => { };
  }, [institute]);

  useEffect(() => {

    calcultateTotaux();
    return () => { };
  }, [data]);

  return (
    <div>
      <p>Institut: {institute.name} ({(Moment(startDate).format('DD MMMM yyyy'))} - {(Moment(endDate).format('DD MMMM yyyy'))}) </p>
      
      <InlineDatePicker start={startDate} end={endDate} max={Date()} action={loadData} ></InlineDatePicker>

      <LoadableContent
        error={error}
        isLoading={loading}
        isEmpty={data === undefined}
        emptyNodeRenderer={() => (
          <EmptyState label={loading ? "Chargement" : "Pas de résultats"} />
        )}
      >
        {data &&
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Esthéticienne</StyledTableCell>
                  <StyledTableCell align="right"><CellCol><p>Total CA</p><p>{totaux[0].toFixed(2)}€</p></CellCol></StyledTableCell>
                  <StyledTableCell align="right"><CellCol><p>CA Passage</p><p>{totaux[1].toFixed(2)}€</p></CellCol></StyledTableCell>
                  <StyledTableCell align="right"><CellCol><p>CA Abonnement Antip</p><p>{totaux[2].toFixed(2)}€</p></CellCol></StyledTableCell>
                  <StyledTableCell align="right"><CellCol><p>CA Vente</p><p>{totaux[3].toFixed(2)}€</p></CellCol></StyledTableCell>
                  <StyledTableCell align="right"><CellCol><p>Total base Prime HT</p><p>{totaux[4].toFixed(2)}€</p></CellCol></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.sort((a, b) => a.ca > b.ca ? -1 : 1)
                  .filter(item => {
                    return item.basePrime > 0
                  }).map(bonus => {
                  return (
                    <StyledTableRow key="test">
                      <StyledTableCell component="th" scope="row">
                        {bonus.name}
                      </StyledTableCell>
                      <StyledTableCell align="right"><STCell>{bonus.ca.toFixed(2)}</STCell></StyledTableCell>
                      <StyledTableCell align="right"><STCell>{bonus.passage.toFixed(2)}</STCell></StyledTableCell>
                      <StyledTableCell align="right"><STCell>{bonus.antip.toFixed(2)}</STCell></StyledTableCell>
                      <StyledTableCell align="right"><STCell>{bonus.vente.toFixed(2)}</STCell></StyledTableCell>
                      <StyledTableCell align="right"><STCell>{bonus.basePrime.toFixed(2)}</STCell></StyledTableCell>
                    </StyledTableRow>
                  )
                })
                }
              </TableBody>
            </Table>
          </TableContainer>
        }
        

      </LoadableContent>
    </div>
  )

}

const fetchBonusStats = async ({ instituteId, start, end }: { instituteId: number, start: Date, end: Date }) => {
  let oneOClockStartDate = new Date(start.setHours(7, 0, 0, 0))
  let elevenOClockEndDate = new Date(end.setHours(23, 0, 0, 0))
  const response = request<BonusStats[]>(`api/stat/prime`, {
    method: 'POST',
    data: { "instituteId": instituteId, "startDate": oneOClockStartDate, "endDate": elevenOClockEndDate },
  });
  return response;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#cbcffe',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const CellCol = style.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;

`;

const STCell = style.div`
    display: flex;
    align-items: center;
    justify-content: center;


`;