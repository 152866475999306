import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components/macro';

type Props = PropsWithChildren<{
  disabled?: boolean;
  secondaryContent: ReactNode;
}>;

export const PortraitPageWrapper = ({
  children,
  disabled = false,
  secondaryContent,
}: Props) => (
  <Wrapper disabled={disabled}>
    {secondaryContent}
    <ContentWrapper>{children}</ContentWrapper>
  </Wrapper>
);

interface WrapperProps {
  disabled: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  margin: 0 auto;
  padding: 0;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: ${p => p.theme.primary};
  pointer-events: ${p => (p.disabled ? 'none' : 'inherited')};
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding: 1rem;
  background: ${p => p.theme.pageBackground};
  border-radius: 1rem 1rem 0 0;
`;
