import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';

import { Order } from 'types/API/orders';
import { ListItem, ListItemProps } from 'app/components/ListItem';
import { EntriesWrapper, InfoEntry } from 'app/components/ListItem/InfoEntry';
import { usePriceFormatter } from 'utils/formatters/hooks/price';
import { Status } from 'app/components/Status';

import { messages } from '../../messages';

interface Props extends ListItemProps {
  currency: string;
  order: Order.Normal;
}

export const OrderItem = (props: Props) => {
  const { currency, order, ...rest } = props;

  const { t } = useTranslation();

  const { formatPrice } = usePriceFormatter({ currency });

  let status: string = order.active ? "Oui" : "Non"

  const entries = useMemo(() => {
    const orderDate = new Date(order.createdAt);

    const entries: { key: string; label: string; value: string }[] = [
      {
        key: 'id',
        label: t(messages.id()),
        value: order.orderNumber,
      },
      {
        key: 'date',
        label: t(messages.date()),
        value: t(messages.dateFormat(), {
          date: orderDate,
        }),
      },
      {
        key: 'active',
        label: 'Validée',
        value: status,
      }
    ];
    return entries;
  }, [order, formatPrice, t]);

  return (
    <ListItem {...rest}>
      <EntriesWrapper>
        {entries?.map(entry => (
          <InfoEntry key={entry.key} label={entry.label} value={entry.value} />
        ))}
      </EntriesWrapper>
    </ListItem>
  );
};
