import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { first } from 'lodash';

import { FormWrapper } from 'app/components/Forms/FormWrapper';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { LoadableContent } from 'app/components/LoadableContent';
import { isDeptingSubscription } from 'utils/entities/subscription';
import { useContextModuleNavigator } from 'app/hooks';
import { paths } from 'app/routes/paths';

import { messages } from './messages';
import { useForm, useQueries, useSelectors } from './hooks';
import { Form } from './Form';
import { Header } from './components/Header';
import styled from 'styled-components/macro';
import { request } from 'utils/request';

import { hasEntitlement } from 'utils/roles/hasEntitlement';
import { meState } from 'app/atoms';
import { useRecoilValue } from 'recoil';

export const SubscriptionPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { id, cardNumber }: { id: string; cardNumber: string } = useParams();

  const customerId = parseInt(id);

  const { currentInstituteId: instituteId } = useSelectors();

  const { customer, error, isLoading } = useQueries({ customerId, cardNumber, instituteId });

  const me = useRecoilValue(meState);

  useContextModuleNavigator({
    context: instituteId,
    rootEndpoint: paths.subscriptions.list.cases.pending,
  });

  const subscription = first(customer?.subscriptionsInstitute);

  const deptingSubscription = subscription
    ? isDeptingSubscription({
        subscription,
      })
    : false;

  // Only display rejected debit option if the IBAN is set.
  const displayRejectedDebit =
    deptingSubscription && !!first(subscription?.subscriptionPayments)?.iban;
  
  const rejectionDate = subscription?.rejectionDate ?? '';
  const RejectionMotif = subscription?.RejectionMotif;

  const { canSubmit, isSubmitting, methods, onSubmit } = useForm({
    customer,
    instituteId,
    isDeptingSubscription: deptingSubscription,
    subscriptionId: subscription?.id,
    onSuccess: () => {
      history.goBack();
    },
  });

  const handleMandatSubmit = e => {
    e.preventDefault();
    generateNewMandatRequest()
      .then(_ => {
        alert('Mandat mis à jour');
      })
      .catch(error => {
        alert('Erreur lors de la mise à jour du mandat');
        console.log(error);
      });
  };

  const generateNewMandatRequest = () =>
    request(`api/subscriptions/new/mandat/${subscription?.id}`, {
      method: 'GET',
    });

  const pageTitle = t(messages.pageTitle(), { identifier: subscription?.id });
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageWrapper
        error={error}
        header={
          !isLoading && (
            <Header backBehaviour customer={customer} label={pageTitle} />
          )
        }
      >
        <LoadableContent isLoading={isLoading}>
          <FormWrapper
            id="customer-edit-form"
            methods={methods}
            onSubmit={onSubmit}
          >
            <Form
              canSubmit={canSubmit}
              displayBankAccount={deptingSubscription}
              displayRejectedDebit={displayRejectedDebit}
              rejectionDate={rejectionDate}
              RejectionMotif={RejectionMotif}
              isSubmitting={isSubmitting}
              isSubscriptionActive={subscription?.status ?? false}
            />
          </FormWrapper>
          {hasEntitlement('super_admin', me) && (
            <>
              <FormSectionTitle>Gestion du Mandat</FormSectionTitle>
              <form onSubmit={handleMandatSubmit}>
                <MandatButton type="submit" value="Renouveler le mandat" />
              </form>
            </>
          )}
        </LoadableContent>
      </PageWrapper>
    </>
  );
};

const MandatButton = styled.input`
  background: #e462a1;
  border: none;
  padding: 20px;
  border-radius: 20px;
  color: white;
  margin: 30px;
`;

export const FormSectionTitle = styled.h3`
  margin-block: 0 1rem;
  color: ${p => p.theme.text.title}; ;
`;

