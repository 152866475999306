import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  startDate: {
    label: () => _t(translations.stats.inputs.period.start_date.label),
  },
  endDate: {
    label: () => _t(translations.stats.inputs.period.end_date.label),
  },
};
