import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo, useState } from 'react';
import { Debit } from 'types/API/debits';
import { ListItem, ListItemProps } from 'app/components/ListItem';
import { EntriesWrapper, InfoEntry } from 'app/components/ListItem/InfoEntry';
import { usePriceFormatter } from 'utils/formatters/hooks/price';
import { Status } from 'app/components/Status';
import { messages } from '../../messages';
import styled from 'styled-components';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { generatePath, useHistory } from 'react-router-dom';
import { pathWithHash } from 'utils/path';
import { paths } from 'app/routes/paths';
import { resiliateOldSubscriptionRequest, resiliateSubscriptionRequest } from 'app/services/subscriptions';
import { useInstituteSelector } from 'app/atoms/selectors/institute';
import { useRecoilState, useRecoilValue } from 'recoil';
import { idabo, subscriptionId } from 'app/atoms/paymentDay';
import moment from 'moment';

interface Props extends ListItemProps {
  currency: string;
  debit: Debit.Normal;
  selectedDate: Date;
  startDate: Date;
  switchVersion: boolean;
  setfilteredCustomers: React.Dispatch<
    React.SetStateAction<Debit.Normal[] | undefined>
  >;
}

export const DebitItem = (props: Props) => {
  const textsuccessresiliation = 'L\'opération de résiliation de prélèvement a été effectuée avec succès. Merci de patienter, le prélèvement sera résilié d\'ici une heure.';
  const [idAbo, setIdAbo] = useRecoilState(idabo);
  const [subscriptionId1, setSubscriptionId1] = useRecoilState(subscriptionId);
  const { institute } = useInstituteSelector();

  const { currency, debit, selectedDate, startDate, setfilteredCustomers, switchVersion, ...rest } = props;
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const history = useHistory();

  const onItemSelection = useCallback(
    (cardNumber: string) => {
      let value = debit.idabo ?? '';
      setIdAbo(value);
      setSubscriptionId1(debit.subscriptionid);

      const location = generatePath(
        pathWithHash({
          path: paths.subscriptions.updateByCard,
          hash: 'bank-account',
        }),
        {
          cardNumber,
        },
      );
      console.log(`generatePath location:${location}`);
      history.push(location);
    },
    [history],
  );

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleValidate = async () => {
    debit.cardNumber.includes('old_')
      ? // This request is temporary and will be replaced with the actual request once available.
      await resiliateOldSubscriptionRequest({
        subscriptionId: debit.subscriptionid, instituteId: debit.institutid/*institute.id*/, idabo: debit.idabo
      })
        .then(() => {
          setfilteredCustomers(prev =>
            prev?.filter(
              (customer: any) =>
                customer.subscriptionid !== debit.subscriptionid,
            ),
          );
          handleClose();
          alert(textsuccessresiliation);
        })
        .catch(() => alert(t(messages.error())))
      : await resiliateSubscriptionRequest({
        subscriptionId: debit.subscriptionid,
      })
        .then(() => {
          setfilteredCustomers(prev =>
            prev?.filter(
              (customer: any) =>
                customer.subscriptionid !== debit.subscriptionid,
            ),
          );
          handleClose();
          alert(textsuccessresiliation);
        })
        .catch(() => alert(t(messages.error())))
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { formatPrice } = usePriceFormatter({ currency });

  const entries = useMemo(() => {
    const entries: { key: string; label: string; value: string }[] = [
      {
        key: 'iban',
        label: t(messages.iban()),
        value: debit.iban,
      },
      {
        key: 'amount',
        label: t(messages.amount()),
        value: formatPrice(debit.amount),
      },
      {
        key: 'lastname',
        label: 'Nom',
        value: debit.firstname,
      },
      {
        key: 'firstname',
        label: 'Prénom',
        value: debit.lastname,
      },
    ];

    return entries;
  }, [debit, formatPrice, t]);

  const WrapperBtns = styled.div`
    display: flex;
    gap: 10px;
  `;

  const status = debit.isIbanValid;
  return (
    <ListItem {...rest}>
      <EntriesWrapper>
        {entries?.map(entry => (
          <InfoEntry key={entry.key} label={entry.label} value={entry.value} />
        ))}
      </EntriesWrapper>
      <WrapperBtns>
        {moment().isSameOrBefore(moment(startDate), 'day') && (
          <Status
            isActive={false}
            label={t(messages.resiliate())}
            onClick={handleClickOpen}
          />
        )}
        <Status
          isActive={status}
          label={t(
            status
              ? messages.details() /*messages.validity.valid()*/
              : 'À corriger' /*messages.validity.invalid()*/,
          )}
          onClick={() => onItemSelection(debit.cardNumber)}
        />
      </WrapperBtns>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>ATTENTION :</b> Si vous procédez à la résiliation d’un abonnement
          en erreur pour le saisir en tant que nouvel abo propre dans le portail
          il faut au préalable :
          <DialogTitle id="alert-dialog-title">
            • Une fois la résiliation enregistrée, aller sur la fonction SAISIR
            NOUVEL ABO » dans la gestion abonnement pour recréer immédiatement
            son abonnement en vous munissant d’une NOUVELLE carte dont vous
            saisirez le numéro sur le portail et prévenir cette cliente que sa
            nouvelle carte l’attend à la caisse de votre institut.
          </DialogTitle>
          <DialogTitle id="alert-dialog-title">
            • Consulter la fiche abonnée dans l’onglet RECHERCHER de la caisse
            ou retrouver son contrat papier pour saisir : nom, prénom, 06, mail,
            adresse et surtout RIB.
          </DialogTitle>
          <b>Voulez vous vraiment résilier cet abonnement?</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Si vous cliquez sur confirmer, le prélèvement sera définitivement
            supprimé et les données associées ne pourront plus être récupérées
            si vous ne les avez pas notées.
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            Cliquez sur annuler pour interrompre la validation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={() => handleValidate()}>Confirmer</Button>
        </DialogActions>
      </Dialog>
    </ListItem>
  );
};
