import { first, range } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { Optional } from 'types/misc';
import { FieldError } from 'react-hook-form';

import { SelectInputOption } from '../../SelectInput';
import { SelectInput } from '../SelectInput';

export interface DayPickerValue {
  day: number;
  month: number;
}
export interface DayPickerProps {
  disabled?: boolean;
  dayPlaceholder?: string;
  error?: (FieldError | undefined)[];
  monthPlaceholder?: string;
  onBlur: () => void;
  onChange: (value?: DayPickerValue) => void;
  value?: DayPickerValue;
}

export const DayPicker = (props: DayPickerProps) => {
  const {
    disabled,
    dayPlaceholder,
    error,
    monthPlaceholder,
    onBlur,
    onChange,
    value,
  } = props;

  const [day, setDay] = useState<Optional<string>>(value?.day.toString());
  const [month, setMonth] = useState<Optional<string>>(value?.month.toString());

  useEffect(() => {
    if (day && month) {
      onChange({ day: parseInt(day), month: parseInt(month) });
    }
    // Do not add onChange in the deps or you'll get an infinite recursion.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, month]);

  const dayOptions: SelectInputOption[] = useMemo(() => {
    const upperIndex = month ? moment(month, 'MM').daysInMonth() + 1 : 31;
    return range(1, upperIndex).map(day => {
      const id = ('0' + day).slice(-2);
      return {
        id,
        label: id.toString(),
      };
    });
  }, [month]);

  const monthOptions: SelectInputOption[] = useMemo(
    () =>
      moment.months().map((month, index) => {
        const id = ('0' + (index + 1)).slice(-2);
        return {
          id,
          label: month.capitalized(),
        };
      }),
    [],
  );

  const onDayChange = (values: string[]) => {
    setDay(first(values));
  };

  const onMonthChange = (values: string[]) => {
    setMonth(first(values));
  };

  return (
    <Wrapper>
      <SelectInput
        disabled={disabled}
        options={dayOptions}
        fullWidth
        error={error}
        onBlur={onBlur}
        onChange={onDayChange}
        placeholder={dayPlaceholder}
        value={day ? [day] : []}
      />
      <SelectInput
        disabled={disabled}
        options={monthOptions}
        fullWidth
        onBlur={onBlur}
        onChange={onMonthChange}
        placeholder={monthPlaceholder}
        value={month ? [month] : []}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  > :first-child {
    margin-right: 0.2rem;
  }
`;
