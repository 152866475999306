import { paths } from 'app/routes/paths';
import { Optional } from 'types/misc';
import { SubscriptionKind, SubscriptionTab } from './types';

export const tabFromURLParam = (param: Optional<string>) => {
  if (param === SubscriptionKind.SUBSCRIBERS) {
    return SubscriptionTab.SUBSCRIBERS;
  } else if (param === SubscriptionKind.PENDING) {
    return SubscriptionTab.PENDING;
  } else /*if (param === SubscriptionKind.OLDDB)*/ {
    return SubscriptionTab.OLDDB;
  }
};

export const locationFromTab = (tab: SubscriptionTab) => {
  if (tab === SubscriptionTab.PENDING) {
    return paths.subscriptions.list.cases.pending;
  } else if (tab === SubscriptionTab.SUBSCRIBERS) {
    return paths.subscriptions.list.cases.subscribers;
  } else if (tab === SubscriptionTab.OLDDB) {
    return paths.subscriptions.list.cases.oldBd;
  } else /*if (tab === SubscriptionTab.OLD_DB)*/ {
    return paths.subscriptions.create_subscription;
  } 
};
