import { Helmet } from 'react-helmet-async';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { ReactComponent as AddIcon } from 'app/assets/plus.svg';
import { List } from 'app/components/List';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { paths } from 'app/routes/paths';
import { LoadableContent } from 'app/components/LoadableContent';
import { ActionPageHeader } from 'app/components/PageHeaders/ActionPageHeader';
import { institutesFromIds } from 'utils/entities/institute';
import { idFromEntityEndpoint } from 'utils/entities/identifier';

import { ModalsProvider } from './hoc/ModalsProvider';
import { UserItem } from './components/UserItem';
import { messages } from './messages';
import { useData, useModals, useSelectors } from './hooks';
import { SectionLabel } from 'app/components/Labels/SectionLabel';
import { useCallback } from 'react';
import { User } from 'types/API/users';
import { hasRoleOrHigher, rolePriority } from 'utils/entities/users';
import { TillItem } from './components/TillItem';
import { first } from 'lodash';
import { useInstituteSelector } from 'app/atoms/selectors';

export const UsersPage = () => {
  const { currentInstituteId, me, institutes } = useSelectors();
  const { queryState } = useData(currentInstituteId);
  const { t } = useTranslation();
  const history = useHistory();

  const onItemSelection = useCallback(
    ({ userId }: { userId: number }) => {
      const location = generatePath(paths.users.update, { id: userId });
      history.push(location);
    },
    [history],
  );

  const onCreate = useCallback(() => {
    history.push(paths.users.create);
  }, [history]);

  const { deleteConfirmation } = useModals();
  const { institute } = useInstituteSelector();

  const renderUserAccessCell = useCallback(
    (user: User.DetailFormat) => {
      const isDeleteEnabled =
        !!me &&
        user.id !== me.id &&
        rolePriority(first(me.roles)!) > rolePriority(first(user.roles)!);
      const isEditionEnabled = isDeleteEnabled || user.id === me?.id;
      const displayIdentifier = hasRoleOrHigher({
        role: User.Roles.ROLE_SUPER_USER,
        user,
      });
      const userInstitutes = institutesFromIds({
        ids: user.institute.map(endpoint => idFromEntityEndpoint(endpoint)),
        institutes: institutes,
      });

      return (
        <UserItem
          displayIdentifier={displayIdentifier}
          isDeleteEnabled={isDeleteEnabled}
          isEditionEnabled={isEditionEnabled}
          key={user.id}
          onClick={() => onItemSelection({ userId: user.id })}
          onDelete={() => {
            deleteConfirmation.toggleOpenState({ userId: user.id });
          }}
          institutes={userInstitutes}
          user={user}
        />
      );
    },
    [deleteConfirmation, institutes, me, onItemSelection],
  );

  const renderTillAccessCell = useCallback(
    (user: User.DetailFormat) => (
      <TillItem
        key={user.id}
        onClick={() => onItemSelection({ userId: user.id })}
        user={user}
      />
    ),
    [onItemSelection],
  );
  return (
    <ModalsProvider
      deleteConfirmation={deleteConfirmation}
      instituteId={currentInstituteId}
    >
      <Helmet>
        <title>{t(messages.pageTitle())} : {institute.name}</title>
      </Helmet>
      <PageWrapper
        header={
          <ActionPageHeader
            button={{
              onClick: onCreate,
              label: t(messages.createUser()),
              icon: <AddIcon />,
            }}
            title={t(messages.pageTitle()) + " : " + institute.name}
          />
        }
      >
        <LoadableContent
          error={queryState.error}
          isLoading={queryState.isLoading}
        >
          <List>
            <SectionLabel>{t(messages.sections.tills.title())}</SectionLabel>
            {queryState.data?.tills.map(user => renderTillAccessCell(user))}
            <SectionLabel>
              {t(messages.sections.portalUsers.title())}
            </SectionLabel>
            {queryState.data?.portalUsers.map(user =>
              renderUserAccessCell(user),
            )}
            <SectionLabel>
              {t(messages.sections.tillUsers.title())}
            </SectionLabel>
            {queryState.data?.tillUsers.map(user => renderUserAccessCell(user))}
          </List>
        </LoadableContent>
      </PageWrapper>
    </ModalsProvider>
  );
};
