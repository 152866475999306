import {
  Avatar as MUIAvatar,
  AvatarProps as MUIAvatarProps,
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { Customer } from 'types/API/customers';
import {
  ImageSize,
  getCustomerCompleteName,
  getCustomerImageURI,
  getCustomerInitials,
} from 'types/format/customers';

type StyledProps = {
  size?: string;
};

interface Props extends MUIAvatarProps {
  customer?: Customer.Base | Customer.External;
  fallbackLabel?: string;
  shouldFetchImage?: boolean;
  size?: string;
}

export const Avatar = (props: Props) => {
  const {
    customer,
    fallbackLabel,
    shouldFetchImage = false, // TODO Restore customers profile photo image (img) if no impact on performances at 12 am
    size,
    ...rest
  } = props;

  return (
    <StyledMUIAvatar
      alt={customer ? getCustomerCompleteName(customer) : undefined}
      aria-label="avatar"
      size={size}
      src={
        customer && shouldFetchImage
          ? getCustomerImageURI(customer.id, ImageSize.Small)
          : undefined
      }
      {...rest}
    >
      {customer
        ? getCustomerInitials(customer)
        : fallbackLabel?.charAt(0).toUpperCase()}
    </StyledMUIAvatar>
  );
};

// eslint-disable-next-line prettier/prettier
const StyledMUIAvatar = styled(MUIAvatar)<StyledProps>`
  && {
    background-color: ${p => p.theme.primary};
    color: ${p => p.theme.text.bright};
    height: ${({ size }) => size};
    width: ${({ size }) => size};
    font-weight: 800;
  }
`;
