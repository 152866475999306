import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  nextMonth: () => _t(translations.date_picker.next_month),
  previousMonth: () => _t(translations.date_picker.previous_month),
  openMonthSelector: () => _t(translations.date_picker.open_month_selector),
  openYearSelector: () => _t(translations.date_picker.open_year_selector),
  closeMonthSelector: () => _t(translations.date_picker.close_month_selector),
  closeYearSelector: () => _t(translations.date_picker.close_year_selector),
  defaultPlaceholder: () => _t(translations.date_picker.default_placeholder),
  from: () => _t(translations.date_picker.from),
  to: () => _t(translations.date_picker.to),
};
