import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Optional } from 'types/misc';

import { messages } from '../messages';
import { ContentConfiguration, PageConfiguration, StatKind } from '../types';

interface Options {
  kind: StatKind;
}

export const useConfiguration = (options: Options) => {
  const { kind } = options;
  const { t } = useTranslation();

  const [contentConfiguration, setContentConfiguration] =
    useState<Optional<ContentConfiguration>>();

  useEffect(() => {
    // TODO think about using a ts file instead
    import(`../configurations/${kind}.ts`).then(module => {
      const json = module.default;
      setContentConfiguration(json as ContentConfiguration);
    });
  }, [kind]);

  const pageConfiguration = useMemo(
    () =>
      contentConfiguration
        ? ({
            contentConfiguration,
            title: t(messages.pageTitle(kind)),
          } as PageConfiguration)
        : undefined,
    [contentConfiguration, kind, t],
  );

  return { pageConfiguration };
};
