import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { authorizationState } from 'app/atoms';

import { getEnv } from '../common/env';
import { signinEndpoint } from 'app/services/auth';
import { RequestError } from './types';
import {
  setRecoilExternalStateAsync,
  getRecoilExternalLoadable,
  setRecoilExternalState,
} from 'utils/recoil';
import { AuthorizationState } from 'app/atoms/authorization/types';

const API_URL = getEnv('API_URL');
const API_URLAWS = getEnv('API_URLAWS');

export const instance = axios.create({
  baseURL: API_URL,
});

export const instanceAWS = axios.create({
  baseURL: API_URLAWS,
});

const logout = () => {
  setRecoilExternalState(authorizationState, {
    refresh_token: undefined,
    token: undefined,
  });
};

const bearer = (token: string) => `bearer ${token}`;

const getAuthorization = () => {
  const authorizationLodable = getRecoilExternalLoadable(authorizationState);
  if (authorizationLodable) {
    return authorizationLodable.valueMaybe();
  }
  return undefined;
};

const getAccessToken = () => {
  const authorization = getAuthorization();
  if (authorization && authorization.token) {
    return bearer(authorization.token);
  }
  return undefined;
};

const getRefreshToken = () => {
  const authorization = getAuthorization();
  if (authorization) {
    return authorization.refresh_token;
  }
  return undefined;
};

const defaultInterceptor = () => {
  instance.interceptors.request.use(axiosRequest => {
    // eslint-disable-next-line no-param-reassign
    const accessToken = getAccessToken();
    if (accessToken) {
      axiosRequest.headers.Authorization = accessToken;
    }
    axiosRequest.headers.Accept = 'application/json';
    return axiosRequest;
  });
};

const defaultInterceptorAWS = () => {
  instanceAWS.interceptors.request.use(axiosRequest => {
    // eslint-disable-next-line no-param-reassign
    const accessToken = getAccessToken();
    if (accessToken) {
      axiosRequest.headers.Authorization = accessToken;
    }
    axiosRequest.headers.Accept = 'application/json';
    return axiosRequest;
  });
};

const refreshInterceptor = () => async (error: AxiosError) => {
  // Don't try to refresh if the 401 is on the signin endpoint (API should probably return 403 instead)
  if (error.config.url === signinEndpoint) {
    logout();
    throw error;
  }

  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    logout();
    return Promise.reject();
  }

  try {
    const tokenRefreshResponse = await instance.post('api/token/refresh', {
      refresh_token: refreshToken,
    });

    const newAuthorization: AuthorizationState = tokenRefreshResponse.data;
    if (newAuthorization.token && error.response) {
      // eslint-disable-next-line no-param-reassign
      error.response.config.headers.Authorization = bearer(
        newAuthorization.token,
      );
    }

    await setRecoilExternalStateAsync(authorizationState, newAuthorization);

    return Promise.resolve();
  } catch (e) {
    console.error(
      'An error occured while refreshing the token. Logging out...',
    );
    logout();

    return Promise.reject(e);
  }
};

const refreshInterceptorAWS = () => async (error: AxiosError) => {
  // Don't try to refresh if the 401 is on the signin endpoint (API should probably return 403 instead)
  if (error.config.url === signinEndpoint) {
    logout();
    throw error;
  }

  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    logout();
    return Promise.reject();
  }

  try {
    const tokenRefreshResponse = await instance.post('api/token/refresh', {
      refresh_token: refreshToken,
    });

    const newAuthorization: AuthorizationState = tokenRefreshResponse.data;
    if (newAuthorization.token && error.response) {
      // eslint-disable-next-line no-param-reassign
      error.response.config.headers.Authorization = bearer(
        newAuthorization.token,
      );
    }

    await setRecoilExternalStateAsync(authorizationState, newAuthorization);

    return Promise.resolve();
  } catch (e) {
    console.error(
      'An error occured while refreshing the token. Logging out...',
    );
    logout();

    return Promise.reject(e);
  }
};

defaultInterceptor();
defaultInterceptorAWS();

createAuthRefreshInterceptor(instance, refreshInterceptor());
createAuthRefreshInterceptor(instanceAWS, refreshInterceptor());

// {
//   pauseInstanceWhileRefreshing: true,
//   interceptNetworkError: true,
// }

export async function request<T>(
  url: string,
  options?: AxiosRequestConfig,
): Promise<T> {
  try {
    const response = await instance.request({ url, ...options });
    return response.data;
  } catch (error: any) {
    let rejectionError = error as Error;
    if (error.response) {
      rejectionError = new RequestError(error.response);
    }
    return Promise.reject(rejectionError);
  }
}

let apiOldDBBaseURL = 'https://dev-search.bodym.net';
export async function request2<T>(
  url: string,
  options?: AxiosRequestConfig,
): Promise<T> {
  try {
    let baseurl = instanceAWS.defaults.baseURL;
    console.log(`request baseurl1:${baseurl}`);
    //instance.defaults.baseURL = apiOldDBBaseURL;
    const response = await instanceAWS.request({ url, ...options });
    //instance.defaults.baseURL = baseurl;
    //console.log(`request baseurl2:${instance.defaults.baseURL}`);
    return response.data;
  } catch (error: any) {
    console.log(`request baseurl2:${instance.defaults.baseURL}, error:${error}`);
    let rejectionError = error as Error;
    if (error.response) {
      rejectionError = new RequestError(error.response);
    }
    return Promise.reject(rejectionError);
  }
}
