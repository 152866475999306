import { createSubscriptionRequest } from 'app/services/subscriptions';
import { useMutation } from 'app/hooks';

export const useMutations = (instituteId: number) => {
  const { mutate: createSubscription, isLoading: isCreating } = useMutation(
    createSubscriptionRequest,
  );

  return {
    isCreating,
    createSubscription,
  };
};

/*
export const useMutations = (instituteId: number) => {
  const updateSubscription = useCallback(async ({ data }: { data: any }) => {
    const customer = await createSubscriptionRequest(data);
    return { customer };
  }, []);

  const { mutate: createSubscription, isLoading: isCreating } = useMutation(
    updateSubscription,
    {
      onSuccess: response => {
        console.log(`useMutations onsuccess answer`);
      },
    },
  );

  return {
    isCreating,
    createSubscription,
  };
};

*/
