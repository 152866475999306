import { AxiosResponse } from 'axios';
import i18next from 'i18next';

import { messages } from 'app/i18n/messages';

export class RequestError<T> extends Error {
  public status: number;

  public content: T;

  constructor(response: AxiosResponse<T>) {
    super();
    this.status = response.status;
    this.content = response.data;
    // Use a generic error message for now.
    this.message = i18next.t(messages.errors.generic()) + ': \n' + this.message;
  }
}
