import { useTranslation } from 'react-i18next';
import React from 'react';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { TextInput } from 'app/components/Inputs/TextInput';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { fieldName } from 'utils/form';

import { messages } from '../../../../messages';

interface Props {
  disabled: boolean;
  namePrefix?: string;
}

export const Names = ({ disabled, namePrefix }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <FormInputGrid item sm={6}>
        <FormLabel>{t(messages.firstname.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name={fieldName('firstname', namePrefix)}
          placeholder={t(messages.firstname.placeholder())}
          required
        />
      </FormInputGrid>
      <FormInputGrid item sm={6}>
        <FormLabel>{t(messages.lastname.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name={fieldName('lastname', namePrefix)}
          placeholder={t(messages.lastname.placeholder())}
          required
        />
      </FormInputGrid>
    </>
  );
};
