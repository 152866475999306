import React from 'react';
import styled from 'styled-components/macro';

import { User } from 'types/API/users';

interface Props {
  onClick?: () => void;
  user: User.DetailFormat;
}

export const TillItem = (props: Props) => {
  const { onClick, user } = props;

  return (
    <Wrapper onClick={onClick}>
      <ContentWrapper>
        <IdentifierLabel>{user.username}</IdentifierLabel>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${p => p.theme.itemBackground};
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  border-radius: 15px;
  cursor: pointer;

  overflow: hidden;
  margin: 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1rem 1rem;
`;

const IdentifierLabel = styled.h3`
  margin-block: 0;
  font-weight: 800;
  letter-spacing: 1.17pt;
  color: ${p => p.theme.text.title};
`;
