import { request } from 'utils/request';
import { Debit, DebitHistory } from 'types/API/debits';

export interface DebitsResponse {
  customer: Debit.Normal[];
  error: Debit.Normal[];
  nbPrelev: Debit.NbPrelev;
  totalPrelev: number;
  xml: string;
}

interface NbPrelevResponse {
  customer: Debit.Normal[];
  error: Debit.Normal[];
  nbPrelev: Debit.NbPrelev
}

interface DebitsHistoryResponse {
  history: DebitHistory.Normal[];
  error: DebitHistory.Normal[];
}
export const fetchDebitsRequest = async ({
  instituteId,
  day,
  month,
  year,
  rejected,
}: {
  instituteId: number;
  day: Debit.PaymentDay;
  month: Debit.PaymentMonth;
  year: number;
  rejected: boolean;
}) => {
  const response = await request<DebitsResponse>(
    `api/prelevement/${instituteId}/${day}/${month}/${year}/xml/1/rejet/${
      rejected ? 1 : 0
    }`,
    {
      method: 'GET',
    },
  );
  console.log(
    '======>fetchDebitsRequest: ' +
      `api/prelevement/${instituteId}/${day}/${month}/${year}/xml/1/rejet/${
        rejected ? 1 : 0
      }`,
  );
  // Cleanup the back-end response.
  return [...response.error, ...response.customer];
};

export const fetchDebitsNbPrelevRequest = async ({
  instituteId,
  day,
  month,
  year,
  rejected,
}: {
  instituteId: number;
  day: Debit.PaymentDay;
  month: Debit.PaymentMonth;
  year: number;
  rejected: boolean;
}) => {
  const response = await request<{ nbPrelev: Debit.NbPrelev }>(
    `api/prelevement/${instituteId}/${day}/${month}/${year}/xml/1/rejet/${
      rejected ? 1 : 0
    }`,
    {
      method: 'GET',
    },
  );
  console.log('======>fetchDebitsNbPrelevRequest');
  // Cleanup the back-end response.
  return response.nbPrelev;
};

export const fetchDebitsFileContentRequest = async ({
  instituteId,
  day,
  month,
  year,
  rejected,
}: {
  instituteId: number;
  day: Debit.PaymentDay;
  month: Debit.PaymentMonth;
  year: number;
  rejected: boolean;
}) => {
  const url = `api/prelevement/${instituteId}/${day}/${month}/${year}/xml/1/rejet/${
    rejected ? 1 : 0
  }`;
  const response = await request<{ xml: string }>(url, {
    method: 'GET',
  });
  return response.xml;
};

export const fetchDebitsHistoryRequest = async ({
  instituteId,
}: {
  instituteId: number;
}) => {
  const response = await request<DebitHistory.Normal[]>(
    `api/prelev/history/${instituteId}`,
    {
      method: 'GET',
    },
  );
  // Cleanup the back-end response.
  return response;
};
