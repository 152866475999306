import * as React from 'react';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { theme } from './themes';
import { muitheme } from './material-ui';

export const ThemeProvider = (props: { children: React.ReactChild }) => (
  <MUIThemeProvider theme={muitheme}>
    <StyledComponentsThemeProvider theme={theme}>
      {React.Children.only(props.children)}
    </StyledComponentsThemeProvider>
  </MUIThemeProvider>
);
