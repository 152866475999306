import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  createdBy: () => _t(translations.receipts.created_by),
  bankedBy: () => _t(translations.receipts.banked_by),
  paymentType: () => _t(translations.receipts.payment_type),
  userDateFormat: () => _t(translations.receipts.user_date_format),
  ticketNumber: () => _t(translations.receipts.ticket_number),
  total: () => _t(translations.receipts.total),
  categories: {
    vente: () => _t(translations.receipts.categories.vente),
    nc: () => _t(translations.receipts.categories.nc),
    epilation: () => _t(translations.receipts.categories.epilation),
    soin: () => _t(translations.receipts.categories.soin),
  },
};
