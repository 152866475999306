import moment from 'moment';
import styled from 'styled-components/macro';
import { indexOf } from 'lodash';
import { useMemo } from 'react';

import { TextInput } from 'app/components/Inputs';
import { Objectives } from 'types/API/objectives';

interface MetricInfo {
  metric: Objectives.Metric;
  label: string;
}

interface Props {
  metricInfos: MetricInfo[];
}

export const Form = (props: Props) => {
  const { metricInfos } = props;

  const weekdays = moment.weekdaysShort();
  const sortedWeekdays = moment.weekdaysShort(true);

  const weekdayIndices = useMemo(
    () => sortedWeekdays.map(weekday => indexOf(weekdays, weekday)),
    [weekdays, sortedWeekdays],
  );

  const columns = useMemo(() => weekdays.length, [weekdays]);
  return (
    <TableWrapper>
      <TableHeaderWrapper>
        <HeaderItemWrapper columns={columns} />
        {sortedWeekdays.map(weekday => (
          <HeaderItemWrapper columns={columns}>{weekday}</HeaderItemWrapper>
        ))}
      </TableHeaderWrapper>

      {metricInfos.map(info => (
        <MetricRow
          columns={columns}
          info={info}
          weekdayIndices={weekdayIndices}
        />
      ))}
    </TableWrapper>
  );
};

interface MetricRowProps {
  columns: number;
  info: MetricInfo;
  weekdayIndices: number[];
}

const MetricRow = ({ columns, info, weekdayIndices }: MetricRowProps) => (
  <RowWrapper>
    <ItemWrapper columns={columns}>
      <span>{info.label}</span>
    </ItemWrapper>
    {weekdayIndices.map(weekdayIndex => (
      <ItemWrapper columns={columns}>
        <TextInput name={`${info.metric}[${weekdayIndex}]`} />
      </ItemWrapper>
    ))}
  </RowWrapper>
);

interface ItemWrapperProps {
  columns: number;
}

const ItemWrapper = styled.div<ItemWrapperProps>`
  width: ${p => `${100 / p.columns}%`};
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > :not(:first-child) {
    margin-bottom: 0.25rem;
  }
  > :first-child {
    margin-bottom: 0.5rem;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: rows;
  align-items: center;

  > :not(:first-child) {
    margin-left: 0.25rem;
  }
`;

const TableHeaderWrapper = styled(RowWrapper)``;

const HeaderItemWrapper = styled(ItemWrapper)`
  text-align: center;
`;
