import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

import { Button } from 'app/components/Button';

interface Props {
  icon: ReactNode;
  label: string;
  onClick: () => void;
}

export const HeaderButton = ({ icon, label, onClick }: Props) => {
  const theme = useTheme();
  const largeHeader = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  return (
    <InternalButton
      onClick={onClick}
      startIcon={largeHeader ? icon : undefined}
      $largeHeader={largeHeader}
    >
      {largeHeader ? label : icon}
    </InternalButton>
  );
};

interface InternalButtonProps {
  $largeHeader: boolean;
}

const InternalButton = styled(Button)<InternalButtonProps>`
  &.MuiButton-contained {
    padding: ${p => (!p.$largeHeader ? '0.5rem 1rem' : '0.75rem 4rem')};
  }
`;
