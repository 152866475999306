import { Redirect, Route, useLocation } from 'react-router-dom';
import React from 'react';

import { entryPointPath, paths } from 'app/routes/paths';

import { useSelectors } from './hooks';

type Props = {
  component: (props: any) => JSX.Element | null;
  exact?: boolean;
  path?: string | string[];
};

export const AuthenticatedRoute = (props: Props) => {
  const location = useLocation();

  const { isAuthenticated } = useSelectors();

  if (isAuthenticated) return <Route {...props} />;

  const searchParams = new URLSearchParams(location.search);

  if (location.pathname !== entryPointPath) {
    searchParams.append('redirect', location.pathname);
  }

  return (
    <Redirect to={`${paths.signin}${searchParams.stringSearchParams()}`} />
  );
};
