import { Route } from 'types/route';
import { ReceiptsPage } from 'app/pages/Receipts/ReceiptsPage';
import { ReceiptPage } from 'app/pages/Receipts/ReceiptPage';

import { paths } from '../../paths';

const receipts: Route = {
  authenticated: true,
  defaultPath: paths.receipts.list.cases.pending,
  content: ReceiptsPage,
  routerPath: paths.receipts.list.pattern,
};

const receipt: Route = {
  authenticated: true,
  content: ReceiptPage,
  routerPath: paths.receipts.detail,
};

export const routes = [receipts, receipt];
