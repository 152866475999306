import { QueryClient, QueryKey } from 'react-query';
import { find, findIndex, remove } from 'lodash';

import { Identifiable, Optional } from 'types/misc';

import { objectPredicate } from '../utils';

export function addOrUpdateObjectToListDataCache<T extends Identifiable>({
  object,
  queryClient,
  queryKey,
}: {
  object: T;
  queryClient: QueryClient;
  queryKey: QueryKey;
}) {
  const cached: Optional<T[]> = queryClient.getQueryData(queryKey);
  if (!cached) {
    queryClient.invalidateQueries(queryKey);
    return;
  }
  const data = [...cached];
  const index = findIndex(data, objectPredicate(object));
  if (index !== -1) {
    // Object exists, updating cache.
    data[index] = object;
  } else {
    // Object does not exist, appending to the cache.
    // @TODO: add parameter to set the insertion behaviour (begin, end, custom index ?)
    data.push(object);
  }

  queryClient.setQueryData(queryKey, data);
}

export function getObjectFromListDataCache<T extends Identifiable>({
  predicate,
  queryClient,
  queryKey,
}: {
  predicate: (object: T) => boolean;
  queryClient: QueryClient;
  queryKey: QueryKey;
}): Optional<T> {
  const cached = queryClient.getQueryData(queryKey) as T[];
  return find(cached, predicate);
}

export function removeObjectFromListDataCache<T extends Identifiable>({
  object,
  queryClient,
  queryKey,
}: {
  object: T;
  queryClient: QueryClient;
  queryKey: QueryKey;
}) {
  const cached = queryClient.getQueryData(queryKey) as T[];
  const data = [...cached];

  remove(data, objectPredicate(object));

  queryClient.setQueryData(queryKey, data);
}
