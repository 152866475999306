import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  pageTitle: () => _t(translations.subscriptions.page_title),
  emptySearchLabel: () => _t(translations.subscriptions.search.empty_result),
  searchPlaceholder: () => _t(translations.subscriptions.search.placeholder),
  subscriptionStatus: {
    active: () => _t(translations.subscriptions.subscription_status.active),
    pending: () => _t(translations.subscriptions.subscription_status.pending),
    inactive: () => _t(translations.subscriptions.subscription_status.inactive),
  },

  subscriptionId: () => _t(translations.subscriptions.subscription_id),
  subscriptionType: () => _t(translations.subscriptions.subscription_type),
  startDate: () => _t(translations.subscriptions.start_date),
  endDate: () => _t(translations.subscriptions.end_date),
  dateFormat: () => _t(translations.subscriptions.date_format),

  tabs: {
    pendings: {
      tabLabel: () => _t(translations.subscriptions.tabs.pendings.tab_label),
      emptyLabel: () =>
        _t(translations.subscriptions.tabs.pendings.empty_label),
    },
    subscriptions: {
      tabLabel: () =>
        _t(translations.subscriptions.tabs.subscriptions.tab_label),
      emptyLabel: () =>
        _t(translations.subscriptions.tabs.subscriptions.empty_label),
    },
    createSubscription: {
      tabLabel: () =>
        _t(translations.subscriptions.tabs.createSubscriptions.tab_label),
      emptyLabel: () =>
        _t(translations.subscriptions.tabs.createSubscriptions.empty_label),
    },
    oldBd: {
      tabLabel: () =>
        _t(translations.subscriptions.tabs.oldBd.tab_label),
      emptyLabel: () =>
        _t(translations.subscriptions.tabs.oldBd.empty_label),
      searchPlaceholder: () => _t(translations.subscriptions.tabs.oldBd.searchPlaceholder),

    },
  },
};
