import { first } from 'lodash';
import { User } from 'types/API/users';
import { Optional } from 'types/misc';
import { Entitlement, rolesEntitements } from 'types/roles/entitlements';

export const hasEntitlement = (
  requestedEntitlement: Entitlement,
  user: Optional<User.DetailFormat>,
): boolean => {
  if (user) {
    const role = first(user.roles);
    if (role) {
      const entitlements = rolesEntitements.get(role);
      if (!entitlements) {
        throw Error(`Entitlement mapping not set for role ${role}`);
      }
      return entitlements.some(
        entitlement => entitlement === requestedEntitlement,
      );
    }
  }
  return false;
};
