import { User } from 'types/API/users';
import { entityEndpoint } from 'utils/entities/identifier';

export const listFormatToDetailFormat = (
  users: User.ListFormat[],
): User.DetailFormat[] =>
  users.map(user => {
    const { institute: institutes, ...rest } = user;
    return {
      institute: institutes.map(institute =>
        entityEndpoint({
          id: institute.id,
          module: 'institutes',
        }),
      ),
      password: '',
      ...rest,
    };
  });
