import { useHistory } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as BackIcon } from 'app/assets/back.svg';

interface Props {
  className?: string;
}

export const BackButton = ({ className }: Props) => {
  const history = useHistory();

  const onClick = () => {
    history.goBack();
  };

  return (
    <Wrapper onClick={onClick} className={className}>
      <BackIcon />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
