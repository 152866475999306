import { ChangeEvent, ReactNode, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  ListItemText as MuiListItemText,
  MenuItem,
  MenuProps,
  Select as MuiSelect,
} from '@material-ui/core';
import { FieldError } from 'react-hook-form';
import { difference, find, first, includes, remove } from 'lodash';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { Optional } from 'types/misc';

import { SelectInputOption } from '../../SelectInput/types';
import { messages } from './messages';

export interface DefaultSelectInputProps {
  allowMultipleSelections?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  options: SelectInputOption[];
  placeholder?: string;
  required?: boolean;
}

interface Props extends DefaultSelectInputProps {
  error?: (FieldError | undefined)[];
  onBlur?: () => void;
  onChange: (values: string[]) => void;
  value: string[];
}

const menuProps: Partial<MenuProps> = {
  MenuListProps: {
    style: {
      maxHeight: '30rem',
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  getContentAnchorEl: null,
};

const selectAllId = 'all';

export const SelectInput = (props: Props) => {
  const {
    allowMultipleSelections,
    disabled,
    error,
    fullWidth,
    onBlur,
    onChange,
    options,
    placeholder,
    required,
    value: rawValue,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const isError = !!error;
  // @ts-ignore
  const errorMessage = error?.message;

  const value = Array.isArray(rawValue) ? rawValue : [rawValue];

  const renderSelectAll = () => (
    <SelectAllMenuItem disabled={false} key={selectAllId} value={selectAllId}>
      <Checkbox checked={value.length === options.length} color="primary" />

      <ListItemText primary={t(messages.selectAll())} />
    </SelectAllMenuItem>
  );

  const shouldRenderSelectAll = allowMultipleSelections && options.length > 1;

  const renderMultipleSectionValue = (
    selected: (string | never)[],
  ): ReactNode => {
    const values: (string | never)[] = [];

    selected.forEach(selectedId => {
      options.forEach((option: SelectInputOption) => {
        if (option.id === selectedId) {
          values.push(option.label);
        }
      });
    });
    return values.join(', ');
  };

  const renderSingleSelectionValue = (
    selected: Optional<string>,
  ): Optional<ReactNode> => {
    const option = find(options, candidate => candidate.id === selected);
    if (option) {
      return (
        <ValueLabel>
          {option.accessory}
          {option.label}
        </ValueLabel>
      );
    }
    return <PlaceholderLabel>{placeholder}</PlaceholderLabel>;
  };

  const renderValue = (selected: (string | never)[]): ReactNode => {
    if (selected.length > 1) {
      return renderMultipleSectionValue(selected);
    }
    return renderSingleSelectionValue(first(selected));
  };

  const handleOnChange = (event: ChangeEvent<{ value: unknown }>) => {
    let currentValue = event.target.value as string[];

    const changes = difference(currentValue, value);
    remove(currentValue, candidate => candidate === selectAllId);

    if (allowMultipleSelections) {
      if (first(changes) === selectAllId) {
        const allSelected = currentValue.length === options.length;
        const isSelectAllChecked = !allSelected;
        currentValue = isSelectAllChecked
          ? options.map(option => option.id)
          : [];
      }
    } else {
      currentValue = changes;
      setOpen(false);
    }

    onChange(currentValue);
  };

  return (
    <StyledFormControl
      error={isError}
      variant="outlined"
      fullWidth={fullWidth}
      className={'placeholder2'}
    >
      <InternalSelect
        disabled={disabled}
        displayEmpty
        MenuProps={menuProps}
        multiple
        onBlur={onBlur}
        onChange={handleOnChange}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        // @ts-ignore
        renderValue={renderValue}
        required={required}
        value={value}
        {...rest}
      >
        {/* <MenuWrapper> */}
        {shouldRenderSelectAll && renderSelectAll()}

        {options.map(option => (
          <StyledMenuItem
            disabled={option.disabled}
            key={option.id}
            value={option.id}
          >
            {option.accessory}
            {allowMultipleSelections && (
              <Checkbox
                checked={includes(value, option.id)}
                color="primary"
                disabled={option.disabled}
              />
            )}
            <ListItemText primary={option.label} />
          </StyledMenuItem>
        ))}
        {/* </MenuWrapper> */}
      </InternalSelect>

      {errorMessage ? (
        <StyledFormHelperText>{errorMessage}</StyledFormHelperText>
      ) : null}
    </StyledFormControl>
  );
};

const StyledFormControl = styled(FormControl)``;

const StyledFormHelperText = styled(FormHelperText)`
  color: #f44336;
`;

// const MenuWrapper = styled.div`
//   max-height: 30rem;
// `;

const StyledMenuItem = styled(MenuItem)`
  &[aria-selected='true'] {
    background-color: transparent;
  }
`;

const SelectAllMenuItem = styled(StyledMenuItem)`
  border-bottom: 2px solid ${p => p.theme.input.text.default} !important;
`;

const InternalSelect = styled(MuiSelect)`
  & .MuiFormLabel-root,
  & .MuiInputBase-input {
    font-family: 'Gabriel Sans Cond';
    font-weight: 700;
    color: ${p => p.theme.input.text.default};
  }

  &.MuiInputBase-root {
    background-color: ${p => p.theme.input.background};
    border-radius: 4px;
    height: 3rem;
  }

  & .MuiSelect-selectMenu {
    max-height: 3rem;
  }

  & .MuiSelect-select:focus {
    background-color: transparent;
  }
`;

const ValueLabel = styled('span')`
  display: flex;
`;

const PlaceholderLabel = styled('span')`
  color: ${p => p.theme.input.text.placeholder};
  font-weight: 500;
  opacity: 0.5;
`;

const ListItemText = styled(MuiListItemText)`
  > .MuiListItemText-primary {
    font-family: 'Gabriel Sans Cond';
    font-weight: 500;
    color: ${p => p.theme.input.text.default};
  }
`;
