import { IconButton, TextField, TextFieldProps } from '@material-ui/core';
import {
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';

export type TextInputProps = TextFieldProps & {
  disabled?: boolean;
};

export const TextInput = (props: TextInputProps) => {
  const { type, ...rest } = props;
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const overridenInputType = () => {
    if (type === 'password' && isPasswordVisible) return 'text';
    return type;
  };

  const renderEndAdornment = useCallback(() => {
    if (type === 'password') {
      return (
        <>
          <IconButton
            aria-label="Toggle password visibility"
            onClick={() => setPasswordVisible(!isPasswordVisible)}
            onMouseDown={e => e.preventDefault()}
            tabIndex="-1"
          >
            {isPasswordVisible && <VisibilityOutlinedIcon />}
            {!isPasswordVisible && <VisibilityOffOutlinedIcon />}
          </IconButton>
        </>
      );
    }
    return undefined;
  }, [isPasswordVisible, type]);

  return (
    <InternalTextInput
      type={overridenInputType()}
      InputProps={{ endAdornment: renderEndAdornment() }}
      {...rest}
    />
  );
};

const InternalTextInput = styled(TextField)`
  & .MuiInputBase-root {
    background-color: ${p => p.theme.input.background};
    border-radius: 4px;
    height: 3rem;
  }

  & .Mui-focused {
    & input {
      color: ${p => p.theme.input.text.focus};
    }
    & fieldset {
      border-width: 1px;
    }
  }

  & .Mui-disabled {
    & input {
      color: ${p => p.theme.input.text.placeholder};
      font-weight: 500;
      opacity: 1;
    }
    & fieldset {
      border: none;
    }
  }

  & .MuiInputBase-input {
    font-family: 'Gabriel Sans Cond';
    font-weight: 700;
    color: ${p => p.theme.input.text.default};

    & ::placeholder {
      color: ${p => p.theme.input.text.placeholder};
      font-weight: 500;
    }
  }

  & .MuiInputBase-inputMultiline {
  }
`;
