import { Route } from 'types/route';
import { SubscriptionPage } from 'app/pages/Subscriptions/SubscriptionPage';
import { SubscriptionsPage } from 'app/pages/Subscriptions/SubscriptionsPage';

import { paths } from '../../paths';
import { CreateSubscriptionPage } from 'app/pages/Subscriptions/CreateSubscriptionPage';

const createSubscription: Route = {
  authenticated: true,
  content: CreateSubscriptionPage,  
  routerPath: [paths.subscriptions.create_subscription]
};

const subscription: Route = {
  authenticated: true,
  content: SubscriptionPage,
  routerPath: [paths.subscriptions.updateByCard, paths.subscriptions.update],
};

const subscriptions: Route = {
  authenticated: true,
  defaultPath: paths.subscriptions.list.cases.pending,
  content: SubscriptionsPage,
  routerPath: paths.subscriptions.list.pattern,
};


export const routes = [createSubscription,subscription,subscriptions];
