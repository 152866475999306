import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { first } from 'lodash';
import { useResetRecoilState } from 'recoil';
import { useQueryClient } from 'react-query';

import { FormLabel as DefaultFormLabel } from 'app/components/Forms/FormLabel';
import { SelectInput } from 'app/components/Inputs/Uncontrolled/SelectInput';
import { Button } from 'app/components/Button';
import { SelectInputOption } from 'app/components/Inputs/SelectInput';
import { authorizationState } from 'app/atoms';
import { UserNamesLabel } from 'app/components/Labels/UserNamesLabel';

import { theme } from 'styles/theme/themes';

import { messages } from './messages';
import { useSelectors } from './hooks';

interface Props {
  container: 'popover' | 'drawer';
  onInstituteChange?: () => void;
}

export const AccountPanel = ({ container, onInstituteChange }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const resetAuth = useResetRecoilState(authorizationState);
  const { currentInstituteId, institutes, me, setInstitute } = useSelectors();

  const onChange = (values: string[]) => {
    const value = first(values);
    if (value) {
      setInstitute(parseInt(value));
      onInstituteChange?.();
    }
  };

  const handleLogout = () => {
    queryClient.clear();
    localStorage.clear();
    resetAuth();
  };

  const intitutsOptions: SelectInputOption[] = useMemo(
    () =>
      institutes.map(institute => ({
        id: institute.id.toString(),
        label: institute.name.charAt(0).toUpperCase() + institute.name.slice(1),
      })).sort((a, b) => (a.label > b.label ? 1 : -1)),
    [institutes],
  );

  return (
    <Wrapper>
      <UserNamesLabel
        // container={container}
        firstname={me!.firstname}
        lastname={me!.lastname}
        style={
          container === 'drawer' ? { color: theme.text.bright } : undefined
        }
      />
      <div>
        <FormLabel container={container}>{t(messages.institute())}</FormLabel>
        <StyedSelectInput
          fullWidth
          onChange={onChange}
          value={[currentInstituteId.toString()]}
          options={intitutsOptions}
          container={container}
        />
      </div>
      <Button className="disconnect-button" onClick={handleLogout}>
        {t(messages.disconnect())}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  > .disconnect-button {
    margin-top: 1.5rem;
  }
  > * {
    margin-bottom: 1rem;
  }
`;

const FormLabel = styled(DefaultFormLabel)<Props>`
  color: ${p => (p.container === 'drawer' ? p.theme.text.bright : 'inherited')};
`;

// const UserNamesLabel = styled(DefaultUserNamesLabel)<Props>`
//   color: ${p => (p.container === 'drawer' ? p.theme.text.bright : 'inherited')};
// `;

const StyedSelectInput = styled(SelectInput)<Props>`
  &.MuiInputBase-root {
    background-color: ${p =>
      p.container === 'popover' ? p.theme.pageBackground : 'inherited'};
  }
`;
