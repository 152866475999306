import { QueryClient, QueryKey } from 'react-query';

import { Identifiable, Optional } from 'types/misc';

import { CacheType } from './types';
import { cacheTypeForQueryKey } from './utils';
import { getObjectFromListDataCache } from './caches/listData';
import { getObjectFromObjectDataCache } from './caches/objectData';

export function getObjectFromCache<T extends Identifiable>({
  predicate,
  queryClient,
  queryKey,
}: {
  predicate: (object: T) => boolean;
  queryClient: QueryClient;
  queryKey: QueryKey;
}): Optional<T> {
  const cacheType = cacheTypeForQueryKey({ queryClient, queryKey });
  switch (cacheType) {
    case CacheType.Object:
      return getObjectFromObjectDataCache({ queryClient, queryKey });
    case CacheType.List:
      return getObjectFromListDataCache({ predicate, queryClient, queryKey });
    default:
      // No cache for this query key.
      return undefined;
  }
}
