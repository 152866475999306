import i18next from 'i18next';

import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const discountAvailabilityLabel = (type: number): string => {
  switch (type) {
    case 0:
      return i18next.t(
        _t(translations.special_offers.discount.availability.values.passing),
      );
    case 1:
      return i18next.t(
        _t(
          translations.special_offers.discount.availability.values.subscription,
        ),
      );
    default:
      return i18next.t(
        _t(
          translations.special_offers.discount.availability.values
            .passing_and_subscription,
        ),
      );
  }
};
