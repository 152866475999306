import { Route } from 'types/route';
import { FiscalArchivesPage } from 'app/pages/FiscalArchivesPage';

import { paths } from '../../paths';

const fiscalArchives: Route = {
  authenticated: true,
  content: FiscalArchivesPage,
  routerPath: paths.fiscalArchives.index,
};

export const routes = [fiscalArchives];
