import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  enabled: boolean;
}>;

export const Disableable = (props: Props) => {
  const { enabled, children } = props;
  if (enabled) {
    return <>{children}</>;
  }
  return null;
};
