import { createTheme } from '@material-ui/core/styles';
import { theme } from '../themes';
import { frFR } from '@mui/x-date-pickers';

export const muitheme = createTheme(
	{
		props: {
			MuiButton: {
				disableElevation: true,
			},
			MuiButtonBase: {
				disableRipple: true,
			},
			MuiCheckbox: {
				disableRipple: true,
			},
			MuiSwitch: {
				disableRipple: true,
			},
		},
		typography: {
			fontFamily: [
				'Gabriel Sans',
				'Helvetica Neue',
				'Helvetica',
				'Arial',
				'sans-serif',
			].join(','),
		},
		palette: {
			primary: {
				contrastText: '#fff',
				dark: theme.primary,
				light: theme.primary,
				main: theme.primary,
			},
			secondary: {
				contrastText: '#fff',
				dark: theme.secondary,
				light: theme.secondary,
				main: theme.secondary,
			},
		},
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 960,
				lg: 1280,
				xl: 1920,
			},
		},
	},
	frFR,
);
