import { selector } from 'recoil';

import { authorizationState } from '../authorization';
import { meState } from '../me';
import { institutesState } from '../institutes';

export const authenticatedState = selector({
  key: 'isAuthenticated',
  get: ({ get }) => {
    const { token } = get(authorizationState);
    const me = get(meState);
    const { currentInstituteId } = get(institutesState);

    const isAuthenticated = !!token && !!me && !!currentInstituteId;
    return isAuthenticated;
  },
});
