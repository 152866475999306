import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { TextInput } from 'app/components/Inputs/TextInput';
import { fieldName } from 'utils/form';
import { Address } from 'app/components/Domain/forms/Address';

import { messages } from '../../../messages';

interface Props {
  disabled: boolean;
}

export const General = ({ disabled }: Props) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1}>
      <FormInputGrid item sm={6}>
        <FormLabel>{t(messages.phone.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name={fieldName('phone')}
          placeholder={t(messages.phone.placeholder())}
          required
        />
      </FormInputGrid>
      <Address disabled={disabled} />
    </Grid>
  );
};
