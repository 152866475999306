import {
  DateInputDescription,
  DropdownInputDescription,
  InputConfiguration,
  PeriodInputDescription,
} from 'app/pages/Stats/StatsPage/types/inputs';

import { Date } from '../Date';
import { Dropdown } from '../Dropdown';
import { Period } from '../Period';

interface Props {
  configuration: InputConfiguration;
  disabled: boolean;
}

export const Input = (props: Props) => {
  const { configuration, disabled } = props;

  switch (configuration.type) {
    case 'date':
      return (
        <Date
          description={configuration.description as DateInputDescription}
          disabled={disabled}
        />
      );
    case 'dropdown':
      return (
        <Dropdown
          description={configuration.description as DropdownInputDescription}
          disabled={disabled}
        />
      );
    case 'period':
      return (
        <Period
          description={configuration.description as PeriodInputDescription}
          disabled={disabled}
        />
      );
  }
};
