import { useMemo } from 'react';

import { Optional } from 'types/misc';

import { Value, ValueFormatter } from '../../../types';

interface Options {
  context?: any;
  values: any[];
  valueFormatter: Optional<ValueFormatter>;
}

export const useValueFormatter = (options: Options) => {
  const { context, values: rawValues, valueFormatter } = options;

  const values = useMemo(() => {
    if (valueFormatter) {
      return rawValues.map(value =>
        Object.entries(value)
          .map(([key, value]) => [
            key,
            valueFormatter(key, value as Value, context),
          ])
          .reduce((acc, partial) => {
            const key = partial[0];
            const value = partial[1];
            // console.log(key, value);
            acc[key] = value;
            return acc;
          }, {}),
      );
    } else {
      return rawValues;
    }
  }, [rawValues]);

  return { values };
};
