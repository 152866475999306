import { Receipt } from 'types/API/receipts';
import { Optional } from 'types/misc';

export const displayableProductsFromReceipt = (
  receipt: Receipt.Detail,
): Receipt.DisplayableProduct[] => {
  return [
    ...displayableProductsFromProducts(receipt.products),
    ...displayableProductsFromDiscounts(receipt.promotions),
    ...displayableProductsFromCures(receipt.cures),
    ...displayableProductsFromCureSessions(receipt.sessionCure),
  ];
};

const displayableProductsFromProducts = (
  products: Optional<Receipt.Product[]>,
): Receipt.DisplayableProduct[] =>
  products?.map(product => {
    const { id, language, quantity, inclTax, unitPrice } = product;
    return {
      id,
      name: language.title,
      quantity,
      totalPrice: inclTax,
      unitPrice,
    };
  }) ?? [];

const displayableProductsFromDiscounts = (
  discounts: Optional<Receipt.Discount[]>,
): Receipt.DisplayableProduct[] =>
  discounts?.map(discount => {
    const { id, inclTax, name } = discount;
    return {
      id,
      name,
      quantity: 1,
      totalPrice: inclTax,
      unitPrice: inclTax,
    };
  }) ?? [];

const displayableProductsFromCures = (
  cures: Optional<Receipt.Cure[]>,
): Receipt.DisplayableProduct[] =>
  cures?.map(cure => {
    const { id, inclTax, name, quantity } = cure;
    return {
      id,
      name,
      quantity,
      totalPrice: inclTax,
      unitPrice: inclTax, // No unit price provided, be careful here
    };
  }) ?? [];

const displayableProductsFromCureSessions = (
  sessions: Optional<Receipt.CureSession[]>,
): Receipt.DisplayableProduct[] =>
  sessions?.map(session => {
    const { id, name } = session;
    return {
      id,
      name,
      quantity: 1,
      totalPrice: 0,
      unitPrice: 0,
    };
  }) ?? [];
