import { Redirect as DefaultRedirect, useLocation } from 'react-router-dom';

interface Props {
  to: string;
  includesParams?: boolean;
}

export const Redirect = ({ to, includesParams = true }: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const target = includesParams
    ? `${to}${searchParams.stringSearchParams()}`
    : to;
  return <DefaultRedirect to={target} />;
};
