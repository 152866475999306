import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { sizing } from '@material-ui/system';


import { ListItem, ListItemProps } from 'app/components/ListItem';
import { Receipt } from 'types/API/receipts';
import { usePriceFormatter } from 'utils/formatters/hooks/price';
import {
  EntriesWrapper,
  InfoEntry,
  InfoEntryHorizontal,
  InfoEntrySeparator,
} from 'app/components/ListItem/InfoEntry';
import { CustomerInfo } from 'app/components/ListItem/CustomerInfo';

import { messages } from './messages';
import { Mode } from '../Selector/Item';
import { Disableable } from 'app/components/Disableable';

interface Props extends ListItemProps {
  currency: string;
  receipt: Receipt.Normal;
}

export const ReceiptItem = (props: Props) => {
  
  const { currency, receipt, ...rest } = props;

  const { t } = useTranslation();

  const { formatPrice } = usePriceFormatter({ currency });

  const { customer } = receipt;

  const theme = useTheme();
  const expandedMode = useMediaQuery(theme.breakpoints.up('lg'), {
    noSsr: true,
  });
  const compactMode = !useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

const mode = expandedMode
  ? Mode.EXPANDED
  : compactMode
  ? Mode.COMPACT
  : Mode.NORMAL;


  const userPaid = useMemo(() => {
    if (receipt.userPaid && receipt.paidAt) {
      const { firstname } = receipt.userPaid;
      return {
        date: new Date(receipt.paidAt),
        firstname,
      };
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receipt]);

  return (
    <ListItem {...rest}>
      <Disableable enabled={mode >= Mode.EXPANDED}>
        
      <CustomerInfo customer={customer} />

      <EntriesWrapper>
        <InfoEntry
          label={t(messages.ticketNumber())}
          value={`${receipt.number}`}
          />
        <InfoEntrySeparator />
        <InfoEntry
          label={t(messages.total())}
          value={formatPrice(receipt.total.inclTax)}
          />
        {Object.keys(receipt.total.byCategories).map(key => (
          <InfoEntry
          key={key}
          label={key.charAt(0).toUpperCase() + key.slice(1).toLowerCase() }
          value={formatPrice(receipt.total.byCategories[key])}
          />
        ))}

        {receipt.paymentType && 
          <>
          <InfoEntry
            label={t(messages.paymentType())}
            value={receipt.paymentType || "-"}
            />
          </>
        }

        <InfoEntrySeparator />

        <InfoEntry
          label={t(messages.createdBy())}
          value={t(messages.userDateFormat(), {
            user: receipt.userCreate.firstname,
            date: new Date(receipt.createdAt),
            escapeInterpolation: true,
          })}
          />

        {userPaid && (
          <>
            <InfoEntrySeparator />
            <InfoEntry
              label={t(messages.bankedBy())}
              value={t(messages.userDateFormat(), {
                user: userPaid.firstname,
                date: userPaid.date,
              })}
              />
          </>
        )}
      </EntriesWrapper>
      </Disableable>






      <Disableable enabled={mode <= Mode.NORMAL}>
        <CustomerInfo customer={customer} />
        <Grid wrap='nowrap' container direction='row'>
        <div>
            <InfoEntryHorizontal
              label={t(messages.ticketNumber())}
              value={`${receipt.number}`}
              />
            <InfoEntryHorizontal
              label={t(messages.total())}
              value={formatPrice(receipt.total.inclTax)}
              />
            {Object.keys(receipt.total.byCategories).map(key => (
              <InfoEntryHorizontal
              key={key}
              label={key.charAt(0).toUpperCase() + key.slice(1).toLowerCase() }
              value={formatPrice(receipt.total.byCategories[key])}
              />
            ))}
    
            {receipt.paymentType && 
              <>
              <InfoEntryHorizontal
                label={t(messages.paymentType())}
                value={receipt.paymentType || "-"}
                />
              </>
            }

        </div>
        <InfoEntrySeparator />
        <div>
          <InfoEntryHorizontal
            label={t(messages.createdBy())}
            value={t(messages.userDateFormat(), {
              user: receipt.userCreate.firstname,
              date: new Date(receipt.createdAt),
              escapeInterpolation: true,
            })}
            />
  
          {userPaid && (
            <>
              <InfoEntryHorizontal
                label={t(messages.bankedBy())}
                value={t(messages.userDateFormat(), {
                  user: userPaid.firstname,
                  date: userPaid.date,
                })}
                />
            </>
          )}

        </div>
          
        </Grid>
      </Disableable>
    </ListItem>
  );
};
