import React from 'react';
import styled from 'styled-components/macro';

interface DefaultProps {
  isActive: boolean;
}

interface Props extends DefaultProps {
  isActive: boolean;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}
export const Status = (props: Props) => {
  const { isActive, label, onClick } = props;

  return (
    <Wrapper className="status" isActive={isActive} onClick={onClick}>
      {label}
    </Wrapper>
  );
};

const Wrapper = styled.div<DefaultProps>`
  display: flex;
  align-items: center;
  font-family: 'Gabriel Sans Cond';
  font-weight: 500;
  font-size: 0.75rem;
  color: ${p => p.theme.text.bright};
  background-color: ${p =>
    p.isActive ? p.theme.status.active : p.theme.status.inactive};
  height: 2rem;
  border-radius: 1rem;
  padding: 1rem 2rem;
  cursor: pointer;
`;