import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { TextInput } from 'app/components/Inputs/TextInput';
import {
  SelectInput,
  SelectInputOption,
} from 'app/components/Inputs/SelectInput';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';

import { messages } from '../../../messages';

interface Props {
  disabled: boolean;
  prestationsOptions: SelectInputOption[];
  sessionsCountOptions: SelectInputOption[];
}

export const General = (props: Props) => {
  const { disabled, prestationsOptions, sessionsCountOptions } = props;

  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <FormInputGrid item sm={12}>
        <FormLabel>{t(messages.name.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name="name"
          placeholder={t(messages.name.placeholder())}
          required
        />
      </FormInputGrid>
      <FormInputGrid item sm={6}>
        <FormLabel>{t(messages.prestation.label())}</FormLabel>
        <SelectInput
          disabled={disabled}
          name="product"
          options={prestationsOptions}
          placeholder={t(messages.prestation.placeholder())}
          required
        />
      </FormInputGrid>
      <FormInputGrid item sm={6}>
        <FormLabel>{t(messages.sessionsCount.label())}</FormLabel>
        <SelectInput
          disabled={disabled}
          name="sessions"
          options={sessionsCountOptions}
          placeholder={t(messages.sessionsCount.placeholder())}
          required
        />
      </FormInputGrid>
    </Grid>
  );
};
