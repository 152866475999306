import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Sales } from 'types/API/sales';
import { usePriceFormatter } from 'utils/formatters/hooks/price';
import { messages } from '../../../messages';

import { SelectableProps, variantForegroundColor } from '../Item';

interface Props {
  currency: string;
  sales: Sales.Normal;
  selected: boolean;
}

export const SalesDetail = (props: Props) => {
  const { currency, sales, selected } = props;
  const { t } = useTranslation();
  const { formatPrice } = usePriceFormatter({ currency });

  return (
    <Wrapper selected={selected}>
      <ItemWrapper>
        <Label>{t(messages.paid.detail.card())}</Label>
        <Value>{formatPrice(sales.cb.sum)}</Value>
      </ItemWrapper>
      <ItemWrapper>
        <Label>{t(messages.paid.detail.cheque())}</Label>
        <Value>{formatPrice(sales.chq.sum)}</Value>
      </ItemWrapper>
      <ItemWrapper>
        <Label>{t(messages.paid.detail.cash())}</Label>
        <Value>{formatPrice(sales.esp.sum)}</Value>
      </ItemWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<SelectableProps>`
  display: flex;
  flex-direction: row;

  color: ${p => variantForegroundColor(p.selected, p.theme)};
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
`;

const Label = styled.span`
  font-size: 0.8rem;
  margin-right: 0.2rem;
`;

const Value = styled.span`
  font-family: 'Gabriel Sans Cond';
  font-weight: 500;
  font-size: 1rem;
`;
