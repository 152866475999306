import { FormProvider, UseFormReturn } from 'react-hook-form';
import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  id?: string;
  methods: UseFormReturn<any>;
  onSubmit?: () => void;
}>;

export const FormWrapper = ({ children, id, methods, onSubmit }: Props) => (
  <FormProvider {...methods}>
    <form id={id} onSubmit={onSubmit}>
      {children}
    </form>
  </FormProvider>
);
