import { SellOff } from 'types/API/selloffs';
import { request } from 'utils/request';

export const fetchSellOffsRequest = ({
  instituteId,
}: {
  instituteId: number;
}) => request<SellOff.Normal[]>(`api/selloff/institute/${instituteId}`, {
    method: 'GET',
  });

export const fetchSellOffRequest = ({
  sellOffId,
}: {
  sellOffId: number;
}) =>
request<SellOff.Normal>(`api/selloff/${sellOffId}`, {
  method: 'GET',
});
