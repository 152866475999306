import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ListItem, ListItemProps } from 'app/components/ListItem';
import { CustomerInfo } from 'app/components/ListItem/CustomerInfo';
import { EntriesWrapper, InfoEntry } from 'app/components/ListItem/InfoEntry';
import { Status } from 'app/components/Status';
import { LoadableContent } from 'app/components/LoadableContent';
import { SubscriptionTab } from '../../types';

import {
  SearchEvent,
  SearchInput,
} from 'app/components/PageHeaders/SearchInput';

import { messages } from '../../messages';
import { useSelectors } from '../../hooks';
import { EmptyState } from 'app/components/EmptyState';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useOldData } from '../../hooks/useOldData';

export const OldDBView = () => {

  const { currentInstituteId } = useSelectors();
  const tab = SubscriptionTab.OLDDB;
  /*
  const { subscribersOldQueryState, isSearchActive, onSearch } = useData({
    instituteId: currentInstituteId,
    tab,
  });
  */
  const { subscribersOldQueryState, isSearchActive, onSearch } = useOldData({
    instituteId: currentInstituteId,
    tab,
  });
  const { t } = useTranslation();
  const isContentEmpty = subscribersOldQueryState.data?.hits.hits.length === 0;
  const theme = useTheme();
  const largeHeader = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const getEmptyContentMessage = () => {
    if (isSearchActive) {
      return t(messages.emptySearchLabel());
    } else {
      var tabKey = messages.tabs.oldBd;
      return t(tabKey.emptyLabel());
    }
  };
  /*
    function onSearch(event: SearchEvent): void {
      throw new Error('Function not implemented.');
    }
  */

  return (
    <div>
      <ActionsWrapper largeHeader={largeHeader}>
        <SearchInput
          className="search-input"
          disabled={false}
          onSearch={onSearch}
          placeholder={t(messages.tabs.oldBd.searchPlaceholder())}
        />
      </ActionsWrapper>

      {
        <LoadableContent
          /*error={subscribersOldQueryState.error}*/
          isLoading={subscribersOldQueryState.isLoading}
          isEmpty={isContentEmpty}
          emptyNodeRenderer={() => (
            <EmptyState label={getEmptyContentMessage()} />
          )}
        ></LoadableContent>
      }
    </div>
  );
};

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
interface InternalProps {
  largeHeader: boolean;
}

const ActionsWrapper = styled.div<InternalProps>`
  z-index: 10;
  background1: blue;
  display: flex;
  flex-direction: ${p => (p.largeHeader ? 'row' : 'column')};
  align-items: ${p => (p.largeHeader ? 'center' : 'flex-start')};
  justify-content: space-between;

  > .search-input {
    margin-right1: 200px;
    margin-top: ${p => (!p.largeHeader ? '1rem' : 'inherit')};
    width: ${p => (p.largeHeader ? '50%' : '70%')};
  }
`;
