import { last } from 'lodash';

export const idFromEntityEndpoint = (endpoint: string) => {
  const raw = last(endpoint.split('/')) as string;
  return parseInt(raw);
};

type EntityModule = 'institutes' | 'products' | 'subscriptions' | 'subcription_types' ;

export const entityEndpoint = ({
  id,
  module,
}: {
  id: number | string;
  module: EntityModule;
}) => `/api/${module}/${id}`;
