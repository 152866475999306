import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { Switch } from 'app/components/Inputs/Switch';
import { TextInput } from 'app/components/Inputs/TextInput';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { fieldName } from 'utils/form';
import { Address } from 'app/components/Domain/forms/Address';

import { Names } from '../Customer/Names';
import { messages } from '../../../messages';

interface Props {
  disabled: boolean;
}

export const BankAccount = ({ disabled }: Props) => {
  const { t } = useTranslation();

  const { watch } = useFormContext();

  const namePrefix = 'subscriptionPayment';

  const isCustomerDebtor = watch(fieldName('isCustomerDebtor', namePrefix));
/* check special characters type in real time
  const [ibanValue, setIbanValue] = useState('');
  const formatValue = (value: string): string => {
    //Strip all special characters from string
    let val = value.replace(/[^\w]/gi, '');
    return val;
  };
  const handleTextInputChange = (value: string) => {
    var newValue = formatValue(value);
    setIbanValue(newValue);
  };
*/

  return (
    <Grid container spacing={1}>
      <FormInputGrid item sm={12}>
        <FormLabel>{t(messages.iban.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name={fieldName('iban', namePrefix)}
          placeholder={t(messages.iban.placeholder())}
          //handleChange1={handleTextInputChange}
          //value={ibanValue}
          type="password"
        />
      </FormInputGrid>
      <FormInputGrid item sm={12}>
        <Switch
          label={t(messages.payerIsSubscriber())}
          name={fieldName('isCustomerDebtor', namePrefix)}
        />
      </FormInputGrid>
      {!isCustomerDebtor ? (
        <>
          <Names disabled={disabled} namePrefix={namePrefix} />
          <Address disabled={disabled} namePrefix={namePrefix} />
        </>
      ) : null}
    </Grid>
  );
};
