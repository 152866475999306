import styled from 'styled-components/macro';
import React from 'react';
import { PageHeaderProps } from 'app/components/PageHeaders/PageHeader';
import { Customer } from 'types/API/customers';
import { Optional } from 'types/misc';
import { BackButton } from 'app/components/PageHeaders/BackButton';
import { PageTitle } from 'app/components/PageHeaders/PageTitle';
import { Avatar } from '@mui/material';
import avatr from 'app/assets/avatr.jpeg';
import { getEnv } from 'utils/common/env';

interface Props extends PageHeaderProps {
  customer: Optional<Customer.Normal>;
}
export const Header = (props: Props) => {
  const { backBehaviour, customer, label } = props;
  const API_URL = getEnv('API_URL');
  const shouldFetchImage: boolean = false; // TODO Restore customers profile photo image (img) if no impact on performances at 12 am
  return (
    <>
      <Wrapper>
        {backBehaviour && <BackButton className="back-button" />}
        <PageTitle> {label}</PageTitle>
      </Wrapper>
      <AvatarWrapper>
        <Avatar
          src={
            customer?.imgPath?.trim() !== '' && shouldFetchImage
              ? `${API_URL}/${customer?.imgPath}`
              : avatr
          }
          sx={{ width: '200px', height: '200px' }}
        />
      </AvatarWrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  > .avatar {
    margin: 0 1rem;
  }
`;

const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
`;