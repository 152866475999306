import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components/macro';

import { GenericPageWrapper } from '../GenericPageWrapper';
import { NavigationBar } from '../components/NavigationBar';
import { SideBar } from '../components/SideBar';
import { Conditional } from 'app/components/Conditional';
import { ContentError } from 'app/components/ContentError';
import { Nullable } from 'types/misc';

type Props = PropsWithChildren<{
  disabled?: boolean;
  error?: Nullable<Error>;
  header: ReactNode;
  idabo?: string;
}>;

export const PageWrapper = ({
  children,
  disabled = false,
  error,
  header,
}: Props) => (
  <GenericPageWrapper
    disabled={disabled}
    secondaryContentRenderer={{
      landscape: () => <SideBar />,
      portrait: () => <NavigationBar />,
    }}
  >
    <Conditional
      condition={!error}
      trueConditionNode={
        <ContentWrapper>
          <HeaderWrapper>{header}</HeaderWrapper>
          <InnerContentWrapper>{children}</InnerContentWrapper>
        </ContentWrapper>
      }
      falseConditionNode={<ContentError label={error?.message} terminate />}
    />
  </GenericPageWrapper>
);
interface HeaderWrapperProps {
  extraMargin: string;
}

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`;

const InnerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden scroll;
  // padding-right: 0.5rem;
`;