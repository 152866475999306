import styled from 'styled-components/macro';

import { PageHeaderProps } from 'app/components/PageHeaders/PageHeader';
import { BackButton } from 'app/components/PageHeaders/BackButton';
import { PageTitle } from 'app/components/PageHeaders/PageTitle';

interface Props extends PageHeaderProps {
}
export const Header = (props: Props) => {
  const { backBehaviour, label } = props;
  return (
    <Wrapper>
      {backBehaviour && <BackButton className="back-button" />}
      <PageTitle> {label}</PageTitle>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  > .avatar {
    margin: 0 1rem;
  }
`;
