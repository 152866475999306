import {
  IconButton as RawIconButton,
  IconButtonProps as RawIconButtonProps,
} from '@material-ui/core';

import styled from 'styled-components/macro';
import { theme } from 'styles/theme/themes';

// export type IconButtonProps = RawIconButtonProps;

interface IconButtonProps extends Omit<RawIconButtonProps, 'color'> {
  color?: string;
}

export const IconButton = (props: IconButtonProps) => {
  const { color = theme.text.bright, ...rest } = props;
  return <InternalIconButton $customColor={color} {...rest} />;
};

interface InternalIconButtonProps {
  $customColor?: string;
}

export const InternalIconButton = styled(
  RawIconButton,
)<InternalIconButtonProps>`
  &.MuiIconButton-root {
    color: ${({ $customColor }) => $customColor};
  }
  &.MuiIconButton-root:hover {
    background-color: transparent;
    opacity: 0.8;
  }
`;
