import { Route } from 'types/route';
import { StatsMenu } from 'app/pages/Stats/StatsMenu';
import { StatsPage } from 'app/pages/Stats/StatsPage';

import { paths } from '../../paths';

const menu: Route = {
  authenticated: true,
  content: StatsMenu,
  routerPath: paths.stats.index,
};

const stats: Route = {
  authenticated: true,
  content: StatsPage,
  routerPath: paths.stats.pattern,
};

export const routes = [stats, menu];
