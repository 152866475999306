import React from 'react';
import styled from 'styled-components/macro';

import { BackButton } from 'app/components/PageHeaders/BackButton';

import { PageTitle } from '../PageTitle';

type BackBehaviour = boolean | (() => void);

export interface PageHeaderProps {
  backBehaviour?: BackBehaviour;
  label: string;
  extramargin?: boolean;
}

export const PageHeader = (props: PageHeaderProps) => {
  const { backBehaviour, label, extramargin } = props;
  return (
    <Wrapper extramargin={extramargin}>
      {backBehaviour && <BackButton className="back-button" />}
      <PageTitle>{label}</PageTitle>
    </Wrapper>
  );
};

interface HeaderWrapperProps {
  extramargin?: boolean;
}

const Wrapper = styled.div<HeaderWrapperProps>`
  display: flex;
  align-items: center;
  background-color1: blue;
  margin-top: ${(props) => (props.extramargin ? '4rem' : '0')};
  > .back-button {
    margin-right: 1rem;
  }
`;