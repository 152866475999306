import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  pageTitle: () => _t(translations.tills.page_title),
  updatePageTitle: () => _t(translations.tills.update_page_title),
  id: () => _t(translations.tills.id),
  number: () => _t(translations.tills.number),
  merchantId: () => _t(translations.tills.merchantId),
  printer: () => _t(translations.tills.printer),
  tpe: () => _t(translations.tills.tpe),
  connected: () => _t(translations.tills.connected)
};
