import { Customer } from 'types/API/customers';
import { getEnv } from 'utils/common/env';

const API_URL = getEnv('API_URL');

export enum ImageSize {
  Normal,
  Small,
}
export const getCustomerImageURI = (
  identifier: number | string,
  size: ImageSize = ImageSize.Normal,
) => {
  let sizeExtension = '';
  if (size === ImageSize.Small) {
    sizeExtension = 'mini/';
  }
  return `${API_URL}/img/customers/${sizeExtension}customer_${identifier}.jpeg`;
};

export const getCustomerInitials = (
  customer: Customer.Base | Customer.External,
) => {
  const i1 = customer.firstname.charAt(0);
  const i2 = customer.lastname.charAt(0);
  return `${i1}${i2}`.toUpperCase();
};

export const getCustomerCompleteName = (
  customer: Customer.Base | Customer.External,
) => `${customer.firstname} ${customer.lastname}`;
