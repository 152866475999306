import { useCallback } from 'react';

import { PropertyMapping } from '../../../types';

interface Options {
  propertyMapping: PropertyMapping;
}

export const usePropertyMapping = (options: Options) => {
  const { propertyMapping } = options;

  const propertyLabel = useCallback(
    (property: string) => {
      if (propertyMapping[property]) {
        return propertyMapping[property];
      }
      return property;
    },
    [propertyMapping],
  );

  return { propertyLabel };
};
