import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  pageTitle: () => _t(translations.debits.page_title),
  error: () => _t(translations.common.errors.generic),
  iban: () => _t(translations.debits.iban),
  amount: () => _t(translations.debits.amount),
  emptyLabel: () => _t(translations.debits.empty_label),
  emptyHistoryLabel: () => _t(translations.debits.empty_history_label),
  searchPlaceholder: () => _t(translations.debits.search_placeholder),
  receiptCount: () => _t(translations.debits.summary.debits_count),
  total: () => _t(translations.debits.summary.total),
  download: () => _t(translations.debits.download),
  details: () => _t(translations.debits.details),
  resiliate: () => _t(translations.debits.resiliate),
  tabs: {
    fifthOfMonth: {
      emptyLabel: () => _t(translations.debits.tabs.fifth_of_month.empty_label),
    },
    twentiethOfMonth: {
      emptyLabel: () =>
        _t(translations.debits.tabs.twentieth_of_month.empty_label),
    },
  },
  validity: {
    valid: () => _t(translations.debits.validity.valid),
    invalid: () => _t(translations.debits.validity.invalid),
  },
};
