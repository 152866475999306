import { QueryClient, QueryKey } from 'react-query';

import { Identifiable, Optional } from 'types/misc';

export function addOrUpdateObjectToObjectDataCache<T extends Identifiable>({
  object,
  queryClient,
  queryKey,
}: {
  object: T;
  queryClient: QueryClient;
  queryKey: QueryKey;
}) {
  const cached = queryClient.getQueryData(queryKey) as Optional<T>;
  if (cached) {
    queryClient.setQueryData(queryKey, object);
  }
}

export function getObjectFromObjectDataCache<T extends Identifiable>({
  queryClient,
  queryKey,
}: {
  queryClient: QueryClient;
  queryKey: QueryKey;
}): Optional<T> {
  return queryClient.getQueryData(queryKey);
}

export function removeObjectFromObjectDataCache({
  queryClient,
  queryKey,
}: {
  queryClient: QueryClient;
  queryKey: QueryKey;
}) {
  queryClient.setQueryData(queryKey, undefined);
}
