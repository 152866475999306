import { useMemo } from 'react';

import { useQuery } from 'app/hooks';
import { genericQueryKey } from 'utils/cache/keys';
import { fetchDiscountsRequest, FetchMode } from 'app/services/discounts';
import { fetchProductCategoriesRequest } from 'app/services/products';
import { Optional } from 'types/misc';
import { useProductsQuery } from 'app/hooks/queries/products';

export const useQueries = ({
  instituteId,
  discountId,
}: {
  instituteId: number;
  discountId: Optional<number>;
}) => {
  const discountsParameters = useMemo(
    () => ({ instituteId, fetchMode: FetchMode.ALL }),
    [instituteId],
  );

  const {
    data: discounts,
    error: discountsError,
    isLoading: isLoadingDiscounts,
  } = useQuery(
    genericQueryKey({
      identifier: 'discounts',
      parameters: discountsParameters,
    }),
    () => fetchDiscountsRequest(discountsParameters),
    { enabled: !!discountId },
  );

  const discount = useMemo(() => {
    return discounts?.find(candidate => candidate.id === discountId);
  }, [discounts, discountId]);

  const {
    data: products,
    error: productsError,
    isLoading: isLoadingProducts,
  } = useProductsQuery({ instituteId });

  const {
    data: productCategories,
    error: productCategoriesError,
    isLoading: isLoadingProductCategories,
  } = useQuery(
    genericQueryKey({
      identifier: 'product-categories',
    }),
    () => fetchProductCategoriesRequest(),
  );

  const error = discountsError ?? productsError ?? productCategoriesError;

  const isLoading =
    isLoadingDiscounts || isLoadingProducts || isLoadingProductCategories;

  return { discount, error, isLoading, productCategories, products };
};
