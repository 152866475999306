import { useQuery } from 'app/hooks';
import { fetchUsersRequest } from 'app/services/users';
import { first } from 'lodash';
import { User } from 'types/API/users';
import { Optional } from 'types/misc';
import { genericQueryKey } from 'utils/cache/keys';
import { hasRoleOrHigher } from 'utils/entities/users';

interface AccessData {
  portalUsers: User.DetailFormat[];
  tills: User.DetailFormat[];
  tillUsers: User.DetailFormat[];
}

export const useData = (instituteId: number) => {
  const parameters = { instituteId };

  const usersQueryState = useQuery(
    genericQueryKey({
      identifier: 'users',
      parameters,
    }),
    () => fetchUsersRequest(parameters),
  );

  const { data, ...rest } = usersQueryState;

  let reworkedData: Optional<AccessData> = undefined;
  if (data) {
    // Data model changed a lot during development and in order to not remove everything, the till account uses the user model.
    let tills: User.DetailFormat[] = [];
    let portalUsers: User.DetailFormat[] = [];
    let tillUsers: User.DetailFormat[] = [];

    data.forEach(user => {
      if (first(user.roles) === User.Roles.ROLE_TILL) {
        tills.push(user);
      } else if (hasRoleOrHigher({ user, role: User.Roles.ROLE_SUPER_USER })) {
        portalUsers.push(user);
      } else {
        tillUsers.push(user);
      }
    });

    reworkedData = { portalUsers, tills, tillUsers };
  }

  const queryState = {
    data: reworkedData,
    ...rest,
  };

  return { queryState };
};
