// import { request } from 'utils/request';
import { Order } from 'types/API/orders';
import { request } from 'utils/request';

export const fetchOrdersRequest = ({
  instituteId,
}: {
  instituteId: number;
}) => request<Order.Normal[]>(`api/orders/list/institute/${instituteId}`, {
    method: 'GET',
  });

export const fetchOrderRequest = ({
  orderId,
}: {
  orderId: number;
}) =>
request<Order.Detail>(`api/orders/${orderId}`, {
  method: 'GET',
});
