import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useMemo } from 'react';

import { messages } from 'app/i18n/messages';
import { PriceMode } from 'app/pages/SpecialOffers/CurePage/hooks/useForm/types';

export const useFormValidation = () => {
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      yup.object({
        availability: yup
          .array(yup.string())
          .length(1, t(messages.forms.requiredFieldError())),
        name: yup.string().required(t(messages.forms.requiredFieldError())),
        description: yup.string().nullable(true).optional(),
        disclaimer: yup.string().nullable(true).optional(),
        autreMontantForFixedPriceMode: yup.string().notRequired(),
        autreMontantForDiscountPriceMode: yup.string().notRequired(),
        //autreMontant: yup.string().notRequired(),
          //.optional().typeError("The field must contain a number")
          //.required(t(messages.forms.requiredFieldError()))
          //.positive('Ce champs doit être un nombre positif')
          //.integer('Ce champs doit être un nombre positif')
          //.max(100, 'Ce champs doit être inférieur ou égal à 100')
          //.min(0, 'Ce champs doit être supérieur ou égal à 1')
        startDate: yup
          .date()
          .min(
            moment().startOf('day').toDate(),
            t(messages.forms.dateMustBeInTheFuture()),
          )
          .required(t(messages.forms.requiredFieldError())),
        endDate: yup
          .date()
          .min(
            yup.ref('startDate'),
            t(messages.forms.endDateMustBeLaterThanStartDate()),
          )
          .required(t(messages.forms.requiredFieldError())),

        priceMode: yup.string(),
        price: yup.array(yup.string()).when('priceMode', {
          is: (priceMode: PriceMode) => priceMode === 'fixed',
          then: yup
            .array(yup.string())
            .length(1, t(messages.forms.requiredFieldError()))
            //.required(t(messages.forms.requiredFieldError())),
        }),

        discountType: yup
          .string()
          .required(t(messages.forms.requiredFieldError())),
        discountValue: yup.array(yup.string()).when('priceMode', {
          is: (priceMode: PriceMode) => priceMode === 'discount',
          then: yup
            .array(yup.string())
            .length(1, t(messages.forms.requiredFieldError())),
            //.required(t(messages.forms.requiredFieldError())),
        }),
      }),
    [t],
  );

  return { schema };
};
