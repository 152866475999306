import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueries as useObjectivesQueries } from 'app/hooks/queries/objectives/useQueries';
import { useQueries as useStatsQueries } from 'app/hooks/queries/stats/useQueries';
import { Month } from 'types/month';

import { messages } from '../messages';

export const useData = (options: { instituteId: number; month: Month }) => {
  const { month } = options;
  const { objectives: monthlyObjectives, isLoading: isLoadingObjectives } =
    useObjectivesQueries(options);
  const { stats: monthlyStats, isLoading: isLoadingStats } =
    useStatsQueries(options);

  const { t } = useTranslation();

  const objectiveForDayIndex = useCallback(
    (index: number) => {
      if (monthlyObjectives && index < monthlyObjectives.length) {
        return monthlyObjectives[index];
      }
      return { total_sales: 0 };
    },
    [monthlyObjectives],
  );

  const statsForDayIndex = useCallback(
    (index: number) => {
      if (monthlyStats && index < monthlyStats.length) {
        return monthlyStats[index];
      }
      return { total_sales: 0, product_sales: 0, services: 0 };
    },
    [monthlyStats],
  );

  const table = useMemo(() => {
    if (monthlyStats) {
      let cumulatedObjective = 0;
      let cumulatedTotalSales = 0;

      let cumulatedServices = 0;
      let cumulatedProductSales = 0;

      let openedDays = 0;

      const rows = month.days().map((date, index) => {
        const dailyObjectives = objectiveForDayIndex(index);
        cumulatedObjective += dailyObjectives.total_sales;
        const dailyStats = statsForDayIndex(index);
        cumulatedTotalSales += dailyStats.total_sales;
        const error = cumulatedTotalSales - cumulatedObjective;
        cumulatedServices += dailyStats.services;
        cumulatedProductSales += dailyStats.product_sales;

        openedDays += dailyStats.total_sales > 0 ? 1 : 0;

        return {
          date,
          dailyObjective: dailyObjectives.total_sales,
          cumulatedObjective,
          dailyTotalSales: dailyStats.total_sales,
          cumulatedTotalSales,
          error,
          services: dailyStats.services,
          productSales: Number(dailyStats.product_sales.toFixed(2)),
        };
      });

      const footer = {
        day: t(messages.openedDays(), { count: openedDays }),
        cumulatedObjective,
        cumulatedTotalSales,
        error: cumulatedTotalSales - cumulatedObjective,
        services: cumulatedServices,
        productSales: Number(cumulatedProductSales.toFixed(2)),
      };
      return {
        footer,
        rows,
      };
    }
    return undefined;
  }, [month, monthlyStats, objectiveForDayIndex, statsForDayIndex, t]);

  const isLoading = isLoadingObjectives || isLoadingStats;

  return { table, isLoading };
};
