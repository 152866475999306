import { useMemo } from 'react';
import { range } from 'lodash';

import { SelectInputOption } from 'app/components/Inputs/SelectInput';
import { Discount } from 'types/API/discounts';
import { usePriceFormatter } from 'utils/formatters/hooks/price';
import { Product } from 'types/API/products';

import { ProductGroup } from './useForm/types';
import { useDiscountValueFormatter } from 'utils/formatters/hooks/discount';
import { discountAvailabilityLabel } from 'types/format/discount';

export const useFormData = ({
  currency,
  productCategories,
  products,
}: {
  currency: string;
  productCategories: Product.Category[];
  products: Product.Normal[];
}) => {
  const { formatPrice } = usePriceFormatter({ currency });

  const { formatDiscountValue } = useDiscountValueFormatter({ currency });

  const productGroups: ProductGroup[] = useMemo(() => {
    const prestationsCategories = productCategories.filter(
      category => category.type.type === 'prestation' && category.screen,
    );
    return prestationsCategories
      .map(category => {
        const categoryProducts = products.filter(
          product => product.category === category.categorie,
        );
        return {
          name: category.categorie.capitalized(),
          id: category.id.toString(),
          options: categoryProducts.map(product => ({
            id: product.id.toString(),
            label: product.language.title,
          })),
        };
      })
      .filter(group => group.options.length > 0);
  }, [productCategories, products]);

  const discountAmountOptionsMap: Map<Discount.Type, SelectInputOption[]> =
    useMemo(() => {
      const map = new Map(
        Object.values(Discount.Type).map(discountType => [
          discountType,
          /*range(5, 55, 5)*/ [5, 8, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(
            discountValue => ({
              id: discountValue.toString(),
              label: formatDiscountValue({ discountType, discountValue }),
            }),
          ),
        ]),
      );
      return map;

    }, [formatDiscountValue]);

  const priceOptions: SelectInputOption[] = useMemo(
    () =>
      [0, 1, 5, 9.9, 10, 14.9, 15, 19.9, 20, 25, 29.9, 30, 35, 39.9, 40, 45, 49.9, 50].map(amount => ({
        id: amount.toString(),
        label: formatPrice(amount),
      })),
    [formatPrice],
  );

  const availabilityOptions: SelectInputOption[] = useMemo(() => {
    const values = [0, 1, 2];
    return values.map(value => ({
      id: value.toString(),
      label: discountAvailabilityLabel(value),
    }));
  }, []);

  return {
    availabilityOptions,
    discountAmountOptionsMap,
    priceOptions,
    productGroups,
  };
};
