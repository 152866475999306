import { Route } from 'types/route';
import { CurePage } from 'app/pages/SpecialOffers/CurePage';

import { paths } from '../../../paths';

const createCure: Route = {
  authenticated: true,
  content: CurePage,
  routerPath: paths.specialOffers.cures.create,
};

const updateCure: Route = {
  authenticated: true,
  content: CurePage,
  routerPath: paths.specialOffers.cures.update,
};

export const routes = [createCure, updateCure];
