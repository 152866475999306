import { useCallback, useEffect, useMemo } from 'react';
import { useForm as useHookForm } from 'react-hook-form';

import { useTransformData } from '../useTransformData';
import { FormValues } from './types';

export const useForm = (validate: (values: any) => void) => {
  const { getFormValues, saveFormValues } = useTransformData();

  const defaultValues = useMemo(() => getFormValues(), [getFormValues]);
  const methods = useHookForm<FormValues>({
    defaultValues,
    mode: 'all',
  });

  const {
    formState: { isSubmitting: isFormSubmitting, isValid },
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const isSubmitting = isFormSubmitting;

  const onSubmit = useCallback(
    async (formValues: any) => {
      saveFormValues(formValues);
      validate(formValues);
    },
    [saveFormValues, validate],
  );

  return {
    canSubmit: isValid && !isSubmitting,
    isSubmitting,
    methods,
    onSubmit: handleSubmit(onSubmit),
  };
};
