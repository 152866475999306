import { BtoBProduct } from 'types/API/btobproducts';
import { request } from 'utils/request';

export const fetchBtoBProductsRequest = ({
  instituteId,
  gammeId,
  categorieId
}: {
    instituteId: number;
    gammeId: number;
    categorieId: number;
}) =>
  request<BtoBProduct.Normal[]>(
    `api/products/btob/institute/${instituteId}/gamme/0/categorie/0`,
    {
      method: 'GET',
    },
  );
