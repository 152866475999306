import { useCallback } from 'react';

import {
  AreaChartDescription,
  BarChartDescription,
  ChartConfiguration,
  LineChartDescription,
  PieChartDescription,
} from 'app/pages/Stats/StatsPage/types/charts';
import {
  PropertyMapping,
  ValueFormatter,
} from 'app/pages/Stats/StatsPage/types';
import { Optional } from 'types/misc';

import { AreaChart } from '../AreaChart';
import { BarChart } from '../BarChart';
import { LineChart } from '../LineChart';
import { PieChart } from '../PieChart';
import { useData } from './hooks';

interface Props {
  configuration: ChartConfiguration;
  propertyMapping: PropertyMapping;
  values: any[];
  valueFormatter: Optional<ValueFormatter>;
}

export const Chart = (props: Props) => {
  const {
    configuration,
    propertyMapping,
    values: rawValues,
    valueFormatter,
  } = props;

  const { values } = useData({
    propertyMapping,
    values: rawValues,
    valueFormatter,
  });

  const renderChart = useCallback(() => {
    switch (configuration.type) {
      case 'area_chart':
        return (
          <AreaChart
            description={configuration.description as AreaChartDescription}
            propertyMapping={propertyMapping}
            values={values}
          />
        );
      case 'bar_chart':
        return (
          <BarChart
            description={configuration.description as BarChartDescription}
            propertyMapping={propertyMapping}
            values={values}
          />
        );
      case 'line_chart':
        return (
          <LineChart
            description={configuration.description as LineChartDescription}
            // propertyMapping={propertyMapping}
            values={values}
          />
        );
      case 'pie_chart':
        return (
          <PieChart
            description={configuration.description as PieChartDescription}
            propertyMapping={propertyMapping}
            values={values}
          />
        );
      default:
        throw new Error('unsupported chart kind');
    }
  }, [configuration, values]);

  return renderChart();
};
