import React from 'react';
import styled from 'styled-components/macro';



type BackBehaviour = boolean | (() => void);

export interface TopBlueBarreProps {
  label?: string;
}

export const TopBlueBarre = (props: TopBlueBarreProps) => {
  const { label } = props;
  return (
    <Wrapper >
      <HeaderEvent>{label}</HeaderEvent>
    </Wrapper>
  );
};


const Wrapper = styled.div`
background: #3459d6;
border-top-left-radius: 60px;
height: 80px;
position:absolute;
width:95%;
margin-left: -53px; 
display:flex;
top:0px;

align-items:center;
justify-content:center;
@media (max-width: 959px) {
    width:100%;

    top:80px;
    border-top-left-radius: 10px;
    margin-left: -16px; 

    border-top-right-radius: 10px;


  }
`;

const HeaderEvent = styled.div`
color:white;
font-weight:bold;
font-size:28px;
text-align:center;
text-transform: uppercase;
@media (max-width: 959px) {
  font-size:25px;

}
`;
