import { useQuery } from 'app/hooks';
import { Optional } from 'types/misc';
import { fetchReceiptRequest } from 'app/services/receipts';
import { genericQueryKey } from 'utils/cache/keys';
import { Receipt } from 'types/API/receipts';
import { displayableProductsFromReceipt } from 'utils/entities/receipts';

interface Data {
  receipt: Receipt.Normal;
  products: Receipt.DisplayableProduct[];
}

export const useData = (receiptId: Optional<number>) => {
  const {
    error,
    isLoading,
    data: receipt,
  } = useQuery(
    genericQueryKey({
      identifier: 'receipt',
      parameters: { receiptId },
    }),
    () => (receiptId ? fetchReceiptRequest({ receiptId }) : undefined),
    { enabled: !!receiptId },
  );

  let data: Optional<Data> = undefined;

  if (receipt) {
    data = {
      receipt,
      products: displayableProductsFromReceipt(receipt),
    };
  }

  const queryState = {
    error,
    isLoading,
    data,
  };

  let summary: Optional<{
    count: number;
    total: Receipt.Total;
  }>;
  if (!error && !isLoading && receipt) {
    summary = {
      count: receipt.nbLigne,
      total: receipt.total,
    };
  }

  return { queryState, summary };
};
