import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useMemo } from 'react';

import { messages } from 'app/i18n/messages';

export const useFormValidation = () => {
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      yup.object({
        name: yup.string().required(t(messages.forms.requiredFieldError())),
        sessions: yup
          .array(yup.string())
          .length(1, t(messages.forms.requiredFieldError())),
        product: yup
          .array(yup.string())
          .length(1, t(messages.forms.requiredFieldError())),
        autreMontantForDiscountPriceMode: yup.string().notRequired(),
        startDate: yup
          .date()
          .min(
            moment().startOf('day').toDate(),
            t(messages.forms.dateMustBeInTheFuture()),
          )
          .required(t(messages.forms.requiredFieldError())),
        endDate: yup
          .date()
          .min(
            yup.ref('startDate'),
            t(messages.forms.endDateMustBeLaterThanStartDate()),
          )
          .required(t(messages.forms.requiredFieldError())),
        discountType: yup
          .string()
          .required(t(messages.forms.requiredFieldError())),
        discountValue: yup
          .array(yup.string())
          .length(1, t(messages.forms.requiredFieldError()))
          .required(t(messages.forms.requiredFieldError())),
      }),
    [t],
  );

  return { schema };
};
