import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  pageTitle: () => _t(translations.objectives_edition.page_title),
  add: () => _t(translations.objectives_edition.add),
  submit: () => _t(translations.objectives_edition.submit),
  dayDateFormat: () => _t(translations.objectives_edition.day_date_format),
  emptyLabel: () => _t(translations.objectives_edition.empty_label),
  columns: {
    day: () => _t(translations.objectives_edition.columns.day),
    totalSales: () => _t(translations.objectives_edition.columns.total_sales),
    cumulatedTotalSales: () =>
      _t(translations.objectives_edition.columns.cumulated_total_sales),
  },
};
