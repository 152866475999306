import { useTranslation } from 'react-i18next';

import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { DateInputDescription } from 'app/pages/Stats/StatsPage/types/inputs';
import { DatePicker } from 'app/components/Inputs/DatePicker';
import { FormLabel } from 'app/components/Forms/FormLabel';

import { messages } from './messages';

interface Props {
  description: DateInputDescription;
  disabled: boolean;
}

export const Date = (props: Props) => {
  const { description, disabled } = props;

  const { t } = useTranslation();

  const { outputProperty } = description;

  return (
    <FormInputGrid item sm={2}>
      <FormLabel>{t(messages.date.label())}</FormLabel>
      <DatePicker disabled={disabled} name={outputProperty} required />
    </FormInputGrid>
  );
};
