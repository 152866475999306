import { Route } from 'types/route';
import { ObjectivesEditionPage } from 'app/pages/Objectives/Edition/ObjectivesEditionPage';
import { ObjectivesPage } from 'app/pages/Objectives/ObjectivesPage';

import { paths } from '../../paths';

const objectivesEdition: Route = {
  authenticated: true,
  content: ObjectivesEditionPage,
  routerPath: paths.objectives.editObjectives,
};

const objectives: Route = {
  authenticated: true,
  content: ObjectivesPage,
  routerPath: paths.objectives.index,
};

export const routes = [objectivesEdition, objectives];
