import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  pageTitle: () => _t(translations.receipts.page_title),
  pending: {
    tabLabel: () => _t(translations.receipts.pending.tab_label),
    emptyLabel: () => _t(translations.receipts.pending.empty_label),
  },
  paid: {
    tabLabel: () => _t(translations.receipts.paid.tab_label),
    emptyLabel: () => _t(translations.receipts.paid.empty_label),
    detail: {
      card: () => _t(translations.receipts.paid.detail.card),
      cheque: () => _t(translations.receipts.paid.detail.cheque),
      cash: () => _t(translations.receipts.paid.detail.cash),
    },
  },
  paidProducts: {
    tabLabel: () => _t(translations.receipts.paid_products.tab_label),
    emptyLabel: () => _t(translations.receipts.paid_products.empty_label),
  },
};
