import { Grid, Avatar as MuiAvatar } from '@mui/material';
import { AddPhotoAlternate as AddPhotoAlternateIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormInputGrid } from 'app/components/Forms/FormInputGrid';

//import avatr from 'app/assets/avatr.jpeg';
import avatr from 'app/assets/avatr_photo_required.jpeg';

interface Props {
  disabled: boolean;
}

export const Avatar = ({ disabled }: Props) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const [previewImage, setPreviewImage] = useState<string | undefined>(avatr);

  const getBase64 = (file: File) => {
    return new Promise<string | undefined>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  };

  useEffect(() => {
    const fetchDefaultImage = async () => {
      const response = await fetch(avatr);
      const blob = await response.blob();
      const file = new File([blob], 'defaultImage.jpeg');
      const base64String = await getBase64(file);
      setPreviewImage(base64String);
      setValue('image', base64String);
    };

    fetchDefaultImage();
  }, []);
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      getBase64(file).then(base64String => {
        setPreviewImage(base64String);
        setValue('image', base64String);
      });
    }
  };

  const removeImage = () => {
    setPreviewImage(undefined);
    setValue('image', null);
  };

  return (
    <Grid container spacing={1}>
      <FormInputGrid item sm={6}>
        {previewImage ? (
          <label>
            <input
              type="file"
              accept=".jpeg"
              disabled={disabled}
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
            <MuiAvatar
              alt="User Avatar"
              src={previewImage}
              sx={{ width: 200, height: 200, cursor: 'pointer' }}
              onClick={() => removeImage()}
            />
          </label>
        ) : (
          <label>
            <input
              type="file"
              accept=".jpeg"
              disabled={disabled}
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
            <MuiAvatar
              alt="Add Photo"
              variant="rounded"
              sx={{
                width: 200,
                height: 200,
                cursor: 'pointer',
                borderRadius: '50%',
              }}
              onClick={() => {
                const input = document.getElementById('image-input');
                input && input.click();
              }}
            >
              <AddPhotoAlternateIcon />
            </MuiAvatar>
          </label>
        )}
      </FormInputGrid>
    </Grid>
  );
};