import React from 'react';
import styled from 'styled-components/macro';

import {
  HeaderButton,
  HeaderButtonProps,
} from 'app/components/PageHeaders/HeaderButton';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';

interface Props {
  button: HeaderButtonProps;
  title: string;
}

export const ActionPageHeader = (props: Props) => {
  const { button, title } = props;
  return (
    <Wrapper>
      <PageHeader label={title} />
      <HeaderButton {...button} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
