import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useMemo } from 'react';

import { Table } from 'app/components/Table';
import { TableCellParams, TableColumns } from 'app/components/Table/table';

import { messages } from '../../messages';
import { MonthTableFooter, MonthTableRow } from '../../types';

interface Props {
  footer: MonthTableFooter;
  rows: MonthTableRow[];
}

export const MonthTable = (props: Props) => {
  const { footer, rows } = props;

  const { t } = useTranslation();

  const columns: TableColumns = useMemo(
    () => [
      { field: 'id', headerName: 'id', hide: true },
      {
        field: 'day',
        headerName: t(messages.columns.day()),
        renderCell: (params: TableCellParams) => (
          <ContentCell>{params.value}</ContentCell>
        ),
      },
      {
        field: 'dailyObjective',
        headerName: t(messages.columns.dailyObjectives()),
      },
      {
        field: 'cumulatedObjective',
        headerName: t(messages.columns.objectives()),
      },
      {
        field: 'dailyTotalSales',
        headerName: t(messages.columns.dailyTotalSales()),
      },
      {
        field: 'cumulatedTotalSales',
        headerName: t(messages.columns.totalSales()),
      },
      {
        field: 'error',
        headerName: t(messages.columns.totalSalesError()),
      },
      {
        field: 'services',
        headerName: t(messages.columns.services()),
      },
      {
        field: 'productSales',
        headerName: t(messages.columns.productSales()),
      },
    ],
    [t],
  );

  const indexedRows = rows.map((daily, index) => {
    const { date, ...rest } = daily;

    return {
      id: index + 1,
      day: t(messages.dayDateFormat(), { date: daily.date }),
      ...rest,
    };
  });

  return <Table rows={indexedRows} columns={columns} footer={footer} />;
};

const ContentCell = styled.span`
  font-weight: 500;
`;
