import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components/macro';

import { Customer } from 'types/API/customers';
import { ListItem, ListItemProps } from 'app/components/ListItem';
import { CustomerInfo } from 'app/components/ListItem/CustomerInfo';
import { EntriesWrapper, InfoEntry } from 'app/components/ListItem/InfoEntry';
import { Status } from 'app/components/Status';

import { messages } from '../../messages';

interface Props extends ListItemProps {
  customer: Customer.Normal;
  isPending?: boolean;
}

export const SubscriptionItem = (props: Props) => {
  const { t } = useTranslation();

  const { customer, isPending = false, ...rest } = props;

  const subscription = customer.subscriptions[0];

  const dates = useMemo(() => {
    const rawEndDate = subscription.subscriptionDates.endDate;
    return {
      start: new Date(subscription.subscriptionDates.startDate),
      end: rawEndDate ? new Date(rawEndDate) : undefined,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);

  let end = customer.subscriptions[0].subscriptionDates.endDate ?? undefined;
  var currentDate = new Date();
  var endDate = end !== undefined ? new Date(end!) : undefined;
  let suppSkinEndDateStr = customer.subscriptions[0].subscriptionType.suppSkinEndDate ?? undefined;
  let suppSkinEndDate = suppSkinEndDateStr !== undefined ? new Date(suppSkinEndDateStr!) : undefined;

  const expired = endDate !== undefined ? currentDate > endDate : false;

  const entries = useMemo(() => {
    const entries: { key: string; label: string; value: string }[] = [
      {
        key: 'id',
        label: t(messages.subscriptionId()),
        value: `${subscription.id}`,
      },
      {
        key: 'type',
        label: t(messages.subscriptionType()),
        value: subscription.subscriptionType.type,
      },
      {
        key: 'start_date',
        label: t(messages.startDate()),
        value: t(messages.dateFormat(), {
          date: dates.start,
        }),
      },
    ];

    if (dates.end) {
      entries.push({
        key: 'end_date',
        label: t(messages.endDate()),
        value: t(messages.dateFormat(), {
          date: dates.end,
        }),
      });
    }
    if (suppSkinEndDateStr !== undefined && suppSkinEndDateStr !== '') {
      entries.push({
        key: 'end_date',
        label: t(messages.endDate()),
        value: t(messages.dateFormat(), {
          date: suppSkinEndDate,
        }),
      });
    }
    return entries;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  return (
    <StyledListItem {...rest}>
      <CustomerInfo customer={customer} />

      <Wrapper>
        <EntriesWrapper>
          {entries?.map(entry => (
            <InfoEntry
              key={entry.key}
              label={entry.label}
              value={entry.value}
            />
          ))}
        </EntriesWrapper>
        {subscription.rejection && 
          <Status
            isActive={!subscription.rejection}
            label="Rejeté"
          />
        }

        {!subscription.rejection && !expired &&
          <Status
            isActive={!isPending}
            label={t(
              isPending
                ? messages.subscriptionStatus.pending()
                : messages.subscriptionStatus.active(),
            )}
          />
        }
        {expired && 
          <Status
            isActive={false}
            label="Résilié"
          />
        }
        
        
      </Wrapper>
    </StyledListItem>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  > .status {
    margin-left: 1rem;
  }
`;

const StyledListItem = styled(ListItem)`
  margin-bottom: 1rem;
`;
