import { useRecoilValue, useResetRecoilState } from 'recoil';

import { snackbarState } from 'app/atoms';

export const useSelectors = () => {
  const snackbar = useRecoilValue(snackbarState);

  const resetSnackbar = useResetRecoilState(snackbarState);

  return {
    resetSnackbar,
    snackbar,
  };
};
