import styled, { CSSProperties } from 'styled-components/macro';

import { theme } from 'styles/theme/themes';

interface Props {
  style?: CSSProperties;
  firstname: string;
  lastname: string;
}
export const UserNamesLabel = ({
  style = { color: theme.text.title },
  firstname,
  lastname,
}: Props) => (
  <Wrapper style={style}>
    <FirstnameLabel>{firstname}</FirstnameLabel>
    <LastnameLabel>&nbsp;{lastname}</LastnameLabel>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const FirstnameLabel = styled.h3`
  margin-block: 0;
  font-weight: 800;
  letter-spacing: 1.17pt;
`;

const LastnameLabel = styled(FirstnameLabel)`
  text-transform: uppercase;
`;
