import { useCallback, useEffect, useMemo } from 'react';
import { useForm as useHookForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Optional } from 'types/misc';
import { User } from 'types/API/users';

import { Mode } from 'types/forms';

import { FormValues } from './types';
import { useTransformData } from '../useTransformData';
import { useMutations } from '../useMutations';
import { useFormValidation } from '../useFormValidation';

interface Options {
  instituteId: number;
  // isPasswordUpdateEnabled: boolean;
  mode: Mode;
  onSuccess: () => void;
  user: Optional<User.DetailFormat>;
}

export const useForm = (options: Options) => {
  const { instituteId, mode, onSuccess, user } = options;

  const { schema } = useFormValidation({ isPasswordUpdateEnabled: false }); //TODO

  const { getFormValues, getRegisterPayload, getUpdatePayload } =
    useTransformData(instituteId);

  const { isLoading, registerMutation, updateMutation } =
    useMutations(instituteId);

  const defaultValues = useMemo(
    () => getFormValues(user),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  const methods = useHookForm<FormValues>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const {
    formState: { isSubmitting: isFormSubmitting, isValid },
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const isSubmitting = isFormSubmitting || isLoading;

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      const options = {
        onSuccess(data, variables, context) {
          onSuccess();
        },
      };
      switch (mode) {
        case Mode.Create:
          const registerPayload = getRegisterPayload({
            formValues,
          });
          registerMutation(registerPayload, options);
          break;
        case Mode.Update:
          if (user) {
            const updatePayload = getUpdatePayload({ formValues });
            updateMutation({ userId: user.id, data: updatePayload }, options);
          } else {
            console.error('Unable to update user while `user` is undefined');
          }
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  return {
    canSubmit: isValid && !isSubmitting,
    isSubmitting,
    methods,
    onSubmit: handleSubmit(onSubmit),
  };
};
