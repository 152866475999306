export namespace Discount {
  export enum Type {
    PERCENT = 'percent',
    AMOUNT = 'amount',
  }

  export interface Product {
    id: number;
    language: {
      title: string;
      content: string;
    };
  }

  export interface Amount {
    discountType: Type;
    discountValue: number;
  }

  export interface Normal {
    id: number;
    name: string;
    institute: number;
    discountValue: number;
    discountType: Type;
    price: number;
    type: number;
    startDate: string;
    endDate: string;
    promotionProducts: Product[];
    disclaimer: string;
    autreMontantForFixedPriceMode: string;
    autreMontantForDiscountPriceMode: string;
    description: string;
  }

  export interface CreatePayload {
    promotion: {
      // Discount name
      name: string;
      // Discount description
      description: string;
      // Discount disclaimer
      disclaimer: string;
      // Discount type.
      discountType: Type;
      // Discount value.
      discountValue: number;
      // Discount end date (ISO 8601 format)
      endDate: string;
      // The institute endpoint identifier
      institute: string;
      // Discount price when a fixed price is set.
      price: number;
      // Discount start date (ISO 8601 format)
      startDate: string;
      // Availability type (passing, subscription or both)
      type: number;
      // cure;
      // SubscriptionType;
    };
    // Array of products identifiers included in the discount.
    products: { productId: number }[];
  }

  export type UpdatePayload = CreatePayload;
}
