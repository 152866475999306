import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  SelectInput as UncontrolledSelectInput,
  DefaultSelectInputProps,
} from '../Uncontrolled/SelectInput';
import { SelectInputOption } from './types';

export type { SelectInputOption };

interface Props extends DefaultSelectInputProps {
  name: string;
  value?: string[];
  onChange1?: (...event: any[]) => void;
  methods?: any;
}

export const SelectInput = (props: Props) => {
  const { name, onChange1, value, methods, ...rest } = props;

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur, onChange, value } }) => (
        <UncontrolledSelectInput
          fullWidth
          error={errors[name]}
          onBlur={onBlur}
          onChange={(event: any[]) => {
            onChange(event);
            onChange1?.(event);
            methods &&
              (() => {
                var modifiedDate = new Date(watch()?.subscription?.startDate);
                ['8', '2'].includes(event[0]) &&
                  methods.setValue('subscription.endDate', undefined);
                event[0] === '7' &&
                  methods.setValue(
                    'subscription.endDate',
                    new Date(
                      modifiedDate.setMonth(modifiedDate?.getMonth() + 3),
                    ),
                  );
                ['9', '5'].includes(event[0]) &&
                  methods.setValue(
                    'subscription.endDate',
                    new Date(
                      modifiedDate.setMonth(modifiedDate?.getMonth() + 6),
                    ),
                  );
                event[0] === '6' &&
                  methods.setValue(
                    'subscription.endDate',
                    new Date(
                      modifiedDate.setMonth(modifiedDate?.getMonth() + 9),
                    ),
                  );
              })();
          }}
          value={value || []}
          {...rest}
        />
      )}
    />
  );
};