import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components/macro';

import { Button } from 'app/components/Button';
import { FormSection } from 'app/components/Forms/FormSection';

import { BankAccount } from './sections/BankAccount';
import { General } from './sections/General';
import { Legal } from './sections/Legal';
import { messages } from '../messages';

interface Props {
  canSubmit: boolean;
  isEditable: boolean;
  isSubmitting: boolean;
}

export const Form = ({ canSubmit, isEditable, isSubmitting }: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <FormSection title={t(messages.sections.general())}>
        <General disabled={isSubmitting || !isEditable } />
      </FormSection>

      <FormSection title={t(messages.sections.legal())}>
        <Legal disabled={isSubmitting || !isEditable} />
      </FormSection>

      <FormSection title={t(messages.sections.bankAccount())}>
        <BankAccount disabled={isSubmitting || !isEditable} />
      </FormSection>

      <Button
        className="submit"
        disabled={!canSubmit || !isEditable}
        isSpinning={isSubmitting}
        type="submit"
      >
        {t(messages.submit())}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .submit {
    float: right;
  }
`;
