import { Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Button } from '../Button';

import { Disableable } from '../Disableable';

export interface ButtonProps {
  disabled?: boolean;
  icon: ReactNode;
  label: string;
  isSpinning?: boolean;
  onClick: () => void;
}
interface Props {
  button?: ButtonProps;
  label: string;
}

export const EmptyState = ({ button, label }: Props) => {
  const { label: buttonLabel, icon: startIcon, ...buttonProps } = { ...button };

  return (
    <Container>
      <Content>
        <Text>{label}</Text>
        <Disableable enabled={!!button}>
          <Button startIcon={startIcon} {...buttonProps}>
            {buttonLabel}
          </Button>
        </Disableable>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  flex-grow: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > :first-child {
    margin-bottom: 2rem;
  }
`;

const Text = styled(Typography)`
  text-align: center;
  color: ${p => p.theme.text.body};
`;
