import moment from 'moment';

import { Objectives } from 'types/API/objectives';
import { Month } from 'types/month';

import { MonthTableRow } from './types';

interface Options {
  month: Month;
  weeklyObjectives: any;
}
export const monthlyFromWeeklyObjectives = ({
  month,
  weeklyObjectives,
}: Options) => {
  const ca_objectives = weeklyObjectives['total_sales'];
  const objectives = ca_objectives;

  const dailyObjectives: Objectives.Daily[] = [];

  month.days().forEach(date => {
    const total_sales = parseInt(objectives[date.getDay()]);
    const objective = {
      date: new Date(date),
      total_sales,
    };
    dailyObjectives.push(objective);
  });
  return dailyObjectives;
};

export const rowsFromDailyObjectives = ({
  objectives,
  month,
}: {
  objectives: Objectives.Daily[];
  month: Month;
}): MonthTableRow[] => {
  let cumulated_total_sales = 0;
  return objectives.map((daily, index) => {
    const date = moment(
      `${index + 1}-${month.index + 1}-${month.year}`,
      'D-M-YYYY',
    ).toDate();
    cumulated_total_sales += daily.total_sales;
    return { cumulated_total_sales, date, ...daily };
  });
};
