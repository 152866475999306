import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Sales } from 'types/API/sales';
import { Optional } from 'types/misc';
import { enabledIfSet } from 'utils/components/enabledIfSet';
import { usePriceFormatter } from 'utils/formatters/hooks/price';
import { transformUnwraped } from 'utils/optional';

import { messages } from '../../messages';
import { ReceiptTab } from '../../types';
import { Item, Mode } from './Item';
import { SalesDetail } from './SalesDetail';
import { Disableable } from 'app/components/Disableable';

interface Props {
  currency: string;
  currentTab: ReceiptTab;
  onChange: (tab: ReceiptTab) => void;
  sales: Optional<Sales.Normal>;
}

export const Selector = (props: Props) => {
  const { currency, currentTab, onChange, sales } = props;

  const { t } = useTranslation();

  const { formatPrice } = usePriceFormatter({
    currency,
  });

  const theme = useTheme();
  const expandedMode = useMediaQuery(theme.breakpoints.up('lg'), {
    noSsr: true,
  });
  const compactMode = !useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const mode = expandedMode
    ? Mode.EXPANDED
    : compactMode
    ? Mode.COMPACT
    : Mode.NORMAL;

  const propsForTab = useCallback(
    (tab: ReceiptTab) => {
      const selected = tab === currentTab;
      switch (tab) {
        case ReceiptTab.PENDING:
          return {
            bubbleValue: sales?.pending.count,
            title: t(messages.pending.tabLabel()),
            selected,
            subtitle: transformUnwraped(sales, sales =>
              formatPrice(sales.pending.sum),
            ),
          };

        case ReceiptTab.PAID:
          return {
            bubbleValue: transformUnwraped(
              sales,
              sales => sales.cb.count + sales.chq.count + sales.esp.count,
            ),
            footer: enabledIfSet(sales, sales => (
              <SalesDetail
                currency={currency}
                sales={sales}
                selected={selected}
              />
            )),
            title: t(messages.paid.tabLabel()),
            selected,
            subtitle: transformUnwraped(sales, sales =>
              formatPrice(sales.cb.sum + sales.chq.sum + sales.esp.sum),
            ),
          };
        case ReceiptTab.PAID_PRODUCTS:
          return {
            bubbleValue: sales?.vente.count,
            title: t(messages.paidProducts.tabLabel()),
            selected,
            subtitle: transformUnwraped(sales, sales =>
              formatPrice(sales.vente.sum),
            ),
          };
      }
    },
    [currency, currentTab, formatPrice, sales, t],
  );

  const tabs = [ReceiptTab.PENDING, ReceiptTab.PAID, ReceiptTab.PAID_PRODUCTS];

  return (
    <>
    <Disableable enabled={mode >= Mode.EXPANDED}>
      <Container>
        {tabs?.map(tab => {
          const props = propsForTab(tab);
          return (
            <Item
            mode={mode}
            key={tab}
            onClick={() => onChange(tab)}
              {...props}
              />
            );
          })}
      </Container>
    </Disableable>
    <Disableable enabled={mode <= Mode.NORMAL}>
      <ContainerSmall>
        {tabs?.map(tab => {
          const props = propsForTab(tab);
          return (
            <Item
            mode={mode}
            key={tab}
            onClick={() => onChange(tab)}
              {...props}
              />
            );
          })}
      </ContainerSmall>
    </Disableable>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  > :not(:first-child) {
    margin-left: 2rem;
  }
`;

const ContainerSmall = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: -webkit-fill-available;

  > :not(:first-child) {
    margin-left: 0.5rem;
  }
`;
