import { useRecoilValue } from 'recoil';

import { institutesState, meState } from 'app/atoms';

export const useSelectors = () => {
  const institutes = useRecoilValue(institutesState);
  const me = useRecoilValue(meState);

  return {
    currentInstituteId: institutes?.currentInstituteId ?? -1,
    me,
    institutes: institutes.userInstitutes,
  };
};
