import { Severity } from 'app/components/Snackbar/types';

export interface SnackbarState {
  readonly label: string;
  readonly open: boolean;
  readonly severity: Severity;
}

export const defaultState: SnackbarState = {
  severity: 'info',
  label: '',
  open: false,
};
