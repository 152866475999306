import { useCallback, useEffect, useMemo } from 'react';
import { useForm as useHookForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Optional } from 'types/misc';
import { Mode } from 'types/forms';
import { Discount } from 'types/API/discounts';
import { useMutationOptions } from 'app/hooks/modal/useMutationsOptions';

import { FormValues, ProductGroup } from './types';
import { useTransformData } from '../useTransformData';
import { useMutations } from '../useMutations';
import { useFormValidation } from '../useFormValidation';

interface Options {
  discount: Optional<Discount.Normal>;
  instituteId: number;
  mode: Mode;
  onSuccess: () => void;
  productGroups: ProductGroup[];
}

export const useForm = (options: Options) => {
  const { discount, instituteId, mode, onSuccess, productGroups } = options;

  const { schema } = useFormValidation();

  const { getCreatePayload, getFormValues, getUpdatePayload } =
    useTransformData({
      productGroups,
    });

  const { isLoading, createMutation, updateMutation } =
    useMutations(instituteId);

  const defaultValues = useMemo(
    () => getFormValues(discount),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [discount],
  );

  const methods = useHookForm<FormValues>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const {
    formState: { isSubmitting: isFormSubmitting, isValid },
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const mutationOptions = useMutationOptions({
    successCallback: _ => {
      onSuccess();
    },
  });

  const isSubmitting = isFormSubmitting || isLoading;

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      switch (mode) {
        case Mode.Create:
          const createPayload = getCreatePayload({
            instituteId,
            formValues,
          });
          createMutation(createPayload, mutationOptions);
          break;
        case Mode.Update:
          if (discount) {
            const updatePayload = getUpdatePayload({ instituteId, formValues });
            updateMutation(
              { discountId: discount.id, data: updatePayload },
              mutationOptions,
            );
          } else {
            console.error(
              'Unable to update discount while `discount` is undefined',
            );
          }
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [discount],
  );

  return {
    canSubmit: isValid && !isSubmitting,
    isSubmitting,
    methods,
    onSubmit: handleSubmit(onSubmit),
  };
};
