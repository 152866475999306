import { paths } from 'app/routes/paths';
import { Optional } from 'types/misc';
import { ReceiptTab, ReceiptKind } from './types';

export const kindFromURLParam = (param: Optional<string>): ReceiptTab => {
  const casted = param as ReceiptKind;
  switch (casted) {
    case ReceiptKind.PENDING:
      return ReceiptTab.PENDING;
    case ReceiptKind.PAID:
      return ReceiptTab.PAID;
    case ReceiptKind.PAID_PRODUCTS:
      return ReceiptTab.PAID_PRODUCTS;
  }
};

export const URLlocationFromKind = (kind: ReceiptTab) => {
  switch (kind) {
    case ReceiptTab.PENDING:
      return paths.receipts.list.cases.pending;
    case ReceiptTab.PAID:
      return paths.receipts.list.cases.paid;
    case ReceiptTab.PAID_PRODUCTS:
      return paths.receipts.list.cases.paidProducts;
  }
};
