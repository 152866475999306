import {
  AreaChart as DefaultAreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeTableau10 } from 'd3-scale-chromatic';

import { AreaChartDescription } from 'app/pages/Stats/StatsPage/types/charts';
import { Optional } from 'types/misc';
import { PropertyMapping } from 'app/pages/Stats/StatsPage/types';

import { usePropertyMapping } from '../../../hooks';

interface Props {
  description: AreaChartDescription;
  propertyMapping: PropertyMapping;
  values: any[];
}

export const AreaChart = (props: Props) => {
  const { description, propertyMapping, values } = props;

  const color = (color: Optional<string>, index: number) => {
    const colors = scaleOrdinal(schemeTableau10).range();
    return color ?? (colors[index % colors.length] as string);
  };

  const { propertyLabel } = usePropertyMapping({ propertyMapping });

  return (
    <ResponsiveContainer>
      <DefaultAreaChart
        data={values}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={propertyLabel(description.xAxisProperty)} />
        <YAxis />
        <Tooltip />
        <Legend />
        {description.areas.map((area, index) => (
          <Area
            key={area.property}
            type="monotone"
            dataKey={propertyLabel(area.property)}
            stackId="1"
            stroke={color(area.color, index)}
            fill={color(area.color, index)}
          />
        ))}
      </DefaultAreaChart>
    </ResponsiveContainer>
  );
};
