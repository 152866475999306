import { FormValues } from './useForm/types';
import { useRecoilState } from 'recoil';
import { weeklyObjectivesState } from 'app/atoms/objectives';
import { useCallback } from 'react';

export const useTransformData = () => {
  const [weeklyObjectives, setWeeklyObjectives] = useRecoilState(
    weeklyObjectivesState,
  );

  const getFormValues = useCallback((): FormValues => {
    return weeklyObjectives;
  }, [weeklyObjectives]);

  const saveFormValues = useCallback(
    (formValues: any) => {
      // const typed = formValues.map()
      setWeeklyObjectives(formValues);
    },
    [setWeeklyObjectives],
  );
  return { getFormValues, saveFormValues };
};
