import { atom } from 'recoil';

import { Optional } from 'types/misc';

import { storageEffect } from '../effects/storage';
import { MeState } from './types';

export const meState = atom<Optional<MeState>>({
  default: undefined,
  effects_UNSTABLE: [storageEffect('me')],
  key: 'me',
});
