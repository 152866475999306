export namespace Debit {
  export interface Normal {
    cardNumber: string;
    institutid: number;
    idabo: string;
    IIABOBOD: string;
    subscriptionid: number;
    amount: number;
    firstname: string;
    lastname: string;
    payment_day: number;
    iban: string;
    bic: string;
    startdate: string;
    isIbanValid: boolean;
  }

  export type PaymentDay = '5' | '20' | '99';
  export type PaymentMonth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

  export const PaymentDays = {
    FIFTH_OF_MONTH: '5' as PaymentDay,
    TWENTIETH_OF_MONTH: '20' as PaymentDay,
    HISTORY: '99' as PaymentDay
  };

   export interface NbPrelev {
     nbAdult: number;
     nbJeune: number;
     nbSkin: number;
  }
}

export namespace DebitHistory {
  export interface Normal {
    id: number;
    created_at: string;
    ca: number;
    paymentDay: number;
    nb: number;
    adult: number;
    young: number;
    skin: number;
  }
}
