import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { messages as commonMessages } from 'app/i18n/messages';
import { getCurrencySymbol } from 'utils/currency';

// Custom formatter for now.
// See https://stackoverflow.com/questions/63586596/how-to-format-currency-using-react-i18next-library for a built-in solution

export const usePriceFormatter = ({ currency }: { currency: string }) => {
  const { t } = useTranslation();

  const formatter = useCallback(
    ({ price, format }: { price: number; format: [string, ...any[]] }) => {
      const currencySymbol = getCurrencySymbol(currency);
      return t(format, {
        price: price.toFixed(2),
        currency: currencySymbol,
      });
    },
    [t, currency],
  );

  const formatPrice = useCallback(
    (price: number) =>
      formatter({ price, format: commonMessages.priceFormat() }),
    [formatter],
  );

  const formatDiscountPrice = useCallback(
    (price: number) =>
      formatter({ price, format: commonMessages.discountPriceFormat() }),
    [formatter],
  );

  return { formatDiscountPrice, formatPrice };
};
