import { useTranslation } from 'react-i18next';
import React, { MouseEvent } from 'react';
import styled from 'styled-components/macro';
import { Box } from '@material-ui/core';

import { ReactComponent as TrashIcon } from 'app/assets/trash.svg';
import { Cure } from 'types/API/cures';
import { IconButton } from 'app/components/IconButton';
import { useDiscountValueFormatter } from 'utils/formatters/hooks/discount';
import { isDiscountEditable } from 'utils/entities/discount';

import { messages } from '../../messages';
import { ValueItemLabel } from '../Common/ValueItemLabel';

interface Props {
  cure: Cure.Normal;
  currency: string;
  isDeleteEnabled: boolean;
  onClick?: () => void;
  onDelete?: () => void;
}

// Edit cure & promo si pas commencé et non terminé
export const CureItem = (props: Props) => {
  const { t } = useTranslation();

  const { cure, currency, isDeleteEnabled, onClick, onDelete } = props;

  const isEditable = isDiscountEditable(cure);

  const handleDeleteAction = (event?: MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    event?.stopPropagation();
    onDelete?.();
  };

  const { formatDiscountValue } = useDiscountValueFormatter({
    currency,
  });

  return (
    <Wrapper onClick={isEditable ? onClick : undefined} isEditable={isEditable}>
      <ContentWrapper>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="baseline">
            <TitleLabel>{cure.name}</TitleLabel>
            <DateLabel
              className="date-label"
              dangerouslySetInnerHTML={{
                __html: t(messages.dateFormat(), {
                  from_date: new Date(cure.startDate),
                  to_date: new Date(cure.endDate),
                }),
              }}
            />
          </Box>
          <ValueItemLabel
            label={t(messages.cures.products())}
            value={cure.prestation.name}
          />
          <ValueItemLabel
            label={t(messages.cures.sessions())}
            value={cure.sessions}
          />
        </Box>
        <DiscountLabel>{formatDiscountValue(cure)}</DiscountLabel>
      </ContentWrapper>
      {isDeleteEnabled && (
        <ActionWrapper>
          <IconButton onClick={handleDeleteAction}>
            <TrashIcon />
          </IconButton>
        </ActionWrapper>
      )}
    </Wrapper>
  );
};

interface WrapperProps {
  isEditable: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  background: ${p => p.theme.itemBackground};
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  border-radius: 15px;
  cursor: ${p => (p.isEditable ? 'pointer' : 'inherited')};

  div: first-child {
    margin-right: 1rem;
  }
  overflow: hidden;
  margin: 0;

  .date-label {
    margin-left: 0.5rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1rem 1rem 1rem;
`;

const TitleLabel = styled.h3`
  margin-block: 0;
  font-weight: 800;
  letter-spacing: 1.17pt;
`;

const DateLabel = styled.span`
  font-family: 'Gabriel Sans Cond';
  font-weight: 500;
  font-size: 1rem;
  color: ${p => p.theme.primary};
  > span {
    color: ${p => p.theme.text.title};
  }
`;

const DiscountLabel = styled.span`
  font-family: 'Gabriel Sans Cond';
  font-weight: 700;
  font-size: 1.2rem;
  color: ${p => p.theme.primary};
  text-transform: uppercase;
  flex: 0 0 auto;
`;

const ActionWrapper = styled.div`
  background-color: ${p => p.theme.primary};
  display: flex;
  width: 5rem;
  justify-content: center;
`;
