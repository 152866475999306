import { Grid } from '@material-ui/core';
import { Disableable } from 'app/components/Disableable';
import { DynamicLabel } from 'app/components/Labels/DynamicLabel';
import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

import { Theme } from 'styles/theme/themes';

export interface SelectableProps {
  selected: boolean;
}

export enum Mode {
  COMPACT,
  NORMAL,
  EXPANDED,
}

export interface Props extends SelectableProps {
  bubbleValue?: number;
  footer?: ReactNode;
  mode: Mode;
  subtitle?: string;
  title: string;
  onClick: () => void;
}

export const Item = (props: Props) => {
  const { bubbleValue, mode, footer, onClick, selected, subtitle, title } =
    props;
  return (
    <>
      <Disableable enabled={mode <= Mode.NORMAL}>
        <SmallContainer selected={selected} onClick={onClick}>
          <Grid container direction="column" alignItems='center'>
            <BubbleSmall selected={selected}>{bubbleValue}</BubbleSmall>
            <ContentWrapper>
              <TitleLabel fontSize={1.5} selected={selected}>
                {title}
              </TitleLabel>
              <SubtitleLabel fontSize={1} selected={selected}>
                {subtitle}
              </SubtitleLabel>
            </ContentWrapper>
          </Grid>
        </SmallContainer>
      </Disableable>

      <Disableable enabled={mode >= Mode.EXPANDED}>
        <BigContainer selected={selected} onClick={onClick}>
            <Bubble selected={selected}>{bubbleValue}</Bubble>
          <ContentWrapper>
            <TitleLabel fontSize={2} selected={selected}>
              {title}
            </TitleLabel>
            <SubtitleLabel fontSize={1.5} selected={selected}>
              {subtitle}
            </SubtitleLabel>
            {footer}
          </ContentWrapper>
        </BigContainer>
      </Disableable>
    </>
  );
};

const backgroundColor = (selected: boolean, theme: Theme) => {
  return selected ? theme.primary : theme.input.background;
};

const foregroundColor = (selected: boolean, theme: Theme) => {
  return backgroundColor(!selected, theme);
};

export const variantForegroundColor = (selected: boolean, theme: Theme) => {
  return selected ? theme.input.background : theme.text.body;
};


const SmallContainer = styled.div<SelectableProps>`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;


  background: ${p => backgroundColor(p.selected, p.theme)};
  display: flex;
  border-radius: 15px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.05);
  padding: 0.2rem 0.2rem;

  cursor: ${p => (!p.selected ? 'pointer' : 'inherit')};
`;

const BigContainer = styled.div<SelectableProps>`
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: ${p => backgroundColor(p.selected, p.theme)};
  display: flex;
  border-radius: 15px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.05);
  padding: 1rem 1rem;

  cursor: ${p => (!p.selected ? 'pointer' : 'inherit')};

  div {
    margin-right: 1rem;
  }
`;

const Bubble = styled.div<SelectableProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-family: 'Gabriel Sans Cond';
  font-weight: 600;
  font-size: 1.5rem;

  background: ${p => foregroundColor(p.selected, p.theme)};
  color: ${p => backgroundColor(p.selected, p.theme)};
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
`;

const BubbleSmall = styled.div<SelectableProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-family: 'Gabriel Sans Cond';
  font-weight: 600;
  font-size: 1.5rem;

  background: ${p => foregroundColor(p.selected, p.theme)};
  color: ${p => backgroundColor(p.selected, p.theme)};
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

const TitleLabel = styled(DynamicLabel)<SelectableProps>`
  color: ${p => foregroundColor(p.selected, p.theme)};
  font-weight: 600;
`;

const SubtitleLabel = styled(DynamicLabel)<SelectableProps>`
  color: ${p => variantForegroundColor(p.selected, p.theme)};
  font-family: 'Gabriel Sans Cond';
  font-weight: 500;
`;
