import { useMemo } from 'react';
import styled from 'styled-components/macro';

import { FormWrapper } from 'app/components/Forms/FormWrapper';
import { LoadableContent } from 'app/components/LoadableContent';

import { ContentConfiguration, StatKind } from '../../types';

import { Form } from './components/Form';
import { useData, useForm } from './hooks';
import { Table } from './components/Table';
import { Chart } from './components/Charts/Chart';

interface Props {
  configuration: ContentConfiguration;
  instituteId: number;
  kind: StatKind;
}

export const StatsContent = (props: Props) => {
  const { configuration, instituteId, kind } = props;

  const { canSubmit, isSubmitting, methods, onSubmit } = useForm({});

  const { queryResult } = useData({ configuration, instituteId, kind });

  const values = useMemo(() => queryResult.data ?? [], [queryResult.data]);

  return (
    <Wrapper>
      <InputsWrapper>
        <FormWrapper methods={methods} onSubmit={onSubmit}>
          <Form
            canSubmit={canSubmit}
            configurations={configuration.inputs}
            isSubmitting={isSubmitting}
          />
        </FormWrapper>
      </InputsWrapper>
      <LoadableContent
        isLoading={queryResult.isLoading}
        error={queryResult.error}
      >
        <ContentWrapper>
          <TableWrapper>
            <Table
              propertyMapping={configuration.propertyMapping}
              values={values}
              valueFormatter={configuration.valueFormatter}
            />
          </TableWrapper>
          <ChartWrapper>
            {
              // Index never change between two render loop, should be fine to use it as the component key.
              configuration.charts.map((chart, index) => (
                <Chart
                  configuration={chart}
                  key={index}
                  propertyMapping={configuration.propertyMapping}
                  values={values}
                  valueFormatter={configuration.valueFormatter}
                />
              ))
            }
          </ChartWrapper>
        </ContentWrapper>
      </LoadableContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const InputsWrapper = styled.div`
  margin-bottom: 2rem;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

const TableWrapper = styled.div`
  height: calc(50% - 2rem);
  margin-bottom: 2rem;
`;

const ChartWrapper = styled.div`
  height: calc(50% - 2rem);
  display: flex;
  // align-items: center;
  justify-content: center;
`;
