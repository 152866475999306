import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { FormLabel } from 'app/components/Forms/FormLabel';
import {
  SelectInput,
  SelectInputOption,
} from 'app/components/Inputs/SelectInput';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';

import { messages } from '../../../../messages';

interface Props {
  disabled: boolean;
  institutesOptions: SelectInputOption[];
  isInstituteEditionEnabled: boolean;
  isRoleEditionEnabled: boolean;
  rolesOptions: SelectInputOption[];
}

export const Access = (props: Props) => {
  const {
    disabled,
    institutesOptions,
    isInstituteEditionEnabled,
    isRoleEditionEnabled,
    rolesOptions,
  } = props;

  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      {isRoleEditionEnabled && (
        <FormInputGrid item sm={12}>
          <FormLabel>{t(messages.role.label())}</FormLabel>
          <SelectInput
            disabled={disabled}
            name="roles"
            placeholder={t(messages.role.placeholder())}
            required
            options={rolesOptions}
          />
        </FormInputGrid>
      )}
      {isInstituteEditionEnabled && (
        <FormInputGrid item sm={12}>
          <FormLabel>{t(messages.institutes.label())}</FormLabel>
          <SelectInput
            allowMultipleSelections={true}
            disabled={disabled}
            name="institute"
            placeholder={t(messages.institutes.placeholder())}
            required
            options={institutesOptions}
          />
        </FormInputGrid>
      )}
    </Grid>
  );
};
