import { useCallback, useMemo, useState } from 'react';

import { useFuse } from 'app/hooks/fuse/useFuse';

import { useQueries } from './useQueries';
import { useOptions } from './useOptions';
import { SubscriptionTab } from '../types';
import { FuseSearchEvent } from 'app/hooks/fuse/types';
import { get } from 'lodash';
import { debounce } from 'throttle-debounce';

export const useData = ({
  instituteId,
  tab,
}: {
  instituteId: number;
  tab: SubscriptionTab;
}) => {
  const [query, updateQuery] = useState('');

  // eslint-disable-next-line
  const setQuery = useCallback(debounce(100, updateQuery), []);

  const {
    //externalSubscribersQueryState,
    pendingSubscribersQueryState,
    subscribersQueryState: registeredSubscribersQueryState,
  } = useQueries({
    instituteId,
    searchQuery: query,
    // searchNotMigratedCustomers: false, /* To disable the search in the old database */
    tab,
  });

  const subscribersQueryState = useMemo(
    () =>
      tab === SubscriptionTab.PENDING
        ? pendingSubscribersQueryState
        : registeredSubscribersQueryState,
    [pendingSubscribersQueryState, registeredSubscribersQueryState, tab],
  );

  const { fuseOptions } = useOptions();

  const {
    hits: filteredSubscribers,
    isActive: isSearchActive,
    onSearch: onFuseSearch,
  } = useFuse({
    list: subscribersQueryState.data,
    options: fuseOptions,
  });

  const filteredSubscribersQueryState = useMemo(
    () => ({ ...subscribersQueryState, data: filteredSubscribers }),
    [filteredSubscribers, subscribersQueryState],
  );

  const onSearch = useCallback(
    (event: FuseSearchEvent) => {
      onFuseSearch(event);
      const value = get(event.target, 'value', '');
      setQuery(value.trim());
    },
    [onFuseSearch, setQuery],
  );
  return {
    //externalSubscribersQueryState,
    subscribersQueryState: filteredSubscribersQueryState,
    isSearchActive,
    onSearch,
  };
};
