import { range } from 'lodash';
import { useMemo } from 'react';

import { SelectInputOption } from 'app/components/Inputs/SelectInput';
import { Product } from 'types/API/products';
import { Discount } from 'types/API/discounts';
import { useDiscountValueFormatter } from 'utils/formatters/hooks/discount';

export const useFormData = ({
  currency,
  products,
}: {
  currency: string;
  products: Product.Normal[];
}) => {
  const { formatDiscountValue } = useDiscountValueFormatter({ currency });

  const sessionsCountOptions: SelectInputOption[] = useMemo(
    () =>
      range(1, 11).map(count => ({
        id: count.toString(),
        label: count.toString(),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const prestations = useMemo(() => {
    return products?.filter(
      candidate => candidate.productType === 'prestation',
    ).sort((a, b) => a.language.title > b.language.title ? 1 : -1);
  }, [products]);

  const prestationsOptions: SelectInputOption[] = useMemo(
    () =>
      prestations.map(prestation => ({
        id: prestation.id.toString(),
        label: prestation.language.title,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prestations],
  );

  const discountAmountOptionsMap: Map<Discount.Type, SelectInputOption[]> =
    useMemo(() => {
      return new Map(
        Object.values(Discount.Type).map(discountType => [
          discountType,
          range(5, 55, 5).map(discountValue => ({
            id: discountValue.toString(),
            label: formatDiscountValue({ discountType, discountValue }),
          })),
        ]),
      );
    }, [formatDiscountValue]);

  return {
    discountAmountOptionsMap,
    prestationsOptions,
    sessionsCountOptions,
  };
};
