import { Route } from 'types/route';
import { DiscountPage } from 'app/pages/SpecialOffers/DiscountPage';

import { paths } from '../../../paths';

const createDiscount: Route = {
  authenticated: true,
  content: DiscountPage,
  routerPath: paths.specialOffers.discounts.create,
};

const updateDiscount: Route = {
  authenticated: true,
  content: DiscountPage,
  routerPath: paths.specialOffers.discounts.update,
};

export const routes = [createDiscount, updateDiscount];
