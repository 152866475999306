import { useQuery } from 'app/hooks';
import { Optional } from 'types/misc';
import { fetchUserRequest } from 'app/services/users';
import { genericQueryKey } from 'utils/cache/keys';

export const useQueries = (userId: Optional<number>) => {
  const userQueryState = useQuery(
    genericQueryKey({
      identifier: 'user',
      parameters: { userId },
    }),
    () => (userId ? fetchUserRequest({ userId }) : undefined),
    { enabled: !!userId },
  );

  return { userQueryState };
};
