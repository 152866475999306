import { includes } from 'lodash';
import { Subscription } from 'types/API/subscriptions';

export const isDeptingSubscription = ({
  subscription,
}: {
  subscription: Subscription.Normal;
}) => {
  const deptingSubscriptionTypes = ['PA', 'PJ', 'PREMIUM'];
  return includes(deptingSubscriptionTypes, subscription.subscriptionType.type);
};
