import { useEffect, useState } from 'react';
import { LoadableContent } from 'app/components/LoadableContent';
import { EmptyState } from 'app/components/EmptyState';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import style from 'styled-components/macro';

import { request } from 'utils/request';
import { SubscriptionsStats, SubscriptionsActiveStats } from 'types/API/stats';

import { InlineDatePicker } from './components/StatsContent/components/inlineDatePicker';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const optionsActive = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Abonnements actifs',
    },
  },
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Visualisation',
    },
  },
};

export const Subscriptions = props => {
  const { institute } = props;

  var date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  var monthName = new Array(
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  );
  const fluxNames = new Array(
    'Nouveaux Contrats Prélèv.',
    'Nouveaux Contrats Antip.',
    'Body Skin',
    'Skin Body',
    'Contrats Prélèv. Antip.',
    'Contrats Antip. Prélèv',
    'Prelev résiliés',
  );
  let firstDayOfWeek = new Date(y, m, 1);
  let firstDayOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1, 8);
  let lastDay = new Date();
  let lastDayOfWeek = new Date(y, m + 1, 0);

  const [startDate, setStartDate] = useState(firstDayOfTheMonth);
  const [endDate, setEndDate] = useState(lastDay);

  const [totalCA, setTotalCA] = useState(0);
  const [totalNb, setTotalNb] = useState(0);
  const [totalActiveNb, setTotalActiveNb] = useState(0);

  const [currentMonth, setCurrentMonth] = useState('Sélectionnez un mois');

  const [labels, setLabels] = useState<Array<string>>();

  const [barData, setBarData] = useState<{ labels: string[]; datasets: any[] }>(
    { labels: [], datasets: [] },
  );
  const [barDataActive, setBarDataActive] = useState<{
    labels: string[];
    datasets: any[];
  }>({ labels: [], datasets: [] });

  const getLastTwelveMonths = () => {
    var d = new Date();
    var resultArray: Array<string> = [];
    var i;
    d.setDate(1);
    for (i = 0; i <= 11; i++) {
      const month = monthName[d.getMonth()] + ' ' + d.getFullYear();
      resultArray.push(month);
      d.setMonth(d.getMonth() - 1);
    }
    return resultArray;
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState<SubscriptionsStats>();
  const [activeData, setActiveData] = useState<SubscriptionsActiveStats>();

  const loadData = async (start, end) => {
    setStartDate(start);
    setEndDate(end);
    if (!loading) {
      fetchSubscriptionsStats({
        instituteId: institute.id,
        start: start,
        end: end,
      })
        .then(data => {
          setLoading(false);
          setData(data);
          var totalCA = 0;
          var totalNb = 0;
          var labels: Array<string> = [];
          var values: Array<number> = [];
          Object.keys(data).map((key, index) => {
            totalCA += data[key].total;
            totalNb += data[key].nb;
            labels.push(key);
            values.push(data[key].total);
          });

          const barData = {
            labels: fluxNames,
            datasets: [
              {
                label: 'Total Transactions par type de changement de contrat',
                data: values,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          };

          setLabels(labels);
          setTotalCA(totalCA);
          setTotalNb(totalNb);
          setBarData(barData);
        })
        .catch(error => {
          setLoading(false);
          setError(error);
        });

      fetchSubscriptionsActiveStats({ instituteId: institute.id })
        .then(activeData => {
          var labels: Array<string> = [
            /*"Prélèvement Adulte",*/ 'Prélèvement Jeune',
            'Prélèvement SKIN' /*, "Antip Adulte"*/,
            'Antip Jeune',
            'Antip SKIN',
            'CE',
            'VIP',
          ];
          var values: Array<number> = [];
          setLoading(false);
          setActiveData(activeData);
          var totalNb = 0;
          /*
        Object.keys(activeData).map((key, index) => {
          totalNb += activeData[key];
          values[index] = activeData[key]

        })
        */
          totalNb += activeData.prelevementJeune;
          values[0] = activeData.prelevementJeune;
          totalNb += activeData.prelevementSkin;
          values[1] = activeData.prelevementSkin;
          totalNb += activeData.antipJeune;
          values[2] = activeData.antipJeune;
          totalNb += activeData.antipSkin;
          values[3] = activeData.antipSkin;
          totalNb += activeData.ce;
          values[4] = activeData.ce;
          totalNb += activeData.vip;
          values[5] = activeData.vip;

          const barDataActive = {
            labels: labels,
            datasets: [
              {
                label: "Total d'abonnements actifs",
                data: values,
                backgroundColor: 'rgba(56, 15, 188, 0.5)',
              },
            ],
          };

          setBarDataActive(barDataActive);
          setTotalActiveNb(totalNb);
          console.log(
            '======>' +
              activeData.prelevementAdulte +
              ' (id: ' +
              institute.id +
              ')',
          );
        })
        .catch(error => {
          setLoading(false);
          setError(error);
        });
    }
  };

  useEffect(() => {
    loadData(startDate, endDate);
  }, []);

  return (
    <div>
      <InlineDatePicker
        start={startDate}
        end={endDate}
        max={Date()}
        action={loadData}
      ></InlineDatePicker>
      <br />

      <LoadableContent
        error={error}
        isLoading={loading}
        isEmpty={data === undefined}
        emptyNodeRenderer={() => <EmptyState label="Pas de résultats" />}
      >
        {data && activeData && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Transferts d'abonnements</StyledTableCell>
                  <StyledTableCell align="right">Nb</StyledTableCell>
                  {/* <StyledTableCell align="right">Total Transactions</StyledTableCell> */}
                  <StyledTableCell>
                    Abonnements actifs : {monthName[date.getMonth()]}
                  </StyledTableCell>
                  <StyledTableCell align="right">Nb</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.newPrelev && (
                  <StyledTableRow key="newPrelev">
                    <StyledTableCell component="th" scope="row">
                      Nouveaux contrats prélèvements
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {data.newPrelev.nb}
                    </StyledTableCell>
                    {/* <StyledTableCell align="right">{data.newPrelev.total.toFixed(2)}€</StyledTableCell> */}
                    {/*
                    <StyledTableCell component="th" scope="row">
                      Prélèvement Adulte
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {activeData.prelevementAdulte}
                    </StyledTableCell>
                */}
                    <StyledTableCell component="th" scope="row">
                      Prélèvement Jeune
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {activeData.prelevementJeune}
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {data.newAntip && (
                  <StyledTableRow key="newantip">
                    <StyledTableCell component="th" scope="row">
                      Nouveaux contrats Antip.
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {data.newAntip.nb}
                    </StyledTableCell>
                    {/* <StyledTableCell align="right">{data.newAntip.total}€</StyledTableCell> */}

                    <StyledTableCell component="th" scope="row">
                      Prélèvement Skin
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {activeData.prelevementSkin}
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {data.bodySkin && (
                  <StyledTableRow key="bodySkin">
                    <StyledTableCell component="th" scope="row">
                      Body &gt; Skin
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {data.bodySkin.nb}
                    </StyledTableCell>
                    {/* <StyledTableCell align="right">{data.bodySkin.total.toFixed(2)}€</StyledTableCell> */}

                    <StyledTableCell component="th" scope="row">
                      Anticipation Jeune
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {activeData.antipJeune}
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {data.skinBody && (
                  <StyledTableRow key="skinBody">
                    <StyledTableCell component="th" scope="row">
                      Skin &gt; Body
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {data.skinBody.nb}
                    </StyledTableCell>
                    {/* <StyledTableCell align="right">{data.skinBody.total.toFixed(2)}€</StyledTableCell> */}
                    {/*
                    <StyledTableCell component="th" scope="row">
                      Anticipation Adulte
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {activeData.antipAdult}
                    </StyledTableCell>
                */}
                    <StyledTableCell component="th" scope="row">
                      Anticipation Skin
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {activeData.antipSkin}
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {data.prelevAntip && (
                  <StyledTableRow key="prelevAntip">
                    <StyledTableCell component="th" scope="row">
                      Contrats Prelev &gt; Antip.
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {data.prelevAntip.nb}
                    </StyledTableCell>
                    {/* <StyledTableCell align="right">{data.prelevAntip.total.toFixed(2)}€</StyledTableCell> */}
                    <StyledTableCell component="th" scope="row">
                      Anticipation CE
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {activeData.ce}
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {data.antipPrelev && (
                  <StyledTableRow key="antipPrelev">
                    <StyledTableCell component="th" scope="row">
                      Contrats Antip &gt; Prelev.
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {data.antipPrelev.nb}
                    </StyledTableCell>
                    {/* <StyledTableCell align="right">{data.antipPrelev.total.toFixed(2)}€</StyledTableCell> */}
                  </StyledTableRow>
                )}

                {data.terminatedPrelev && (
                  <StyledTableRow key="terminatedPrelev">
                    <StyledTableCell component="th" scope="row">
                      Prelev résiliés
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {data.terminatedPrelev.nb}
                    </StyledTableCell>
                    {/* <StyledTableCell align="right">{data.terminatedPrelev.total.toFixed(2)}€</StyledTableCell> */}
                  </StyledTableRow>
                )}

                <StyledTableRow key="total">
                  <StyledTableCell component="th" scope="row">
                    <b>Total</b>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <b>{totalNb}</b>
                  </StyledTableCell>
                  {/* <StyledTableCell align="right"><b>{totalCA.toFixed(2)}€</b></StyledTableCell> */}
                  <StyledTableCell component="th" scope="row">
                    <b>Total</b>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <b>{totalActiveNb}</b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Bar options={options} data={barData} />

        {<Bar options={optionsActive} data={barDataActive} />}
      </LoadableContent>
    </div>
  );
};

const fetchSubscriptionsStats = async ({
  instituteId,
  start,
  end,
}: {
  instituteId: number;
  start: Date;
  end: Date;
}) => {
  let oneOClockStartDate = new Date(start.setHours(7, 0, 0, 0));
  let elevenOClockEndDate = new Date(end.setHours(23, 0, 0, 0));
  const response = request<SubscriptionsStats>(`api/stat/subscription`, {
    method: 'POST',
    data: {
      instituteId: instituteId,
      startDate: oneOClockStartDate,
      endDate: elevenOClockEndDate,
    },
  });
  return response;
};

const fetchSubscriptionsActiveStats = async ({
  instituteId,
}: {
  instituteId: number;
}) => {
  const response = request<SubscriptionsActiveStats>(
    `api/subscriptions/active/${instituteId}`,
    {
      method: 'GET',
    },
  );
  return response;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#cbcffe',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const DropdownWrapper = style.div`
    margin-top: 20px;
    margin-bottom: 20px;
  `;

const DateWrapper = style.div`
  /* display: flex;
  flex-direction: row; */
`;

const DateWrapperLabel = style.div`

`;