import { atom } from 'recoil';

// Define a Recoil atom
export const paymentDay = atom({
  key: 'paymentDay',
  default: '5',
});

export const idabo = atom({
  key: 'idabo',
  default: '',
});

export const subscriptionId = atom({
  key: 'subscriptionId',
  default: 0,
});
