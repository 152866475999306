import { Route } from 'types/route';
import { DebitsPage } from 'app/pages/DebitsPage';

import { paths } from '../../paths';

const debits: Route = {
  authenticated: true,
  content: DebitsPage,
  routerPath: paths.debits.list.patterns,
};

export const routes = [debits];
