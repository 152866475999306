import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  pageTitle: () => _t(translations.objectives.page_title),
  dayDateFormat: () => _t(translations.objectives.day_date_format),
  editObjectives: () => _t(translations.objectives.edit_objectives),
  columns: {
    day: () => _t(translations.objectives.columns.day),
    dailyObjectives: () => _t(translations.objectives.columns.objectives_daily),
    objectives: () => _t(translations.objectives.columns.objectives_cumulated),
    dailyTotalSales: () =>
      _t(translations.objectives.columns.total_sales_daily),
    totalSales: () => _t(translations.objectives.columns.total_sales_cumulated),
    totalSalesError: () =>
      _t(translations.objectives.columns.total_sales_error),
    productSales: () => _t(translations.objectives.columns.product_sales),
    services: () => _t(translations.objectives.columns.services),
  },
  openedDays: () => _t(translations.objectives.opened_days),
};
