import { Controller, useFormContext } from 'react-hook-form';
import React, { ChangeEvent } from 'react';
import { SwitchProps as MuiSwitchProps } from '@material-ui/core';

import { Switch as UncontrolledSwitch } from '../Uncontrolled/Switch';

export interface SwitchProps extends MuiSwitchProps {
  name: string;
  label: string;
}

export const Switch = (props: SwitchProps) => {
  const { name, onChange, ...rest } = props;

  const { control } = useFormContext();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    forwardedOnChange: (...e: any[]) => void,
  ) => {
    const { checked } = event.target;
    onChange?.(event, checked);
    forwardedOnChange(checked);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur, onChange: forwardedOnChange, value } }) => (
        <UncontrolledSwitch
          onBlur={onBlur}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            handleChange(event, forwardedOnChange);
          }}
          name={name}
          checked={value ?? false}
          {...rest}
        />
      )}
    />
  );
};
