import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { messages } from './messages';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { useInstituteSelector } from 'app/atoms/selectors';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { useParams } from 'react-router-dom';
import { LoadableContent } from 'app/components/LoadableContent';
import { useQueries, useSellOffQueries } from './hooks';

import { SellOff } from 'types/API/selloffs';
import { request } from 'utils/request';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const SellOffPage = () => {

  const { institute } = useInstituteSelector();
  const { currency } = institute.country;
  const { t } = useTranslation();

  // const pageTitle = t(messages.createPageTitle())

  const { id }: { id?: string } = useParams();
  const { sellOffId } = useMemo(() => {
    return {
      sellOffId: id ? parseInt(id) : undefined,
    };
  }, [id]);


  const pageTitle = useMemo(
    () =>
      sellOffId
        ? t(messages.updatePageTitle(), { identifier: sellOffId })
        : t(messages.createPageTitle()),
    [sellOffId, t],
  );

  return (
    <>
      <Helmet>
        <title>{pageTitle} : {institute.name}</title>
      </Helmet>
      <PageWrapper header={<PageHeader backBehaviour label={pageTitle + " : " + institute.name} />}>
        <SellOffBody sellOffId={sellOffId} />
        
      </PageWrapper>
    </>
  )
}

function SellOffBody(props) {
  const sellOffId = props.sellOffId
  if (sellOffId) {
    return <SellOffUpdateForm sellOffId={sellOffId}/>
  }
  return <SellOffCreationForm />
}

const SellOffUpdateForm = (props) => {

  const { sellOffQueryState } = useSellOffQueries({
    sellOffId: props.sellOffId,
  });

  const data = sellOffQueryState.data
  const dType = data?.discountType === "percent" ? "%" : "€"
  const dValue = '-' + data?.discountValue.toString() + dType
  const products = data?.products



  return (
    <>
      <SellOffUpdateHeaderWrapper>
      <SellOffUpdateHeaderRow>
          <SellOffUpdateHeaderRowItem>
          Nom
          </SellOffUpdateHeaderRowItem>
          <SellOffUpdateHeaderRowItem>
          Type de réduction
          </SellOffUpdateHeaderRowItem>
          <SellOffUpdateHeaderRowItem>
          Valeur de la réduction
          </SellOffUpdateHeaderRowItem>
        </SellOffUpdateHeaderRow>
      <SellOffUpdateHeaderRow>
          <SellOffUpdateHeaderRowItem>
          {data?.name}
          </SellOffUpdateHeaderRowItem>
          <SellOffUpdateHeaderRowItem>
          {dType}
          </SellOffUpdateHeaderRowItem>
          <SellOffUpdateHeaderRowItem>
          {dValue}
          </SellOffUpdateHeaderRowItem>
        
        </SellOffUpdateHeaderRow>
      </SellOffUpdateHeaderWrapper>
      Produits concernés: 
      <div>{sellOffQueryState?.data?.products?.map(prd => (
        <SellOffUpdateHeaderWrapper>{prd.language.title}</SellOffUpdateHeaderWrapper>
      ))}</div>
      
      </>
  )
}

const DateWrapper = styled.div`
  /* display: flex;
  flex-direction: row; */
`;

const DateWrapperLabel = styled.div`

`;

const SellOffUpdateHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  background: #fff;
  border: none;
  padding: 20px;
  border-radius: 20px;
  margin: 30px;
`;

const SellOffUpdateHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const SellOffUpdateHeaderRowItem = styled.div`
  width: 33%;
  text-align: center;
`;


const BtoBHeaderItem = styled.div`
  width: 16%;
`;

const SellOffCreationForm = () => {

  const [name, setName] = useState('')
  const [discountType, setDiscountType] = useState('percent')
  const [discountValue, setDiscountValue] = useState('0')
  const { institute } = useInstituteSelector();
  const [prods, setProds] = useState([])
  const { error, isLoading, products } = useQueries({
    instituteId: institute.id,
  });
  const prodOptions: { value: string, label: string }[] | undefined = products?.map((product) => {
    const option = { value: product.id.toString(), label: product.language.title }
    return { value: product.id.toString(), label: product.language.title };
  })
  const [checkedState, setCheckedState] = useState(
    new Array(prodOptions?.length).fill(false)
  );
  const history = useHistory();

  let nextWeek = new Date();
  nextWeek.setDate(nextWeek.getDate() + 7);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(nextWeek);

  const handleSubmit = e => {
    e.preventDefault();

    var prodArray : any[] = [];

    checkedState.forEach((elt, index) => {
      if (elt) {
        const id = products?.[index].id;
        const prod = { id: id, delete: false };
        prodArray.push(prod)
      }
    })

    const data = {
      institute: '/api/institutes/' + institute.id.toString(),
      name: name,
      discountType: discountType,
      discountValue: Number(discountValue),
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      products: prodArray,
    }

    createSellOffRequest(data)

    alert('La braderie : ' + name + ' a été créée');

    setName('');
    setDiscountType('percent');
    setDiscountValue('0');

    history.goBack()
  }

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    // console.log(updatedCheckedState.toString())
    setCheckedState(updatedCheckedState);
  };

  return (
    <LoadableContent
          error={error}
          isLoading={isLoading}
        >
      
      
      <form onSubmit={handleSubmit}>
        <h2>Informations de la braderie:</h2>
        <SellOffProductWrapper>
          <SellOffProductRow>
          Nom :
          <input name="name" type="text" value={name} onChange={event => setName(event.target.value)} />
          </SellOffProductRow>
        
          <SellOffProductRow>
          Type de réduction :
          <select name="discountType" value={discountType} onChange={event => setDiscountType(event.target.value)}>
            <option value="percent">%</option>
            <option value="amount">€</option>
          </select>
          </SellOffProductRow>
          <SellOffProductRow>
          Valeur de la réduction :
          <select name="discountValue" value={discountValue} onChange={event => setDiscountValue(event.target.value)}>
            <option value='0'>0</option>
            <option value='1'>-1</option>
            <option value='5'>-5</option>
            <option value='10'>-10</option>
            <option value='15'>-15</option>
            <option value='20'>-20</option>
            <option value='25'>-25</option>
            <option value='30'>-30</option>
            <option value='35'>-35</option>
            <option value='40'>-40</option>
            <option value='45'>-45</option>
            <option value='50'>-50</option>
          </select>
          </SellOffProductRow>
          <DateWrapperLabel>Du</DateWrapperLabel>
          <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
          <DateWrapperLabel>au</DateWrapperLabel>
          <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
        </SellOffProductWrapper>
        <h2>Liste des produits:</h2>
        <SellOffProductWrapper>
            {prodOptions?.map(({ value, label }, index) => {
              return (
                <SellOffProductRow>
                  <label htmlFor={`custom-checkbox-${index}`}>{label}</label>
                  <input
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name={label}
                    value={value}
                    checked={checkedState[index]}
                    onChange={() => handleOnChange(index)}
                  />
                </SellOffProductRow>
              );
            })}

        </SellOffProductWrapper>
        <SellOffButtons><SellOffButton type="submit" value="Créer la Braderie" /></SellOffButtons>
      </form>
    </LoadableContent>
  )
};

const createSellOffRequest = async (data: SellOff.CreatePayload) => {
  const sellOff = await request<SellOff.ResponsePayload>(`api/selloff`, {
    method: 'POST',
    data,
  });
  return sellOff;
};

const SellOffProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 95%;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  margin: 10px;
`;

const SellOffProductRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin: 10px;
`;

const SellOffButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 95%;
`;

const SellOffButton = styled.input`
  background: #e462a1;
  border: none;
  padding: 20px;
  border-radius: 20px;
  color: white;
  margin: 30px;
`;
