import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { ChangeEvent, useCallback, useMemo } from 'react';
import styled from 'styled-components/macro';

import { Conditional } from '../Conditional';
import {
  TableColumn,
  TableColumns,
  TableEditCellValueParams,
  TableRowData,
} from './table';

interface TableProps {
  columns: TableColumns;
  footer?: TableRowData;
  onCellValueChange?: (
    params: TableEditCellValueParams,
    event: ChangeEvent<HTMLInputElement>,
  ) => void;
  rows: TableRowData[];
}

export const Table = (props: TableProps) => {
  const { columns, footer, onCellValueChange, rows } = props;

  const visibleColumns = useMemo(
    () => columns.filter(column => !column.hide),
    [columns],
  );

  const renderSimpleContentCell = useCallback(
    (row: TableRowData, column: TableColumn) => {
      if (column.renderCell) {
        return column.renderCell({
          id: row.id,
          field: column.field,
          value: row[column.field],
        });
      }
      return row[column.field];
    },
    [],
  );

  const renderEditableContentCell = useCallback(
    (row: TableRowData, column: TableColumn) => (
      <input
        defaultValue={row[column.field]}
        onChange={event => {
          onCellValueChange?.(
            {
              id: row.id,
              field: column.field,
              value: event.target.value,
            },
            event,
          );
        }}
      />
    ),
    [onCellValueChange],
  );

  const renderContentCell = useCallback(
    (row: TableRowData, column: TableColumn) => (
      <TableCell key={`${row.id}-${column.field}`}>
        <Conditional
          condition={column.editable ?? false}
          trueConditionNode={renderEditableContentCell(row, column)}
          falseConditionNode={renderSimpleContentCell(row, column)}
        />
      </TableCell>
    ),
    [renderEditableContentCell, renderSimpleContentCell],
  );

  const renderFooterCell = useCallback(
    (footer: TableRowData, column: TableColumn) => {
      const renderer = () => {
        if (column.renderFooter) {
          return column.renderFooter({
            id: footer.id,
            field: column.field,
            value: footer[column.field],
          });
        }
        return footer[column.field];
      };
      return (
        <TableCell key={`${footer.id}-${column.field}`}>{renderer()}</TableCell>
      );
    },
    [],
  );

  return (
    <TableContainer component={Paper} style={{ maxHeight: '100%' }}>
      <StyledTable stickyHeader>
        <TableHead>
          <TableRow>
            {visibleColumns.map(column => (
              <TableCell key={column.headerName}>{column.headerName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              {visibleColumns.map(column => renderContentCell(row, column))}
            </TableRow>
          ))}
        </TableBody>
        {footer && (
          <TableFooter>
            <TableRow>
              {visibleColumns.map(column => renderFooterCell(footer, column))}
            </TableRow>
          </TableFooter>
        )}
      </StyledTable>
    </TableContainer>
  );
};

const StyledTable = styled(MuiTable)`
  & .MuiTableCell-footer {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.text.title};
  }
`;
