import { Optional } from 'types/misc';
import { Discount } from 'types/API/discounts';
import { intersection, omit } from 'lodash';
import { useCallback } from 'react';

import { entityEndpoint } from 'utils/entities/identifier';
import { formatDateInput } from 'utils/formatters/date';

import { FormValues, ProductGroup } from './useForm/types';

export const useTransformData = ({
  productGroups,
}: {
  productGroups: ProductGroup[];
}) => {
  const getFormValues = useCallback(
    (discount: Optional<Discount.Normal>): FormValues => {
      if (!discount)
        return { priceMode: 'discount', discountType: Discount.Type.PERCENT };
      const payload = omit(discount, ['id', 'institute']);
      const {
        discountValue: rawDiscountValue,
        endDate,
        price: rawPrice,
        promotionProducts,
        startDate,
        type,
        ...rest
      } = payload;
      const priceMode = rawPrice !== 0 ? 'fixed' : 'discount';
      const products = promotionProducts.map(product => product.id.toString());
      //console.log(`payload:${payload}, rawDiscountValues:${rawDiscountValue}`);

      return {
        availability: [type.toString()],
        discountValue: [rawDiscountValue.toString()],
        endDate: formatDateInput({
          date: endDate,
        }),
        startDate: formatDateInput({
          date: startDate,
        }),
        price: [rawPrice?.toString()],
        priceMode,
        productGroups: productGroups.map(group =>
          intersection(
            group.options.map(option => option.id),
            products,
          ),
        ),
        ...rest,
      };
    },
    [productGroups],
  );

  const getCreatePayload = useCallback(
    ({
      formValues,
      instituteId,
    }: {
      formValues: FormValues;
      instituteId: number;
    }): Discount.CreatePayload => {
      const {
        availability,
        description,
        disclaimer,
        autreMontantForFixedPriceMode,
        autreMontantForDiscountPriceMode,
        discountType,
        discountValue: rawDiscountValues,
        endDate,
        name,
        price: rawPriceValues,
        priceMode,
        productGroups,
        startDate,
      } = formValues as Required<FormValues>;
      const groupedProducts = Object.values(productGroups).filter(
        group => !!group,
      );
      const products = groupedProducts.reduce(
        (accumulator, value) => accumulator.concat(value),
        [],
      );
      let rawDiscountValuesFloat = rawDiscountValues !== undefined ? parseFloat(rawDiscountValues[0]) : rawDiscountValues;
      let rawPriceValuesFloat = rawPriceValues !== undefined ? parseFloat(rawPriceValues[0]) : rawPriceValues;
/*
      console.log(`autreMontantForDiscountPriceMode:${autreMontantForDiscountPriceMode},
      autreMontantForFixedPriceMode:${autreMontantForFixedPriceMode}, 
      rawPriceValues: ${rawPriceValues}, rawDiscountValues: ${rawDiscountValues}, 
      prawDiscountValuesFloat:${rawDiscountValuesFloat}, rawPriceValuesFloat:${rawPriceValuesFloat} `);
*/
      return {
        promotion: {
          description,
          disclaimer,
          discountType,
          discountValue:
            autreMontantForDiscountPriceMode !== undefined && autreMontantForDiscountPriceMode !== '0' && 
            autreMontantForDiscountPriceMode !== null && autreMontantForDiscountPriceMode !== '' && !(rawDiscountValuesFloat > 0)
              ? Math.abs(parseFloat(autreMontantForDiscountPriceMode)) > 50 ? 50 : Math.abs(parseFloat(autreMontantForDiscountPriceMode))
              : priceMode === 'discount'
              ? parseFloat(rawDiscountValues[0])
              : 0,
          endDate: new Date(endDate).toISOString(),
          institute: entityEndpoint({ id: instituteId, module: 'institutes' }),
          name,
          price:
            autreMontantForFixedPriceMode !== undefined && autreMontantForFixedPriceMode !== '0' && autreMontantForFixedPriceMode !== null && autreMontantForFixedPriceMode !== '' && !(rawPriceValuesFloat > 0)
              ? Math.abs(parseFloat(autreMontantForFixedPriceMode))
              : priceMode === 'fixed'
              ? parseFloat(rawPriceValues[0])
              : 0,
          startDate: new Date(startDate).toISOString(),
          type: parseInt(availability[0]),
        },
        products: products.map(productId => ({
          productId: parseInt(productId),
        })),
      };
    },
    [],
  );

  const getUpdatePayload = useCallback(
    ({
      formValues,
      instituteId,
    }: {
      formValues: FormValues;
      instituteId: number;
    }): Discount.UpdatePayload => {
      //console.log(`getUpdatePayload:${formValues}`);
      return getCreatePayload({ instituteId, formValues });
    },
    [getCreatePayload],
  );

  return { getCreatePayload, getFormValues, getUpdatePayload };
};
