import { QueryClient, QueryKey } from 'react-query';

import { Identifiable } from 'types/misc';

import { CacheType } from './types';

export const objectPredicate =
  (object: Identifiable) =>
  (candidate: Identifiable): boolean =>
    candidate.id === object.id;

export const cacheTypeForQueryKey = ({
  queryClient,
  queryKey,
}: {
  queryClient: QueryClient;
  queryKey: QueryKey;
}): CacheType => {
  const cache = queryClient.getQueryData(queryKey);
  // @ts-ignore
  if (cache) {
    // @ts-ignore
    if (Array.isArray(cache)) {
      return CacheType.List;
    }
    return CacheType.Object;
  }

  return CacheType.Unset;
};
