import {
  useQuery as useReactQuery,
  useMutation as useReactMutation,
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  MutationFunction,
  UseMutationResult,
  UseMutationOptions,
} from 'react-query';

export function useQuery<
  TQueryFnData = unknown,
  TError extends Error = Error,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError> {
  return useReactQuery(queryKey, queryFn, options);
}

export function useMutation<
  TData = unknown,
  TError extends Error = Error,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: UseMutationOptions<TData, TError, TVariables, TContext>,
): UseMutationResult<TData, TError, TVariables, TContext> {
  return useReactMutation(mutationFn, options);
}
