import React from 'react';
import styled from 'styled-components/macro';

interface Props {
  label: string;
  value: string | number;
}

export const ValueItemLabel = ({ label, value }: Props) => {
  return (
    <Wrapper>
      <ItemLabel>{label}</ItemLabel>
      <ValueLabel>{value}</ValueLabel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const CommonLabel = styled.span`
  font-family: 'Gabriel Sans Cond';
  font-size: 1rem;
`;

const ItemLabel = styled(CommonLabel)`
  font-weight: 700;
  margin-right: 0.5rem;
  flex: 0 0 auto;
`;

const ValueLabel = styled(CommonLabel)`
  color: ${p => p.theme.text.body};
`;
