import React, { MouseEvent } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { IconButton } from 'app/components/IconButton';
import { ReactComponent as TrashIcon } from 'app/assets/trash.svg';
import { User } from 'types/API/users';
import { Institute } from 'types/API/institutes';
import { UserNamesLabel } from 'app/components/Labels/UserNamesLabel';
import { Disableable } from 'app/components/Disableable';

import { messages } from './messages';

interface Props {
  displayIdentifier: boolean;
  institutes: Institute.Light[];
  isDeleteEnabled: boolean;
  isEditionEnabled: boolean;
  onClick?: () => void;
  onDelete?: () => void;
  user: User.DetailFormat;
}

export const UserItem = (props: Props) => {
  const {
    displayIdentifier,
    institutes,
    isDeleteEnabled,
    isEditionEnabled,
    onClick,
    onDelete,
    user,
  } = props;

  const { t } = useTranslation();

  const handleDeleteAction = (event?: MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    event?.stopPropagation();
    onDelete?.();
  };

  return (
    <Wrapper onClick={isEditionEnabled ? onClick : undefined}>
      <ContentWrapper>
        <PrimaryLineWrapper>
          <UserNamesLabel firstname={user.firstname} lastname={user.lastname} />
          <Disableable enabled={displayIdentifier}>
            <IdentifierLabel>{user.username}</IdentifierLabel>
          </Disableable>
        </PrimaryLineWrapper>
        <SecondaryLineWrapper>
          <InstitutesLabel>
            {t(messages.institutes(), { count: institutes.length })}
          </InstitutesLabel>
          <InstitutesDivider />
          <InstitutesValueLabel>
            {institutes.map(institute => institute.name).join(', ')}
          </InstitutesValueLabel>
        </SecondaryLineWrapper>
      </ContentWrapper>
      {isDeleteEnabled && (
        <ActionWrapper>
          <IconButton onClick={handleDeleteAction}>
            <TrashIcon />
          </IconButton>
        </ActionWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${p => p.theme.itemBackground};
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  border-radius: 15px;
  cursor: ${p => (p.onClick ? 'pointer' : 'inherit')};

  overflow: hidden;
  margin: 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1rem 1rem;
`;

const IdentifierLabel = styled.h3`
  font-family: 'Gabriel Sans Cond';
  margin-block: 0;
  font-weight: 400;
  letter-spacing: 0;
  color: ${p => p.theme.text.title};
  margin-left: 0.8rem;
`;

const SecondaryLabel = styled.span`
  font-family: 'Gabriel Sans Cond';
  color: ${p => p.theme.text.body};
`;

const InstitutesLabel = styled(SecondaryLabel)`
  font-weight: 700;
`;

const InstitutesValueLabel = styled(SecondaryLabel)`
  font-weight: 400;
`;

const InstitutesDivider = styled.span`
  border: none;
  border-bottom: 1px dotted ${p => p.theme.text.title};
  height: 1px;
  width: 2rem;
  margin: 0 1rem;
`;

const PrimaryLineWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const SecondaryLineWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ActionWrapper = styled.div`
  background-color: ${p => p.theme.primary};
  display: flex;
  width: 5rem;
  justify-content: center;
`;
