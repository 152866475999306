import { ModalState, useModal } from 'app/hooks';

type DeleteConfiramtionModalState = ModalState<{ userId: number }>;
export interface UserModalStates {
  deleteConfirmation: DeleteConfiramtionModalState;
}

export const useModals = (): UserModalStates => {
  const deleteConfirmation: DeleteConfiramtionModalState = useModal();

  return {
    deleteConfirmation,
  };
};
