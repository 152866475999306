import { useRecoilValue } from 'recoil';

import { meState } from 'app/atoms';

export const useSelectors = () => {
  const me = useRecoilValue(meState);
  return {
    me,
  };
};
