import {
  NavigateBeforeOutlined as NavigateBeforeIcon,
  NavigateNextOutlined as NavigateNextIcon,
} from '@material-ui/icons';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';

import { Month } from 'types/month';
import { messages } from 'app/i18n/messages';

interface Props {
  month: Month;
  nextDisabled?: boolean;
  onChange: (month: Month) => void;
  previousDisabled?: boolean;
}

export const MonthSelector = (props: Props) => {
  const { month, nextDisabled, onChange, previousDisabled } = props;

  const { t } = useTranslation();

  const onPrevious = () => {
    onChange(month.bySubstractingMonth());
  };

  const onNext = () => {
    onChange(month.byAddingMonth());
  };

  return (
    <>
      <IconButton disabled={previousDisabled} onClick={onPrevious}>
        <NavigateBeforeIcon />
      </IconButton>
      <MonthLabel>
        {t(messages.monthDateFormat(), { date: month.toDate() })}
      </MonthLabel>
      <IconButton disabled={nextDisabled} onClick={onNext}>
        <NavigateNextIcon />
      </IconButton>
    </>
  );
};

const MonthLabel = styled.h3`
  display: inline;
  margin-block: 0;
  font-weight: 800;
  letter-spacing: 1.17pt;
  color: ${p => p.theme.text.title};
`;
