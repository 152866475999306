import { Helmet } from 'react-helmet-async';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { TrendingUpOutlined as EditObjectivesIcon } from '@material-ui/icons';

import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { ActionPageHeader } from 'app/components/PageHeaders/ActionPageHeader';
import { LoadableContent } from 'app/components/LoadableContent';
import { Month } from 'types/month';
import { MonthSelector } from 'app/components/Calendar/MonthSelector';
import { useInstituteSelector } from 'app/atoms/selectors';

import { messages } from './messages';
import { MonthTable } from './components/MonthTable';
import { useData } from './hooks';
import { paths } from 'app/routes/paths';

export const ObjectivesPage = () => {
  const { institute } = useInstituteSelector();

  const history = useHistory();
  const { t } = useTranslation();

  const [month, setMonth] = useState<Month>(Month.current);

  const { table, isLoading } = useData({ instituteId: institute.id, month });

  const pageTitle = t(messages.pageTitle());

  const nextDisabled = useMemo(
    // Only current and previous months are visible
    () => month.byAddingMonth().toDate() >= new Date(),
    [month],
  );

  const onObjectiveEdition = () => {
    history.push(paths.objectives.editObjectives);
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle} : {institute.name}</title>
      </Helmet>
      <PageWrapper
        header={
          <ActionPageHeader
            button={{
              onClick: onObjectiveEdition,
              label: t(messages.editObjectives()),
              icon: <EditObjectivesIcon />,
            }}
            title={t(messages.pageTitle()) + " : " + institute.name}
          />
        }
      >
        <MonthSelectorWrapper>
          <MonthSelector
            month={month}
            nextDisabled={nextDisabled}
            onChange={newMonth => setMonth(newMonth)}
          />
        </MonthSelectorWrapper>
        <LoadableContent isLoading={isLoading}>
          {table && <MonthTable rows={table.rows} footer={table.footer} />}
        </LoadableContent>
      </PageWrapper>
    </>
  );
};
const MonthSelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
