import { Institute } from 'types/API/institutes';

export interface InstituesState {
  /**
   * Institute identifier of the current selected institute.
   */
  currentInstituteId?: number;

  /**
   * BodyMinute institutes (all institutes)
   */
  institutes: Institute.Normal[];

  /**
   * User owned institutes
   */
  userInstitutes: Institute.Normal[];
}

export const defaultState: InstituesState = {
  currentInstituteId: undefined,
  institutes: [],
  userInstitutes: [],
};
