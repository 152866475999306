import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SalesSummary, SummaryRow } from 'app/components/SalesSummary';

import { messages } from './messages';
import { Receipt } from 'types/API/receipts';

interface Props {
  currency: string;
  count: number;
  total: Receipt.Total;
}

export const Summary = (props: Props) => {
  const { currency, count, total } = props;
  const { t } = useTranslation();

  const title = t(messages.count(), { count });

  const totalRow = useMemo(
    () => ({
      label: t(messages.total()),
      price: total.inclTax,
    }),
    [total, t],
  );

  const rows: SummaryRow[] = useMemo(
    () =>
      Object.keys(total.byCategories).map(key => ({
        label: t(messages.categories[key]()),
        price: total.byCategories[key],
      })),
    [total, t],
  );

  return (
    <SalesSummary
      currency={currency}
      rows={rows}
      title={title}
      total={totalRow}
    />
  );
};
