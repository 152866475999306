import { Theme } from 'styles/theme/themes';
import React from 'react';
import styled from 'styled-components/macro';

type LayoutDirection = 'vertical' | 'horizontal';

interface Props {
  icon: (selected: boolean) => JSX.Element;
  layoutDirection: LayoutDirection;
  onClick: () => void;
  selected: boolean;
  title: string;
}

type WrapperProps = {
  layoutDirection: LayoutDirection;
  selected: boolean;
};

const getBackgroundColor = ({
  selected,
  hover,
  theme,
}: {
  selected: boolean;
  hover: boolean;
  theme: Theme;
}) => {
  let color = selected || hover ? theme.itemBackground : theme.primary;
  const opacity = hover && !selected ? 0.25 : 1;
  return `${color}${Math.round(opacity * 255).toString(16)}`;
};

const getColor = (selected: boolean, theme: Theme) =>
  selected ? theme.secondary : theme.text.bright;

export const MenuItem = (props: Props) => {
  const { layoutDirection, icon, onClick, selected, title } = props;
  
  return (
    <Wrapper
      onClick={onClick}
      layoutDirection={layoutDirection}
      selected={selected}
    >
      
      {icon(selected)}
      <TitleWrapper>
        {title.split(" ", 2).map(word => (
          <div>{word}</div>
        ))}
      </TitleWrapper>
    </Wrapper>
  );
};

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    word-break: break-all;
    text-align: center;
  `;

// eslint-disable-next-line prettier/prettier
const Wrapper = styled('li')<WrapperProps>`
  display: flex;
  flex-direction: ${({ layoutDirection }) =>
    layoutDirection === 'vertical' ? 'column' : 'row'};
  align-items: center;
  justify-content: ${({ layoutDirection }) =>
    layoutDirection === 'vertical' ? 'center' : 'flex-start'};

  color: ${({ selected, theme }) => getColor(selected, theme)};
  background-color: ${({ selected, theme }) =>
    getBackgroundColor({ selected, hover: false, theme })};
  border-radius: calc(100vh * 0.5);
  border-style: none;
  cursor: pointer;

  overflow-x: hidden;
  padding: 0.5rem 1rem;

  width: ${({ layoutDirection }) =>
    layoutDirection === 'vertical' ? '5.5rem' : 'inherit'};
  height: ${({ layoutDirection }) =>
    layoutDirection === 'vertical' ? '5.5rem' : 'inherit'};

  font-family: 'Gabriel Sans Cond';
  font-weight: 500;
  font-size: 0.8rem;

  &:hover {
    background: ${({ selected, theme }) =>
      getBackgroundColor({ selected, hover: true, theme })};
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &::before {
    display: none;
  }

  > svg {
    margin-bottom: ${({ layoutDirection }) =>
      layoutDirection === 'vertical' ? '0.25rem' : '0'};
    margin-right: ${({ layoutDirection }) =>
      layoutDirection === 'vertical' ? '0' : '1rem'};
  }
`;
