import React, { PropsWithChildren, ReactNode } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';

import styled from 'styled-components/macro';
import { Conditional } from '../Conditional';

interface ContainerCommonProps {
  className?: string;
  includesInterItemMargin?: boolean;
  style?: React.CSSProperties;
}

export type ListItemProps = PropsWithChildren<
  {
    accessoryNode?: ReactNode;
    onClick?: () => void;
  } & ContainerCommonProps
>;

export const ListItem = (props: ListItemProps) => {
  const {
    accessoryNode,
    children,
    className,
    includesInterItemMargin,
    onClick,
    style,
  } = props;
  const theme = useTheme();
  const largeRow = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true /*see https://github.com/mui-org/material-ui/issues/21048*/,
  });

  return (
    <ContainerProxy
      includesInterItemMargin={includesInterItemMargin}
      style={includesInterItemMargin ? style : undefined}
    >
      <Cell
        className={className}
        onClick={onClick}
        style={!includesInterItemMargin ? style : undefined}
      >
        <Content largeRow={largeRow}>{children}</Content>
        {accessoryNode}
      </Cell>
    </ContainerProxy>
  );
};

type ContainerProps = PropsWithChildren<ContainerCommonProps>;

const ContainerProxy = ({
  children,

  includesInterItemMargin,
  style,
}: ContainerProps) => (
  <Conditional
    condition={includesInterItemMargin ?? false}
    trueConditionNode={<Container style={style}>{children}</Container>}
    falseConditionNode={<>{children}</>}
  />
);

const Container = styled.div`
  padding-bottom: 1rem;

  width: 100%;
`;

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  background: ${p => p.theme.itemBackground};
  width: 100%;

  border-radius: 15px;

  cursor: ${p => (!!p.onClick ? 'pointer' : 'inherited')};

  overflow: hidden;
  margin: 0;
`;

interface ContentProps {
  largeRow: boolean;
}

const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: ${p => (p.largeRow ? 'row' : 'column')};
  justify-content: space-between;
  align-items: ${p => (p.largeRow ? 'center' : 'flex-start')};

  width: 100%;

  padding: 0.5rem 2rem 0.5rem 1rem;
`;
