import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';

import { FormWrapper } from 'app/components/Forms/FormWrapper';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';

import { paths } from 'app/routes/paths';
import { LoadableContent } from 'app/components/LoadableContent';
import { Mode } from 'types/forms';
import { useContextModuleNavigator } from 'app/hooks';
import { useInstituteSelector } from 'app/atoms/selectors';

import { messages } from './messages';
import { useForm, useQueries } from './hooks';
import { Form } from './Form';
import { useFormData } from './hooks/useFormData';

export const DiscountPage = () => {
  const { institute } = useInstituteSelector();
  const { id }: { id?: string } = useParams();

  const currency = useMemo(() => institute.country.currency, [institute]);

  const { t } = useTranslation();

  const { popToRoot } = useContextModuleNavigator({
    context: institute.id,
    rootEndpoint: paths.specialOffers.list,
  });

  const { mode, discountId } = useMemo(() => {
    const m = id ? Mode.Update : Mode.Create;
    return {
      mode: m,
      discountId: id ? parseInt(id) : undefined,
    };
  }, [id]);

  const { discount, error, isLoading, productCategories, products } =
    useQueries({
      discountId,
      instituteId: institute.id,
    });

  const {
    availabilityOptions,
    discountAmountOptionsMap,
    priceOptions,
    productGroups,
  } = useFormData({
    currency,
    productCategories: productCategories ?? [],
    products: products ?? [],
  });

  const { canSubmit, isSubmitting, methods, onSubmit } = useForm({
    discount,
    instituteId: institute.id,
    mode,
    onSuccess: popToRoot,
    productGroups,
  });

  const pageTitle = useMemo(() => {
    switch (mode) {
      case Mode.Create:
        return t(messages.createPageTitle());
      case Mode.Update:
        return t(messages.updatePageTitle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const isContentLoading = isLoading && productGroups.length === 0;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageWrapper header={<PageHeader backBehaviour label={pageTitle} />}>
        <LoadableContent error={error} isLoading={isContentLoading}>
          <FormWrapper
            id="discount-edit-form"
            methods={methods}
            onSubmit={onSubmit}
          >
            <Form
              availabilityOptions={availabilityOptions}
              canSubmit={canSubmit}
              currency={currency}
              discountAmountOptionsMap={discountAmountOptionsMap}
              isSubmitting={isSubmitting}
              priceOptions={priceOptions}
              productGroups={productGroups}
            />
          </FormWrapper>
        </LoadableContent>
      </PageWrapper>
    </>
  );
};
