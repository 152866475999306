import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  createPageTitle: () =>
    _t(translations.special_offers.discount.create.page_title),
  updatePageTitle: () =>
    _t(translations.special_offers.discount.update.page_title),
  name: {
    label: () => _t(translations.special_offers.discount.name.label),
    placeholder: () =>
      _t(translations.special_offers.discount.name.placeholder),
  },
  description: {
    label: () => _t(translations.special_offers.discount.description.label),
    placeholder: () =>
      _t(translations.special_offers.discount.description.placeholder),
  },
  disclaimer: {
    label: () => _t(translations.special_offers.discount.disclaimer.label),
    placeholder: () =>
      _t(translations.special_offers.discount.disclaimer.placeholder),
  },
  otherPrice: {
    label: () => _t(translations.special_offers.discount.otherPrice.label),
    placeholder: () =>
      _t(translations.special_offers.discount.otherPrice.placeholder),
  },
  discountAmount: {
    label: () => _t(translations.special_offers.discount.discount_amount.label),
    placeholder: () =>
      _t(translations.special_offers.discount.discount_amount.placeholder),
    placeholderSetPrice: () =>
      _t(
        translations.special_offers.discount.discount_amount
          .placeholderSetPrice,
      ),
    placeholderSetAnotherPrice: () =>
      _t(
        translations.special_offers.discount.discount_amount
          .placeholderSetAnotherPrice,
      ),
    placeholderSetAnotherDiscountPercentage: () =>
      _t(
        translations.special_offers.discount.discount_amount
          .placeholderSetAnotherDiscountPercentage,
      ),
  },
  price: {
    label: () => _t(translations.special_offers.discount.price.label),
    placeholder: () =>
      _t(translations.special_offers.discount.price.placeholder),
  },
  startDate: {
    label: () => _t(translations.special_offers.discount.start_date.label),
  },
  endDate: {
    label: () => _t(translations.special_offers.discount.end_date.label),
  },
  availability: {
    label: () => _t(translations.special_offers.discount.availability.label),
    placeholder: () =>
      _t(translations.special_offers.discount.availability.placeholder),
  },
  prestations: {
    placeholder: () =>
      _t(translations.special_offers.discount.prestations.placeholder),
  },
  priceMode: {
    discount: () =>
      _t(translations.special_offers.discount.price_mode.discount),
    fixed: () => _t(translations.special_offers.discount.price_mode.fixed),
  },

  sections: {
    general: () => _t(translations.special_offers.discount.sections.general),
    prestations: () =>
      _t(translations.special_offers.discount.sections.prestations),
    price: () => _t(translations.special_offers.discount.sections.price),
    dates: () => _t(translations.special_offers.discount.sections.dates),
  },

  submit: () => _t(translations.special_offers.discount.submit),
};
