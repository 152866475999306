import { fetchSellOffsRequest } from 'app/services/selloffs';
import { useQuery } from 'app/hooks';
import { genericQueryKey } from 'utils/cache/keys';

export const useQueries = (parameters: { instituteId: number }) => {
  const sellOffsQueryState = useQuery(
    genericQueryKey({
      identifier: 'selloff',
      parameters,
    }),
    () => fetchSellOffsRequest(parameters),
  );
  return {
    sellOffsQueryState,
  };
};