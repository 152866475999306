import { useHistory } from 'react-router-dom';

interface ModuleNavigatorOptions {
  rootEndpoint: string;
}

export const useModuleNavigator = (options: ModuleNavigatorOptions) => {
  const { rootEndpoint } = options;

  const history = useHistory();

  const popToRoot = () => {
    history.push(rootEndpoint);
  };

  return { popToRoot };
};
