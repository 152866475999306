import { Route } from 'types/route';
import { SigninPage } from 'app/pages/SigninPage/Loadable';

import { paths } from '../../paths';

const signin: Route = {
  authenticated: false,
  content: SigninPage,
  routerPath: paths.signin,
};

export const routes = [signin];
