import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

import { usePriceFormatter } from 'utils/formatters/hooks/price';

export interface SummaryRow {
  label: string;
  price: number;
}

interface Props {
  currency: string;
  rows?: SummaryRow[];
  title: string;
  total: SummaryRow;
  nbAdult?: number;
  nbJeune?: number;
  nbSkin?: number;
}

export const SalesSummary = (props: Props) => {
  const { currency, rows, title, total, nbAdult, nbJeune, nbSkin } = props;

  const { formatPrice } = usePriceFormatter({
    currency,
  });

  return (
    <Wrapper>
      <HeaderLabel>{title}</HeaderLabel>
      {rows?.map(row => (
        <DetailItemWrapper key={row.label}>
          <span>{row.label}</span>
          <span>{formatPrice(row.price)}</span>
        </DetailItemWrapper>
      ))}
      <TotalItemWrapper>
        <span>{total.label}</span>
        <TotalDivider />
        <span>{formatPrice(total.price)}</span>
      </TotalItemWrapper>

      {/*nbAdult !== undefined && 
        <TotalItemWrapper>
          <span>Nb Adulte</span>
          <TotalDivider />
          <span>{nbAdult}</span>
        </TotalItemWrapper>
      */}

      {nbJeune !== undefined &&
        <TotalItemWrapper>
          <span>Nb Jeune</span>
          <TotalDivider />
          <span>{nbJeune}</span>
        </TotalItemWrapper>
      }

      {nbSkin !== undefined &&
        <TotalItemWrapper>
          <span>Nb Skin</span>
          <TotalDivider />
          <span>{nbSkin}</span>
        </TotalItemWrapper>
      }
      

      


      
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const HeaderLabel = styled(Typography)`
  && {
    color: ${p => p.theme.primary};
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1.2rem;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DetailItemWrapper = styled(ItemWrapper)`
  color: ${p => p.theme.text.title};
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.9rem;
  > :first-child {
    margin-right: 0.8rem;
  }
`;

const TotalItemWrapper = styled(ItemWrapper)`
  align-items: center;
  color: ${p => p.theme.text.title};
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.9rem;
`;

const TotalDivider = styled.span`
  border: none;
  border-bottom: 1px dotted ${p => p.theme.text.title};
  height: 1px;
  flex: 0.8;
  min-width: 1rem;
`;
