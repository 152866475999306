import { atom } from 'recoil';

import { AuthorizationState, defaultState } from './types';
import { storageEffect } from '../effects/storage';

export const authorizationState = atom<AuthorizationState>({
  default: defaultState,
  effects_UNSTABLE: [storageEffect('auth')],
  key: 'auth',
});
