import { entityEndpoint } from 'utils/entities/identifier';

import { FormValues } from './useForm/types';
// import moment from 'moment';

export const useTransformData = () => {
  // const currentDate = moment();
  // const startDate = currentDate;
  // const endDate = startDate.clone().add(6, 'months');
  // const formattedStartDate = startDate.format('YYYY-MM-DD HH:mm:ss Z');
  // const formattedEndDate = endDate.format('YYYY-MM-DD HH:mm:ss Z');

  const getCreatePayload = ({
    formValues,
    instituteId,
  }: {
    formValues: FormValues;
    instituteId: number;
  }): any => {
    const { customer, subscription, subscriptionPayment, image } =
      formValues as Required<FormValues>;

    // The code below fix the Timezone issue
    subscription.startDate &&
      (() => {
        let hoursDiff =
          subscription.startDate.getHours() -
          subscription.startDate.getTimezoneOffset() / 60;
        let minutesDiff =
          (subscription.startDate.getHours() -
            subscription.startDate.getTimezoneOffset()) %
          60;
        subscription.startDate.setHours(hoursDiff);
        subscription.startDate.setMinutes(minutesDiff);
      })();

    subscription.endDate &&
      (() => {
        let hoursDifference =
          subscription.endDate.getHours() -
          subscription.endDate.getTimezoneOffset() / 60;
        let minutesDifference =
          (subscription.endDate.getHours() -
            subscription.endDate.getTimezoneOffset()) %
          60;
        subscription.endDate.setHours(hoursDifference);
        subscription.endDate.setMinutes(minutesDifference);
      })();
    // End
    return {
      source: 'browser',
      customer: {
        ...customer,
        wrongMail: false,
        //noMailing: false,
        pending: false,
        wrongAddress: false,
        wrongMobile: false,
        noMail: false,
        //noSms: false,
      },
      subscription: {
        institute: entityEndpoint({ id: instituteId, module: 'institutes' }),
        type: entityEndpoint({
          id: subscription.type[0],
          module: 'subcription_types',
        }),
        status: true,
        paymentStartDate: subscription.startDate,
        suppSkinEndDate: subscription.suppSkinEndDate,
        paymentDay: parseInt(subscription.paymentDay[0]),
      },
      /*
      subscriptionPayment: {
        firstname: subscriptionPayment.isCustomerDebtor ? customer.firstname : subscriptionPayment.firstname,
        ld: '',
      },
      */
      subscriptionPayment: { ...subscriptionPayment },
      /*
firstname?: string | undefined;
    lastname?: string | undefined;
    address?: string | undefined;
    complement?: string | undefined;
    zipcode?: string | undefined;
    city?: string | undefined;
    iban?: string | undefined;
    bic?: string | undefined;
    isCustomerDebtor: boolean;
      */
      subscriptiondate: {
        startDate: subscription.startDate,
        endDate: subscription.endDate,
      },
      image: image,
      card: { cardnumber: subscription.cardCode, status: true },
    };
  };

  return { getCreatePayload };
};
