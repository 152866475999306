import { useQuery } from 'app/hooks';
import { genericQueryKey } from 'utils/cache/keys';
import { fetchAllDailySummary, fetchDailySummary, fetchBook, fetchTopServiceRequest } from 'app/services/stats';

export const useQueries = (parameters: { caisseId: number, start: Date, end: Date }) => {
  const statsQueryState = useQuery(
    genericQueryKey({
      identifier: 'stats',
      parameters,
    }),
    () => fetchDailySummary(parameters),
  );
  return {
    statsQueryState,
  };
};

export const useAllQueries = (parameters: { caisseId: number }) => {
  const statsQueryState = useQuery(
    genericQueryKey({
      identifier: 'stats',
      parameters,
    }),
    () => fetchAllDailySummary(parameters),
  );
  return {
    statsQueryState,
  };
};

export const useBookQueries = (parameters: { instituteId: number, start: Date, end: Date }) => {
  const statsQueryState = useQuery(
    genericQueryKey({
      identifier: 'stats',
      parameters,
    }),
    () => fetchBook(parameters),
  );
  return {
    statsQueryState,
  };
};

export const useTopServiceQueries = (parameters: { instituteId: number, start: Date, end: Date }) => {
  const statsQueryState = useQuery(
    genericQueryKey({
      identifier: 'stats',
      parameters,
    }),
    () => fetchTopServiceRequest(parameters),
  );
  return {
    statsQueryState,
  };
};
