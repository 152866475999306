export namespace BtoBProduct {

  /*
  /*
{
        "id": 475,
        "language": {
            "title": "ELIXIR CELL FLASH",
            "content": "ELIXIR CELL FLASH"
        },
        "ref": "V026.0",
        "price": 16.3,
        "box": 6,
        "range": "metabolissime",
        "category": "skinminute",
        "orderCategorie": "vente",
        "available": false
    }
  */

  export interface Normal {
    id: number;
    language: {
      title: string;
      content: string;
    };
    ref: string;
    price: number;
    unitPrice: number;
    quantity: number;
    box: number;
    isCategory: boolean | undefined;
    range: string;
    category: string;
    orderCategorie: string;
    franchisedPurchasePriceExclVATPerPCB?: number;
    subscriberPriceExclVATPerUnit?: number;
    subscriberRateExclVATPerPCB?: number;
    franchiseMarginPerPCB?: number;
    subscriberSalesPriceInclUnitVAT?: number;
  }

  /*
          "ref": "V032.0",
          "name": "SOIN CORPS PROFOND HYPER HYDRATANT",
          "unitPriceExclTax": "7.04",
          "box": "6",
          "OrderQuantity": "1",
          "franchisedPurchasePriceExclTaxPerPCB": "42.23",
          "subscriberPriceExclTaxPerUnit": "9.28",
          "SubscriberRateExclVATPerPCB": "55.65",
          "franchiseMarginPerPCB": "13.42",
          "subscriberSalesPriceInclUnitTax": "8.45"
  */
  export interface NormalV1 {
    ref: string;
    name: string;
    unitPriceExclVAT: number;
    box: number;
    orderQuantity: number;
    franchisedPurchasePriceExclVATPerPCB: number;
    subscriberPriceExclVATPerUnit: number;
    subscriberRateExclVATPerPCB: number;
    franchiseMarginPerPCB: number;
    subscriberSalesPriceInclUnitVAT: number;
  }

  export interface NormalV2 {
    normal: Normal;
    normalV1: NormalV1;
  }

  export interface ProductsWithCat {
    rangeName: string; //gamme de produit
    rangeDesc: string; //gamme de produit
    rangeId: number;
    products: [NormalV2];
  }

  export interface ProductsV2SubcategoriesInterface {
    subcategories: ProductsV2Subcategory[];
  }

  export interface ProductsV2Subcategory {
    rangeName: string; //gamme de produit
    rangeDesc: string;
    rangeID: number;
    products: NormalV1[];
  }

  export interface CreatePayload {
    instituteId: number;
    products: {
      productId: number;
      quantity: number;
      delete: boolean;
    }[]
  }

  export interface UpdatePayload {
    products: {
      productId: number;
      quantity: number;
      delete: boolean;
    }[],
    active: boolean;
  }

  export interface Language {
    title: string;
    content: string;
  }

  export interface ResponsePayload {
    id: number;
    institute: {
      id: number,
      name: string
    };
    orderNulmber: string;
    createdAt: string;
    products: {
      id: number;
      ref: string;
      language: Language;}[];
      quantity: number;
      unitPrice: number;
      price: number;
  }

  export function describeProduct(product: Normal) {
    console.log(`describeProduct:\n
    ref:${product.ref}, franchisedPurchasePriceExclVATPerPCB:${product.franchisedPurchasePriceExclVATPerPCB},  
    subscriberPriceExclVATPerUnit:${product.subscriberPriceExclVATPerUnit},  
    subscriberRateExclVATPerPCB:${product.subscriberRateExclVATPerPCB}, 
    franchiseMarginPerPCB:${product.franchiseMarginPerPCB},
    subscriberSalesPriceInclUnitVAT:${product.subscriberSalesPriceInclUnitVAT}`)
  }

  export function describeProductV1(product: NormalV1) {
    console.log(`describeProductV1\n
    ref:${product.ref}, franchisedPurchasePriceExclVATPerPCB:${product.franchisedPurchasePriceExclVATPerPCB},  
    subscriberPriceExclVATPerUnit:${product.subscriberPriceExclVATPerUnit},  
    subscriberRateExclVATPerPCB:${product.subscriberRateExclVATPerPCB}, 
    franchiseMarginPerPCB:${product.franchiseMarginPerPCB},
    subscriberSalesPriceInclUnitVAT:${product.subscriberSalesPriceInclUnitVAT}`)
  }

  export function updateProduct(
    product: Normal,
    withProductV1: NormalV1,
  ): Normal {
    product.franchisedPurchasePriceExclVATPerPCB = withProductV1.franchisedPurchasePriceExclVATPerPCB;
    product.subscriberPriceExclVATPerUnit = withProductV1.subscriberPriceExclVATPerUnit;
    product.subscriberRateExclVATPerPCB = withProductV1.subscriberRateExclVATPerPCB;
    product.franchiseMarginPerPCB = withProductV1.franchiseMarginPerPCB;
    product.subscriberSalesPriceInclUnitVAT = withProductV1.subscriberSalesPriceInclUnitVAT;
    return product;
  }
}
