import { Cure } from 'types/API/cures';
import { rawCureToCure } from 'types/format/cures';
import { request } from 'utils/request';
import { FetchMode } from './discounts';

export const fetchCuresRequest = ({
  fetchMode,
  instituteId,
}: {
  fetchMode: FetchMode;
  instituteId: number;
}) => {
  const endPoint = `api/cures/portal/institute/${instituteId}`;
  return request<Cure.Normal[]>(endPoint, {
    method: 'GET',
  });
};

export const createCureRequest = async (data: Cure.CreatePayload) => {
  const rawCure = await request<Cure.Raw>(`api/cures`, {
    method: 'POST',
    data,
  });
  return rawCureToCure(rawCure);
};

export const updateCureRequest = async ({
  cureId,
  data,
}: {
  cureId: number;
  data: Cure.UpdatePayload;
}) => {
  const rawCure = await request<Cure.Raw>(`api/cures/${cureId}`, {
    method: 'PUT',
    data,
  });
  return rawCureToCure(rawCure);
};

export const deleteCureRequest = ({ id }: { id: number }) =>
  request<Cure.Normal>(`api/cures/${id}`, {
    method: 'PUT',
    data: { deleted: true },
  });
