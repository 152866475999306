import { useCallback, useMemo } from 'react';
import { differenceWith } from 'lodash';

import { useQuery } from 'app/hooks';
import {
  fetchOldCustomersRequest,
  searchCustomersRequest,
} from 'app/services/customers';
import { genericQueryKey } from 'utils/cache/keys';
import { Customer } from 'types/API/customers';

import { SubscriptionTab } from '../types';

export const useOldQueries = ({
  instituteId,
  searchQuery,
  tab,
}: {
  instituteId: number;
  searchQuery: string;
  tab: SubscriptionTab;
}) => {
  const parameters = { instituteId, searchQuery };

  const subscribersOldQueryState = useQuery(
    genericQueryKey({
      identifier: 'oldSubscribers',
      parameters,
    }),
    () => fetchOldCustomersRequest(parameters),
    { enabled: tab === SubscriptionTab.OLDDB },
  );

  const searchParams = useMemo(
    () => ({ lastname: searchQuery }),
    [searchQuery],
  );

  const subscribersOldQueryState2 = useCallback(async () => {
    const customers = await fetchOldCustomersRequest(parameters);
    return customers;
  }, [parameters]);

  return {
    subscribersOldQueryState,
    subscribersOldQueryState2,
  };
};
