import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RecoilRoot } from 'recoil';
import { useTranslation } from 'react-i18next';
import ms from 'ms';

import { RecoilExternalStatePortal } from 'utils/recoil';
import { Redirect } from 'app/components/Router/Redirect';
import { AuthenticatedRoute } from 'app/routes/components/AuthenticatedRoute';
import { entryPointPath, paths } from 'app/routes/paths';
import { routes } from 'app/routes';

import { GlobalStyle } from '../styles/global-styles';

export function App() {
  const { i18n } = useTranslation();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        staleTime: ms('5m'),
      },
      mutations: {
        // mutation options
      },
    },
  });

  var startTime = '12:00:00';
  var endTime = '20:00:00';

  var currentDate = new Date()

  var startDate = new Date(currentDate.getTime());
  startDate.setHours(+startTime.split(":")[0]);
  startDate.setMinutes(+startTime.split(":")[1]);
  startDate.setSeconds(+startTime.split(":")[2]);

  var endDate = new Date(currentDate.getTime());
  endDate.setHours(+endTime.split(":")[0]);
  endDate.setMinutes(+endTime.split(":")[1]);
  endDate.setSeconds(+endTime.split(":")[2]);

  console.log("Dates :")
  console.log(startDate)
  console.log(endDate)
  console.log(currentDate)
  const portalClosed = false; //startDate < currentDate && endDate > currentDate;

  return (
    <>
      {portalClosed &&
        <h1>Pour des raisons de performances, l'accès au portail est désactivé de 12h à 20h.</h1>
      }

      {!portalClosed &&
        <RecoilRoot>
          <RecoilExternalStatePortal />
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />

            <BrowserRouter>
              <Helmet
                titleTemplate="%s - Body Minute"
                defaultTitle="Body Minute"
                htmlAttributes={{ lang: i18n.language }}
              >
                <meta name="description" content="Body Minute Franchise" />
              </Helmet>

              <Router />
              <GlobalStyle />
            </BrowserRouter>
          </QueryClientProvider>
        </RecoilRoot>
      }
    </>


  );
}

const Router = () => (
  <Switch>
    <Route
      exact
      path={paths.home}
      render={() => <Redirect to={entryPointPath} />}
    />
    {Object.entries(routes).map(([key, route]) => {
      const routeProps = {
        component: route.content,
        exact: route.exact,
        key,
        path: route.routerPath,
      };

      if (route.authenticated) {
        return <AuthenticatedRoute {...routeProps} />;
      }
      return <Route {...routeProps} />;
    })}
  </Switch>
);
