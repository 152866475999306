import { Stats } from 'types/API/stats';
import { ChartConfiguration } from './charts';
import { InputConfiguration } from './inputs';

export type Value = number | string;

export type ValueFormatter = (
  property: string,
  value: Value,
  context?: any,
) => Value;

type Datasource = () => Promise<Stats.Values>;

export type PropertyMapping = any;
export interface ContentConfiguration {
  inputs: InputConfiguration[];
  datasource?: Datasource; // for now optional
  charts: ChartConfiguration[];

  valueFormatter?: ValueFormatter;
  // Used to map json property from the API to human readable translated labels
  propertyMapping: PropertyMapping;
}

export interface PageConfiguration {
  contentConfiguration: ContentConfiguration;
  title: string;
}

export const allStatKinds = [
  // 'till_monitoring',
  // 'cash_book',
  // 'cash_book_b',
  'cash_book_per_ticket',
  // 'cash_book_per_contract_type', // par poste en FR
  // 'cash_book_per_contract_type_b', // 4-B en FR
  'daily_summary',
  'subscriptions',
  // 'statistics',
  'bonus',
  'esthet_summary',
  'top_service',
  'top_service_per_user',
  // 'bonus',
  // 'sales_revenue_per_customer',
] as const;

export type StatKind = typeof allStatKinds[number];
