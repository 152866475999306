import { QueryClient, QueryKey } from 'react-query';

import { Identifiable } from 'types/misc';

import { CacheType } from './types';
import { addOrUpdateObjectToListDataCache } from './caches/listData';
import { addOrUpdateObjectToObjectDataCache } from './caches/objectData';
import { cacheTypeForQueryKey } from './utils';

export function addOrUpdateObjectToCache<T extends Identifiable>({
  object,
  queryClient,
  queryKey,
}: {
  object: T;
  queryClient: QueryClient;
  queryKey: QueryKey;
}) {
  const cacheType = cacheTypeForQueryKey({ queryClient, queryKey });
  switch (cacheType) {
    case CacheType.Object:
      addOrUpdateObjectToObjectDataCache({ object, queryClient, queryKey });
      break;
    case CacheType.List:
      addOrUpdateObjectToListDataCache({
        object,
        queryClient,
        queryKey,
      });
      break;
    default:
      // No cache for this query key.
      break;
  }
}

export function addOrUpdateObjectToCaches<T extends Identifiable>({
  object,
  queryClient,
  queryKeys,
}: {
  object: T;
  queryClient: QueryClient;
  queryKeys: QueryKey[];
}) {
  queryKeys.forEach(queryKey =>
    addOrUpdateObjectToCache({ object, queryClient, queryKey }),
  );
}

export function addOrUpdateObjectsToCache<T extends Identifiable>({
  objects,
  queryClient,
  queryKey,
}: {
  objects: T[];
  queryClient: QueryClient;
  queryKey: QueryKey;
}) {
  objects.forEach(object =>
    addOrUpdateObjectToCache({ object, queryClient, queryKey }),
  );
}
