import { Helmet } from 'react-helmet-async';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { fr } from 'date-fns/locale';
import {
	fetchFiscalArchivesList,
	generateFiscalArchives,
} from 'app/services/fiscalArchives';
import { institutesState } from 'app/atoms';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import styledHtml from 'styled-components/macro';
import { FiscalArchiveFile, FiscalArchivesList } from './types';
import { FiscalArchive } from './components/FiscalArchive';
import { FiscalArchiveList } from './components/FiscalArchiveList';

export const FiscalArchivesPage = () => {
	const institutes = useRecoilValue(institutesState);

	// Set startDate to yesterday
	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);
	const [startDate, setStartDate] = useState<Date | null>(yesterday);
	// Set endDate to today
	const [endDate, setEndDate] = useState<Date | null>(new Date());

	const [fiscalArchivesList, setFiscalArchivesList] =
		useState<FiscalArchivesList | null>(null);
	const monthlyArchives = fiscalArchivesList?.month ?? [];
	const yearlyArchives = fiscalArchivesList?.year ?? [];

	function onDateSubmit() {
		if (!institutes || !institutes.currentInstituteId) {
			alert("Pas d'institut trouvé");
			return;
		}
		if (!startDate || !endDate) {
			alert('Pas de date sélectionnée');
			return;
		}
		generateFiscalArchives({
			instituteId: institutes.currentInstituteId,
			start: startDate,
			end: endDate,
		});
	}

	useEffect(() => {
		if (!institutes.currentInstituteId) {
			return;
		}
		fetchFiscalArchivesList({
			instituteId: institutes.currentInstituteId,
		}).then(data => {
			setFiscalArchivesList(data);
		});
	}, [institutes.currentInstituteId]);

	return (
		<>
			<Helmet>
				<title>Fiscal Archives</title>
			</Helmet>
			<PageWrapper header={<PageHeader label={'Archives Fiscales'} />}>
				<p>
					Vous pouvez télécharger une archive fiscale de période (mois) ou d'exercice
					(année) sur cette page
				</p>
				<p>Vous pouvez aussi générer une archive fiscale de date à date</p>
				<FiscalArchiveList
					title="Archives Fiscales de Période"
					fiscalArchivesList={monthlyArchives}
				/>
				<FiscalArchiveList
					title="Archives Fiscales d'Exercice"
					fiscalArchivesList={yearlyArchives}
				/>

				<h2>Générer une archive fiscale</h2>
				<DateWrapper>
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
						<DatePicker
							value={startDate}
							onChange={d => {
								setStartDate(d);
							}}
							renderInput={params => <TextField {...params} />}
							maxDate={new Date()}
						/>
					</LocalizationProvider>
				</DateWrapper>
				<DateWrapper>
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
						<DatePicker
							value={endDate}
							onChange={d => {
								setEndDate(d);
							}}
							renderInput={params => <TextField {...params} />}
							maxDate={new Date()}
						/>
					</LocalizationProvider>
				</DateWrapper>
				<div>
					<DownloadButton variant="contained" onClick={() => onDateSubmit()}>
						Télécharger l'archive fiscale
					</DownloadButton>
				</div>
			</PageWrapper>
		</>
	);
};

const DateWrapper = styledHtml.div`
	margin-bottom: 15px;
`;

const DownloadButton = styled(Button)({
	background: '#e462a1',
	border: 'none',
	padding: '7px',
	borderRadius: '10px',
	color: 'white',
	'&:hover': {
		backgroundColor: '#ed7eb4',
	},
});
