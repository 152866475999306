import { QueryClient, QueryKey } from 'react-query';

import { Identifiable } from 'types/misc';

import { CacheType } from './types';
import { cacheTypeForQueryKey } from './utils';
import { removeObjectFromListDataCache } from './caches/listData';
import { removeObjectFromObjectDataCache } from './caches/objectData';

export function removeObjectFromCache<T extends Identifiable>({
  object,
  queryClient,
  queryKey,
}: {
  object: T;
  queryClient: QueryClient;
  queryKey: QueryKey;
}) {
  const cacheType = cacheTypeForQueryKey({ queryClient, queryKey });
  switch (cacheType) {
    case CacheType.Object:
      removeObjectFromObjectDataCache({ queryClient, queryKey });
      break;
    case CacheType.List:
      removeObjectFromListDataCache({ object, queryClient, queryKey });
      break;
    default:
      // No cache for this query key.
      break;
  }
}

export function removeObjectFromCaches<T extends Identifiable>({
  object,
  queryClient,
  queryKeys,
}: {
  object: T;
  queryClient: QueryClient;
  queryKeys: QueryKey[];
}) {
  queryKeys.forEach(queryKey => {
    removeObjectFromCache({ object, queryClient, queryKey });
  });
}
