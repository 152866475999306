import {
  Bar,
  BarChart as DefaultBarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { BarChartDescription } from 'app/pages/Stats/StatsPage/types/charts';
import { PropertyMapping } from 'app/pages/Stats/StatsPage/types';

import { usePropertyMapping } from '../../../hooks';

interface Props {
  description: BarChartDescription;
  propertyMapping: PropertyMapping;
  values: any[];
}

export const BarChart = (props: Props) => {
  const { description, propertyMapping, values } = props;

  const { propertyLabel } = usePropertyMapping({ propertyMapping });

  return (
    <ResponsiveContainer>
      <DefaultBarChart data={values}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={propertyLabel(description.xAxisProperty)} />
        <YAxis />
        <Tooltip />
        <Legend />
        {description.bars.map(bar => (
          <Bar
            key={bar.property}
            dataKey={propertyLabel(bar.property)}
            fill={bar.color}
          />
        ))}
      </DefaultBarChart>
    </ResponsiveContainer>
  );
};
