import React, { ChangeEvent, KeyboardEvent } from 'react';
import styled from 'styled-components/macro';
import { InputAdornment } from '@material-ui/core';

import { TextInput as DefaultTextInput } from 'app/components/Inputs/Uncontrolled/TextInput';
import { ReactComponent as SearchIcon } from 'app/assets/search.svg';

export type SearchEvent =
  | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  | KeyboardEvent<HTMLDivElement>;

interface Props {
  disabled?: boolean;
  className?: string;
  onSearch: (event: SearchEvent) => void;
  placeholder: string;
}

export const SearchInput = ({
  disabled,
  onSearch,
  placeholder,
  ...rest
}: Props) => (
  <TextInput
    autoComplete="off"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
    onKeyUp={onSearch}
    onChange={onSearch}
    placeholder={placeholder}
    {...rest}
  />
);

export const TextInput = styled(DefaultTextInput)`
  & .MuiInputBase-root {
    background-color: transparent;
  }
  & .MuiInput-underline:before {
    border-bottom: 1px solid ${p => p.theme.input.border.placeholder};
    opacity: 0.5;
  }
`;
