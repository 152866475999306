import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { messages as commonMessages } from 'app/i18n/messages';
import { usePriceFormatter } from 'utils/formatters/hooks/price';
import { Discount } from 'types/API/discounts';

type Options = Discount.Amount & { price?: number };

export const useDiscountValueFormatter = ({
  currency,
}: {
  currency: string;
}) => {
  const { formatPrice, formatDiscountPrice } = usePriceFormatter({ currency });

  const { t } = useTranslation();

  const formatDiscountValue = useCallback(
    ({ discountType, discountValue, price }: Options) => {
      if (discountValue && discountValue > 0) {
        if (discountType === Discount.Type.PERCENT) {
          return t(commonMessages.discountPercentFormat(), {
            percent: discountValue,
          });
        }
        return formatDiscountPrice(discountValue);
      } else if (price && price > 0) {
        return formatPrice(price);
      }
      return '-';
    },
    [formatDiscountPrice, formatPrice, t],
  );
  return { formatDiscountValue };
};
