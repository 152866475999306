import { useState, useEffect } from 'react';
import style from 'styled-components/macro';
import { LoadableContent } from 'app/components/LoadableContent';
import { EmptyState } from 'app/components/EmptyState';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import randomColor from "randomcolor"; //https://medium.com/officialrajdeepsingh/best-way-to-generate-a-random-color-in-react-js-and-build-a-color-project-222e3dff3571
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Bar } from 'react-chartjs-2';
import { InlineDatePicker } from './components/StatsContent/components/inlineDatePicker';
import { Stats, CashBookPerTicketService } from 'types/API/stats';
import { request } from 'utils/request';

import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

interface PieValue {
  title: string;
  value: number;
  color: string;
}

interface StatsValue {
  title: string;
  price: number;
}

interface BarValue {
  label: string,
  data: number,
  backgroundColor: string,
}

const barOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Visualisation',
    },
  },
};

export const TopServicePerUser = (props) => {

  const { institute } = props;

  let lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 30);
  var date = new Date();
  let firstDayOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  const [startDate, setStartDate] = useState(firstDayOfTheMonth);
  const [endDate, setEndDate] = useState(new Date());

  const DropdownWrapper = style.div`
    margin-top: 20px;
    margin-bottom: 20px;
  `;

  const PieDiv = style.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; 
    height: 100%;
  `;

  const [userSelected, setSelectedUser] = useState()
  const [prestas, setPrestas] = useState<Array<StatsValue>>([])

  const [total, setTotal] = useState(0)
  const [pieValues, setPieValues] = useState<Array<PieValue>>([])

  const propertyMapping = { value: 'Prestation', price: 'CA' }

  const [barData, setBarData] = useState<{ labels: string[], datasets: any[] }>({ labels: [], datasets: [] });



  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState<Stats.Values[]>();

  function getUnique(arr, index) {

    const unique = arr
      .map(e => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }

  const options = [...new Set(data?.map(user => { return user.userPaid.replace('_', ' ') }) || [])] 

  const loadData = async (start: Date, end: Date) => {
    setStartDate(start)
    setEndDate(end)
    if (!loading) {
      setLoading(true)

      fetchBookPerUserStats({ instituteId: institute.id, start: start, end: end }).then(data => {
        setLoading(false)
        setData(data)
      }).catch(error => {
        setLoading(false)
        setError(error)
      })
      
    }
  }

  useEffect(() => {
    loadData(startDate, endDate);
    return () => { };
  }, [institute]);

  const setBarDataForSelectedEsthet = () => {
    if (data) {

      var userTickets: any = data.filter(stats => {
        return stats.userPaid === userSelected
      })

      if (userTickets) {

        var servicesWithDuplicates: Array<CashBookPerTicketService> = []
        var services = {}

        userTickets.forEach(user => {
          servicesWithDuplicates = servicesWithDuplicates.concat(user.service);
        })

        servicesWithDuplicates.forEach(service => {

          if (services.hasOwnProperty(service.title)) {
            services[service.title] += service.price
          } else {
            services[service.title] = service.price
          }
          
        })

        const headers = Object.keys(services);
        console.log(headers)

        const barVal: BarValue[] = headers.map(ser => {
          const result: BarValue = { label: ser, data: Math.round(Number(services[ser])), backgroundColor: 'rgba(255, 99, 132, 0.5)' }
          return result
        })

        var fluxNames = barVal.map(val => {
          return val.label
        })

        var values = barVal.map(val => {
          return val.data
        })

        const barData = {
          labels: fluxNames,
          datasets: [
            {
              label: 'Chiffre d\'affaires réalisé par prestation',
              data: values,
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
          ],
        }

        setBarData(barData);
        
      }

    }
  }

  useEffect(() => {
    setBarDataForSelectedEsthet();
    return () => { };
  }, [userSelected]);

  const onSelect = (option) => {
    console.log('You selected ', option.label)
    setSelectedUser(option.label)

    if (data) {
      var user: any = data.filter(stats => {
        return stats.userPaid.replace('_', ' ') === option.label
      })

      var servicesWithDuplicates: Array<CashBookPerTicketService> = []
      var servicesJSON = {}

      user.forEach(user => {
        servicesWithDuplicates = servicesWithDuplicates.concat(user.service);
      })

      servicesWithDuplicates.forEach(service => {

        if (servicesJSON.hasOwnProperty(service.title)) {
          servicesJSON[service.title] += service.price
        } else {
          servicesJSON[service.title] = service.price
        }

      })

      const headers = Object.keys(servicesJSON);

      var services = headers.map(key => {
        return { title: key, price: servicesJSON[key] }
      })

      if (services.length > 1) {
        const total = services.reduce((total, num) => total + num.price, 0)
        setTotal(total)
      } else {
        if (services.length == 1) {
          setTotal(services[0].price)
        }
        
      }
      setPrestas(services)
    }
  }



  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#cbcffe',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <div>
      {/* <PieChart description={pieDesc} propertyMapping={pieMapping} values={pieValues}></PieChart> */}
      <InlineDatePicker start={startDate} end={endDate} max={Date()} action={loadData} ></InlineDatePicker>

      <LoadableContent
        error={error}
        isLoading={loading}
        isEmpty={data?.length === 0}
        emptyNodeRenderer={() => (
          <EmptyState label="Aucun résultat" />
        )}
      >
        <DropdownWrapper>
          <Dropdown options={options} onChange={onSelect.bind(this)} placeholder="Choisissez une esthet" value={userSelected} />
        </DropdownWrapper>

        {prestas.length > 0 &&
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Prestations</StyledTableCell>
                  <StyledTableCell align="right">prix</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prestas.map((row) => (
                  <StyledTableRow key={row.title.toLowerCase()}>
                    <StyledTableCell component="th" scope="row">
                      {row.title.toLowerCase()}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.price.toFixed(2)}€</StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow key="total">
                  <StyledTableCell component="th" scope="row">
                    <b>Total</b>
                  </StyledTableCell>
                  <StyledTableCell align="right">{total.toFixed(2)}€</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        }

        {/* 
        {total > 0 &&
          <p>Total {total}€</p>
        } */}


        {/* <PieDiv>
          <PieChart style={{ width: 650, height: 650 }}
            data={pieValues}
            label={({ dataEntry }) => dataEntry.title}
            labelStyle={{
              fontSize: '3px',
              fontFamily: 'sans-serif',
              color: '#fff',
            }}
          />
        </PieDiv> */}

        <Bar options={barOptions} data={barData} />



      </LoadableContent>
    </div>


  );
}

const fetchBookPerUserStats = async ({ instituteId, start, end }: { instituteId: number, start: Date, end: Date }) => {
  let oneOClockStartDate = new Date(start.setHours(7, 0, 0, 0))
  let elevenOClockEndDate = new Date(end.setHours(23, 0, 0, 0))
  
  const response = request<Stats.Values>(`api/stat/ticket/book`, {
    method: 'POST',
    data: { "instituteId": instituteId, "startDate": oneOClockStartDate, "endDate": elevenOClockEndDate, cancel: false },
  });
  return response;
};