import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { Option } from 'react-dropdown';
import styled from 'styled-components/macro';

import { Box } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import {
  InputToggleButtons,
  ToggleButton,
} from 'app/components/Inputs/InputToggleButtons';
import { SelectInputOption } from 'app/components/Inputs/SelectInput';
import { DiscountInput } from 'app/pages/SpecialOffers/components/DiscountInput';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { Discount } from 'types/API/discounts';

import { messages } from '../../../messages';
import { PriceInput } from './PriceInput';
import { TextInput } from 'app/components/Inputs/TextInput';
import { getCurrencySymbol } from 'utils/currency';

interface Props {
  currency: string;
  disabled: boolean;
  discountAmountOptionsMap: Map<Discount.Type, SelectInputOption[]>;
  priceOptions: SelectInputOption[];
}

export const Price = ({
  currency,
  disabled,
  discountAmountOptionsMap,
  priceOptions,
}: Props) => {
  const { t } = useTranslation();

  const { watch } = useFormContext();

  const priceMode = watch('priceMode');
  const [otherPriceValueForDiscountPriceMode, setOtherPriceValueForDiscountPriceMode] = useState('');
  const [otherPriceValueForFixedPriceMode, setOtherPriceValueForFixedPriceMode] = useState('');

  const currencySymbol = getCurrencySymbol(currency);

  const [togglePriceModeChanged, setTogglePriceModeChanged] = useState(false);
  const [customDiscountOptionSelected, setCustomDiscountOptionSelected] =
    useState(false);
  const [discountOptionSelected, setDiscountOptionSelected] = useState('');
  const [discountTypeSelected, setDiscountTypeSelected] = useState('percent');
  const [displayCustomPriceFieldForDiscountPriceMode, setDisplayCustomPriceFieldForDiscountPriceMode] = useState(false);
  const [displayCustomPriceFieldForFixedPriceMode, setDisplayCustomPriceFieldForFixedPriceMode] = useState(false);

  const shouldListShowOtherPrice = true;
  const discountType = watch('discountType');

  const discountAmountOptions = useMemo(() => {
    var myList = discountAmountOptionsMap.get(discountType)!;
    if (shouldListShowOtherPrice) {
      let customOption1 = myList?.filter(
        option => option.id === 'customOption',
      );
      if (customOption1 !== undefined && customOption1.length === 0) {
        let customOption = {
          id: 'customOption',
          label: 'Montant spécifique',
        };
        myList.unshift(customOption);
      }
    }
    return myList;
  }, [discountAmountOptionsMap, discountType]);

  const fixedAmountOptions = useMemo(() => {
    var myList = priceOptions;
    if (shouldListShowOtherPrice) {
      let customOption1 = myList?.filter(
        option => option.id === 'customOption',
      );
      if (customOption1 !== undefined && customOption1.length === 0) {
        let customOption = {
          id: 'customOption',
          label: 'Montant spécifique',
        };
        myList.unshift(customOption);
      }
    }
    return myList;
  }, [discountAmountOptionsMap, discountType]);

  const onDiscountChange = (arg: Option) => {
    console.log(`option.label:${arg.label}, option.value:${arg.value}`);
    let discountOptionSelected1 = arg.value.includes('Montant spécifique');
    let label: string = arg.value;
    console.log(
      `onDayChangeonDayChange onDayChange:${arg.value}, equal:${discountOptionSelected1}`,
    );
    setCustomDiscountOptionSelected(discountOptionSelected1);
    setDiscountOptionSelected(label);
  };

  const onDiscountChange2 = (arg: string, comingFrom: 'FixedPriceMode' | 'DiscountPriceMode') => {
    console.log(`onDiscountChange2-->arg:${arg}, comingFrom:${comingFrom}}`);
    let isAutreSelected = arg.includes('Montant spécifique');
    setDisplayCustomPriceFieldForFixedPriceMode(isAutreSelected);
//    setDisplayCustomPriceField(isAutreSelected);
    if (!isAutreSelected) {
      //setOtherPriceValue(formatCustomValue(arg.value));
    }
  };

  const onDiscountChangeFromDiscountPriceMode = (arg: string) => {
    let isAutreSelected = arg.includes('customOption');
    console.log(`onDiscountChangeFromDiscountPriceMode-->arg:${arg}, isAutreSelected:${isAutreSelected}`);
    setOtherPriceValueForDiscountPriceMode('');
    setOtherPriceValueForFixedPriceMode('');
    setDisplayCustomPriceFieldForDiscountPriceMode(isAutreSelected);
//    setDisplayCustomPriceField(isAutreSelected);
  };

  const onDiscountChangeFromFixedPriceMode = (arg: string) => {
    let isAutreSelected = arg.includes('customOption');
    console.log(`onDiscountChangeFromFixedPriceMode-->arg:${arg}, isAutreSelected:${isAutreSelected}`);
    setDisplayCustomPriceFieldForFixedPriceMode(isAutreSelected);
    //setDisplayCustomPriceField(isAutreSelected);
  };

  const onTogglePriceModeChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string,
  ) => {
    console.log(
      `onTogglePriceModeChange event.target:${event.target}, value:${value}`,
    );
    setTogglePriceModeChanged(true);
  };

  const discountTypeChanged = (value: string) => {
    setDiscountTypeSelected(value);
    console.log(
      `discountTypeChanged, value:${value}, discountTypeSelected:${discountTypeSelected}`,
    );
  };

  const formatCustomValue = (value: string): string => {
    //Strip all non-numeric characters from string
    //Regex.Replace(s, "[^0-9.]", "");
    return value.replace(/[^0-9$.]/g, ''); /*replace(/[^0-9$.,]/g, '');*/ //.replace(/\D/g, '');
  };

  const handleTextInputChangeForFixedPriceMode = (value: string) => {
    setOtherPriceValueForFixedPriceMode(formatCustomValue(value));
  };

  const handleTextInputChangeForDiscountPriceMode = (value: string) => {
    var newValue = formatCustomValue(value);
    //convert it to float and check if it is > 50, if it is change value to 50
    let floatValue = Math.abs(parseFloat(newValue));
    if (floatValue > 50) newValue = '50';
    newValue = newValue.length > 0 ? '-' + newValue : newValue;
    setOtherPriceValueForDiscountPriceMode(newValue);
  };

  return (
    <Grid container spacing={1}>
      <FormInputGrid item sm={12}>
        <Box display="flex">
          <InputToggleButtons
            name="priceMode"
            onChange={onTogglePriceModeChange}
          >
            <ToggleButton value="fixed">
              {t(messages.priceMode.fixed())}
            </ToggleButton>
            <ToggleButton value="discount">
              {t(messages.priceMode.discount())}
            </ToggleButton>
          </InputToggleButtons>
        </Box>
      </FormInputGrid>
      {priceMode === 'fixed' && (
        <FormInputGrid item sm={12}>
          <PriceInput
            disabled={disabled}
            label={t(messages.price.label())}
            name="price"
            options={fixedAmountOptions}//{priceOptions}
            onDiscountChange={onDiscountChangeFromFixedPriceMode}
            placeholder={t(messages.price.placeholder())}
            required
          />

          {shouldListShowOtherPrice && displayCustomPriceFieldForFixedPriceMode && (
              <TextInputWrapper isForFixedAmount={true}>
                <TextInput
                  className="autreMontant"
                  disabled={disabled}
                  name="autreMontantForFixedPriceMode"
                  handleChange1={handleTextInputChangeForFixedPriceMode}
                  value={otherPriceValueForFixedPriceMode}
                  placeholder={t(messages.discountAmount.placeholderSetAnotherPrice())}
                />
                <div className="autreMontantDiscountType">{currencySymbol}</div>
              </TextInputWrapper>
            )}

          {/*shouldListShowOtherPrice && displayCustomPriceField && (
            <TextInput
              name="CustomDiscountValue"
              placeholder={t(
                messages.discountAmount.placeholderSetAnotherPrice(),
              )}
            />
          )*/}
        </FormInputGrid>
      )}
      {priceMode !== 'fixed' && (
        <FormInputGrid item sm={12}>
          <DiscountInput
            currency={currency}
            disabled={disabled}
            label={t(messages.discountAmount.label())}
            name="discountValue"
            selectedDiscountValue={discountOptionSelected}
            togglePriceModeChanged={togglePriceModeChanged}
            options={discountAmountOptions}
            placeholder={t(messages.discountAmount.placeholder())}
            placeholderSetPrice={t(
              messages.discountAmount.placeholderSetPrice(),
            )}
            //required
            onDiscountChange={onDiscountChangeFromDiscountPriceMode}
            discountTypeChanged={discountTypeChanged}
            customDiscountOptionSelected={customDiscountOptionSelected}
            //value={[discountOptionSelected]}
            toggleName="discountType"
            hasCustomPrice={shouldListShowOtherPrice}
          />

          {shouldListShowOtherPrice && displayCustomPriceFieldForDiscountPriceMode && (
              <TextInputWrapper isForFixedAmount={false}>
                <TextInput
                  className="autreMontant"
                  disabled={disabled}
                  name="autreMontantForDiscountPriceMode"
                  handleChange1={handleTextInputChangeForDiscountPriceMode}
                  value={otherPriceValueForDiscountPriceMode}
                  placeholder={
                    discountTypeSelected === 'percent'
                      ? t(
                          messages.discountAmount.placeholderSetAnotherDiscountPercentage(),
                        )
                      : t(messages.discountAmount.placeholderSetAnotherPrice())
                  }
                />
                <div className="autreMontantDiscountType">{discountTypeSelected === 'percent' ? '%' : currencySymbol}</div>
              </TextInputWrapper>
            )}
        </FormInputGrid>
      )}
    </Grid>
  );
};
interface ContentProps {
  isForFixedAmount: boolean;
}
const TextInputWrapper = styled.div<ContentProps>`
    margin-left:  ${p => (p.isForFixedAmount ? '0rem' : '8.5rem')};
    fmargin-left: 8.5rem;
    width: 250px;
    height: 150px;
    display: block;
    dbackground-color: red;
    tmext-align: inline-block;
    lpadding-top:10px;

    > .autreMontant {
      kdisplay: inline-block;
      width: 185px;
      margin-right: 5px;
    }

    > .autreMontant > * {
      kdisplay: inline-block;
      font-size: 1.0rem;    
    }

    > .autreMontantDiscountType {
      padding-top:18px;
      display: inline-block;
      width: 50px;
      ffont-weight:bold;
      text-align: left;
      fbackground-color: green;

    }
  }
`;