import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components/macro';
import { Grid } from '@material-ui/core';

import { Button } from 'app/components/Button';
import { FormSection } from 'app/components/Forms/FormSection';
import { TextInput } from 'app/components/Inputs';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { FormLabel } from 'app/components/Forms/FormLabel';

import { messages } from '../../messages';

interface Props {
  canSubmit: boolean;
  isSubmitting: boolean;
}

export const Form = (props: Props) => {
  const { canSubmit, isSubmitting } = props;
  const { t } = useTranslation();

  return (
    <Wrapper>
      <FormSection title={t(messages.sections.general())}>
        <Grid container spacing={1}>
          <FormInputGrid item sm={12}>
            <FormLabel>{t(messages.password.label())}</FormLabel>
            <TextInput
              disabled={isSubmitting}
              name="password"
              placeholder={t(messages.password.placeholder())}
              required
              type="password"
            />
          </FormInputGrid>
        </Grid>
      </FormSection>
      <Button
        className="submit"
        disabled={!canSubmit}
        isSpinning={isSubmitting}
        type="submit"
      >
        {t(messages.submit())}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .submit {
    margin-top: 3rem;
    float: right;
  }
`;
