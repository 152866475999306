import DatePicker from 'react-datepicker';
import { useState } from 'react';
import { Button } from 'app/components/Button';
import styled from 'styled-components/macro';

interface Props {
	start;
	end;
	max;
	action;
}

export const InlineDatePicker = (props: Props) => {
	const [startDate, setStartDate] = useState(props.start);
	const [endDate, setEndDate] = useState(props.end);

	return (
		<div>
			<span>Du</span>
			<DatePicker
				locale="fr"
				maxDate={props.max}
				dateFormat="dd/MM/yyyy"
				selected={startDate}
				onChange={date => setStartDate(date)}
			/>
			<span>au</span>
			<DatePicker
				locale="fr"
				maxDate={props.max}
				dateFormat="dd/MM/yyyy"
				selected={endDate}
				onChange={date => setEndDate(date)}
			/>
			<div> </div>
			<DatePickerTriggerButton
				type="input"
				value="Afficher les résultats"
				onClick={() => props.action(startDate, endDate)}
			/>
		</div>
	);
};

const DatePickerTriggerButton = styled.input`
	background: #e462a1;
	border: none;
	padding: 10px;
	border-radius: 20px;
	color: white;
	margin-top: 10px;
	margin-bottom: 20px;
	width: 195px;
`;
