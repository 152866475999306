import { Route } from 'types/route';
import { ManualPage } from 'app/pages/ManualPage';

import { paths } from '../../paths';

const manual: Route = {
  authenticated: true,
  content: ManualPage,
  routerPath: paths.manual.index,
};

export const routes = [manual];
