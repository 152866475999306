import styled from 'styled-components/macro';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { SelectInput } from 'app/components/Inputs';
import {
  InputToggleButtons,
  ToggleButton,
} from 'app/components/Inputs/InputToggleButtons';
import { SelectInputOption } from 'app/components/Inputs/SelectInput';
import { getCurrencySymbol } from 'utils/currency';
import Dropdown from 'react-dropdown';
import { useState } from 'react';
import { TextInput } from 'app/components/Inputs/TextInput';

interface Props {
  currency: string;
  disabled: boolean;
  label: string;
  name: string;
  selectedDiscountValue?: string;
  onDiscountChange?: (...event: any[]) => void;
  discountTypeChanged?: (value: string) => void;
  customDiscountOptionSelected?: boolean;
  hasCustomPrice: boolean;
  togglePriceModeChanged?: boolean;
  value?: string[];
  options: SelectInputOption[];
  placeholder: string;
  placeholderSetPrice?: string;
  required?: boolean;
  toggleName: string;
}

export const DiscountInput = (props: Props) => {
  const {
    currency,
    disabled,
    label,
    name,
    onDiscountChange,
    discountTypeChanged,
    customDiscountOptionSelected,
    hasCustomPrice,
    togglePriceModeChanged,
    selectedDiscountValue,
    value,
    options,
    placeholder,
    placeholderSetPrice,
    required,
    toggleName,
  } = props;
  const currencySymbol = getCurrencySymbol(currency);
  return (
    <>
      <Wrapper>
        <InputToggleButtons
          name={toggleName}
          discountTypeChanged={discountTypeChanged}
        >
          <ToggleButton value="percent">%</ToggleButton>
          <ToggleButton value="amount">{currencySymbol}</ToggleButton>
        </InputToggleButtons>

        {/*hasCustomPrice && (
          <SelectWrapper>
            <FormLabel>{label}</FormLabel>
            {(togglePriceModeChanged || !togglePriceModeChanged) && (
              <Dropdown
                options={options?.map(EL => EL.label)}
                onChange={onDiscountChange}
                placeholder={placeholder}
                value={selectedDiscountValue}
              />
            )}
            {customDiscountOptionSelected && (
              <TextInput name="description" placeholder={placeholderSetPrice} />
            )}
          </SelectWrapper>
            )*/}
        {hasCustomPrice && (
          <SelectWrapper>
            <FormLabel>{label}</FormLabel>
            <SelectInput
              disabled={disabled}
              name={name}
              onChange1={onDiscountChange}
              options={options}
              placeholder={placeholder}
              required={required}
            />
            {/*customDiscountOptionSelected && (
              <TextInput name="description" placeholder={placeholderSetPrice} />
            )*/}
          </SelectWrapper>
        )}

        {!hasCustomPrice && (
          <SelectWrapper>
            <FormLabel>{label}</FormLabel>
            <SelectInput
              disabled={disabled}
              name={name}
              onChange1={onDiscountChange}
              options={options}
              placeholder={placeholder}
              required={required}
            />
          </SelectWrapper>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;

  > :first-child {
    margin-right: 1rem;
  }
`;
const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
