import { useQuery } from 'app/hooks';
import { Optional } from 'types/misc';
import { genericQueryKey } from 'utils/cache/keys';
import { FetchMode } from 'app/services/discounts';
import { fetchCuresRequest } from 'app/services/cures';
import { fetchProductsRequest } from 'app/services/products';
import { useMemo } from 'react';

export const useQueries = ({
  instituteId,
  cureId,
}: {
  instituteId: number;
  cureId: Optional<number>;
}) => {
  const curesParameters = { instituteId, fetchMode: FetchMode.ALL };

  const {
    data: cures,
    error: curesError,
    isLoading: isLoadingCures,
  } = useQuery(
    genericQueryKey({
      identifier: 'cures',
      parameters: curesParameters,
    }),
    () => fetchCuresRequest(curesParameters),
    { enabled: !!cureId },
  );

  const cure = useMemo(() => {
    return cures?.find(candidate => candidate.id === cureId);
  }, [cures, cureId]);

  const productsParameters = { instituteId };

  const {
    data: products,
    error: productsError,
    isLoading: isLoadingProducts,
  } = useQuery(
    genericQueryKey({
      identifier: 'products',
      parameters: productsParameters,
    }),
    () => fetchProductsRequest(productsParameters),
  );

  const error = curesError ?? productsError;
  const isLoading = isLoadingCures || isLoadingProducts;

  return { cure, error, isLoading, products };
};
