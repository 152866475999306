import { Menu as MenuIcon } from '@material-ui/icons';
import React, { useState } from 'react';

import styled from 'styled-components/macro';

import { IconButton } from 'app/components/IconButton';
import { ReactComponent as Logo } from 'app/assets/logo.svg';
import { Drawer } from '../Drawer';

const Wrapper = styled.div`
  padding: 1rem;

  color: ${({ theme }) => theme.text.bright};

  .logo {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
  }
`;

interface Props {
  menuEnabled?: boolean;
}
export const NavigationBar = ({ menuEnabled = true }: Props) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  return (
    <Wrapper>
      {menuEnabled ? (
        <>
          <IconButton onClick={toggleDrawer(!isDrawerOpen)}>
            <MenuIcon />
          </IconButton>
          <Drawer
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          />
        </>
      ) : null}
      <Logo className="logo" />
    </Wrapper>
  );
};
