import { TillsPage } from 'app/pages/Tills/TillsPage';
import { TillPage } from 'app/pages/Tills/TillPage';
import { Route } from 'types/route';

import { paths } from '../../paths';

const tills: Route = {
  authenticated: true,
  content: TillsPage,
  routerPath: paths.tills.index,
};

const updateTill: Route = {
    authenticated: true,
    content: TillPage,
    routerPath: paths.tills.update,
  };

export const routes = [updateTill, tills];
