import { Optional } from 'types/misc';

export function enabledIfSet<T>(
  value: Optional<T>,
  renderer: (value: T) => JSX.Element,
) {
  if (value) {
    return renderer(value);
  }
  return null;
}
