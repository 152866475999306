import { useQueryClient } from 'react-query';
import { useCallback, useMemo } from 'react';
import { first } from 'lodash';

import { Customer } from 'types/API/customers';
import { Subscription } from 'types/API/subscriptions';
import {
  createSubscriptionPaymentRequest,
  updateSubscriptionPaymentRequest,
} from 'app/services/subscriptions/payments';
import { genericQueryKey } from 'utils/cache/keys';
import { updateCustomerRequest } from 'app/services/customers';
import { Optional } from 'types/misc';
import {
  addOrUpdateObjectToCache,
  getObjectFromCache,
  removeObjectFromCache,
} from 'utils/react-query/cache';
import { updateSubscriptionRequest, resiliateSubscriptionRequest } from 'app/services/subscriptions';
import { useMutation } from 'app/hooks';

export const useMutations = (instituteId: number) => {
  const queryClient = useQueryClient();

  const parameters = useMemo(() => ({ instituteId }), [instituteId]);

  const updateSubscription = useCallback(
    async ({
      customerId,
      customerPayload,
      isDeptingSubscription,
      subscriptionId,
      subscriptionPayload,
      subscriptionPaymentId,
      subscriptionPaymentPayload,
    }: {
      customerId: number;
      customerPayload: Customer.Payload;
      isDeptingSubscription: boolean;
      subscriptionId: Optional<number>;
      subscriptionPayload: Optional<Subscription.Payload>;
      subscriptionPaymentId: Optional<number>;
      subscriptionPaymentPayload: Subscription.Payment.Payload;
    }) => {
      let subscriptionPayment: Optional<Subscription.Payment.Normal>;
      if (isDeptingSubscription) {
        if (subscriptionPaymentId) {
          subscriptionPayment = await updateSubscriptionPaymentRequest({
            subscriptionPaymentId,
            data: subscriptionPaymentPayload,
          });
        } else {
          subscriptionPayment = await createSubscriptionPaymentRequest({
            data: subscriptionPaymentPayload,
          });
        }
      }

      let subscriptionEndDate: Optional<any>;
      if (subscriptionPayload?.resiliate && subscriptionId) {
         subscriptionEndDate = await resiliateSubscriptionRequest({
          subscriptionId
        })
      }
      
      //console.log("subscriptionPayload:", subscriptionPayload);
      let subscription: Optional<Subscription.Normal>;
      if (subscriptionId && subscriptionPayload) {
        subscription = await updateSubscriptionRequest({
          subscriptionId,
          data: subscriptionPayload,
        });
      }



      const customer = await updateCustomerRequest({
        customerId,
        data: customerPayload,
      });
      return { customer, subscription, subscriptionPayment };
    },
    [],
  );

  const { mutate: updateSubscriptionMutation, isLoading: isUpdating } =
    useMutation(updateSubscription, {
      onSuccess: response => {
        const { customer, subscription, subscriptionPayment } = response;
        const { id: customerId } = customer;

        // Remove customer from the pending subscribers cache (if present)
        updatePendingSubscribersCache({ customerId });

        // Update customer in subscribers list cache.
        updateSubscribersCache({ customer });

        // Update customer in subscriber obejct cache.
        updateSubscriberCache({ customer, subscription, subscriptionPayment });
      },
    });

  const updatePendingSubscribersCache = useCallback(
    ({ customerId }: { customerId: number }) => {
      const pendingCustomersQueryKey = genericQueryKey({
        identifier: 'pendingSubscribers',
        parameters,
      });
      removeObjectFromCache({
        object: { id: customerId },
        queryClient,
        queryKey: pendingCustomersQueryKey,
      });
    },
    [parameters, queryClient],
  );

  const updateSubscribersCache = useCallback(
    ({ customer }: { customer: Customer.Normal }) => {
      const customersQueryKey = genericQueryKey({
        identifier: 'subscribers',
        parameters,
      });

      const customerFromCustomersCache: Optional<Customer.Normal> =
        getObjectFromCache({
          predicate: candidate => candidate.id === customer.id,
          queryClient,
          queryKey: customersQueryKey,
        });
      if (customerFromCustomersCache) {
        const updatedCustomerForCustomersCache = {
          ...customer,
          subscriptions: customerFromCustomersCache.subscriptions,
        };
        addOrUpdateObjectToCache({
          object: updatedCustomerForCustomersCache,
          queryClient,
          queryKey: customersQueryKey,
        });
      }
    },
    [parameters, queryClient],
  );

  const updateSubscriberCache = useCallback(
    ({
      customer,
      subscription,
      subscriptionPayment,
    }: {
      customer: Customer.Normal;
      subscription: Optional<Subscription.Normal>;
      subscriptionPayment: Optional<Subscription.Payment.Normal>;
    }) => {
      const customerQueryKey = genericQueryKey({
        identifier: 'customer',
        parameters: { customerId: customer.id },
      });

      const customerFromCustomerCache: Optional<Customer.Normal> =
        getObjectFromCache({
          predicate: candidate => candidate.id === customer.id,
          queryClient,
          queryKey: customerQueryKey,
        });

      if (customerFromCustomerCache) {
        const subscriptionFromCustomerCache = first(
          customerFromCustomerCache.subscriptions,
        ) as Subscription.Normal;

        const updatedSubscription = {
          ...subscriptionFromCustomerCache,
          rejection:
            subscription?.rejection ?? subscriptionFromCustomerCache.rejection,
          subscriptionPayments: subscriptionPayment
            ? [subscriptionPayment]
            : subscriptionFromCustomerCache?.subscriptionPayments,
        };
        const updatedCustomerForCustomerCache = {
          ...customerFromCustomerCache,
          ...customer,
          subscriptions: [updatedSubscription],
        };
        addOrUpdateObjectToCache({
          object: updatedCustomerForCustomerCache,
          queryClient,
          queryKey: customerQueryKey,
        });
      }
    },
    [queryClient],
  );
  return {
    isUpdating,
    updateSubscriptionMutation,
  };
};
