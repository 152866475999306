import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components/macro';

type FormSectionProps = PropsWithChildren<{
  id?: string;
  title: string;
}>;

export const FormSection = ({ children, title, ...rest }: FormSectionProps) => {
  return (
    <DefaultFormSection
      title={<FormSectionTitle>{title}</FormSectionTitle>}
      {...rest}
    >
      {children}
    </DefaultFormSection>
  );
};

type DefaultFormSectionProps = PropsWithChildren<{
  id?: string;
  title: ReactNode;
}>;

export const DefaultFormSection = ({
  children,
  title,
  ...rest
}: DefaultFormSectionProps) => {
  return (
    <Wrapper {...rest}>
      {title}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 3rem;
`;

export const FormSectionTitle = styled.h3`
  margin-block: 0 1rem;
  color: ${p => p.theme.text.title}; ;
`;
