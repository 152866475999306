import React, { useMemo } from 'react';
import { ListItem, ListItemProps } from 'app/components/ListItem';
import { Institute } from 'types/API/institutes';
import { useTranslation } from 'react-i18next';
import { messages } from '../../messages';
import { EntriesWrapper, InfoEntry } from 'app/components/ListItem/InfoEntry';

interface Props extends ListItemProps {
  till: Institute.Till;
}

export const TillsItem = (props: Props) => {
  const { till, ...rest } = props

  const { t } = useTranslation();

  const entries = useMemo(() => {

    const entries: { key: string; label: string; value: string }[] = [
      {
        key: 'id',
        label: t(messages.id()),
        value: till.id.toString(),
      },
      {
        key: 'number',
        label: t(messages.number()),
        value: till.number.toString(),
      },
      {
        key: 'merchantId',
        label: t(messages.merchantId()),
        value: till.merchantId?.toString() || "0",
      },
      {
        key: 'printer',
        label: t(messages.printer()),
        value: till.printer || "0",
      },
      {
        key: 'tpe',
        label: t(messages.tpe()),
        value: till.tpe || "0",
      },
      {
        key: 'connected',
        label: t(messages.connected()),
        value: till.connected ? "oui" : "non",
      }
    ];
    return entries;
  }, [till, t]);

  return (
    <ListItem {...rest}>
      <EntriesWrapper>
        {entries?.map(entry => (
          <InfoEntry key={entry.key} label={entry.label} value={entry.value} />
        ))}
      </EntriesWrapper>
    </ListItem>
  );
};