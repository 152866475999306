declare global {
  interface Date {
    isToday(): boolean;
  }
}

Date.prototype.isToday = function () {
  const today = new Date();
  return (
    this.getDate() === today.getDate() &&
    this.getMonth() === today.getMonth() &&
    this.getFullYear() === today.getFullYear()
  );
};

export {};
