import { createGlobalStyle } from 'styled-components';
// import { StyleConstants } from './StyleConstants';
/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    font-family: 'Gabriel Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: ${p => p.theme.pageBackground};
  }
  
  p,
  label {
    line-height: 1.5em;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  ::-webkit-scrollbar {
    width: 6px;
  }
      
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
  }
      
  ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.primary};
    border-radius: 5px;
  }
      
  ::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.body};
  }

  .dropdownPink {
    color: #e462a1;
  }
`;
