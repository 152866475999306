import { first, includes } from 'lodash';
import { allStatKinds, StatKind } from '../types';

export const statKindFromParameter = (parameter: string) => {
  const converted = parameter.replace(/-/g, '_');
  if (!includes(allStatKinds, converted)) {
    return first(allStatKinds) as StatKind; // default fallback on the first one.
  }
  return converted as StatKind;
};

export const parameterFromStatKind = (statKind: StatKind) =>
  statKind.replace(/_/g, '-');
