import { useCallback, useEffect, useMemo } from 'react';
import { useForm as useHookForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Institute } from 'types/API/institutes';
import { Optional } from 'types/misc';

import { FormValues } from './types';
import { useMutations } from '../useMutations';
import { useFormValidation } from '../useFormValidation';
import { useTransformData } from '../useTransformData';

interface Options {
  institute: Optional<any>;
  instituteId: number;
}

export const useForm = (options: Options) => {
  const { institute, instituteId } = options;
  
  // fix to avoid sending useless keys to the API
  delete institute?.users
  

  const { schema } = useFormValidation();

  const { getFormValues, getUpdatePayload } = useTransformData();

  const { isLoading, updateMutation } = useMutations();

  const defaultValues = useMemo(
    () => getFormValues(institute),
    [getFormValues, institute],
  );

  const methods = useHookForm<FormValues>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const {
    formState: { isSubmitting: isFormSubmitting, isValid },
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const isSubmitting = isFormSubmitting || isLoading;

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      const options = {
        onSuccess(data, variables, context) {},
      };
      const updatePayload = getUpdatePayload({ formValues });
      updateMutation(
        { instituteId: instituteId, data: updatePayload },
        options,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [instituteId],
  );

  return {
    canSubmit: isValid && !isSubmitting,
    isSubmitting,
    methods,
    onSubmit: handleSubmit(onSubmit),
  };
};
