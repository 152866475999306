import { Cure } from 'types/API/cures';

export const rawCureToCure = (rawCure: Cure.Raw): Cure.Normal => {
  const { product: rawProduct, ...rest } = rawCure;
  const prestation: Cure.Prestation = {
    id: rawProduct.id,
    name: rawProduct.productLangues[0].title,
  };
  return { prestation, ...rest };
};
