import { Controller, useFormContext } from 'react-hook-form';
import React, { PropsWithChildren } from 'react';

import { ToggleButton, ToggleButtonGroup } from 'app/components/Toggle';

type Props = PropsWithChildren<{
  name: string;
  discountTypeChanged?: (value: string) => void;
  onChange?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string,
  ) => void;
}>;

export const InputToggleButtons = (props: Props) => {
  const { children, name, discountTypeChanged, onChange } = props;

  const { control } = useFormContext();

  const handleChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string,
    forwardedOnChange: (...e: any[]) => void,
  ) => {
    console.log(`toggle handleChange handleChange with value:${value}`);
    onChange?.(event, value);
    forwardedOnChange(value);
    discountTypeChanged?.(value);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange: forwardedOnChange, value } }) => (
        <ToggleButtonGroup
          value={value}
          exclusive
          onChange={(event, newValue) => {
            handleChange(event, newValue, forwardedOnChange);
          }}
        >
          {children}
        </ToggleButtonGroup>
      )}
    />
  );
};

export { ToggleButton };
