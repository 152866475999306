import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  pageTitle: () => _t(translations.users.page_title),
  createUser: () => _t(translations.users.create),
  deleteConfirmationTitle: () =>
    _t(translations.users.delete.confirmation.title),
  deleteConfirmationDescritption: () =>
    _t(translations.users.delete.confirmation.description),
  deleteAlertSuccessDescription: () =>
    _t(translations.users.delete.alert.success.description),
  sections: {
    tills: {
      title: () => _t(translations.users.sections.tills.title),
    },
    portalUsers: {
      title: () => _t(translations.users.sections.portal_users.title),
    },
    tillUsers: {
      title: () => _t(translations.users.sections.till_users.title),
    },
  },
};
