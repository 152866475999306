declare global {
  interface Number {
    pad(length?: number): string;

    roundOff(places: number): number;
  }
}

Number.prototype.pad = function (length?: number) {
  return new Array(length).join('0').slice((length || 2) * -1) + this;
};

Number.prototype.roundOff = function (places: number) {
  const value = this as number;
  const exponent = Math.pow(10, places);
  return Math.round(value * exponent) / exponent;
};

export {};
