import { ModalState, useModal } from 'app/hooks';

export enum EntityKind {
  DISCOUNT,
  CURE,
}

type DeleteConfiramtionModalState = ModalState<{
  kind: EntityKind;
  id: number;
}>;

export interface ModalStates {
  deleteConfirmation: DeleteConfiramtionModalState;
  maxDiscountCountReached: ModalState<void>;
}

export const useModals = (): ModalStates => {
  const deleteConfirmation: DeleteConfiramtionModalState = useModal();
  const maxDiscountCountReached: ModalState<void> = useModal();
  return {
    deleteConfirmation,
    maxDiscountCountReached,
  };
};
