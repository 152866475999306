import { useState } from 'react';

import { Optional } from 'types/misc';

interface InternalState<C> {
  open: boolean;
  context: Optional<C>;
}

export interface ModalState<C> extends InternalState<C> {
  toggleOpenState: (ctx?: C) => void;
}

export function useModal<C>(): ModalState<C> {
  const [state, setState] = useState<InternalState<C>>({
    open: false,
    context: undefined,
  });

  const toggleOpenState = (ctx?: C) => {
    setState({ context: ctx, open: !state.open });
  };

  return {
    ...state,
    toggleOpenState,
  };
}
