import React, { createContext, useContext, useState, ReactNode } from 'react';

interface TotalContextType {
  total: number;
  Totalupdate: (newTotal: number) => void;
  Quantityupdate:(newQantity: number) => void;
  quantity: number

}

const TotalContext = createContext<TotalContextType | undefined>(undefined);

export const TotalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [total, setTotal] = useState(0);
const [quantity , setQuantity] = useState(0)
  const Totalupdate = (newTotal: number) => {
    setTotal(newTotal);
  };
  const Quantityupdate = (newQantity: number) => {
    setQuantity(newQantity);
  };
  return (
    <TotalContext.Provider value={{ total, Totalupdate,Quantityupdate,quantity }}>
      {children}
    </TotalContext.Provider>
  );
};

export const useTotal = (): TotalContextType => {
  const context = useContext(TotalContext);
  if (context === undefined) {
    throw new Error('useTotal must be used within a TotalProvider');
  }
  return context;
};