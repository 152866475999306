import { first, indexOf } from 'lodash';
import { User } from 'types/API/users';

export const hasRoleOrHigher = ({
  user,
  role,
}: {
  role: User.Role;
  user: User.ListFormat | User.DetailFormat;
}) => {
  const userRole = first(user.roles);
  if (!userRole) return false;
  return rolePriority(userRole) >= rolePriority(role);
};

export const rolePriority = (role: User.Role) =>
  indexOf(User.RolesPriority, role) ?? 0;
