import { Helmet } from 'react-helmet-async';
import React, { useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { useInstituteSelector } from 'app/atoms/selectors';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';

import { useConfiguration } from './hooks';

import { statKindFromParameter } from './utils';

import style from 'styled-components/macro';
import "react-datepicker/dist/react-datepicker.css";

import { StatsContent } from './components/StatsContent';

import { DailySummary } from './dailySummary';
import { Statistics } from './statistics';
import { Subscriptions } from './subscriptions';
import { Bonus } from './bonus';
import { EsthetSummary } from './esthetSummary';
import { CashBookB } from './cashBookB';
import { CashBookTicket } from './cashBookTicket';
import { TopServicePerUser } from './top_service_per_user';
import { TopService } from './top_service';

import ReactToPrint from 'react-to-print';

export const StatsPage = () => {

  const { institute } = useInstituteSelector();

  const { kind: parameter }: { kind: string } = useParams();

  const kind = useMemo(() => statKindFromParameter(parameter), [parameter]);

  const { pageConfiguration } = useConfiguration({ kind });

  const pageTitle = pageConfiguration?.title ?? '';


  interface Props {
    disabled: boolean;
  }
  


  return (
    <>
      <Helmet>
        <title>{pageTitle} : {institute.name}</title>
      </Helmet>

      <PageWrapper header={<PageHeader backBehaviour label={pageTitle + " : " + institute.name} />}>
        <PageContent kind={kind} institute={institute} config={pageConfiguration}></PageContent>
      </PageWrapper>
    </>
  );
};

const PageContent = (props) => {
  const { kind, institute, config } = props
  const componentRef = useRef<HTMLDivElement>(null)

  var body = <div></div>

  switch (kind) {
    case 'daily_summary':
      body = <DailySummary institute={institute}></DailySummary>
      break;
    case 'statistics':
      body = <Statistics institute={institute}></Statistics>
      break;
    case 'subscriptions':
      body = <Subscriptions institute={institute}></Subscriptions>
      break;
    case 'bonus':
      body = <Bonus institute={institute}></Bonus>
      break;
    case 'esthet_summary':
      body = <EsthetSummary institute={institute}></EsthetSummary>
      break;
    case 'cash_book_b':
      body = <CashBookB institute={institute}></CashBookB>
      break;
    case 'cash_book_per_ticket':
      body = <CashBookTicket institute={institute}></CashBookTicket>
      break; 
    case 'top_service_per_user':
      body = <TopServicePerUser institute={institute}></TopServicePerUser>
      break;
    case 'top_service':
      body = <TopService institute={institute}></TopService>
      break;
    
    default:
      body = <div>
        {config && (
          <StatsContent
            configuration={config.contentConfiguration}
            instituteId={institute.id}
            kind={kind}
          />
        )}
      </div>
      break;
  }

  return <div>
    <PrintButtonRow>
      <ReactToPrint
        trigger={() => <PrintButton type="submit" value="Imprimer" />}
        content={() => componentRef.current}
        pageStyle={pageStyle}
        />
    </PrintButtonRow>
    <div ref={componentRef}>
      {body}
      <style type="text/css" media="print">{"\
      @page {\ size: landscape;\ }\
      "}</style>
    </div>
  </div>
}

const PrintButtonRow = style.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 95%;
`;

const PrintButton = style.input`
  background: #e462a1;
  border: none;
  padding: 20px;
  border-radius: 20px;
  color: white;
`;

const pageStyle = `
  @page {
    size: 80mm 50mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;