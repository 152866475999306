import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { SelectInput } from 'app/components/Inputs/SelectInput';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';

import { messages } from '../../../messages';
import { ProductGroup } from '../../../hooks/useForm/types';

interface Props {
  disabled: boolean;
  productGroups: ProductGroup[];
}

export const Products = ({ disabled, productGroups }: Props) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1}>
      {productGroups.map((group, index) => (
        <FormInputGrid item sm={4} key={group.id}>
          <FormLabel>{group.name}</FormLabel>
          <SelectInput
            allowMultipleSelections={true}
            disabled={disabled}
            name={`productGroups[${index}]`}
            options={group.options}
            placeholder={t(messages.prestations.placeholder())}
          />
        </FormInputGrid>
      ))}
    </Grid>
  );
};
