import React from "react";
import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { messages } from './messages';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { LoadableContent } from 'app/components/LoadableContent';
import styled from 'styled-components/macro';
import { Institute } from 'types/API/institutes';
import { request } from 'utils/request';
import DatePicker from "react-datepicker";
import { getEnv } from 'utils/common/env';
import { useForm } from "react-hook-form";
import { FormLabel } from 'app/components/Forms/FormLabel';
import ToggleButton from 'react-toggle-button'
import ReactToPrint from 'react-to-print';

export const AddInstitutesPage = () => {

  const { t } = useTranslation();
  const pageTitle = t(messages.pageTitle());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [countries, setCountries] = useState<[Institute.Country]>()
  const [formes, setFormes] = useState<[Institute.Forme]>()
  const [types, setTypes] = useState<[Institute.Type]>()

  const loadData = async () => {
    if (!loading) {
      setLoading(true)
      fetchCountriesRequest().then(data => {
        if (data) {
          setCountries(data)
          fetchFormesRequest().then(formes => {
            if (formes) {
              setFormes(formes)
              fetchTypesRequest().then(types => {
                if (types) {
                  setTypes(types)
                  setLoading(false)
                } else {
                  setLoading(false)
                }
              }).catch(error => {
                setLoading(false)
                setError(error)
                console.log(error)
              })
            } else {
              setLoading(false)
            }
          }).catch(error => {
            setLoading(false)
            setError(error)
            console.log(error)
          })
        } else {
          setLoading(false)
        }
        
      }).catch(error => {
        setLoading(false)
        setError(error)
        console.log(error)
      })
    }
  }

  useEffect(() => {
    loadData();
    return () => { };
  }, []);

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageWrapper
        header={<PageHeader label={pageTitle} />}>

        <LoadableContent
          error={error}
          isLoading={loading}
        >
          <AddInstituteForm countries={countries} formes={formes} types={types} />
        </LoadableContent>
      </PageWrapper>
    </div>
  )
}

const AddInstituteForm = (props) => {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [siret, setSiret] = useState('')
  const [ape, setApe] = useState('')
  const [codetva, setCodetva] = useState('')
  const [address, setAddress] = useState('')
  const [zipcode, setZipcode] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('/api/countries/1')
  const [forme, setForme] = useState('/api/institute_formes/1')
  const [type, setType] = useState<string[]>()
  let countries = props.countries || []
  let formes = props.formes || []
  let types = props.types || []
  const [ics, setIcs] = useState('')
  const [iban, setIban] = useState('')
  const [bic, setBic] = useState('')
  const [opening, setOpening] = useState(new Date())
  const [treatment_cabine, setTreatment_cabine] = useState('')
  const [ipl_cabine, setIpl_cabine] = useState('')
  const [uv_cabine, setUv_cabine] = useState('')
  const [vsp, setVsp] = useState('')
  const [gescomBody, setGescomBody] = useState('')
  const [gescomNail, setGescomNail] = useState('')
  const [gescomHair, setGescomHair] = useState('')
  const [migrationBody, setMigrationBody] = useState('')
  const [migrationNail, setMigrationNail] = useState('')
  const [migrationHair, setMigrationHair] = useState('')
  const [infos, setInfos] = useState<Institute.CreationInfo>()

  const [instituteCreated, setInstituteCreated] = useState(false)

  const handleCountryChange = (e) => {
    let scheme = 'api/countries/' + e.target.value
    setCountry(scheme)
    console.log(scheme)
  }

  const handleFormeChange = (e) => {
    let scheme = 'api/institute_formes/' + e.target.value
    setForme(scheme)
    console.log(scheme)
  }

  const handleTypeChange = (e) => {
    let scheme = 'api/institute_types/' + e.target.value
    
    console.log(scheme)
    // let value = Array.from(e.target.selectedOptions, option => option.value);
    const values = [...e.target.selectedOptions].map(opt => String('api/institute_types/'+String(opt.value)));
    setType(values)
    console.log(values)
  }

  const resetFields = () => {
    const isDevelopment = false//getEnv('ENV') === 'development';

    setName(isDevelopment ? '0005A' : '')
    setPhone(isDevelopment ? '0000000000' : '')
    setSiret(isDevelopment ? '904 005 882 000 70' : '')
    setApe(isDevelopment ? '9602BA' : '')
    setCodetva(isDevelopment ? 'FR87904005880' : '')
    setAddress(isDevelopment ? 'Centre Commercial CARREFOUR ST JACQUES 2 Quai Louis Blanc' : '')
    setZipcode(isDevelopment ? '03100' : '')
    setCity(isDevelopment ? 'MONTLUCON' : '')
    setCountry('/api/countries/1')
    setForme('/api/institute_formes/1')
    setType([])
    countries = props.countries || []
    formes = props.formes || []
    types = props.types || []
    setIcs(isDevelopment ? '1' : '')
    setIban(isDevelopment ? 'FR7630004014570001014613526' : '')
    setBic(isDevelopment ? 'BNPAFRPPXXY' : '')
    setOpening(new Date())
    setTreatment_cabine(isDevelopment ? '1' : '')
    setIpl_cabine(isDevelopment ? '1' : '')
    setUv_cabine(isDevelopment ? '1' : '')
    setVsp(isDevelopment ? '1' : '')
    setGescomBody(isDevelopment ? '1' : '')
    setGescomNail(isDevelopment ? '1' : '')
    setGescomHair(isDevelopment ? '1' : '')
  }

  const [state, setState] = useState({
    oldOn: false
  });

  const onSubmit = e => {

    if (opening == null) {
      alert("Merci de renseigner une date d'ouverture")
      return
    }

    const institute = {
      name: name,
      phone: phone,
      siret: siret,
      ape: ape,
      codetva: codetva,
      address: address,
      zipcode: zipcode,
      city: city,
      country: country,
      forme: forme,
      type: type || [],
      ics: ics,
      iban: iban,
      bic: bic,
      opening: opening,
      treatment_cabine: treatment_cabine,
      ipl_cabine: ipl_cabine,
      uv_cabine: uv_cabine,
      vsp: Number(vsp),
      old: state.oldOn
    }

    const data = {
      institute: institute,
      gescom: {
        body: gescomBody,
        nail: gescomNail,
        hair: gescomHair
      },
      migration: {
        Body: migrationBody,
        Nail: migrationNail,
        Hair: migrationHair
      }

    }


    addInstituteRequest(data)
      .then((infos) => {
        // alert('L\'intstitut a bien été ajouté');
        setInstituteCreated(true)
        setInfos(infos)
        resetFields()
      }).catch(error => {
        if (error.status == 500) {
          alert(`Erreur lors de l'ajout de l'institut, veuillez remplir tout les champs.\n ${error}`);
        } else {
          let violation = error.content.violations
          alert(`Erreur lors de l'ajout de l'institut: ${violation[0].message}`);
        }
      })

  }

  const addInstituteRequest = async (data: Institute.CreationPayload) => {

    const url = `api/institutes`
    const infos = await request<Institute.CreationInfo>(url, {
      method: 'POST',
      data,
    });
    return infos;
  };

  const onReset = e => {
    resetFields()
    setInstituteCreated(false)
  }


  let defaultErrorMessage = "Ce champ est obligatoire"

  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <div>

      {instituteCreated &&
        <div>
          <PrintButtonRow>
            <ReactToPrint
              trigger={() => <PrintButton type="submit" value="Imprimer" />}
              content={() => componentRef.current}
            />
          </PrintButtonRow>
          
          <form onSubmit={handleSubmit(onReset)} >
            <div ref={componentRef}>
            <div>L'institut a bien été créé. Notez bien ou imprimez ces informations car elle ne s'afficherons qu'une seule fois.</div>
            <AddInstituteFormRow>
              <FormLabel>Institut</FormLabel>
              <AddInstituteInputCol>
                <TextInputStyle disabled type="text" value={infos?.institute} />
              </AddInstituteInputCol>
            </AddInstituteFormRow>
            <AddInstituteFormRow>
              <FormLabel>ID</FormLabel>
              <AddInstituteInputCol>
                <TextInputStyle disabled type="text" value={infos?.id} />
              </AddInstituteInputCol>
            </AddInstituteFormRow>
            <AddInstituteFormRow>
              <FormLabel>Utilisateur caisse</FormLabel>
              <AddInstituteInputCol>
                <TextInputStyle disabled type="text" value={infos?.userTill} />
              </AddInstituteInputCol>
            </AddInstituteFormRow>
            <AddInstituteFormRow>
              <FormLabel>Mot de passe caisse</FormLabel>
              <AddInstituteInputCol>
                <TextInputStyle disabled type="text" value={infos?.tillPassword} />
              </AddInstituteInputCol>
            </AddInstituteFormRow>
            <AddInstituteFormRow>
              <FormLabel>Utilisateur portail</FormLabel>
              <AddInstituteInputCol>
                <TextInputStyle disabled type="text" value={infos?.userPortal} />
              </AddInstituteInputCol>
            </AddInstituteFormRow>
            <AddInstituteFormRow>
              <FormLabel>Mot de passe portail</FormLabel>
              <AddInstituteInputCol>
                <TextInputStyle disabled type="text" value={infos?.portalPassword} />
              </AddInstituteInputCol>
              </AddInstituteFormRow>
          </div>
            <AddInstituteSaveButtons><AddInstituteSaveButton type="submit" value="Recréer un institut" /></AddInstituteSaveButtons>

          </form>
          
          </div>

      }

      {!instituteCreated &&
        <form onSubmit={handleSubmit(onSubmit)}>
          <AddInstituteFormRow>
            <FormLabel>Nom</FormLabel>
            <AddInstituteInputCol>
              <TextInputStyle {...register("name", { required: true })} type="text" value={name} onChange={event => setName(event.target.value)} />
              {errors.name && <RedError>{defaultErrorMessage}</RedError>}
            </AddInstituteInputCol>
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Téléphone</FormLabel>
            <AddInstituteInputCol>
              <TextInputStyle {...register("phone", { required: true })} type="text" value={phone} onChange={event => setPhone(event.target.value)} />
              {errors.phone && <RedError>{defaultErrorMessage}</RedError>}
            </AddInstituteInputCol>
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Siret</FormLabel>
            <AddInstituteInputCol>
              <TextInputStyle {...register("siret", { required: true })} type="text" value={siret} onChange={event => setSiret(event.target.value)} />
              {errors.siret && <RedError>{defaultErrorMessage}</RedError>}
            </AddInstituteInputCol>
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Ape</FormLabel>
            <AddInstituteInputCol>
              <TextInputStyle {...register("ape", { required: true })} type="text" value={ape} onChange={event => setApe(event.target.value)} />
              {errors.ape && <RedError>{defaultErrorMessage}</RedError>}
            </AddInstituteInputCol>
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Code TVA</FormLabel>
            <AddInstituteInputCol>
              <TextInputStyle {...register("codetva", { required: true })} type="text" value={codetva} onChange={event => setCodetva(event.target.value)} />
              {errors.codetva && <RedError>{defaultErrorMessage}</RedError>}
            </AddInstituteInputCol>
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Adresse</FormLabel>
            <AddInstituteInputCol>
              <TextInputStyle {...register("address", { required: true })} type="text" value={address} onChange={event => setAddress(event.target.value)} />
              {errors.address && <RedError>{defaultErrorMessage}</RedError>}
            </AddInstituteInputCol>
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Code postal</FormLabel>
            <AddInstituteInputCol>
              <TextInputStyle {...register("zipcode", { required: true })} type="text" value={zipcode} onChange={event => setZipcode(event.target.value)} />
              {errors.zipcode && <RedError>{defaultErrorMessage}</RedError>}
            </AddInstituteInputCol>
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Ville</FormLabel>
            <AddInstituteInputCol>
              <TextInputStyle {...register("city", { required: true })} type="text" value={city} onChange={event => setCity(event.target.value)} />
              {errors.city && <RedError>{defaultErrorMessage}</RedError>}
            </AddInstituteInputCol>
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Pays</FormLabel>
            <SelectInputStyle onChange={handleCountryChange.bind(this)}>
              {countries.map((country) => (
                <option value={country.id}>{country.name}</option>
              ))}
            </SelectInputStyle>
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Forme</FormLabel>
            <SelectInputStyle onChange={handleFormeChange.bind(this)}>
              {formes.map((forme) => (
                <option value={forme.id}>{forme.forme}</option>
              ))}
            </SelectInputStyle>
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Type (cliquer sur ctrl + type pour en sélectionner plusieurs)</FormLabel>
            <AddInstituteInputCol>
              <SelectInputStyle {...register("type", { required: true })} multiple={true} onChange={handleTypeChange.bind(this)}>
                {types.map((type) => (
                  <option value={type.id}>{type.instituteType}</option>
                ))}
              </SelectInputStyle>
              {errors.type && <RedError>{defaultErrorMessage}</RedError>}
            </AddInstituteInputCol>
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>ICS</FormLabel>
            <AddInstituteInputCol>
              <TextInputStyle {...register("ics", { required: true })} type="text" value={ics} onChange={event => setIcs(event.target.value)} />
              {errors.ics && <RedError>{defaultErrorMessage}</RedError>}
            </AddInstituteInputCol>

          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>IBAN</FormLabel>
            <AddInstituteInputCol>
              <TextInputStyle {...register("iban", { required: true })} name="iban" type="text" value={iban} onChange={event => setIban(event.target.value)} />
              {errors.iban && <RedError>{defaultErrorMessage}</RedError>}
            </AddInstituteInputCol>
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>BIC</FormLabel>
            <AddInstituteInputCol>
              <TextInputStyle {...register("bic", { required: true })} name="bic" type="text" value={bic} onChange={event => setBic(event.target.value)} />
              {errors.bic && <RedError>{defaultErrorMessage}</RedError>}
            </AddInstituteInputCol>
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Date d'ouverture</FormLabel>
            <AddInstituteInputCol>
              <style>
                {`.date-picker input {
                width: 100%;
                height: 3rem;
                border-radius: 4px;
                height: 3rem;
                font-family: 'Gabriel Sans Cond';
                font-weight: 700;
                color: ${p => p.theme.input.text.default};
      }`}
              </style>
              <DatePicker wrapperClassName="date-picker" locale="fr" dateFormat="dd/MM/yyyy" selected={opening} onChange={(date) => setOpening(date)} />
            </AddInstituteInputCol>


          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Cabine Traitement</FormLabel>
            <TextInputStyle name="treatment_cabine" type="text" value={treatment_cabine} onChange={event => setTreatment_cabine(event.target.value)} />
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Cabine Ipl</FormLabel>
            <TextInputStyle name="ipl_cabine" type="text" value={ipl_cabine} onChange={event => setIpl_cabine(event.target.value)} />
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Cabine UV</FormLabel>
            <TextInputStyle name="uv_cabine" type="text" value={uv_cabine} onChange={event => setUv_cabine(event.target.value)} />
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>VSP</FormLabel>
            <TextInputStyle name="vsp" type="text" value={vsp} onChange={event => setVsp(event.target.value)} />
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Gescom Body</FormLabel>
            <TextInputStyle name="gescomBody" type="text" value={gescomBody} onChange={event => setGescomBody(event.target.value)} />
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Gescom Nail</FormLabel>
            <TextInputStyle name="gescomNail" type="text" value={gescomNail} onChange={event => setGescomNail(event.target.value)} />
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Gescom Hair</FormLabel>
            <TextInputStyle name="gescomHair" type="text" value={gescomHair} onChange={event => setGescomHair(event.target.value)} />
          </AddInstituteFormRow>
          <AddInstituteFormRow>
            <FormLabel>Migration ancien institut</FormLabel>
            <ToggleButton

              colors={{
                activeThumb: {
                  base: 'rgb(228,98,161)',
                },
                inactiveThumb: {
                  base: 'rgb(62,130,247)',
                },
                active: {
                  base: 'rgb(228,98,161)',
                  hover: 'rgb(218, 42, 128)',
                },
                inactive: {
                  base: 'rgb(65,66,68)',
                  hover: 'rgb(95,96,98)',
                }
              }}
              inactiveLabel="Non"
              activeLabel="Oui"
              value={state.oldOn || false}
              onToggle={(value) => {
                setState({
                  oldOn: !value,
                })
              }} />
          </AddInstituteFormRow>

          {state.oldOn &&
            <div>
              <AddInstituteFormRow>
                <FormLabel>Body</FormLabel>
                <TextInputStyle name="migrationBody" type="text" value={migrationBody} onChange={event => setMigrationBody(event.target.value)} />
              </AddInstituteFormRow>
              <AddInstituteFormRow>
                <FormLabel>Nail</FormLabel>
                <TextInputStyle name="migrationNail" type="text" value={migrationNail} onChange={event => setMigrationNail(event.target.value)} />
              </AddInstituteFormRow>
              <AddInstituteFormRow>
                <FormLabel>Hair</FormLabel>
                <TextInputStyle name="migrationHair" type="text" value={migrationHair} onChange={event => setMigrationHair(event.target.value)} />
              </AddInstituteFormRow>
            </div>

          }

          <AddInstituteSaveButtons><AddInstituteSaveButton type="submit" value="Ajouter" /></AddInstituteSaveButtons>
        </form>
      }

    </div>
    )
}

export const fetchCountriesRequest = () =>
  request<[Institute.Country]>(`api/countries`, {
    method: 'GET',
  });

export const fetchFormesRequest = () =>
  request<[Institute.Forme]>(`api/institute_formes`, {
    method: 'GET',
  }); 

export const fetchTypesRequest = () =>
  request<[Institute.Type]>(`api/institute_types`, {
    method: 'GET',
  });

const AddInstituteFormRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 95%;
  margin: 10px;
`;

const AddInstituteInputCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const RedError = styled.span`
  color: red;
`;

const AddInstituteSaveButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 95%;
`;

const AddInstituteSaveButton = styled.input`
  background: #e462a1;
  border: none;
  padding: 20px;
  border-radius: 20px;
  color: white;
  margin: 30px;
`;

const TextInputStyle = styled.input`
    background-color: ${p => p.theme.input.background};
    border-radius: 4px;
    height: 3rem;
    font-family: 'Gabriel Sans Cond';
    font-weight: 700;
    color: ${p => p.theme.input.text.default};
  }
`;

const DatePickerStyle = styled.div`
    background-color: ${p => p.theme.input.background};
    border-radius: 4px;
    height: 3rem;
    font-family: 'Gabriel Sans Cond';
    font-weight: 700;
    color: ${p => p.theme.input.text.default};
  }
`;


const SelectInputStyle = styled.select`
    background-color: ${p => p.theme.input.background};
    border-radius: 4px;
    height: 3rem;
    font-family: 'Gabriel Sans Cond';
    font-weight: 700;
    color: ${p => p.theme.input.text.default};
  }
`;

const PrintButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 95%;
`;

const PrintButton = styled.input`
  background: #e462a1;
  border: none;
  padding: 20px;
  border-radius: 20px;
  color: white;
`;