import { useCallback } from 'react';

import { Optional } from 'types/misc';
import { Auth } from 'types/API/auth';
import { User } from 'types/API/users';
import { entityEndpoint } from 'utils/entities/identifier';

import { FormValues } from './useForm/types';

export const useTransformData = (instituteId: number) => {
  const getFormValues = useCallback(
    (user: Optional<User.DetailFormat>): Optional<FormValues> => {
      if (!user) {
        return {
          institute: [
            entityEndpoint({ id: instituteId, module: 'institutes' }),
          ],
          roles: [User.Roles.ROLE_USER],
          updatePassword: true,
        };
      }
      const { firstname, lastname, institute, roles } = user;
      return {
        firstname,
        lastname,
        password: '',
        institute,
        roles,
        updatePassword: false,
      };
    },
    [instituteId],
  );

  const getRegisterPayload = useCallback(
    ({ formValues }: { formValues: FormValues }): Auth.RegisterPayload => {
      const { firstname, lastname, password, institute, roles } =
        formValues as Required<FormValues>;
      const role = roles[0];
      const type =
        role === User.Roles.ROLE_USER
          ? User.Types.TYPE_USER
          : User.Types.TYPE_OTHER;
      return {
        firstname,
        institute,
        lastname,
        password,
        role,
        type,
      };
    },
    [],
  );

  const getUpdatePayload = useCallback(
    ({ formValues }: { formValues: FormValues }): User.UpdatePayload => {
      const {
        firstname,
        lastname,
        institute,
        password,
        roles,
        updatePassword,
      } = formValues as Required<FormValues>;
      return {
        firstname,
        lastname,
        institute,
        roles,
        password: updatePassword ? password : undefined,
      };
    },
    [],
  );

  return {
    getFormValues,
    getRegisterPayload,
    getUpdatePayload,
  };
};
