import React from 'react';
import styled from 'styled-components/macro';

import { Menu } from 'app/components/Menu';
import { ReactComponent as RawLogoIcon } from 'app/assets/logo.svg';
import { AccountButton } from 'app/components/PageWrappers/components/AccountButton';
import { useHistory } from 'react-router-dom';
import { paths } from 'app/routes/paths';
import { useInstituteSelector } from 'app/atoms/selectors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  overflow-y: scroll;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1rem;
  
  > svg {
    margin-top 1rem;
    margin-bottom: 4rem;
  }
`;

export const SideBar = () => {
  const history = useHistory();
  const { institute } = useInstituteSelector();

  return (
    <Wrapper>
      <TopWrapper>
        <LogoIcon onClick={() => history.push(paths.home)} />
        <Menu />
      </TopWrapper>
      <AccountButton label={institute.name} />
    </Wrapper>
  );
};

const LogoIcon = styled(RawLogoIcon)`
  cursor: pointer;
`;
