import { DialogContentText } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

import { Button } from 'app/components/Button';
import { Modal } from 'app/components/Modal';

interface Action {
  action: () => void;
  label: string;
  isLoading?: boolean;
}

interface Props {
  close: () => void;
  description: string;
  primaryAction: Action;
  secondaryAction?: Action;
  title: string;
}

const StyledDialogContentText = styled(DialogContentText)`
  font-family: 'Gabriel Sans Cond';
  font-weight: 500;
  color: ${p => p.theme.text.body};
`;

export const TextModal = (props: Props) => {
  const { close, description, primaryAction, secondaryAction, title } = props;

  return (
    <Modal closeModal={close} fullWidth maxWidth="sm" onClose={close}>
      {({ Content, Footer, Header }) => (
        <>
          <Header title={title} />

          <Content>
            <StyledDialogContentText>{description}</StyledDialogContentText>
          </Content>

          <Footer>
            {secondaryAction && (
              <Button
                color="default"
                isSpinning={secondaryAction.isLoading}
                onClick={secondaryAction.action}
                variant="outlined"
              >
                {secondaryAction.label}
              </Button>
            )}

            <Button
              color="secondary"
              isSpinning={primaryAction.isLoading}
              onClick={primaryAction.action}
            >
              {primaryAction.label}
            </Button>
          </Footer>
        </>
      )}
    </Modal>
  );
};
