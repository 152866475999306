import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

interface WrapperProps {
  disabled?: boolean;
}
type Props = PropsWithChildren<
  WrapperProps & {
    className?: string;
    onClick?: () => void;
  }
>;

export const FormButton = (props: Props) => {
  const { children, disabled, onClick, ...rest } = props;
  return (
    <Wrapper
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<WrapperProps>`
  font-family: 'Gabriel Sans Cond';
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
  color: ${p => p.theme.text.body};
  &:hover {
    opacity: ${p => (p.disabled ? 'inherit' : 0.5)};
  }
`;
