import { Product } from 'types/API/products';
import { request } from 'utils/request';

export const fetchProductsRequest = ({
  instituteId,
  subscriptionId = 1,
}: {
  instituteId: number;
  subscriptionId?: number;
}) =>
  request<Product.Normal[]>(
    `api/products/institute/${instituteId}/0/${subscriptionId}/0`,
    {
      method: 'GET',
    },
  );

export const fetchProductCategoriesRequest = () =>
  request<Product.Category[]>(`api/product_categories`, {
    method: 'GET',
  });

  export const fetchAllProductsRequest = ({
    instituteId,
  }: {
    instituteId: number;
  }) =>
    request<Product.Min[]>(
      `api/products/all/0/0/0`,
      {
        method: 'GET',
      },
    );
