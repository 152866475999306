import { Institute } from 'types/API/institutes';
import { request } from 'utils/request';

export const fetchAllInstitutesRequest = () =>
  request<Institute.Normal[]>(`api/institutes`, {
    method: 'GET',
  });

export const fetchInstituteRequest = ({
  instituteId,
}: {
  instituteId: number;
}) =>
  request<Institute.Full>(`api/institutes/${instituteId}`, {
    method: 'GET',
  });

export const updateInstituteRequest = ({
  instituteId,
  data,
}: {
  instituteId: number;
  data: Institute.Payload;
}) =>
  request<Institute.Normal>(`api/institutes/${instituteId}`, {
    method: 'PUT',
    data,
  });
