import { first } from 'lodash';
import { useMemo } from 'react';

import { Table as DefaultTable } from 'app/components/Table';
import { TableColumns } from 'app/components/Table/table';
import {
  PropertyMapping,
  Value,
  ValueFormatter,
} from 'app/pages/Stats/StatsPage/types';

import { usePropertyMapping, useValueFormatter } from '../../hooks';
import { Optional } from 'types/misc';

interface Props {
  displayTotals?: boolean;
  propertyMapping: PropertyMapping;
  values: any[];
  valueFormatter: Optional<ValueFormatter>;
}

export const Table = (props: Props) => {
  const {
    displayTotals = true,
    propertyMapping,
    values: rawValues,
    valueFormatter,
  } = props;

  const { propertyLabel } = usePropertyMapping({ propertyMapping });

  const columns: TableColumns = useMemo(() => {
    const keys = Object.keys(first(rawValues));
    return [
      { field: 'id', headerName: 'id', hide: true },
      ...keys.map(key => ({
        field: key,
        headerName: propertyLabel(key),
      })),
    ];
  }, [rawValues]);

  const { values } = useValueFormatter({
    values: rawValues,
    valueFormatter,
  });

  const indexedRows = useMemo(
    () =>
      values.map((value, index) => {
        //   const { date, ...rest } = daily;

        return {
          id: index + 1,
          // day: t(messages.dayDateFormat(), { date: daily.date }),
          ...value,
        };
      }),
    [values],
  );

  const footer = useMemo(() => {
    if (displayTotals) {
      return values.reduce((previous, current) => {
        const accumulator = { ...previous };
        for (const [key, value] of Object.entries(current)) {
          if (!accumulator[key]) {
            accumulator[key] = 0;
          }
          const accumulatedValue = parseFloat(accumulator[key]); // TMP AS THE API GIVES STRING VALUES
          const currentValue = parseFloat(value as string);
          if (!isNaN(currentValue)) {
            accumulator[key] = Number(
              (accumulatedValue + currentValue).toFixed(2),
            );
          } else {
            accumulator[key] = '';
          }
        }
        return accumulator;
      });
    } else {
      return undefined;
    }
  }, [displayTotals, values]);

  return <DefaultTable rows={indexedRows} columns={columns} footer={footer} />;
};
