import { Helmet } from 'react-helmet-async';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { useInstituteSelector } from 'app/atoms/selectors';
import { List } from 'app/components/List';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { LoadableContent } from 'app/components/LoadableContent';

import { messages } from './messages';
import { useData } from './hooks';
import { Header } from './components/Header';
import { ProductItem } from './components/ProductItem';
import { ReceiptInfo } from './components/ReceiptInfo';
import { enabledIfSet } from 'utils/components/enabledIfSet';
import { SectionLabel } from 'app/components/Labels/SectionLabel';

export const ReceiptPage = () => {
  const { institute } = useInstituteSelector();

  const { currency } = institute.country;

  // const { popToRoot } = useContextModuleNavigator({
  //   context: institute.id,
  //   rootEndpoint: paths.orders.list,
  // });

  const { id }: { id?: string } = useParams();

  const { receiptId } = useMemo(() => {
    return {
      receiptId: id ? parseInt(id) : undefined,
    };
  }, [id]);

  const { t } = useTranslation();

  const { queryState, summary } = useData(receiptId);

  const { error, isLoading, data } = queryState;

  const pageTitle = useMemo(
    () =>
      data ? t(messages.pageTitle(), { identifier: data.receipt.number }) : '',
    [data, t],
  );

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageWrapper
        header={
          <Header
            backBehaviour
            currency={currency}
            label={pageTitle}
            summary={summary}
          />
        }
      >
        <LoadableContent error={error} isLoading={isLoading}>
          <ContentWrapper>
            {enabledIfSet(data, data => (
              <ReceiptInfo className="receipt-info" receipt={data.receipt} />
            ))}
            <SectionLabel>{t(messages.productsSection())}</SectionLabel>
            <ListWrapper>
              {data?.products.map(product => (
                <ProductItem
                  currency={currency}
                  key={product.id}
                  product={product}
                />
              ))}
            </ListWrapper>
          </ContentWrapper>
        </LoadableContent>
      </PageWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  > .receipt-info {
    margin-bottom: 1rem;
  }
`;

const ListWrapper = styled(List)`
  overflow-y: scroll;
`;
