import { useMemo } from 'react';

import {
  PropertyMapping,
  Value,
  ValueFormatter,
} from 'app/pages/Stats/StatsPage/types';
import { Optional } from 'types/misc';

import { useClustering } from './useClustering';
import { useValueFormatter } from '../../../../hooks';

interface Options {
  propertyMapping: PropertyMapping;
  values: any[];
  valueFormatter: Optional<ValueFormatter>;
}

export const useData = (options: Options) => {
  const { propertyMapping, values: rawValues, valueFormatter } = options;

  const { clusteringMode, values: clusteredValues } = useClustering({
    values: rawValues,
  });

  const { values: formattedValues } = useValueFormatter({
    values: clusteredValues,
    valueFormatter,
    context: { clusteringMode },
  });

  // Map keys with their translated values for the graph legend
  const values = useMemo(() => {
    const renameKeys = (obj: any, newKeys: any) => {
      const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
      });
      return Object.assign({}, ...keyValues);
    };
    return formattedValues.map(value => renameKeys(value, propertyMapping));
  }, [formattedValues]);

  return { values };
};
