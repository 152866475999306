import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Switch } from 'app/components/Inputs/Switch';
import { useFormContext } from 'react-hook-form';
import { fieldName } from 'utils/form';

import { Button } from 'app/components/Button';
import {
  DefaultFormSection,
  FormSection,
  FormSectionTitle,
} from 'app/components/Forms/FormSection';

import { Contract } from './sections/Contract';
import { BankAccount } from './sections/BankAccount';
import { Customer } from './sections/Customer';
import { messages } from '../messages';
import { Avatar } from './sections/Avatar';
import { Institute } from './sections/institute';

interface Props {
  canSubmit: boolean;
  isSubmitting: boolean;
  methods?: any;
}

export const Form = (props: Props) => {
  const { canSubmit, isSubmitting, methods } = props;
  //  const { watch } = useFormContext();
  // const namePrefix = 'subscriptionPayment';
  // const displayBankInfo = watch(fieldName('displayBankInfo', namePrefix));
  const [isSKINorPJContract, setIsSKINorPJContract] = useState(false);

  const { t } = useTranslation();

  const location = useLocation();

  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => {
    if (hasRendered) {
      // Called after the first render loop (this could be extracted inside a custom hook if used more than once)
      if (location.hash) {
        document.getElementById(location.hash.slice(1))?.scrollIntoView();
      }
    } else {
      setHasRendered(true);
    }
  }, [hasRendered, location.hash]);

  const onContractChanged = (arg: any) => {
    console.log(typeof arg);
    if (arg == 2 || arg == 8) {
      setIsSKINorPJContract(true);
    } else {
      setIsSKINorPJContract(false);
    }
  };

  return (
    <Wrapper>
      <Button
        className="submit"
        //disabled={!canSubmit}
        //isSpinning={isSubmitting}
        type="submit"
      >
        {t(messages.submit())}
      </Button>

      <Grid justifyContent="center" container spacing={4}>
        <Grid item md={2}>
          <Avatar disabled={false /*isSubmitting*/} />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item md={8}>
          <FormSection title={t(messages.sections.customer())}>
            <Customer disabled={false /*isSubmitting*/} />
          </FormSection>
        </Grid>
        <Grid item md={4}>
          <DefaultFormSection
            id="contract-section"
            title={
              <SectionTitleWrapper>
                <FormSectionTitle>
                  {t(messages.sections.contract())}
                </FormSectionTitle>
              </SectionTitleWrapper>
            }
          >
            <Contract
              disabled={false /*isSubmitting*/}
              methods={methods}
              onContractChanged={onContractChanged}
              isSKINorPJContract={isSKINorPJContract}
            />
          </DefaultFormSection>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item sm={12}>
          <FormSection title={t(messages.sections.institute())}>
            <Institute />
          </FormSection>
        </Grid>
      </Grid>
      {isSKINorPJContract &&
        <Grid container spacing={1}>
          <Grid item sm={8}>
            <FormSection
              id="bank-account"
              title={t(messages.sections.bankAccount())}
            >
              <BankAccount
                disabled={false /*isSubmitting*/}
                isSKINorPJContract={isSKINorPJContract}
              />
            </FormSection>
          </Grid>
        </Grid>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
  .submit {
    float: right;
  }
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
`;