import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useFormContext } from 'react-hook-form';

import { Button } from 'app/components/Button';
import { SelectInputOption } from 'app/components/Inputs/SelectInput';
import { Discount } from 'types/API/discounts';

import { messages } from '../messages';
import { messages as messagesFromPromotion } from '../../DiscountPage/messages';
import { DiscountInput } from '../../components/DiscountInput';
import { General } from './sections/General';
import { FormSection } from 'app/components/Forms/FormSection';
import { Dates } from './sections/Dates';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { Grid } from '@material-ui/core';
import { TextInput } from 'app/components/Inputs/TextInput';
import { getCurrencySymbol } from 'utils/currency';

interface Props {
  canSubmit: boolean;
  currency: string;
  discountAmountOptionsMap: Map<Discount.Type, SelectInputOption[]>;
  isSubmitting: boolean;
  prestationsOptions: SelectInputOption[];
  sessionsCountOptions: SelectInputOption[];
}

export const Form = (props: Props) => {
  const {
    canSubmit,
    currency,
    discountAmountOptionsMap,
    isSubmitting,
    prestationsOptions,
    sessionsCountOptions,
  } = props;
  const { t } = useTranslation();
  const shouldListShowOtherPrice = true;
  const { watch } = useFormContext();
  const currencySymbol = getCurrencySymbol(currency);
  const discountType = watch('discountType');
  const [discountTypeSelected, setDiscountTypeSelected] = useState('percent');
  const [otherPriceValueForDiscountPriceMode, setOtherPriceValueForDiscountPriceMode] = useState('');
  const [displayCustomPriceFieldForDiscountPriceMode, setDisplayCustomPriceFieldForDiscountPriceMode] = useState(false);
  const [customDiscountOptionSelected, setCustomDiscountOptionSelected] =
    useState(false);

  const discountAmountOptions = useMemo(() => {
    var myList = discountAmountOptionsMap.get(discountType)!
    if (shouldListShowOtherPrice) {
      let customOption1 = myList?.filter(
        option => option.id === 'customOption',
      );
      if (customOption1 !== undefined && customOption1.length === 0) {
        let customOption = {
          id: 'customOption',
          label: 'Montant spécifique',
        };
        myList.unshift(customOption);
      }
    }
    return myList;
  },
    [discountAmountOptionsMap, discountType],
  );

  const onDiscountChangeFromDiscountPriceMode = (arg: string) => {
    let isAutreSelected = arg.includes('customOption');
    console.log(`onDiscountChangeFromDiscountPriceMode-->arg:${arg}, isAutreSelected:${isAutreSelected}`);
    setDisplayCustomPriceFieldForDiscountPriceMode(isAutreSelected);
//    setDisplayCustomPriceField(isAutreSelected);
  };
  
  const discountTypeChanged = (value: string) => {
    setDiscountTypeSelected(value);
    console.log(
      `discountTypeChanged, value:${value}, discountTypeSelected:${discountTypeSelected}`,
    );
  };
  const formatCustomValue = (value: string): string => {
    //Strip all non-numeric characters from string
    //Regex.Replace(s, "[^0-9.]", "");
    return value.replace(/[^0-9$.]/g, ''); /*replace(/[^0-9$.,]/g, '');*/ //.replace(/\D/g, '');
  };
  const handleTextInputChangeForDiscountPriceMode = (value: string) => {
    var newValue = formatCustomValue(value);
    //convert it to float and check if it is > 50, if it is change value to 50
    let floatValue = Math.abs(parseFloat(newValue));
    if (floatValue > 50) newValue = '50';
    newValue = newValue.length > 0 ? '-' + newValue : newValue;
    setOtherPriceValueForDiscountPriceMode(newValue);
  };

  return (
    <Wrapper>
      <FormSection title={t(messages.sections.general())}>
        <General
          disabled={isSubmitting}
          prestationsOptions={prestationsOptions}
          sessionsCountOptions={sessionsCountOptions}
        />
      </FormSection>
      <FormSection title={t(messages.sections.price())}>
        <Grid container spacing={1}>
          <FormInputGrid item sm={12}>
            <DiscountInput
              currency={currency}
              disabled={isSubmitting}
              label={t(messages.discountAmount.label())}
              name="discountValue"
              options={discountAmountOptions}
              placeholder={t(messages.discountAmount.placeholder())}
              //required
              onDiscountChange={onDiscountChangeFromDiscountPriceMode}
              discountTypeChanged={discountTypeChanged}
              customDiscountOptionSelected={customDiscountOptionSelected}
              toggleName="discountType"
              hasCustomPrice={shouldListShowOtherPrice}
            />

            {shouldListShowOtherPrice && displayCustomPriceFieldForDiscountPriceMode && (
                <TextInputWrapper isForFixedAmount={false}>
                  <TextInput
                    className="autreMontant"
                    name="autreMontantForDiscountPriceMode"
                    handleChange1={handleTextInputChangeForDiscountPriceMode}
                    value={otherPriceValueForDiscountPriceMode}
                    placeholder={
                      discountTypeSelected === 'percent'
                        ? t(
                            messagesFromPromotion.discountAmount.placeholderSetAnotherDiscountPercentage(),
                          )
                        : t(
                            messagesFromPromotion.discountAmount.placeholderSetAnotherPrice()
                          )
                    }
                  />
                  <div className="autreMontantDiscountType">{discountTypeSelected === 'percent' ? '%' : currencySymbol}</div>
                </TextInputWrapper>
              )}
          </FormInputGrid>
        </Grid>
      </FormSection>
      <FormSection title={t(messages.sections.dates())}>
        <Dates disabled={isSubmitting} />
      </FormSection>

      <Button
        className="submit"
        disabled={!canSubmit}
        isSpinning={isSubmitting}
        type="submit"
      >
        {t(messages.submit())}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .submit {
    margin-top: 3rem;
    float: right;
  }
`;

interface ContentProps {
  isForFixedAmount: boolean;
}
const TextInputWrapper = styled.div<ContentProps>`
    margin-left:  ${p => (p.isForFixedAmount ? '0rem' : '8.5rem')};
    fmargin-left: 8.5rem;
    width: 250px;
    height: 150px;
    display: block;
    dbackground-color: red;
    tmext-align: inline-block;
    lpadding-top:10px;

    > .autreMontant {
      kdisplay: inline-block;
      width: 185px;
      margin-right: 5px;
    }

    > .autreMontant > * {
      kdisplay: inline-block;
      font-size: 1.0rem;    
    }

    > .autreMontantDiscountType {
      padding-top:18px;
      display: inline-block;
      width: 50px;
      ffont-weight:bold;
      text-align: left;
      fbackground-color: green;

    }
  }
`;