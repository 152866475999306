import { first, includes } from 'lodash';
import { Institute } from 'types/API/institutes';

export const instituteFromId = ({
  id,
  institutes,
}: {
  id: number;
  institutes: Institute.Normal[];
}) => first(institutesFromIds({ ids: [id], institutes }));

export const institutesFromIds = ({
  ids,
  institutes,
}: {
  ids: number[];
  institutes: Institute.Normal[];
}) => institutes.filter(institute => includes(ids, institute.id));
