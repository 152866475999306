import { useCallback, useMemo } from 'react';

import {
  fetchPaidReceiptsRequest,
  fetchPendingReceiptsRequest,
} from 'app/services/receipts';
import { genericQueryKey } from 'utils/cache/keys';
import { fetchSalesRequest } from 'app/services/sales';
import { useQuery } from 'app/hooks';

import { ReceiptTab } from '../types';

export const useQueries = (instituteId: number) => {
  const parameters = { instituteId };

  const pendingResult = useQuery(
    genericQueryKey({
      identifier: 'pendingReceipts',
      parameters,
    }),
    () => fetchPendingReceiptsRequest(parameters),
  );

  const paidResult = useQuery(
    genericQueryKey({
      identifier: 'paidReceipts',
      parameters,
    }),
    () => fetchPaidReceiptsRequest(parameters),
  );

  const paidProductsResult = useMemo(() => {
    const { data, ...rest } = paidResult;
    return {
      data: data?.filter(receipt => receipt.total.byCategories.vente > 0),
      ...rest,
    };
  }, [paidResult]);

  const { data: sales } = useQuery(
    genericQueryKey({
      identifier: 'sales',
      parameters,
    }),
    () => fetchSalesRequest(parameters),
  );

  const queryResult = useCallback(
    (tab: ReceiptTab) => {
      const sections = [pendingResult, paidResult, paidProductsResult];
      return sections[tab];
    },
    [paidResult, paidProductsResult, pendingResult],
  );

  return { queryResult, sales };
};
