import React, { PropsWithChildren } from 'react';

import { ModalLoader } from 'app/hoc/ModalLoader';

import { ObjectivesWeeklyModal } from '../../../ObjectivesWeeklyModal';
import { ModalStates } from '../../hooks/useModals';

type Props = PropsWithChildren<
  ModalStates & {
    instituteId: number;
    setWeeklyObjectives: (weeklyObjectives: any) => void;
  }
>;

export const ModalsProvider = (props: Props) => {
  const { children, setWeeklyObjectives, weeklyObjectivesModal } = props;

  const renderWeeklyObjectives = () => (
    <ModalLoader shouldRender={weeklyObjectivesModal.open}>
      <ObjectivesWeeklyModal
        close={weeklyObjectivesModal.toggleOpenState}
        validate={weeklyObjectives => {
          setWeeklyObjectives(weeklyObjectives);
          weeklyObjectivesModal.toggleOpenState();
        }}
      />
    </ModalLoader>
  );

  return (
    <>
      {children}
      {renderWeeklyObjectives()}
    </>
  );
};
