import { useQuery } from 'app/hooks';
import { fetchDummyStatsRequest } from 'app/services/stats';
import { first, includes } from 'lodash';
import { genericQueryKey } from 'utils/cache/keys';
import { ContentConfiguration, StatKind } from '../../../types';

interface Options {
  configuration: ContentConfiguration;
  instituteId: number;
  kind: StatKind;
}

export const useData = (options: Options) => {
  const { configuration, instituteId, kind } = options;

  const queryResult = useQuery(
    genericQueryKey({
      identifier: 'stats',
      parameters: { instituteId, kind },
    }),
    () => {
      if (configuration.datasource) {
        return configuration.datasource();
      } else {
        return fetchDummyStatsRequest({ kind });
      }
    },
  );

  return { queryResult };
};
