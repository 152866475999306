import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { ActionPageHeader } from 'app/components/PageHeaders/ActionPageHeader';
import { LoadableContent } from 'app/components/LoadableContent';
import { useInstituteSelector } from 'app/atoms/selectors';
import { paths } from 'app/routes/paths';
import { ReactComponent as AddIcon } from 'app/assets/plus.svg';
import { EmptyState } from 'app/components/EmptyState';

import { messages } from './messages';
import { OrderItem } from './components/OrderItem';

import { fetchOrdersRequest } from 'app/services/orders';
import { Order } from 'types/API/orders';
import styled from 'styled-components/macro';

export const OrdersPage = () => {
  const { institute } = useInstituteSelector();

  const currency = institute.country.currency;

  const history = useHistory();
  const { t } = useTranslation();

  const [data, setData] = useState<Array<Order.Normal>>([]);

  const pageTitle = t(messages.pageTitle());

  const onOrderCreation = () => {
    history.push(paths.orders.create);
  };

  const onOrderEdition = ({ orderId }: { orderId: number }) => {
    console.log(`orderId: ${orderId}`);
    const location = generatePath(paths.orders.update, { id: orderId });
    history.push(location);
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const loadData = async () => {
    setData([])

    fetchOrdersRequest({ instituteId: institute.id }).then(data => {
      setLoading(false);
      setData(data)
    }).catch(error => {
      setError(true)
    })
  }

  useEffect(() => {
    loadData();
    return () => { };
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitle} : {institute.name}</title>
      </Helmet>

      <PageWrapper
        header={
          <ActionPageHeader
            button={{
              onClick: onOrderCreation,
              label: t(messages.add()),
              icon: <AddIcon />,
            }}
            title={t(messages.pageTitle()) + " : " + institute.name}
          />
        }
      >
        {error ?
          <p>Erreur!</p>
          :
          <LoadableContent
            isLoading={loading}
            isEmpty={data.length === 0}
            emptyNodeRenderer={() => (
              <EmptyState label={t(messages.emptyLabel())} />
            )}
          >
            <ReversedList>
              {data.map(order => (
                <OrderItem
                  currency={currency}
                  key={order.orderNumber}
                  order={order}
                  onClick={() => onOrderEdition({ orderId: order.id })}
                />
              ))}
            </ReversedList>
          </LoadableContent>
        }
      </PageWrapper>
    </>
  );
};

export const ReversedList = styled.div`
  display: flex;
  flex-direction: column-reverse;
  > div:not(:first-child) {
    margin-bottom: 1rem;
  }
`;
