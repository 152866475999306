import { omit } from 'lodash';

import { Optional } from 'types/misc';
import { Cure } from 'types/API/cures';
import { entityEndpoint } from 'utils/entities/identifier';
import { Discount } from 'types/API/discounts';
import { formatDateInput } from 'utils/formatters/date';

import { FormValues } from './useForm/types';

export const useTransformData = () => {
  const getFormValues = (cure: Optional<Cure.Normal>): FormValues => {
    if (!cure) return { discountType: Discount.Type.PERCENT };

    const payload = omit(cure, ['id', 'institute']);
    // const payload = pick(cure, [
    //   'discountType',
    //   'discountValue',
    //   'endDate',
    //   'name',
    //   'product',
    //   'sessions',
    //   'startDate',
    // ]);
    const {
      discountValue: rawDiscountValue,
      endDate,
      prestation: rawPrestation,
      sessions: rawSessions,
      startDate,
      ...rest
    } = payload;
    return {
      discountValue: [rawDiscountValue!.toString()],
      endDate: formatDateInput({
        date: endDate,
      }),
      sessions: [rawSessions!.toString()],
      startDate: formatDateInput({
        date: startDate,
      }),
      product: [rawPrestation!.id.toString()],
      ...rest,
    };
  };

  const getCreatePayload = ({
    formValues,
    instituteId,
  }: {
    formValues: FormValues;
    instituteId: number;
  }): Cure.CreatePayload => {
    const {
      discountValue: rawDiscountValues,
      endDate,
      product: rawProducts,
      sessions: rawSessions,
      startDate,
      autreMontantForDiscountPriceMode,
      ...values
    } = formValues as Required<FormValues>;
    const productId = rawProducts[0];
    console.log(`autreMontantForDiscountPriceMode:${autreMontantForDiscountPriceMode}, rawDiscountValues: ${rawDiscountValues}`);
    return {
      discountValue: autreMontantForDiscountPriceMode !== undefined && autreMontantForDiscountPriceMode !== '0' && autreMontantForDiscountPriceMode !== null && autreMontantForDiscountPriceMode !== ''
      ? Math.abs(parseFloat(autreMontantForDiscountPriceMode))  > 50 ? 50 : Math.abs(parseFloat(autreMontantForDiscountPriceMode))
      : parseFloat(rawDiscountValues[0]), //parseInt(rawDiscountValues[0]),
      endDate: new Date(endDate).toISOString(),
      institute: entityEndpoint({ id: instituteId, module: 'institutes' }),
      price: 0,
      product: entityEndpoint({ id: productId, module: 'products' }),
      sessions: parseInt(rawSessions[0]),
      startDate: new Date(startDate).toISOString(),
      ...values,
    };
  };

  const getUpdatePayload = ({
    formValues,
    instituteId,
  }: {
    formValues: FormValues;
    instituteId: number;
  }): Cure.UpdatePayload => {
    return getCreatePayload({ instituteId, formValues });
  };

  return { getCreatePayload, getFormValues, getUpdatePayload };
};
