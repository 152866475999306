import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';

import { messages } from '../../../messages';
import { DatePicker } from 'app/components/Inputs/DatePicker';

interface Props {
  disabled: boolean;
}

export const Dates = ({ disabled }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <FormInputGrid item sm={6}>
        <FormLabel>{t(messages.startDate.label())}</FormLabel>
        <DatePicker disabled={disabled} name="startDate" required />
      </FormInputGrid>
      <FormInputGrid item sm={6}>
        <FormLabel>{t(messages.endDate.label())}</FormLabel>
        <DatePicker disabled={disabled} name="endDate" required />
      </FormInputGrid>
    </Grid>
  );
};
