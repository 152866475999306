import {
  CartesianGrid,
  Legend,
  Line as DefaultLine,
  LineChart as DefaultLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { LineChartDescription } from 'app/pages/Stats/StatsPage/types/charts';

interface Props {
  description: LineChartDescription;
  values: any[];
}

export const LineChart = (props: Props) => {
  const { description, values } = props;

  return (
    <ResponsiveContainer>
      <DefaultLineChart
        data={values}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={description.xAxisProperty} />
        <YAxis />
        <Tooltip />
        <Legend />
        {description.lines.map(line => (
          <DefaultLine
            key={line.property}
            dataKey={line.property}
            stroke={line.strokeColor}
            type="monotone"
            strokeWidth={3}
          />
        ))}
      </DefaultLineChart>
    </ResponsiveContainer>
  );
};
