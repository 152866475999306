import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components/macro';

import {
  ListItem as DefaultListItem,
  ListItemProps,
} from 'app/components/ListItem';
import {
  EntriesWrapper,
  InfoEntry,
  InfoEntrySeparator,
} from 'app/components/ListItem/InfoEntry';
import { CustomerInfo } from 'app/components/ListItem/CustomerInfo';
import { Receipt } from 'types/API/receipts';
import { enabledIfSet } from 'utils/components/enabledIfSet';

import { messages } from './messages';

interface Props extends ListItemProps {
  receipt: Receipt.Normal;
}

export const ReceiptInfo = (props: Props) => {
  const { receipt, ...rest } = props;

  const { t } = useTranslation();

  const userPaid = useMemo(() => {
    if (receipt.userPaid && receipt.paidAt) {
      const { firstname } = receipt.userPaid;
      return {
        date: new Date(receipt.paidAt),
        firstname,
      };
    }
    return undefined;
  }, [receipt]);

  return (
    <ListItem {...rest}>
      <CustomerInfo customer={receipt.customer} />

      <EntriesWrapper>
        <InfoEntry
          label={t(messages.createdBy())}
          value={t(messages.userDateFormat(), {
            user: receipt.userCreate.firstname,
            date: new Date(receipt.createdAt),
            escapeInterpolation: true,
          })}
        />
        {enabledIfSet(userPaid, userPaid => (
          <>
            <InfoEntrySeparator />
            <InfoEntry
              label={t(messages.bankedBy())}
              value={t(messages.userDateFormat(), {
                user: userPaid.firstname,
                date: userPaid.date,
              })}
            />
          </>
        ))}
      </EntriesWrapper>
    </ListItem>
  );
};

const ListItem = styled(DefaultListItem)`
  margin-right: 0.5rem;
  width: auto;
`;
