import { ModalState, useModal } from 'app/hooks';

type WeeklyObjectivesModalState = ModalState<{ userId: number }>;
export interface ModalStates {
  weeklyObjectivesModal: WeeklyObjectivesModalState;
}

export const useModals = (): ModalStates => {
  const weeklyObjectivesModal: WeeklyObjectivesModalState = useModal();

  return {
    weeklyObjectivesModal,
  };
};
