import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  createPageTitle: () => _t(translations.special_offers.cure.create.page_title),
  updatePageTitle: () => _t(translations.special_offers.cure.update.page_title),
  name: {
    label: () => _t(translations.special_offers.cure.name.label),
    placeholder: () => _t(translations.special_offers.cure.name.placeholder),
  },
  sessionsCount: {
    label: () => _t(translations.special_offers.cure.sessions_count.label),
    placeholder: () =>
      _t(translations.special_offers.cure.sessions_count.placeholder),
  },
  prestation: {
    label: () => _t(translations.special_offers.cure.prestation.label),
    placeholder: () =>
      _t(translations.special_offers.cure.prestation.placeholder),
  },
  discountAmount: {
    label: () => _t(translations.special_offers.cure.discount_amount.label),
    placeholder: () =>
      _t(translations.special_offers.cure.discount_amount.placeholder),
  },
  startDate: {
    label: () => _t(translations.special_offers.cure.start_date.label),
  },
  endDate: {
    label: () => _t(translations.special_offers.cure.end_date.label),
  },
  submit: () => _t(translations.special_offers.cure.submit),
  sections: {
    general: () => _t(translations.special_offers.cure.sections.general),
    price: () => _t(translations.special_offers.cure.sections.price),
    dates: () => _t(translations.special_offers.cure.sections.dates),
  },
};
