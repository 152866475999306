import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { messages } from 'app/i18n/messages';
import { useMemo } from 'react';

export const useFormValidation = () => {
  const { t } = useTranslation();
  const schema = useMemo(
    () =>
      yup.object({
        phone: yup.string(),
        address: yup.string().required(t(messages.forms.requiredFieldError())),
        complement: yup.string(),
        zipcode: yup.string().required(t(messages.forms.requiredFieldError())),
        city: yup.string().required(t(messages.forms.requiredFieldError())),

        siret: yup.string().required(t(messages.forms.requiredFieldError())),
        ape: yup.string().required(t(messages.forms.requiredFieldError())),
        codetva: yup.string().required(t(messages.forms.requiredFieldError())),

        iban: yup.string().required(t(messages.forms.requiredFieldError())),
        ics: yup.string(),
        //@TODO: make it required when the API send it.
        /*.required(t(messages.forms.requiredFieldError())),*/
      }),
    [t],
  );
  return { schema };
};
