import { User } from 'types/API/users';
import { listFormatToDetailFormat } from 'types/format/users';
import { request } from 'utils/request';

export const fetchUsersRequest = async ({
  instituteId,
}: {
  instituteId: number;
}) => {
  const response = await request<User.ListFormat[]>(
    `api/users/institute/${instituteId}`,
    {
      method: 'GET',
    },
  );
  // List & Detail does not share the same format (but the same infos) so we have to make it generic in order to simplify cache management.
  // Should probably be fixed on the API side.
  return listFormatToDetailFormat(response);
};

export const fetchUserRequest = ({ userId }: { userId: number }) =>
  request<User.DetailFormat>(`api/users/${userId}`, {
    method: 'GET',
  });

export const deleteUserRequest = ({ userId }: { userId: number }) =>
  request<User.DetailFormat>(`api/users/${userId}`, {
    method: 'DELETE',
  });

export const updateUserRequest = ({
  userId,
  data,
}: {
  userId: number;
  data: User.UpdatePayload;
}) =>
  request<User.DetailFormat>(`api/users/${userId}`, {
    method: 'PUT',
    data,
  });
