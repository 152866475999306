import { useMutation, useQueryClient } from 'react-query';

import { addOrUpdateObjectToCache } from 'utils/react-query/cache';
import { genericQueryKey } from 'utils/cache/keys';
import { Discount } from 'types/API/discounts';
import {
  createDiscountRequest,
  FetchMode,
  updateDiscountRequest,
} from 'app/services/discounts';
import { useMutationOptions } from 'app/hooks/modal/useMutationsOptions';

export const useMutations = (instituteId: number) => {
  const queryClient = useQueryClient();

  const parameters = { instituteId, fetchMode: FetchMode.ALL };
  const updateCache = (discount: Discount.Normal) => {
    const queryKey = genericQueryKey({
      identifier: 'discounts',
      parameters,
    });
    addOrUpdateObjectToCache({
      object: discount,
      queryClient,
      queryKey,
    });
  };

  const mutationOptions = useMutationOptions({
    successCallback: (response: Discount.Normal, _) => {
      updateCache(response);
    },
  });

  const { mutate: createMutation, isLoading: isCreating } = useMutation(
    createDiscountRequest,
    mutationOptions,
  );

  const { mutate: updateMutation, isLoading: isUpdating } = useMutation(
    updateDiscountRequest,
    mutationOptions,
  );

  const isLoading = isUpdating || isCreating;

  return {
    isLoading,
    createMutation,
    updateMutation,
  };
};
