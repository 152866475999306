import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  createPageTitle: () => _t(translations.user.create.page_title),
  updatePageTitle: () => _t(translations.user.update.page_title),
  submit: () => _t(translations.user.submit),
  firstname: {
    label: () => _t(translations.user.firstname.label),
    placeholder: () => _t(translations.user.firstname.placeholder),
  },
  lastname: {
    label: () => _t(translations.user.lastname.label),
    placeholder: () => _t(translations.user.lastname.placeholder),
  },
  password: {
    label: () => _t(translations.user.password.label),
    placeholder: () => _t(translations.user.password.placeholder),
  },
  role: {
    label: () => _t(translations.user.role.label),
    placeholder: () => _t(translations.user.role.placeholder),
    options: (option: string) => _t(translations.user.role.options[option]),
  },
  institutes: {
    label: () => _t(translations.user.institutes.label),
    placeholder: () => _t(translations.user.institutes.placeholder),
  },
  sections: {
    general: () => _t(translations.user.sections.general),
    access: () => _t(translations.user.sections.access),
  },
  updatePassword: () => _t(translations.user.update_password),
};
