import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { Switch } from 'app/components/Inputs/Switch';
import { TextInput } from 'app/components/Inputs/TextInput';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { fieldName } from 'utils/form';
import { Address } from 'app/components/Domain/forms/Address';

import { Names } from '../Customer/Names';
import { messages } from '../../../messages';

interface Props {
  disabled: boolean;
  isSKINorPJContract: boolean;
}

export const BankAccount = ({
  disabled, isSKINorPJContract }: Props) => {
  const { t } = useTranslation();

  const { watch } = useFormContext();

  const namePrefix = 'subscriptionPayment';

  const isCustomerDebtor = watch(fieldName('isCustomerDebtor', namePrefix));

  return (
    <Grid container spacing={1}>
      <FormInputGrid item sm={12}>
        <FormLabel>{t(messages.iban.label())}</FormLabel>
        <TextInput
          autoComplete="new-password"
          disabled={disabled}
          name={fieldName('iban', namePrefix)}
          placeholder={t(messages.iban.placeholder())}
          type="password"
          required={isSKINorPJContract}
        />
      </FormInputGrid>
      {
        <FormInputGrid item sm={12}>
          <Switch
            label={t(messages.payerIsSubscriber())}
            name={fieldName('isCustomerDebtor', namePrefix)}
          />
        </FormInputGrid>
      }
      {!isCustomerDebtor ? (
        <>
          <Names disabled={disabled} namePrefix={namePrefix} isRequired={!isCustomerDebtor} />
          <Address disabled={disabled} namePrefix={namePrefix} isRequired={!isCustomerDebtor} />
        </>
      ) : null}
    </Grid>
  );
};