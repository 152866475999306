import { useTranslation } from 'react-i18next';
import React, { PropsWithChildren, useMemo } from 'react';

import { Confirmation } from 'app/components/Modals/Confirmation';
import { ModalLoader } from 'app/hoc/ModalLoader';
import { useMutationOptions } from 'app/hooks/modal/useMutationsOptions';
import { TextModal } from 'app/components/Modals/TextModal';

import { EntityKind, ModalStates } from '../../hooks/useModals';
import { messages } from '../../messages';
import { useMutations } from '../../hooks';

type Props = PropsWithChildren<
  ModalStates & {
    instituteId: number;
  }
>;

export const ModalsProvider = (props: Props) => {
  const { children, deleteConfirmation, instituteId, maxDiscountCountReached } =
    props;

  const { t } = useTranslation();

  const {
    deleteCureMutation,
    deleteDiscountMutation,
    isDeletingCure,
    isDeletingDiscount,
  } = useMutations(instituteId);

  const { context } = deleteConfirmation;

  const mutationOptions = useMutationOptions({
    successCallback: () => {
      deleteConfirmation.toggleOpenState();
    },
    successMessage:
      context?.kind === EntityKind.CURE
        ? t(messages.cures.deleteAlertSuccessDescription())
        : t(messages.discounts.deleteAlertSuccessDescription()),
  });

  const modalProps = useMemo(() => {
    let confirmAction = () => {};
    let description = '';
    let title = '';

    if (context) {
      const { id } = context;
      switch (context.kind) {
        case EntityKind.DISCOUNT:
          confirmAction = () => deleteDiscountMutation({ id }, mutationOptions);
          description = t(messages.discounts.deleteConfirmationDescritption());
          title = t(messages.discounts.deleteConfirmationTitle());
          break;
        case EntityKind.CURE:
          confirmAction = () => deleteCureMutation({ id }, mutationOptions);
          description = t(messages.cures.deleteConfirmationDescritption());
          title = t(messages.cures.deleteConfirmationTitle());
          break;
      }
    }
    return { confirmAction, description, title };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const renderDeleteModal = () => (
    <ModalLoader shouldRender={deleteConfirmation.open}>
      <Confirmation
        cancelAction={deleteConfirmation.toggleOpenState}
        isConfirmActionLoading={isDeletingCure || isDeletingDiscount}
        {...modalProps}
      />
    </ModalLoader>
  );

  const renderMaxDiscountCountReachedModal = () => {
    const primaryAction = {
      action: maxDiscountCountReached.toggleOpenState,
      label: t(messages.maxDiscountAmountReached.primaryActionLabel()),
    };
    return (
      <ModalLoader shouldRender={maxDiscountCountReached.open}>
        <TextModal
          close={maxDiscountCountReached.toggleOpenState}
          description={t(messages.maxDiscountAmountReached.descritption())}
          primaryAction={primaryAction}
          title={t(messages.maxDiscountAmountReached.title())}
        />
      </ModalLoader>
    );
  };

  return (
    <>
      {children}
      {renderDeleteModal()}
      {renderMaxDiscountCountReachedModal()}
    </>
  );
};
