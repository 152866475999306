import {
  FormControlLabel as MuiFormControlLabel,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

export interface SwitchProps extends MuiSwitchProps {
  label: string;
}

export const Switch = (props: SwitchProps) => {
  const { label, ...rest } = props;
  return (
    <FormControlLabel
      control={<MuiSwitch disableRipple {...rest} />}
      label={label}
    />
  );
};

const FormControlLabel = styled(MuiFormControlLabel)`
  color: ${p => p.theme.text.body};
  & .MuiTypography-body1 {
    font-family: 'Gabriel Sans Cond';
    font-weight: 400;
    font-size: 0.9rem;
  }
`;
