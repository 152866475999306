import { useCallback, useEffect, useMemo } from 'react';
import { useForm as useHookForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormValues } from './types';

interface Options {
  // institute: any;
}

export const useForm = (options: Options) => {
  const {} = options;

  // const { schema } = useFormValidation();

  // const { getFormValues, getUpdatePayload } = useTransformData();

  // const { isLoading, updateMutation } = useMutations();

  // const defaultValues = useMemo(
  //   () => getFormValues(institute),
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [institute],
  // );
  const defaultValues = useMemo(() => ({}), []);
  const isLoading = false;

  const methods = useHookForm<FormValues>({
    defaultValues,
    mode: 'all',
    // resolver: yupResolver(schema),
  });

  const {
    formState: { isSubmitting: isFormSubmitting, isValid },
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const isSubmitting = isFormSubmitting || isLoading;

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      const options = {
        onSuccess(data, variables, context) {
          // onSuccess();
        },
      };
      // const updatePayload = getUpdatePayload({ formValues });
      // updateMutation(
      //   { instituteId: institute.id, data: updatePayload },
      //   options,
      // );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    canSubmit: isValid && !isSubmitting,
    isSubmitting,
    methods,
    onSubmit: handleSubmit(onSubmit),
  };
};
