import { Fragment } from 'react';
import { FiscalArchiveFile } from '../types';
import { FiscalArchive } from './FiscalArchive';
import styledHtml from 'styled-components/macro';

export const FiscalArchiveList = ({
	fiscalArchivesList,
	title,
}: {
	fiscalArchivesList: FiscalArchiveFile[];
	title: string;
}) => {
	return (
		<Fragment>
			<h2>{title}</h2>
			<FiscalArchiveWrapper>
				{fiscalArchivesList.map((archive: FiscalArchiveFile) => {
					return <FiscalArchive fiscalArchiveData={archive} key={archive.id} />;
				})}
				{fiscalArchivesList.length === 0 && <p>Aucune archive disponible</p>}
			</FiscalArchiveWrapper>
		</Fragment>
	);
};

const FiscalArchiveWrapper = styledHtml.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	background-color: white;
	border-radius: 20px;
	padding: 10px;
	margin-bottom: 30px;
`;
