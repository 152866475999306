import { Controller, useFormContext } from 'react-hook-form';
import React, { ChangeEvent } from 'react';
import { get } from 'lodash';

import {
  TextInput as UncontrolledTextInput,
  TextInputProps as UncontrolledTextInputProps,
} from '../Uncontrolled/TextInput';

type TextInputProps = Omit<UncontrolledTextInputProps, 'variant'> & {
  name: string;
  handleChange1?: (value: string) => void;
  trim?: boolean;
};

export const TextInput = (props: TextInputProps) => {
  const { name, trim, handleChange1, ...rest } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);
  const isError = !!error;
  const errorMessage = error?.message;

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    onChange: (...event: any[]) => void,
  ) => {
    const { value: eventValue } = e.target;
    onChange(trim ? eventValue.replace(/ /g, '') : eventValue);
    handleChange1?.(e.target.value);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur, onChange, value } }) => (
        <UncontrolledTextInput
          error={isError}
          fullWidth
          helperText={errorMessage}
          margin="none"
          onBlur={onBlur}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            handleChange(event, onChange);
          }}
          value={value || ''}
          variant="outlined"
          {...rest}
        />
      )}
    />
  );
};
