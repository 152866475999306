import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { List as RawList } from 'app/components/List';
import { LoadableContent } from 'app/components/LoadableContent';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { useInstituteSelector } from 'app/atoms/selectors';
import { EmptyState } from 'app/components/EmptyState';
import { paths } from 'app/routes/paths';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';

import { ReceiptItem } from './components/ReceiptItem';
import { messages } from './messages';
import { useQueries } from './hooks';
import { ReceiptTab } from './types';
import { kindFromURLParam, URLlocationFromKind } from './utils';
import { Selector } from './components/Selector';
import { Disableable } from 'app/components/Disableable';

export const ReceiptsPage = () => {
  const history = useHistory();
  const { kind }: { kind?: string } = useParams();

  const { institute } = useInstituteSelector();

  const { queryResult, sales } = useQueries(institute.id);

  const { t } = useTranslation();

  const [tab, setTab] = useState<ReceiptTab>(kindFromURLParam(kind));

  useEffect(() => {
    history.push(URLlocationFromKind(tab));
  }, [history, tab]);

  const handleTabChange = (newTab: ReceiptTab) => {
    setTab(newTab);
  };

  const onReceiptSelection = (id: number) => {
    const location = generatePath(paths.receipts.detail, { id });
    history.push(location);
  };

  const result = useMemo(() => queryResult(tab), [tab, queryResult]);

  const emptyLabelForTab = useCallback(
    (tab: ReceiptTab) => {
      switch (tab) {
        case ReceiptTab.PENDING:
          return t(messages.pending.emptyLabel());
        case ReceiptTab.PAID:
          return t(messages.paid.emptyLabel());
        case ReceiptTab.PAID_PRODUCTS:
          return t(messages.paidProducts.emptyLabel());
      }
    },
    [t],
  );

  const currency = institute.country.currency;

  return (
    <>
      <Helmet>
        <title>{t(messages.pageTitle())} : {institute.name} </title>
      </Helmet>
      <PageWrapper header={<PageHeader label={t(messages.pageTitle()) + " : " + institute.name} />}>
        <Disableable enabled={!result.isLoading}>
          <Selector
            currency={currency}
            currentTab={tab}
            onChange={handleTabChange}
            sales={sales}
          />
        </Disableable>
        <LoadableContent
          error={result.error}
          isLoading={result.isLoading}
          isEmpty={result.data?.length === 0}
          emptyNodeRenderer={() => <EmptyState label={emptyLabelForTab(tab)} />}
        >
          <List>
            {result.data?.map(receipt => (
              <ReceiptItem
                key={receipt.id}
                currency={institute.country.currency}
                onClick={() => onReceiptSelection(receipt.id)}
                receipt={receipt}
              />
            ))}
          </List>
        </LoadableContent>
      </PageWrapper>
    </>
  );
};

const List = styled(RawList)`
  margin-top: 1rem;
`;
