import { first, omit, pick } from 'lodash';

import { Customer } from 'types/API/customers';
import { Optional } from 'types/misc';
import { Subscription } from 'types/API/subscriptions';
import { formatDateInput } from 'utils/formatters/date';
import { entityEndpoint } from 'utils/entities/identifier';
import { messages } from 'app/i18n/messages';
import { useTranslation } from 'react-i18next';

import {
  CustomerFormValues,
  FormValues,
  SubscriptionFormValues,
  SubscriptionPaymentFormValues,
} from './useForm/types';
import { isCustomerDebtor } from './useForm/utils';

export const useFormData = () => {
  const { t } = useTranslation();

  const getFormValues = (
    customer: Optional<Customer.Normal>,
  ): {
    values: Optional<FormValues>;
    subscriptionPaymentId: Optional<number>;
  } => {
    if (!customer)
      return { values: undefined, subscriptionPaymentId: undefined };
    const customerValues = pick(customer, [
      'firstname',
      'lastname',
      'phone',
      'email',
      'mobile',
      'address',
      'complement',
      'zipcode',
      'city',
    ]) as CustomerFormValues;

    const subscription = customer.subscriptionsInstitute ? customer.subscriptionsInstitute[0] : customer.subscriptions[0];

    const subscriptionValues: SubscriptionFormValues = {
      rum: subscription.rum,
      instituteId: subscription.instituteId,
      startDate: formatDateInput({
        date: subscription.subscriptionDates.startDate,
      }),
      type: subscription.subscriptionType.type,
      endDate: formatDateInput({
        date: subscription.subscriptionDates.endDate,
      }),
      suppSkinEndDate: formatDateInput({
        date: subscription.subscriptionType.suppSkinEndDate,
      }),
      paymentDay: subscription.paymentDay,
      rejection: subscription.rejection,
      RejectionMotif: subscription.RejectionMotif,
      resiliate: subscription.resiliate,
    };

    const subscriptionPayment = first(
      first(customer.subscriptions)?.subscriptionPayments,
    );
    const subscriptionPaymentValues: SubscriptionPaymentFormValues = {
      isCustomerDebtor: isCustomerDebtor(customer),
      ...subscriptionPayment,
    };

    const values = {
      customer: customerValues,
      subscription: subscriptionValues,
      subscriptionPayment: subscriptionPaymentValues,
    };
    const subscriptionPaymentId = subscriptionPayment?.id;
    return { values, subscriptionPaymentId };
  };

  const getPayloads = ({
    subscriptionId,
    formValues,
  }: {
    subscriptionId: number;
    formValues: FormValues;
  }) => {
    const { customer, subscription, subscriptionPayment } = formValues;

    const customerPayload: Customer.Payload = {
      ...customer,
      pending: false,
    };

    var paymentDay = subscription.paymentDay

    if (!Number.isInteger(paymentDay)) {
      paymentDay = Number(subscription.paymentDay[0])
    }

    var RejectionMotif = subscription.RejectionMotif


    if (Array.isArray(RejectionMotif)) {
      RejectionMotif =  RejectionMotif[0]
    }

    const subscriptionPayload: Subscription.Payload = {
      rejection: subscription.rejection,
      RejectionMotif: RejectionMotif,
      resiliate: subscription.resiliate,
      paymentDay: paymentDay
    };

    const { isCustomerDebtor } = subscriptionPayment;

    const addressKeys = [
      'firstname',
      'lastname',
      'address',
      'complement',
      'zipcode',
      'city',
    ];

    let payerAddress = pick(customerPayload, addressKeys);

    if (!isCustomerDebtor) {
      payerAddress = pick(subscriptionPayment, addressKeys);
    }

    const subscriptionPaymentPayload: Subscription.Payment.Payload = {
      firstname: payerAddress?.firstname ?? '',
      lastname: payerAddress?.lastname ?? '',
      address: payerAddress?.address ?? '',
      complement: payerAddress?.complement ?? '',
      zipcode: payerAddress?.zipcode ?? '',
      city: payerAddress?.city ?? '',
      iban: subscriptionPayment.iban /*,//?.replace(/[^\w]/gi, '')*/ ?? '',
      subscription: entityEndpoint({
        id: subscriptionId,
        module: 'subscriptions',
      }),
    };

    return {
      customerPayload,
      subscriptionPayload,
      subscriptionPaymentPayload,
    };
  };

  const errorMapping = {
    iban: {
      filedName: 'subscriptionPayment.iban',
      message: t(messages.forms.invalidIBANError()),
    },
  };

  return {
    getFormValues,
    getPayloads,
    errorMapping,
  };
};
