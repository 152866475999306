import { atom } from 'recoil';

import { defaultState, InstituesState } from './types';
import { storageEffect } from '../effects/storage';
import { updateLocale } from '../effects/locale';

export const institutesState = atom<InstituesState>({
	default: defaultState,
	effects_UNSTABLE: [storageEffect('institutes'), updateLocale()],
	key: 'institutes',
});
