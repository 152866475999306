import Fuse from 'fuse.js';
import { get } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { debounce } from 'throttle-debounce';

import { Optional } from 'types/misc';

import { FuseSearchEvent, IFuseOptions } from './types';

export interface Options<T> extends IFuseOptions<T> {
  matchAllOnEmptyQuery?: boolean;
}

export function useFuse<T>({
  list,
  options,
}: {
  list: Optional<T[]>;
  options: Options<T>;
}) {
  const [query, updateQuery] = useState('');

  const { matchAllOnEmptyQuery = true, ...fuseOptions } = options;

  const fuse = useMemo(
    () => new Fuse(list ?? [], fuseOptions),
    [list, fuseOptions],
  );

  const isActive = !!query;

  const hits = useMemo(
    // if query is empty and `matchAllOnEmptyQuery` is `true` then return the entire list
    () =>
      !isActive && matchAllOnEmptyQuery
        ? list
        : fuse.search(query).map(result => result.item),
    [fuse, isActive, list, matchAllOnEmptyQuery, query],
  );

  // eslint-disable-next-line
  const setQuery = useCallback(debounce(100, updateQuery), []);

  const onSearch = useCallback(
    (event: FuseSearchEvent) => {
      const value = get(event.target, 'value', '');
      setQuery(value.trim());
    },
    [setQuery],
  );

  return {
    hits,
    isActive,
    onSearch,
    query,
    setQuery,
  };
}
