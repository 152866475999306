import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  shouldRender: boolean;
}>;

export const ModalLoader = ({ children, shouldRender }: Props) => {
  if (!shouldRender) return null;

  return children as JSX.Element;
};
