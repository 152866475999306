import { Route } from 'types/route';
import { FaqPage } from 'app/pages/FaqPage';

import { paths } from '../../paths';

const faq: Route = {
  authenticated: true,
  content: FaqPage,
  routerPath: paths.faq.index,
};

export const routes = [faq];
