import { useTotal } from 'app/pages/Orders/OrderPage/hooks/useContext';
import React from 'react';
import styled from 'styled-components/macro';



type BackBehaviour = boolean | (() => void);

export interface BottomBlueBarreProps {
  label?: string;
  count?: string;
  total?: number;
}

export const BottomBlueBarre = (props: BottomBlueBarreProps) => {
  const { total,quantity } = useTotal();

  return (
    <Wrapper >
      <HeaderEvent>{`Qté: ${quantity}`}</HeaderEvent>

      <HeaderEvent>{`Total: ${total.toFixed(2)}€`}</HeaderEvent>
    </Wrapper>
  );
};


const Wrapper = styled.div`
  background: #3459d6;
  height: 70px;
  position: sticky;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  bottom: 0;
  z-index:0;
  margin-left:125px;
  margin-top:-69px;
  padding-right:70px;
  @media (max-width: 959px) {
    position: fixed;
    bottom: 0;
    top: auto; /* Remove top property */
    margin-left: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width:100%
  }
`;

const HeaderEvent = styled.div`
color:white;
font-weight:bold;
font-size:28px;
text-align:center;
margin-left:50px;
@media (max-width: 959px) {
  font-size:25px;

}

`;
