import { useQuery } from 'app/hooks';
import {
  fetchCustomerByCardRequest,
  fetchCustomerRequest,
} from 'app/services/customers';
import { genericQueryKey } from 'utils/cache/keys';

export const useQueries = ({
  cardNumber,
  customerId,
  instituteId,
}: {
  cardNumber: string;
  customerId: number;
  instituteId: number;
}) => {
  const customerFromIdentifierParameters = { customerId, instituteId };
  const {
    data: customerFromIdentifier,
    error: errorFromIdentifier,
    isLoading: isLoadingFromIdentifier,
  } = useQuery(
    genericQueryKey({
      identifier: 'customer',
      parameters: customerFromIdentifierParameters,
    }),
    () => fetchCustomerRequest(customerFromIdentifierParameters),
    { enabled: !!customerId, cacheTime: 0 }, );

  const customerFromCardParameters = { cardNumber, instituteId };
  const {
    data: customerFromCard,
    error: errorFromCard,
    isLoading: isLoadingFromCard,
  } = useQuery(
    genericQueryKey({
      identifier: 'customer',
      parameters: customerFromCardParameters,
    }),
    () => fetchCustomerByCardRequest(customerFromCardParameters),
    { enabled: !!cardNumber },
  );

  const customer = customerFromIdentifier ?? customerFromCard;
  const error = errorFromIdentifier ?? errorFromCard;
  const isLoading = isLoadingFromIdentifier || isLoadingFromCard;

  return { customer, error: error, isLoading };
};
