import { useQueryClient } from 'react-query';

import { FetchMode, deleteDiscountRequest } from 'app/services/discounts';
import { genericQueryKey } from 'utils/cache/keys';
import { removeObjectFromCache } from 'utils/react-query/cache';
import { useMutation } from 'app/hooks';

import { EntityKind } from './useModals';
import { deleteCureRequest } from 'app/services/cures';

export const useMutations = (instituteId: number) => {
  const queryClient = useQueryClient();

  const updateCache = ({ id, kind }: { id: number; kind: EntityKind }) => {
    const parameters = { instituteId, fetchMode: FetchMode.ALL };
    const cacheIdentifier =
      kind === EntityKind.DISCOUNT ? 'discounts' : 'cures';

    const queryKey = genericQueryKey({
      identifier: cacheIdentifier,
      parameters,
    });

    removeObjectFromCache({
      object: { id },
      queryClient,
      queryKey,
    });
  };

  const { mutate: deleteDiscountMutation, isLoading: isDeletingDiscount } =
    useMutation(deleteDiscountRequest, {
      onSuccess: (_, { id }) => {
        updateCache({ id, kind: EntityKind.DISCOUNT });
      },
    });

  const { mutate: deleteCureMutation, isLoading: isDeletingCure } = useMutation(
    deleteCureRequest,
    {
      onSuccess: (_, { id }) => {
        updateCache({ id, kind: EntityKind.CURE });
      },
    },
  );

  return {
    deleteCureMutation,
    deleteDiscountMutation,
    isDeletingCure,
    isDeletingDiscount,
  };
};
