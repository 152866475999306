import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { TextInput } from 'app/components/Inputs/TextInput';
import {
  SelectInput,
  SelectInputOption,
} from 'app/components/Inputs/SelectInput';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';

import { messages } from '../../../messages';

interface Props {
  availabilityOptions: SelectInputOption[];
  disabled: boolean;
}

export const General = ({ availabilityOptions, disabled }: Props) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1}>
      <FormInputGrid item sm={8}>
        <FormLabel>{t(messages.name.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name="name"
          placeholder={t(messages.name.placeholder())}
          required
        />
      </FormInputGrid>
      <FormInputGrid item sm={4}>
        <FormLabel>{t(messages.availability.label())}</FormLabel>
        <SelectInput
          disabled={disabled}
          name="availability"
          options={availabilityOptions}
          placeholder={t(messages.availability.placeholder())}
          required
        />
      </FormInputGrid>
      <FormInputGrid item sm={12}>
        <FormLabel>{t(messages.description.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name="description"
          placeholder={t(messages.description.placeholder())}
        />
      </FormInputGrid>
      <FormInputGrid item sm={12}>
        <FormLabel>{t(messages.disclaimer.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name="disclaimer"
          placeholder={t(messages.disclaimer.placeholder())}
        />
      </FormInputGrid>
    </Grid>
  );
};
