import { Route } from 'types/route';
import { SpecialOffersPage } from 'app/pages/SpecialOffers/SpecialOffersPage';

import { paths } from '../../paths';
import { routes as cures } from './cures';
import { routes as discounts } from './discounts';

export const specialOffers: Route = {
  authenticated: true,
  content: SpecialOffersPage,
  routerPath: paths.specialOffers.list,
};

export const routes = [specialOffers, ...cures, ...discounts];
