import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Table } from 'app/components/Table';
import { TableCellParams } from 'app/components/Table/table';

import { messages } from '../../messages';
import { MonthTableRow } from '../../types';

interface Props {
  editable: boolean;
  rows: MonthTableRow[];
  onUpdate: (day: number, value: number) => void;
}

export const MonthTable = (props: Props) => {
  const { editable, rows, onUpdate } = props;

  const { t } = useTranslation();

  const columns = [
    { field: 'id', headerName: 'id', hide: true },
    {
      field: 'day',
      headerName: t(messages.columns.day()),
      renderCell: (params: TableCellParams) => {
        return <Cell>{params.value}</Cell>;
      },
    },
    {
      field: 'total_sales',
      headerName: t(messages.columns.totalSales()),
      minWidth: 190,
      editable,
    },
    {
      field: 'cumulated_total_sales',
      headerName: t(messages.columns.cumulatedTotalSales()),
      minWidth: 130,
    },
  ];

  const indexedRows = useMemo(
    () =>
      rows.map((row, index) => {
        const { date, ...rest } = row;
        return {
          id: index + 1,
          day: t(messages.dayDateFormat(), { date }),
          ...rest,
        };
      }),
    [rows, t],
  );

  return (
    <Table
      rows={indexedRows}
      columns={columns}
      onCellValueChange={params => {
        onUpdate(params.id as number, parseInt(params.value as string));
      }}
    />
  );
};

const Cell = styled.span`
  font-weight: 500;
`;
