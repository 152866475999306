import React, { ReactNode } from 'react';

interface Props {
  condition: boolean;
  trueConditionNode: ReactNode;
  falseConditionNode: ReactNode;
}

export const Conditional = ({
  condition,
  trueConditionNode,
  falseConditionNode,
}: Props) => <>{condition ? trueConditionNode : falseConditionNode}</>;
