import { useTranslation } from 'react-i18next';
import React from 'react';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { TextInput } from 'app/components/Inputs/TextInput';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { fieldName } from 'utils/form';

import { messages } from '../../../../pages/Subscriptions/SubscriptionPage/messages';

interface Props {
  disabled: boolean;
  namePrefix?: string;
  isRequired?: boolean;
}

export const Address = ({ disabled, namePrefix, isRequired = true }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <FormInputGrid item sm={12}>
        <FormLabel>{t(messages.address.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name={fieldName('address', namePrefix)}
          placeholder={t(messages.address.placeholder())}
          required={isRequired}
        />
      </FormInputGrid>
      <FormInputGrid item sm={12}>
        <FormLabel>{t(messages.additionnal_address.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name={fieldName('complement', namePrefix)}
          placeholder={t(messages.additionnal_address.placeholder())}
        />
      </FormInputGrid>
      <FormInputGrid item sm={8}>
        <FormLabel>{t(messages.city.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name={fieldName('city', namePrefix)}
          placeholder={t(messages.city.placeholder())}
          required={isRequired}
        />
      </FormInputGrid>
      <FormInputGrid item sm={4}>
        <FormLabel>{t(messages.zipcode.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name={fieldName('zipcode', namePrefix)}
          placeholder={t(messages.zipcode.placeholder())}
          required={isRequired}
        />
      </FormInputGrid>
    </>
  );
};
