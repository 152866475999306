import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  modalTitle: () => _t(translations.objectives_edition.weekly.modal_title),
  metrics: {
    total_sales: () =>
      _t(translations.objectives_edition.weekly.metrics.total_sales),
    products_sales: () =>
      _t(translations.objectives_edition.weekly.metrics.products_sales),
    services_count: () =>
      _t(translations.objectives_edition.weekly.metrics.services_count),
  },
  submit: () => _t(translations.objectives_edition.weekly.submit),
};
