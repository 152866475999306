import { institutesState } from 'app/atoms';
import { useRecoilState } from 'recoil';
import { Institute } from 'types/API/institutes';
import { instituteFromId } from 'utils/entities/institute';

export const useInstituteSelector = () => {
  const [institutesValue, setInstitutes] = useRecoilState(institutesState);

  const setInstitute = (instituteId: number) => {
    let newValue = { ...institutesValue };
    newValue.currentInstituteId = instituteId;
    setInstitutes(newValue);
  };

  const institutes = institutesValue.userInstitutes;

  const instituteId = institutesValue.currentInstituteId as number;

  const institute = instituteFromId({
    id: instituteId,
    institutes,
  }) as Institute.Normal;

  return {
    instituteId,
    institute,
    institutes,
    setInstitute,
  };
};
