import { Customer } from 'types/API/customers';
import { OldDBCustomerMigration } from 'types/API/customersOld';
import { request, request2 } from 'utils/request';

export const fetchCustomersRequest = ({
  instituteId,
}: {
  instituteId: number;
}) =>
  request<Customer.Normal[]>(`api/customers/institute/${instituteId}/all/1`, {
    method: 'GET',
  });

export const fetchOldCustomersRequest = ({
  instituteId,
  searchQuery,
}: {
  instituteId: number;
  searchQuery: string;
}) =>
  request2<OldDBCustomerMigration>(
    `/search?search=${searchQuery}&instituteId=${instituteId}&size=250&from=0`,
    {
      // /search?search=ma&instituteId=1&size=25&from=0
      method: 'GET',
    },
  );

export const fetchPendingCustomersRequest = ({
  instituteId,
}: {
  instituteId: number;
}) =>
  request<Customer.Normal[]>(`api/customers/pending/${instituteId}`, {
    method: 'GET',
  });

export const updateCustomerRequest = ({
  customerId,
  data,
}: {
  customerId: number;
  data: Customer.Payload;
}) =>
  request<Customer.Normal>(`api/customers/${customerId}`, {
    method: 'PUT',
    data,
  });

export const fetchCustomerRequest = ({ customerId, instituteId }: { customerId: number, instituteId: number }) =>
  request<Customer.Normal>(`/api/getcustomers/${customerId}/institute/${instituteId}`, {
    method: 'GET',
});

export const searchCustomersRequest = (data: Customer.ExternalSearchPayload) =>
  request<Customer.External[]>(`/api/likecustomers`, {
    method: 'POST',
    data,
  });

export const fetchCustomerByCardRequest = ({
  cardNumber,
  instituteId
}: {
  cardNumber: string,
  instituteId: number
}) =>
  request<Customer.Normal>(`api/customers_card/${cardNumber}/institute/${instituteId}`, {
    method: 'GET',
  });
