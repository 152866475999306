import i18next from 'i18next';
import { AtomEffect, DefaultValue } from 'recoil';

import { instituteFromId } from 'utils/entities/institute';
import { InstituesState } from 'app/atoms/institutes/types';

export const updateLocale =
  (): AtomEffect<InstituesState> =>
  ({ onSet }) => {
    onSet(async state => {
      if (!(state instanceof DefaultValue)) {
        const { currentInstituteId, userInstitutes } = state;

        if (currentInstituteId) {
          const currentInstitute = instituteFromId({
            id: currentInstituteId,
            institutes: userInstitutes,
          });
          const isocode = currentInstitute?.country.langue.isocode;
          if (isocode) {
            i18next.changeLanguage(isocode.toLowerCase());
          }
        }
      }
    });
  };
