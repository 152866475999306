import { AddInstitutesPage } from 'app/pages/AddInstitutes/AddInstitutesPage';
import { Route } from 'types/route';

import { paths } from '../../paths';

const createInstitutes: Route = {
  authenticated: true,
  content: AddInstitutesPage,
  routerPath: paths.addInstitutes.index,
};

export const routes = [createInstitutes];
