import { DialogContent } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

const StyledDialogContent = styled(DialogContent)`
  color: #000;
  padding: 0 40px 40px;

  &.MuiDialogContent-root:first-child {
    padding-top: 0;
  }
`;

export const Content = ({ children }: PropsWithChildren<{}>) => (
  <StyledDialogContent>{children}</StyledDialogContent>
);
