import { Box, Theme } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import styled from 'styled-components/macro';

import { getTransition } from 'utils/components/getTransition';

import { MenuItem } from './MenuItem';
import { MenuItemConfiguration } from './types';
import { useData } from './hooks';

interface WrapperProps {
  muitheme: Theme;
}

const Wrapper = styled.div<WrapperProps>`
  flex-shrink: 0;
  transition: ${({ muitheme }) => getTransition(muitheme, 'width')};
  white-space: nowrap;
`;

interface Props {
  extended?: boolean;
}

export const Menu = ({ extended = false }: Props) => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const { menuItems } = useData();

  const renderMenuItem = (configuration: MenuItemConfiguration) => {
    const { pathname: currentPath } = location;
    const { path, selectionPredicate, ...itemConfiguration } = configuration;

    const selected = selectionPredicate(currentPath);

    return (
      <MenuItem
        layoutDirection={extended ? 'horizontal' : 'vertical'}
        key={path}
        onClick={() => history.push(path)}
        selected={selected}
        {...itemConfiguration}
      />
    );
  };

  return (
    <Wrapper muitheme={theme}>
      <Box
        component="nav"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
      >
        {menuItems.map(item => renderMenuItem(item))}
      </Box>
    </Wrapper>
  );
};
