import styled from 'styled-components/macro';

export const FormLabel = styled.label`
  font-family: 'Gabriel Sans Cond';
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: ${p => p.theme.text.highlighted};
  font-size: 0.75rem;
`;
