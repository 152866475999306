const base = {
  primary: '#3459d6',
  secondary: '#e462a1',
  danger: '#e33535',
  body: '#6b758b',
};

export const theme = {
  ...base,
  text: {
    title: '#333540',
    highlighted: base.primary,
    body: base.body,
    bright: '#ffffff',
  },

  pageBackground: '#f3f2fc',
  itemBackground: '#ffffff',
  buttonBackground: base.secondary,

  input: {
    text: {
      focus: base.primary,
      default: base.body,
      error: base.danger,
      placeholder: base.body,
    },
    border: {
      placeholder: base.body,
    },
    background: '#ffffff',
  },

  status: {
    active: '#36b37e',
    inactive: '#e2284a',
  },
};

export type Theme = typeof theme;
