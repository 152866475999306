import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { PropsWithChildren, ReactNode } from 'react';

import { LandscapePageWrapper } from './LandscapePageWrapper';
import { PortraitPageWrapper } from './PortraitPageWrapper';
import { SnackbarLoader } from '../components/SnackbarLoader';
import Marquee from "react-fast-marquee";
import { getEnv } from 'utils/common/env';

type Props = PropsWithChildren<{
  disabled?: boolean;
  // Lazy load secondary content.
  secondaryContentRenderer: {
    landscape: () => ReactNode;
    portrait: () => ReactNode;
  };
}>;

export const GenericPageWrapper = (props: Props) => {
  const { children, disabled = false, secondaryContentRenderer } = props;

  const theme = useTheme();
  const useLandscape = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const addMarquee = getEnv('ENV') === 'staging';

  return useLandscape ? (
    <div>
      <AddMarquee addMarquee={addMarquee} />
      <LandscapePageWrapper
        disabled={disabled}
        secondaryContent={secondaryContentRenderer.landscape()}
      >
        {children}
        <SnackbarLoader />
      </LandscapePageWrapper>
    </div>

  ) : (
      <div>
        <AddMarquee addMarquee={addMarquee} />
        <PortraitPageWrapper
          disabled={disabled}
          secondaryContent={secondaryContentRenderer.portrait()}
        >
          {children}
          <SnackbarLoader />
        </PortraitPageWrapper>
      </div>

  );
};

const AddMarquee = (props) => {

  let addMarquee = props.addMarquee

  return (
    <>
      {addMarquee &&
        <Marquee speed={80} gradientWidth="0" style={{ color: "red" }} >Attention, vous êtes sur le portail Recette!!!!</Marquee>
      }
    </>
  )
}
