import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components/macro';

import { Button } from 'app/components/Button';
import { FormSection } from 'app/components/Forms/FormSection';
import { SelectInputOption } from 'app/components/Inputs/SelectInput';
import { Discount } from 'types/API/discounts';

import { messages } from '../messages';
import { General } from './sections/General';
import { Products } from './sections/Products';
import { Price } from './sections/Price';
import { Dates } from './sections/Dates';
import { ProductGroup } from '../hooks/useForm/types';

interface Props {
  availabilityOptions: SelectInputOption[];
  canSubmit: boolean;
  currency: string;
  discountAmountOptionsMap: Map<Discount.Type, SelectInputOption[]>;
  isSubmitting: boolean;
  priceOptions: SelectInputOption[];
  productGroups: ProductGroup[];
}

export const Form = (props: Props) => {
  const {
    availabilityOptions,
    canSubmit,
    currency,
    discountAmountOptionsMap,
    isSubmitting,
    priceOptions,
    productGroups,
  } = props;

  const { t } = useTranslation();

  return (
    <Wrapper>
      <FormSection title={t(messages.sections.general())}>
        <General
          availabilityOptions={availabilityOptions}
          disabled={isSubmitting}
        />
      </FormSection>
      <FormSection title={t(messages.sections.prestations())}>
        <Products disabled={isSubmitting} productGroups={productGroups} />
      </FormSection>
      <FormSection title={t(messages.sections.price())}>
        <Price
          currency={currency}
          disabled={isSubmitting}
          discountAmountOptionsMap={discountAmountOptionsMap}
          priceOptions={priceOptions}
        />
      </FormSection>
      <FormSection title={t(messages.sections.dates())}>
        <Dates disabled={isSubmitting} />
      </FormSection>

      <Button
        className="submit"
        disabled={!canSubmit}
        isSpinning={isSubmitting}
        type="submit"
      >
        {t(messages.submit())}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .submit {
    margin-top: 3rem;
    float: right;
  }
`;
