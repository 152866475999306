import { first, last } from 'lodash';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Optional } from 'types/misc';
import {
  DayPicker as UncontrolledDayPicker,
  DayPickerProps as UncontrolledDayPickerProps,
  DayPickerValue,
} from '../Uncontrolled/DayPicker';

interface Props
  extends Omit<UncontrolledDayPickerProps, 'onChange' | 'onBlur' | 'value'> {
  name: string;
}

export const DayPicker = (props: Props) => {
  const { name, ...rest } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const deserialize = useCallback(
    (serialized: Optional<string>): Optional<DayPickerValue> => {
      if (serialized) {
        const values = serialized.split('-');
        const day = first(values);
        const month = last(values);
        if (day && month) {
          return { day: parseInt(day), month: parseInt(month) };
        }
      }
      return undefined;
    },
    [],
  );

  const serialize = useCallback(
    (value: Optional<DayPickerValue>) =>
      value ? `${value.day.pad(2)}-${value.month.pad(2)}` : undefined,
    [],
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur, onChange, value } }) => (
        <UncontrolledDayPicker
          error={errors[name]}
          onBlur={onBlur}
          onChange={(value: Optional<DayPickerValue>) => {
            onChange(value ? serialize(value) : undefined);
          }}
          value={deserialize(value)}
          {...rest}
        />
      )}
    />
  );
};
