import { format, parse } from 'date-fns';
import { FiscalArchiveFile } from '../types';
import { downloadFiscalArchive } from 'app/services/fiscalArchives';
import styledHtml from 'styled-components/macro';
import { styled } from '@mui/material/styles';
import { Button } from '@material-ui/core';
import { fr } from 'date-fns/locale';

export const FiscalArchive = ({
	fiscalArchiveData,
}: {
	fiscalArchiveData: FiscalArchiveFile;
}) => {
	const dateName = timeCodeToDateName(fiscalArchiveData.timeCode);

	function timeCodeToDateName(timeCode: string) {
		// timecode is either YYYY for yearly archives or YYYYMM for monthly archives
		// Output is either something like 2022 for yearly archives or "July 2022" for monthly archives
		if (timeCode.length === 4) {
			return timeCode;
		} else {
			const date = parse(timeCode, 'yyyyMM', new Date());
			const monthName = format(date, 'LLLL', { locale: fr });
			return monthName.capitalized() + ' ' + timeCode.substring(0, 4);
		}
	}

	async function downloadArchive() {
		await downloadFiscalArchive({
			archiveId: fiscalArchiveData.id,
			fileName: fiscalArchiveData.fileName,
		});
	}
	return (
		<FiscalArchiveItemWrapper>
			<div style={{ margin: 'auto' }}>{dateName}</div>
			<DownloadButton onClick={downloadArchive}>Télécharger</DownloadButton>
		</FiscalArchiveItemWrapper>
	);
};

const FiscalArchiveItemWrapper = styledHtml.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: white;
	border-radius: 20px;
	padding: 10px;
	margin: 10px;
`;

const DownloadButton = styled(Button)({
	background: '#e462a1',
	border: 'none',
	padding: '7px',
	borderRadius: '10px',
	color: 'white',
	'&:hover': {
		backgroundColor: '#ed7eb4',
	},
});
