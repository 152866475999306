import { Close as CloseIcon } from '@material-ui/icons';
import { SwipeableDrawer } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components/macro';

import { Menu } from 'app/components/Menu';

import { messages } from './messages';
import { AccountPanel } from '../AccountPanel';

interface Props {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}

export const Drawer = (props: Props) => {
  const { onClose, ...rest } = props;
  const { t } = useTranslation();

  return (
    <StyledDrawer anchor="left" onClose={onClose} {...rest}>
      <TopWrapper>
        <Header onClick={onClose}>
          <span className="label">{t(messages.close())}</span>
          <CloseIcon className="icon" />
        </Header>
        <Menu extended />
      </TopWrapper>
      <BottomWrapper>
        <Divider />
        <AccountPanel container="drawer" onInstituteChange={onClose} />
      </BottomWrapper>
    </StyledDrawer>
  );
};

const StyledDrawer = styled(SwipeableDrawer)`
  & .MuiPaper-root {
    background: ${p => p.theme.primary};
    padding: 1rem;
  }
  & .MuiDrawer-paper {
    justify-content: space-between;
  }
`;

const Header = styled.div`
  color: ${({ theme }) => theme.text.bright};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  margin-top: 1rem;
  margin-bottom: 1rem;
  opacity: 0.6;
  .label {
    font-weight: 30;
    font-size: 0.7rem;
  }
  .icon {
    margin-left: 0.5rem;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const TopWrapper = styled.div``;
const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.input.background};
  opacity: 0.5;
  margin-bottom: 1rem;
`;
