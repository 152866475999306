import moment from 'moment';

import { Debit } from 'types/API/debits';
interface Parameters {
  instituteName: string;
  day: Debit.PaymentDay;
  month: Debit.PaymentMonth;
  year: number;
}

export const getDebitExportFileName = ({
  instituteName,
  day,
  month,
  year,
}: Parameters) => {
  const date = new Date();
  date.setDate(parseInt(day));
  date.setMonth(month - 1);
  date.setFullYear(year, month - 1, parseInt(day));
  const formattedPaymentDate = moment(date).format('YYYY-MM-DD');
  return `${instituteName}_${formattedPaymentDate}.xml`;
};
