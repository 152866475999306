import { useMemo } from 'react';

import { Options } from 'app/hooks/fuse/useFuse';

import { Source } from 'types/API/customersOld';

export const useOldOptions = () => {
  const fuseOptions = useMemo<Options<Source>>(
    () => ({
      keys: [
        {
          name: 'firstname',
          weight: 1,
        },
        {
          name: 'lastname',
          weight: 1,
        },
        {
          name: 'city',
          weight: 0.5,
        },
        {
          name: 'zipcode',
          weight: 0.5,
        },
        {
          name: 'address',
          weight: 0.2,
        },
        {
          name: 'subscriptionsType',
          weight: 1,
        },
      ],
      findAllMatches: false,
      threshold: 0.3,
      ignoreLocation: true,
    }),
    [],
  );

  return {
    fuseOptions,
  };
};
