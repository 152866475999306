import { User } from 'types/API/users';

export type Entitlement = 'debits_management' | 'institute_management' | 'add_institute_management' | 'test' | 'super_admin';

export const rolesEntitements = new Map<User.Role, Entitlement[]>([
  [User.Roles.ROLE_TILL, []],
  [User.Roles.ROLE_USER, []],
  [User.Roles.ROLE_SUPER_USER, ['debits_management']],
  [User.Roles.ROLE_ADMIN, ['debits_management', 'institute_management']],
  [User.Roles.ROLE_FORMATRICE, ['debits_management', 'institute_management']],
  [User.Roles.ROLE_SUPER_ADMIN, ['debits_management', 'institute_management', 'add_institute_management', 'super_admin']],
]);
