import styled from 'styled-components/macro';
import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { Tab, Tabs } from 'app/components/Tabs';
import {
  SearchEvent,
  SearchInput,
} from 'app/components/PageHeaders/SearchInput';

import { messages } from '../../messages';
import { SubscriptionTab } from '../../types';

interface Props {
  isLoading: boolean;
  label: string;
  onSearch: (event: SearchEvent) => void;
  onTabChange?: (event: React.ChangeEvent<{}>, value: SubscriptionTab) => void;
  searchEnabled: boolean;
  tab: SubscriptionTab;
}
export const Header = (props: Props) => {
  const { isLoading, label, onSearch, onTabChange, searchEnabled, tab } = props;

  const { t } = useTranslation();

  const theme = useTheme();
  const largeHeader = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  return (
    <Wrapper>
      <PageHeader label={label} />
      <ActionsWrapper largeHeader={largeHeader}>
        <Tabs
          className="tabs"
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={onTabChange}
        >
          <Tab label={t(messages.tabs.pendings.tabLabel())} />
          <Tab label={t(messages.tabs.subscriptions.tabLabel())} />
          {<Tab label={t(messages.tabs.createSubscription.tabLabel())} />}
          {/*<Tab label={t(messages.tabs.oldBd.tabLabel())} />*/}
        </Tabs>
        {!isLoading && searchEnabled && (
          <SearchInput
            className="search-input"
            disabled={false}
            onSearch={onSearch}
            placeholder={t(messages.searchPlaceholder())}
          />
        )}
      </ActionsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface InternalProps {
  largeHeader: boolean;
}

const ActionsWrapper = styled.div<InternalProps>`
  z-index: 10;
  background1: blue;
  display: flex;
  flex-direction: ${p => (p.largeHeader ? 'row' : 'column')};
  align-items: ${p => (p.largeHeader ? 'center' : 'flex-start')};
  justify-content: space-between;

  > .search-input {
    margin-right1: 200px;
    margin-top: ${p => (!p.largeHeader ? '1rem' : 'inherit')};
    width: ${p => (p.largeHeader ? '50%' : '70%')};
  }
`;
