import Moment from 'moment';
import { useState, useEffect } from 'react';
import styledMacro from 'styled-components/macro';
import { request } from 'utils/request';
import { LoadableContent } from 'app/components/LoadableContent';
import { EmptyState } from 'app/components/EmptyState';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CashBookPerTicketStatsDTO } from 'types/API/stats';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import Dropdown from 'react-dropdown';
import ScrollToTop from 'app/components/ScrollToTop/ScrollToTop';
import { InlineDatePicker } from './components/StatsContent/components/inlineDatePicker';

registerLocale('fr', fr);

export const CashBookTicket = props => {
  // Initialisation des variables de la page
  const { institute } = props;

  var date = new Date();
  let firstDayOfTheMonth = new Date(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );

  const [startDate, setStartDate] = useState(firstDayOfTheMonth);
  const [endDate, setEndDate] = useState(new Date());
  const [maxDate] = useState(new Date());
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState<CashBookPerTicketStatsDTO[]>();
  const [checked, setChecked] = useState(false);
  const [isDisplayingCancelOnly, setIsDisplayingCancelOnly] = useState(checked);
  const [sortFilter, setSortFilter] = useState('Date (décroissante)');
  var filters = [
    'Date (décroissante)',
    'Date (croissante)',
    'Esthéticienne créatrice',
    'Esthéticienne paiement/annulation',
    "Type d'abonnement",
    'Moyen de paiement',
    'Montant (décroissant)',
    'Montant (croissant)'
  ];

  Moment.locale('fr');

  // Evènements
  const onSelect = option => {
    console.log('You selected ', option.label);
    setSortFilter(option.value);
  };

  const handleChange = () => {
    setChecked(!checked);
    //validateDates(startDate, endDate);
  };

  useEffect(() => {
    validateDates(startDate, endDate);
  }, [checked]);

  // Fonctions
  // Chargement des données
  const loadData = async (start, end) => {
    if (!loading) {
      setLoading(true);
      setIsDisplayingCancelOnly(checked);
      let startDateTyped = new Date(start.setHours(1, 0, 0, 0));
      let endDateTyped = new Date(end.setHours(23, 0, 0, 0));
      fetchBookPerTicketStats({
        instituteId: institute.id,
        start: startDateTyped,
        end: endDateTyped,
        cancel: checked,
      })
        .then(data => {
          setData(data);
          var calculatedTotal = 0;
          for (var i = 0; i < data.length; i++) {
            calculatedTotal += data[i].ticketPriceTTC;
          }
          setTotal(calculatedTotal);
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
          console.log(error);
        });
    }
  };

  // Validation des dates sélectionnées
  const validateDates = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    let difference = start.getTime() - end.getTime();
    let totalDays = Math.ceil(difference / (1000 * 3600 * 24));

    if (totalDays > 0) {
      setData([]);
      setTotal(0);
      alert('Merci de choisir une date de fin supérieure à celle de début.');
    } else {
      if (totalDays > -366) { // +1 pour permettre de date à date
        loadData(start, end);
      } else {
        alert("Merci de choisir un intervalle n'excédant pas 1 an et 1 mois.");
      }
    }
  };

  // Interrogation de l'endpoint API (à exporter dans un service dédié)
  const fetchBookPerTicketStats = async ({
    instituteId,
    start,
    end,
    cancel,
  }: {
    instituteId: number;
    start: Date;
    end: Date;
    cancel: Boolean;
  }) => {
    const startDateString = start.toISOString();
    const endDateString = end.toISOString();
    const response = request<CashBookPerTicketStatsDTO[]>(`api/stat/ticket/book_refacto`, {
      method: 'POST',
      data: {
        instituteId: instituteId,
        startDate: startDateString,
        endDate: endDateString,
        cancel: cancel,
      },
    });
    return response;
  };

  // Template HTML
  return (
    <div>
      {/* Table de données principales */}
      <p>
        Institut: {institute.name} ({Moment(startDate).format('DD MMMM yyyy')} -{' '}
        {Moment(endDate).format('DD MMMM yyyy')}){' '}
      </p>
      <p></p>

      {/* Checkbox d'affichage des tickets annulés */}
      <label>
        <input type="checkbox" checked={checked} onChange={handleChange} />
        <SpanCheckBox>Uniquement les tickets annulés</SpanCheckBox>
      </label>

      {/* Sélection des dates de début et de fin */}
      <InlineDatePicker
        start={startDate}
        end={endDate}
        max={maxDate}
        action={validateDates}
      ></InlineDatePicker>

      {/* Contenu principal de la page */}
      <LoadableContent
        error={error}
        isLoading={loading}
        isEmpty={data === undefined || data.length == 0}
        emptyNodeRenderer={() => (
          <EmptyState label={loading ? 'Chargement' : 'Pas de résultats'} />
        )}
      >
        {data && (
          <div>
            {/* Tri des résultats TODO : Refacto par clic sur colonne */}
            <DropdownWrapper>
              Classer les résultats (Ordre Alphanumérique) par :{' '}
              <Dropdown
                options={filters}
                value={sortFilter}
                onChange={onSelect.bind(this)}
                placeholder="Classer les résultats par:"
              />
            </DropdownWrapper>
            <div>
              Nombre de tickets affichés : {data.length}
            </div>
            {/* Table de données principales (Headers) */}
            <TableContainer component={Paper}>
              <Table sx={{ width: 'max-content', minWidth: '-webkit-fill-available' }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Numéro<br/>Ticket</StyledTableCell>
                    <StyledTableCell align="center">Esthét.<br/>Création</StyledTableCell>
                    {!isDisplayingCancelOnly && (
                      <StyledTableCell align="center">Esthét.<br/>Paiement</StyledTableCell>
                    )}
                    {isDisplayingCancelOnly && (
                      <StyledTableCell align="center">Esthét.<br/>Annulation</StyledTableCell>
                    )}
                    <StyledTableCell align="center">Date<br/>Création</StyledTableCell>
                    {!isDisplayingCancelOnly && (
                      <StyledTableCell align="center">Date<br/>Paiement</StyledTableCell>
                    )}
                    {isDisplayingCancelOnly && (
                      <StyledTableCell align="center">Date<br/>Annulation</StyledTableCell>
                    )}
                    <StyledTableCell align="center">Nom<br/>Cliente</StyledTableCell>
                    <StyledTableCell align="center">Tél.<br/>Cliente</StyledTableCell>
                    <StyledTableCell align="center">Type<br/>Abo.</StyledTableCell>
                    <StyledTableCell align="center">Institut<br/>Abo.</StyledTableCell>
                    {!isDisplayingCancelOnly && (
                    <StyledTableCell align="center">Moyen<br/>paiement</StyledTableCell>
                    )}
                    {isDisplayingCancelOnly && (
                      <StyledTableCell align="center">
                        Motif<br/>Annulation
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="center">Prestations</StyledTableCell>
                    <StyledTableCell align="center">
                      Total {isDisplayingCancelOnly && (<span><br/>Annulations</span>)}<br/>({total.toFixed(2)}€)
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                {/* Table de données principales (Body) */}
                <TableBody>
                  {
                    // Gestion du tri des données
                    data
                    .sort((a, b) => {
                      switch (sortFilter) {
                        case "Type d'abonnement": {
                          return a.subscriptionTypeLabel.localeCompare(
                            b.subscriptionTypeLabel,
                          );
                        }

                        case 'Moyen de paiement': {
                          return a.paymentType.localeCompare(b.paymentType);
                        }

                        case 'Esthéticienne paiement/annulation': {
                          if (isDisplayingCancelOnly) {
                            return a.cancellingUserFullName.localeCompare(b.cancellingUserFullName);
                          } else {
                            return a.payingUserFullName.localeCompare(b.payingUserFullName);
                          }
                        }

                        case 'Esthéticienne créatrice': {
                          return a.creatingUserName.localeCompare(b.creatingUserName);
                        }

                        case 'Montant (croissant)': {
                          return a.ticketPriceTTC - b.ticketPriceTTC;
                        }

                        case 'Montant (décroissant)': {
                          return b.ticketPriceTTC - a.ticketPriceTTC;
                        }

                        case 'Date (croissante)': {
                          return a.ticketNumber - b.ticketNumber;
                        }

                        case 'Date (décroissante)': {
                          return b.ticketNumber - a.ticketNumber;
                        }
                        
                        default: {
                          return b.ticketNumber - a.ticketNumber;
                        }
                      }
                    })
                    .map(ticket => {
                      return (
                        <StyledTableRow key={ticket.ticketId}>
                          <StyledTableCell align="left">
                            {ticket.ticketNumber}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {ticket.creatingUserName}
                          </StyledTableCell>
                          {!isDisplayingCancelOnly && (
                            <StyledTableCell align="left">
                              {ticket.payingUserFullName}
                            </StyledTableCell>
                          )}
                          {isDisplayingCancelOnly && (
                            <StyledTableCell  align="left">
                              {ticket.cancellingUserFullName}
                            </StyledTableCell>
                          )}
                          <StyledTableCell align="center">
                            {ticket.ticketCreatedAtDate}<br/>à {ticket.ticketCreatedAtHour}
                          </StyledTableCell>
                          {!isDisplayingCancelOnly && (
                            <StyledTableCell align="center">
                              {ticket.ticketCreatedAtDate}<br/>à {ticket.paidCreatedAtHour}
                            </StyledTableCell>
                          )}
                          {isDisplayingCancelOnly && (
                            <StyledTableCell align="center">
                              {ticket.cancelledCreatedAtDate}<br/>à {ticket.cancelledCreatedAtHour}
                            </StyledTableCell>
                          )}
                          <StyledTableCell align="right">
                            {ticket.customerFullName}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {ticket.customerPhone}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {ticket.subscriptionTypeLabel}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {ticket.instituteName}
                          </StyledTableCell>
                          {!isDisplayingCancelOnly && (
                          <StyledTableCell align="center">
                            {ticket.paymentType}
                          </StyledTableCell>
                          )}
                          {isDisplayingCancelOnly && (
                            <StyledTableCell align="left">
                              {ticket.cancellationComment}
                            </StyledTableCell>
                          )}
                          <StyledTableCell align="right">
                            <><br/><b>PRODUITS ET PRESTATIONS ({ticket.productsSumPrice} €)</b><br/>
                            {ticket.productsNames}
                            </>
                            {ticket.skinQuantity > 0 && (
                              <>
                                <br/>SKIN x {ticket.skinAmount} ({ticket.skinQuantity}€)
                              </>
                            )}
                            {ticket.promotionsSumPrice > 0 && (
                              <>
                                <br/><b>PROMOTIONS ({ticket.promotionsSumPrice}€)</b><br/>
                                {ticket.promotionsNames}
                              </>
                            )}
                            {ticket.curesSumPrice > 0 && (
                              <>
                                <br/><b>CURES ({ticket.curesSumPrice}€)</b><br/>
                                {ticket.curesNames}
                              </>
                            )}
                            {ticket.giftCardAmount > 0 && (
                              <>
                                <br/><b>CARTE CADEAU N°{ticket.giftCardNumber} ({ticket.giftCardAmount}€)</b>
                              </>
                            )}
                            {ticket.tipsAmount > 0 && (
                              <>
                                --- <br/> <b>POURBOIRE ({ticket.tipsAmount}€)</b>
                              </>
                            )}                       
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {ticket.ticketPriceTTC} €
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <ScrollToTop />
          </div>
        )}
      </LoadableContent>
    </div>
  );
};

// Styles CSS
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#cbcffe',
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    lineHeight: 1,
  },
  whiteSpace: 'pre-wrap',
  width: 'max-content',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// Add of a small aesthetic padding next to the checkbox label
const SpanCheckBox = styledMacro.span`
  padding-left: 8px;
`; 

const DropdownWrapper = styledMacro.div`
    margin-top: 20px;
    margin-bottom: 20px;
  `;
