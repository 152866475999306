import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  pageTitle: () => _t(translations.institute.page_title),
  submit: () => _t(translations.institute.submit),
  name: {
    label: () => _t(translations.institute.name.label),
    placeholder: () => _t(translations.institute.name.placeholder),
  },
  phone: {
    label: () => _t(translations.institute.phone.label),
    placeholder: () => _t(translations.institute.phone.placeholder),
  },
  iban: {
    label: () => _t(translations.institute.iban.label),
    placeholder: () => _t(translations.institute.iban.placeholder),
  },
  ics: {
    label: () => _t(translations.institute.ics.label),
    placeholder: () => _t(translations.institute.ics.placeholder),
  },
  bic: {
    label: () => _t(translations.institute.bic.label),
    placeholder: () => _t(translations.institute.bic.placeholder),
  },
  alma: {
    label: () => _t(translations.institute.alma.label),
    placeholder: () => _t(translations.institute.alma.placeholder),
  },
  siret: {
    label: () => _t(translations.institute.siret.label),
    placeholder: () => _t(translations.institute.siret.placeholder),
  },
  ape: {
    label: () => _t(translations.institute.ape.label),
    placeholder: () => _t(translations.institute.ape.placeholder),
  },
  vat: {
    label: () => _t(translations.institute.vat.label),
    placeholder: () => _t(translations.institute.vat.placeholder),
  },

  dayPicker: {
    dayPlaceholder: () => _t(translations.institute.day_picker.day_paceholder),
    monthPlaceholder: () =>
      _t(translations.institute.day_picker.month_paceholder),
  },
  fiscalYearStart: {
    label: () => _t(translations.institute.fiscal_year_start.label),
  },
  fiscalYearEnd: {
    label: () => _t(translations.institute.fiscal_year_end.label),
  },
  additionalPeriodicClosing: {
    label: () => _t(translations.institute.additional_periodic_closing.label),
  },

  addPeriodicClosing: () => _t(translations.institute.add_periodic_closing),

  sections: {
    bankAccount: () => _t(translations.institute.sections.bank_account),
    general: () => _t(translations.institute.sections.general),
    legal: () => _t(translations.institute.sections.legal),
  },
};
