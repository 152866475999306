import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { messages } from 'app/i18n/messages';

export const useFormValidation = () => {
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      yup.object({
        customer: yup.object().shape({
          firstname: yup
            .string()
            .required(t(messages.forms.requiredFieldError())),
          lastname: yup
            .string()
            .required(t(messages.forms.requiredFieldError())),
          phone: yup.string(),
          email: yup
            .string()
            .email(t(messages.forms.invalidEmailError()))
            .required(t(messages.forms.requiredFieldError())),
          address: yup
            .string()
            .required(t(messages.forms.requiredFieldError())),
          complement: yup.string().optional(),
          zipcode: yup
            .string()
            .required(t(messages.forms.requiredFieldError())),
          city: yup.string().required(t(messages.forms.requiredFieldError())),
        }),
        subscriptionPayment: yup.object().shape({
          //iban: yup.string().required(t(messages.forms.requiredFieldError())),
          isCustomerDebtor: yup.boolean(),
          firstname: yup.string().when('isCustomerDebtor', {
            is: false,
            then: yup.string().required(),
          }),

          lastname: yup.string().when('isCustomerDebtor', {
            is: false,
            then: yup.string().required(),
          }),

          address: yup.string().when('isCustomerDebtor', {
            is: false,
            then: yup.string().required(),
          }),

          complement: yup.string().optional(), //.required(t(messages.forms.requiredFieldError())),

          zipcode: yup.string().when('isCustomerDebtor', {
            is: false,
            then: yup.string().required(),
          }),

          city: yup.string().when('isCustomerDebtor', {
            is: false,
            then: yup.string().required(),
          }),
        }),
      }),
    [t],
  );

  return { schema };
};
