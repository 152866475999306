import { format as formater } from 'date-fns';

import { Optional } from 'types/misc';

export function formatDate<T extends string | Optional<string>>({
  date,
  format,
}: {
  date: T;
  format: string;
}): T {
  return (date ? formater(Date.parse(date as string), format) : undefined) as T;
}

const inputDateFormat = 'dd-MM-yyyy';

export function formatDateInput<T extends string | Optional<string>>({
  date,
}: {
  date: T;
}): T {
  return (
    date ? formater(Date.parse(date as string), inputDateFormat) : undefined
  ) as T;
}
