import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components/macro';

import { Customer } from 'types/API/customers';
import { Optional } from 'types/misc';
import { Avatar } from 'app/components/Avatar';

import { messages } from './messages';

type Style = 'normal' | 'condensed';

interface InternalProps {
  style?: Style;
}

interface Props extends InternalProps {
  customer: Optional<Customer.Base | Customer.External>;
  shouldFetchAvatar?: boolean;
}

export const CustomerInfo = (props: Props) => {
  const { customer, shouldFetchAvatar = false } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const largeRow = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  return (
    <Wrapper className="customer-info">
      <Avatar
        customer={customer}
        fallbackLabel={t(messages.passingThrough())}
        shouldFetchImage={shouldFetchAvatar}
        size={largeRow ? '4rem' : '3rem'}
      />
      <Box display="flex" flexDirection="column" justifyContent="center">
        {customer?.firstname && (
          <FirstnameLabel>{customer?.firstname}</FirstnameLabel>
        )}
        <LastnameLabel usePrimaryColor={!!customer}>
          {customer?.lastname ?? t(messages.passingThrough())}
        </LastnameLabel>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  div: first-child {
    margin-right: 1rem;
  }
`;

const FirstnameLabel = styled.span<InternalProps>`
  font-weight: 400;
  color: ${p => p.theme.text.body};
`;

interface LastnameLabelProps extends InternalProps {
  usePrimaryColor: boolean;
}

const LastnameLabel = styled.span<LastnameLabelProps>`
  font-weight: 800;
  color: ${p =>
    p.usePrimaryColor ? p.theme.text.highlighted : p.theme.text.title};
  text-transform: uppercase;
  font-size: 1.2rem;
`;
