declare global {
  interface String {
    capitalized(): string;
  }
}

String.prototype.capitalized = function () {
  return this[0].toUpperCase() + this.slice(1);
};

export {};
