import { Route } from 'types/route';
import { InstitutePage } from 'app/pages/InstitutePage';

import { paths } from '../../paths';

const institute: Route = {
  authenticated: true,
  content: InstitutePage,
  routerPath: paths.institutes.update,
};

export const routes = [institute];
