//ancienne implémentation date de commit: c453ebb
/*
import Moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { useMemo, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { useInstituteSelector } from 'app/atoms/selectors';
import { messages } from './messages';
import { BtoBProduct } from 'types/API/btobproducts';

import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { useOrderQueries, useBtoBProductsQueries } from './hooks';
import { request } from 'utils/request';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { fetchOrderRequest } from 'app/services/orders';
import { Order } from 'types/API/orders';
import { fetchBtoBProductsRequest } from 'app/services/btobproducts';

import { Categorie } from 'types/API/categorie';
import { Gamme } from 'types/API/gamme';

import Dropdown from 'react-dropdown';
import style from 'styled-components/macro';
import { RadioButtonUncheckedSharp } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core'; 
import { RiDeleteBin2Fill } from 'react-icons/ri';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ReactToPrint from 'react-to-print';
import { TopBlueBarre } from 'app/components/TopBlueBarre';
import { BottomBlueBarre } from 'app/components/BottomBlueBarre';
import { TotalProvider, useTotal } from './hooks/useContext';

interface Option {
  value: string,
  label: string
}

export const OrderPage = () => {
  const { institute } = useInstituteSelector();

  const { currency } = institute.country;

  const { id }: { id?: string } = useParams();

  const { orderId } = useMemo(() => {
    return {
      orderId: id ? parseInt(id) : undefined,
    };
  }, [id]);

  const [orderNum, setOrderNum] = useState("")

  const history = useHistory();
  const { t } = useTranslation();

  const pageTitle = useMemo(
    () =>
      orderId
        ? t(messages.updatePageTitle(), { identifier: orderNum })
        : t(messages.createPageTitle()),
    [orderNum, t],
  );

  const OrderForm = (props) => {
    const { Totalupdate, Quantityupdate, quantity } = useTotal();
    const [total, setTotal] = useState(0);
    const [data, setData] = useState<Order.Detail>();
    
    const [products, setProducts] = useState<BtoBProduct.Normal[]>();
    const [productsWithQuantities, setProductsWithQuantities] = useState<BtoBProduct.Normal[]>();
    const [selectedProducts, setSelectedProducts] = useState<Order.ProductOrder[]>();
    const active = data ? data.active : false;

    const [gammes, setGammes] = useState<Option[]>()
    const [categories, setCategories] = useState<Option[]>()

    const [gammeSelected, setGammeSelected] = useState(0)
    const [categorieSelected, setCategorieSelected] = useState(0)

    const [loadCatAndGam] = useState(0)

    const [showAddProduct, setShowAddProduct] = useState(false)

    const setQuantity = (value, id) => { 
      if (productsWithQuantities) {
        const newProducts = productsWithQuantities.map(prd => {
          if (prd.id == id) {
            return { ...prd, quantity: value }
          }
          return prd
        })
      
        setProductsWithQuantities(newProducts)
      }
    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleValidate = () => {
      setOpen(false);
      createOrdUpdateOrder(true)
    };

    const handleDismiss = () => {
      setOpen(false);
    };



    useEffect(() => {
      updateTotal(); // This is be executed when `loading` state changes
    }, [data])

    const updateTotal = () => {
      var calculatedTotal = 0
      let Qte = 0;
      if (productsWithQuantities) {
        productsWithQuantities.map(prd => {
          if (prd.quantity > 0) {
            calculatedTotal += prd.quantity * prd.price * prd.box;
            Qte += parseInt(`${prd.quantity}`);
            // console.log(prd.quantity);
            Quantityupdate(Qte);
          }
        })
      }
      setTotal(calculatedTotal)
      Totalupdate(calculatedTotal);
    }

    

    const handleShowAddProduct = e => {
      e.preventDefault();
      setShowAddProduct(true);
    }

    const handleSubmit = e => {
      e.preventDefault();
      
      if (e.nativeEvent.submitter.name === "addProduct") {
        setShowAddProduct(!showAddProduct);
      } else {
        setLoading(true);
        var active = false;

        if (e.nativeEvent.submitter.name === "validate") {
          setOpen(true)
          // active = true;
          return
        } else {
          createOrdUpdateOrder(false)
        }
      }
    }

    const createOrdUpdateOrder = (active) => {
      if (productsWithQuantities) {
        const productPayload = productsWithQuantities.map(prd => {
          return {
            productId: prd.id,
            quantity: prd.quantity,
            delete: prd.quantity > 0 ? false : true
          }
        })
        const data = {
          instituteId: institute.id,
          products: productPayload,
          active: active
        }

        if (orderId) {
          updateBtoBOrderRequest(data, orderId)
            .then(() => {
              setLoading(false);
              // alert('La commande a été mise à jour');
              history.goBack()
            }).catch(error => {
              setLoading(false);
              alert('Erreur lors de la mise à jour de la commande');
            })

        } else {
          createBtoBOrderRequest(data).then(() => {
            // alert('La commande a été créée');
            setLoading(false);
            history.goBack()
          }).catch(error => {
            setLoading(false);
            alert('Erreur lors de la création de la commande');
          })
        }
      }
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const loadData = async () => {
      if (!loading) {
        setLoading(true)
        setProducts([])

        fetchBtoBProductsRequest({ instituteId: institute.id, gammeId: 0, categorieId: 0 }).then(products => {
          setProducts(products)
          if (orderId) {
            fetchOrderRequest({ orderId: orderId as number }).then(data => {
              setData(data)
              setOrderNum(data.orderNumber)

              const productsWithQuantities = products.map(prd => {
                const found = data.products.find(obj => {
                  return obj.id === prd.id;
                });

                if (found) {
                  return { ...prd, quantity: found.quantity, unitPrice: found.unitPrice }
                } else {
                  return { ...prd, quantity: 0, unitPrice: prd.price }
                }
              })

              setProductsWithQuantities(productsWithQuantities)              
              setLoading(false)
            }).catch(error => {
              setLoading(false)
              setError(true)
            })
          } else {
            setShowAddProduct(true);
            const productsWithQuantities = products.map(prd => {
              return { ...prd, quantity: 0, unitPrice: prd.price }
            })
            setProductsWithQuantities(productsWithQuantities)   
            setLoading(false)
          }
        }).catch(error => {
          setLoading(false)
          setError(true)
        })
      }
    }

    useEffect(() => {
      updateTotal()
    }, [productsWithQuantities])

    useEffect(() => {
        fetchCategoriesRequest().then(categories => {
          var tmpCategorie: Option[] = [{ value: "0", label: "Afficher toutes les catégories" }]
          categories.map(categorie => {
            tmpCategorie.push({ value: categorie.id.toString(), label: categorie.name.charAt(0).toUpperCase() + categorie.name.slice(1).toLowerCase() })
          })
          setCategories(tmpCategorie)
        })
    }, [loadCatAndGam])

    useEffect(() => {
        fetchGammesRequest().then(gammes => {
          var tmpGamme: Option[] = [{ value: "0", label: "Afficher toutes les gammes" }]
          gammes.map(gamme => {
            tmpGamme.push({ value: gamme.id.toString(), label: gamme.name })
          })
          setGammes(tmpGamme)
          // console.log(gammes.toString())
        });
    }, [loadCatAndGam])

    const onCategorieSelect = (option) => {
      // console.log(option)
      setCategorieSelected(Number(option.value))
    }

    const onGammeSelect = (option) => {
      // console.log(option)
      setGammeSelected(Number(option.value))
    }

    useEffect(() => {
      loadData(); // This is be executed when `loading` state changes
    }, [])

    const numberInOrder = (id) => {
      const found = data?.products.find(obj => {
        return obj.id === id;
      });
      return found?.quantity || 0
    }

    const componentRef = useRef<HTMLDivElement>(null)
    
    return (
      <>
        {loading &&
          <Spinner>
            <CircularProgress color="primary" />
          </Spinner>
        }

        {!loading &&
          <div>
            
            {orderId &&
              // <BtoBOrderContent total={total} data={data} productsWithQuantities={productsWithQuantities}  />
              <div ref={componentRef} >
                {active &&
                  <div>
                    <PrintButtonRow>
                      <ReactToPrint
                        trigger={() => <PrintButton type="submit" value="Imprimer le récapitulatif de commande" />}
                        content={() => componentRef.current}
                      />
                    </PrintButtonRow>
                  <p>Institut : {institute.name}</p>
                  {data && 
                    <p>Commande validée le : {data.validatedAt.length > 0 ? Moment(data.validatedAt).format('DD MMMM yyyy à HH:mm') : "-"}</p>
                  }
                  </div>
                }
        
                  <p>Total de la commande : {total.toFixed(2) || 0}€</p>
                  <BtoBProductHeader>
                    <BtoBProductItem>Produit</BtoBProductItem>
                    <BtoBProductItemCentered>Quantité</BtoBProductItemCentered>
                    <BtoBProductItemCentered>Prix</BtoBProductItemCentered>
                    <BtoBProductItemCentered>Conditionnement</BtoBProductItemCentered>
                    <BtoBProductItemCentered>Montant</BtoBProductItemCentered>
                    {!active &&
                      <BtoBProductItemCentered> </BtoBProductItemCentered>
                    }

                  </BtoBProductHeader>
                  {productsWithQuantities?.filter(prd => { return prd.quantity > 0 })
                    .map(prd => (
                      <BtoBProductWrapper>
                        <BtoBProductItem >{prd.language.title}</BtoBProductItem>
                        <BtoBProductItemCentered >{prd.quantity} </BtoBProductItemCentered>
                        <BtoBProductItemCentered >{prd.unitPrice}€</BtoBProductItemCentered>
                        <BtoBProductItemCentered >{prd.box}</BtoBProductItemCentered>
                        <BtoBProductItemCentered >{Number(prd.price * prd.quantity * prd.box).toFixed(2)}€</BtoBProductItemCentered>
                        {!active &&
                          <BtoBProductItemCentered ><button onClick={() => setQuantity(0, prd.id)}><RiDeleteBin2Fill /></button></BtoBProductItemCentered>
                        }

                      </BtoBProductWrapper>
                    ))}

              </div>
              
            }

            {!active &&
              <div>
                <form onSubmit={handleSubmit}>
                {!orderId &&
                  <p>Total  de la commande : {total.toFixed(2)}€</p>
                }

                {orderId &&
                  <div>
                    //{ <p>Ajouter des produits: </p> }
                    <br />
                    <br />
                    <br />
                    //{ <form onSubmit={handleShowAddProduct}> }
                      <BtoBButtons><BtoBButton name="addProduct" type="submit" value={showAddProduct ? "Masquer la liste des produits" : "Ajouter ou Supprimer des produits"} /></BtoBButtons>
                      <br />
                      <br />
                      <BtoBButtons>
                        //{ {showAddProduct && }}
                        <BtoBButton name="createUpdate" type="submit" value={orderId ? 'Mettre à jour la commande' : "Créer la commande"} />
                        <BtoBButton name="validate" onClick={handleClickOpen} value="Valider la commande" />
                      </BtoBButtons>
                      <Dialog
                        open={open}
                        onClose={handleDismiss}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Voulez vous vraiment valider la commande?"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Si vous cliquez sur confirmer la commande sera envoyée directement au siège et ne pourra plus être modifiée. Cliquez sur annuler pour interrompre la validation.
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleDismiss}>Annuler</Button>
                          <Button onClick={handleValidate} autoFocus>
                            Confirmer
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <br />
                      <br />
                    //{ </form> }
                    
                    
                  </div>
                }
                
                {showAddProduct && 
                    <div>
                      {orderId &&
                        <p>Pour supprimer des produits, veuillez mettre sa quantité à 0. Validez toutes vos modifications en cliquant sur le bouton "Mettre à jour la commande" en bas de la page.</p>
                      }
                    <DropdownWrapper>
                        <Dropdown className='dropdownPink' controlClassName='dropdownPink' menuClassName='dropdownPink' placeholderClassName='dropdownPink' options={categories || []} onChange={onCategorieSelect.bind(this)} placeholder='Sélectionnez une catégorie' />
                    </DropdownWrapper>

                //     {<DropdownWrapper>
                //   <Dropdown options={gammes || []} onChange={onGammeSelect.bind(this)} placeholder='Sélectionnez une gamme' />
                // </DropdownWrapper> }
                    <BtoBOrderHeader />

                    

                      {productsWithQuantities?.filter(prd => {
                        var currentCategory = false
                        var currentRange = false
                        if (categorieSelected == 0) {
                          currentCategory = true
                        } else {
                          let curCat = categories!.find(obj => {
                            return obj.value === String(categorieSelected);
                          });

                          if (prd.orderCategorie === curCat?.label.toLowerCase()) {
                            currentCategory = true
                          }
                        }
                        if (gammeSelected == 0) {
                          currentRange = true
                        } else {
                          let curRange = gammes!.find(obj => {
                            return obj.value === String(gammeSelected);
                          });

                          if (prd.range === curRange?.label) {
                            currentRange = true
                          }
                        }
                        return currentCategory && currentRange
                      }).map(({ id, language, ref, price, box, quantity, unitPrice }) => {
                        return (
                          <BtoBProductWrapper>
                            <BtoBProductItem >{ref}</BtoBProductItem>
                            <BtoBProductItemCentered>{language.title}</BtoBProductItemCentered>
                            // { <BtoBProductItemCentered>{category}/{range}</BtoBProductItemCentered> }
                            <BtoBProductItemCentered>{price}€</BtoBProductItemCentered>
                            <BtoBProductItemCentered>{box}</BtoBProductItemCentered>
                            <BtoBProductItemCentered>{active ? -1 : <input name="quantity" value={quantity} type="text" onChange={event => setQuantity(event.target.value, id)} />}</BtoBProductItemCentered>
                            <BtoBProductItemCentered>{Number(price * quantity * box).toLocaleString('en-US', { maximumFractionDigits: 2, useGrouping: false })}€</BtoBProductItemCentered>
                          </BtoBProductWrapper>
                        );
                      })}
                      <br />
                      <br />
                      <BtoBButtons>
                        <BtoBButton name="createUpdate" type="submit" value={orderId ? 'Mettre à jour la commande' : "Créer la commande"} />
                        {orderId &&
                          <BtoBButton name="validate" type="submit" value="Valider la commande" />
                        }
                      </BtoBButtons>
                  </div>
                }
                
                <br />
                <br />
                // { <BtoBButtons>
                //   {orderId &&
                //     <BtoBButton name="validate" type="submit" value="Valider la commande" />
                //   }
                // </BtoBButtons>}
                </form>
              </div>

              
            }
          </div>
        }
      </>
    )
  }


  const BtoBOrderHeader = () => { 

    return (
      <div>
        <BtoBProductHeader>
          <BtoBProductItem>Référence</BtoBProductItem>
          <BtoBProductItemCentered>Produit</BtoBProductItemCentered>
          // {<BtoBProductItemCentered>Catégorie/Gamme</BtoBProductItemCentered>}
          <BtoBProductItemCentered>Prix Unitaire</BtoBProductItemCentered>
          <BtoBProductItemCentered>Conditionnement</BtoBProductItemCentered>
          <BtoBProductItemCentered>Quantité</BtoBProductItemCentered>
          <BtoBProductItemCentered>Montant</BtoBProductItemCentered>
        </BtoBProductHeader>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle} : {institute.name}</title>
      </Helmet>
      <TotalProvider>
        <PageWrapper header={<><TopBlueBarre label={''} />
          <PageHeader extramargin={true} backBehaviour label={pageTitle + " : " + institute.name} /></>}>
          <OrderForm />
        </PageWrapper>
        <BottomBlueBarre/>
      </TotalProvider>
      // {
      // <PageWrapper header={<PageHeader backBehaviour label={pageTitle + " : " + institute.name} />}>
      //   <OrderForm />
      // </PageWrapper>
      // }
    </>
  );
};

const createBtoBOrderRequest = async (data: BtoBProduct.CreatePayload) => {
  const order = await request<BtoBProduct.ResponsePayload>(`api/orders`, {
    method: 'POST',
    data,
  });
  return order;
};

const updateBtoBOrderRequest = async (data: BtoBProduct.UpdatePayload, orderId: number) => {
  const url = `api/orders/${orderId}`;
  const order = await request<BtoBProduct.ResponsePayload>(url, {
    method: 'PUT',
    data,
  });
  return order;
};

// const validateBtoBOrderRequest = async (orderId: number) => {
//   const url = `api/orders/${orderId}`;
//   const data = { 'active': true}
//   const order = request<BtoBProduct.ResponsePayload>(url, {
//     method: 'PUT',
//     data,
//   });
//   return order;
// };

export const fetchCategoriesRequest = () => 
  request<[Categorie.Order]>(`api/order/categories`, {
      method: 'GET',
  });

export const fetchGammesRequest = () =>
  request<[Gamme]>(`api/gammes`, {
    method: 'GET',
  });
  


const BtoBProductWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  margin: 10px;
`;

const BtoBProductHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  margin: 10px;
  margin-bottom: 30px;
`;

const BtoBProductItemCentered = styled.div`
  width: 16%;
  text-align: center;
`;

const BtoBButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;

const DropdownWrapper = style.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Spinner = style.div`
  display: flex;
  align-items: center;
  justify-content: center;  
  width: 100%;
  height: 100%;
`;

const BtoBProductItem = styled.div`
  width: 16%;
`;

const BtoBButton = styled.input`
  background: #e462a1;
  border: none;
  padding: 20px;
  border-radius: 20px;
  color: white;
  marginooo: 30px;
`;

const PrintButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 95%;
`;

const PrintButton = styled.input`
  background: #e462a1;
  border: none;
  padding: 20px;
  border-radius: 20px;
  color: white;
`;

const DivGray = styled.div`
  color: gray;
`;

const DivCategory = styled.div`
margin-block: 0;
color1: ${p => p.theme.primary};
font-family1: 'Gabriel Sans Cond';
font-weight: 700;
font-size: 1rem;`;
*/

//Nouvelle implémentation date de commit: 5a8918a
import Moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { useMemo, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { useInstituteSelector } from 'app/atoms/selectors';
import { messages } from './messages';
import { BtoBProduct } from 'types/API/btobproducts';

import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { useOrderQueries, useBtoBProductsQueries } from './hooks';
import { request } from 'utils/request';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { fetchOrderRequest } from 'app/services/orders';
import { Order } from 'types/API/orders';
import { fetchBtoBProductsRequest } from 'app/services/btobproducts';

import { Categorie } from 'types/API/categorie';
import { Gamme } from 'types/API/gamme';

import Dropdown from 'react-dropdown';
import style from 'styled-components/macro';
import { RadioButtonUncheckedSharp } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import { RiDeleteBin2Fill } from 'react-icons/ri';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ReactToPrint from 'react-to-print';
import { TopBlueBarre } from 'app/components/TopBlueBarre';
import { BottomBlueBarre } from 'app/components/BottomBlueBarre';
import { TotalProvider, useTotal } from './hooks/useContext';

//import productsV1 from 'types/API/productsV1.json';
import productsV2 from 'types/API/productsV2.json';

interface Option {
  value: string,
  label: string
}

export const OrderPage = () => {
  const turnOnMargeFrancisePerPCBFeature = true;
  const { institute } = useInstituteSelector();

  const { currency } = institute.country;

  var { id }: { id?: string } = useParams();

  var orderIdCreatedInThisPage: number;

  const { orderId } = useMemo(() => {
    return {
      orderId: id ? parseInt(id) : undefined,
    };
  }, [id]);

  const [orderNum, setOrderNum] = useState("")

  const history = useHistory();
  const { t } = useTranslation();

  const pageTitle = useMemo(
    () =>
      orderId
        ? t(messages.updatePageTitle(), { identifier: orderNum })
        : t(messages.createPageTitle()),
    [orderNum, t],
  );

  const OrderForm = (props) => {
    const { total, Totalupdate, Quantityupdate, quantity } = useTotal();

    const [data, setData] = useState<Order.Detail>();

    const [products, setProducts] = useState<BtoBProduct.Normal[]>();
    const [productsWithQuantities, setProductsWithQuantities] = useState<BtoBProduct.Normal[]>();
    const [selectedProducts, setSelectedProducts] = useState<Order.ProductOrder[]>();
    const active = data ? data.active : false;

    const [gammes, setGammes] = useState<Option[]>()
    const [categories, setCategories] = useState<Option[]>()

    const [gammeSelected, setGammeSelected] = useState(0)
    const [categorieSelected, setCategorieSelected] = useState(0)

    const [loadCatAndGam] = useState(0)

    const [showAddProduct, setShowAddProduct] = useState(false)

    const setQuantity = (value, id) => {
      if (productsWithQuantities) {
        const newProducts = productsWithQuantities.map(prd => {
          if (prd.id == id) {
            return { ...prd, quantity: !value ? 0 : value };
          }
          return prd;
        });
        setProductsWithQuantities(newProducts);
      }
    };

    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false);

    const handleValidate = () => {
      setOpen(false);
      createOrdUpdateOrder(true);
    };

    const handleDismiss = () => {
      setOpen(false);
    };

    const handleClickOpen = () => {
      // console.log('handleClickOpen handleClickOpen handleClickOpen, orderId:', orderId);
      if (orderIdCreatedInThisPage === undefined && orderId === undefined) {
        // console.log('orderId is undefined:', orderId);
        setOpenError(true);
      } else {
        setOpen(true);
      }
    };

    const handleDismissError = () => {
      setOpenError(false);
    };

    useEffect(() => {
      updateTotal(); // This is be executed when `loading` state changes
    }, [data])

    const updateTotal = () => {
      var calculatedTotal = 0;
      let Qte = 0;

      if (productsWithQuantities) {
        productsWithQuantities.map(prd => {
          if (prd.quantity >= 0) {
            //// console.log(`updating total for prod:${prd.language.title}, price:${prd.price}, franchisedPurchasePriceExclVATPerPCB:${prd.franchisedPurchasePriceExclVATPerPCB} `);
            const price =
              prd.franchisedPurchasePriceExclVATPerPCB !== undefined &&
                prd.franchisedPurchasePriceExclVATPerPCB !== 0
                ? prd.franchisedPurchasePriceExclVATPerPCB
                : prd.price * prd.box;
            calculatedTotal += prd.quantity * price;
            Qte += parseInt(`${prd.quantity}`);
            // console.log(prd.quantity);
            Quantityupdate(Qte);
          }
        });
      }
      Totalupdate(calculatedTotal);
    };

    const handleSubmit = e => {
      e.preventDefault();
      if (e.nativeEvent.submitter.name === "addProduct") {
        setShowAddProduct(!showAddProduct);
      } else {
        setLoading(true);
        var active = false;
        if (e.nativeEvent.submitter.name === "validate") {
          setOpen(true)
          // active = true;
          return
        } else {
          createOrdUpdateOrder(false)
        }
      }
    };

    const createOrdUpdateOrder = (active) => {
      if (productsWithQuantities) {
        const productPayload = productsWithQuantities.map(prd => {
          return {
            productId: prd.id,
            quantity: prd.quantity,
            delete: prd.quantity > 0 ? false : true
          };
        });
        const data = {
          instituteId: institute.id,
          products: productPayload,
          active: active,
        };

        if (orderId) {
          updateBtoBOrderRequest(data, orderId)
            .then(() => {
              setLoading(false);
              // alert('La commande a été mise à jour');
              history.goBack();
            }).catch(error => {
              setLoading(false);
              //alert('Erreur lors de la mise à jour de la commande');
              // console.log(`Erreur lors de la création de la commande, error:${error}`);
              history.goBack();
            });
        } else if (orderIdCreatedInThisPage) {
          // console.log("updateBtoBOrderRequest, orderIdCreatedInThisPage:", orderIdCreatedInThisPage);

          updateBtoBOrderRequest(data, orderIdCreatedInThisPage)
            .then(() => {
              setLoading(false);
              // alert('La commande a été mise à jour');
              history.goBack()
            }).catch(error => {
              setLoading(false);
              history.goBack();
              //alert('Erreur lors de la mise à jour de la commande');
              // console.log(`Erreur lors de la création de la commande, error:${error}`);
            })

        } else {
          createBtoBOrderRequest(data).then((res) => {
            orderIdCreatedInThisPage = res.id;
            // console.log("createBtoBOrderRequest:", res);
            // console.log(`orderId: ${orderId}, res.id:${res.id}, orderIdCreatedInThisPage:${orderIdCreatedInThisPage}`);
            //should update orderId
            alert('La commande a été créée');
            setLoading(false);
            //history.goBack()
          }).catch(error => {
            setLoading(false);
            // console.log(`Erreur lors de la création de la commande, error:${error}`);
            //alert('Erreur lors de la création de la commande');
          })
        }
      }
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const updateProducts = (productsFromServer: BtoBProduct.Normal[]): BtoBProduct.Normal[] => {
      // console.log(`productsV2:${productsV2.subcategories.length}`);
      // console.log(`productsV2 length:${productsV2.subcategories[0].products.length}`);
      // console.log(`products:${products?.length}`);
      // console.log(`products21:${productsFromServer[0].franchisedPurchasePriceExclVATPerPCB}`);


      productsV2.subcategories.map(subcategory => {
        const catProduct: BtoBProduct.Normal = {
          isCategory: true,
          id: 0,
          language: {
            title: subcategory.rangeDesc,
            content: subcategory.rangeDesc,
          },
          ref: '',
          price: 0,
          unitPrice: 0,
          quantity: 0,
          box: 0,
          range: subcategory.rangeName,
          category: subcategory.rangeName,
          orderCategorie: 'vente',
        };
        productsFromServer.push(catProduct);

        subcategory.products.map(prodV1 => {
          productsFromServer.forEach(function (product, index) {
            //// console.log('%d: %s, prodV1: %s', index, product.ref, prodV1.ref);
            if (prodV1.ref == product.ref) {
              //// console.log('%d: %s, prodV1: %s', index, product.ref, prodV1.ref);
              BtoBProduct.describeProductV1(prodV1);
              //let updatedProduct = BtoBProduct.updateProduct(product, prodV1);
              //products[index] = updatedProduct;
              const readPriceFromServer = true;
              product.franchisedPurchasePriceExclVATPerPCB = readPriceFromServer ? product.price * product.box : prodV1.franchisedPurchasePriceExclVATPerPCB;
              product.subscriberPriceExclVATPerUnit = prodV1.subscriberPriceExclVATPerUnit;
              product.subscriberRateExclVATPerPCB = prodV1.subscriberRateExclVATPerPCB;
              product.franchiseMarginPerPCB = prodV1.franchiseMarginPerPCB;
              product.subscriberSalesPriceInclUnitVAT = prodV1.subscriberSalesPriceInclUnitVAT;
              product.price = prodV1.unitPriceExclVAT;
              BtoBProduct.describeProduct(product);
              productsFromServer[index] = product;
            }
          });
        });
      });
      //setProducts(products);
      // console.log(`products:${products?.length}`);
      BtoBProduct.describeProduct(productsFromServer[2]);
      return productsFromServer;
    };

    const customSort = (a: BtoBProduct.Normal, b: BtoBProduct.Normal) => {
      // console.log(`range:${a.range}, range${b.range}`);
      if (a.range === b.range) {
        if (a.isCategory) {
          return -1;
        } //else if (b.isCategory) {
        //return -1;
        //}
        return a.ref > b.ref ? 1 : -1;
      }
      return a.range > b.range ? 1 : -1;
    }

    const loadData = async () => {
      if (!loading) {
        setLoading(true);
        setProducts([]);

        fetchBtoBProductsRequest({
          instituteId: institute.id,
          gammeId: 0,
          categorieId: 0,
        })
          .then(products => {
            products = updateProducts(products);
            // console.log(`products2:${products?.length}`);
            setProducts(products);
            if (orderId) {
              fetchOrderRequest({ orderId: orderId as number }).then(data => {
                setData(data);
                setOrderNum(data.orderNumber);

                const productsWithQuantities = products.map(prd => {
                  const found = data.products.find(obj => {
                    return obj.id === prd.id;
                  });

                  if (found) {
                    return { ...prd, quantity: found.quantity, unitPrice: found.unitPrice }
                  } else {
                    return { ...prd, quantity: 0, unitPrice: prd.price }
                  }
                })

                setProductsWithQuantities(productsWithQuantities)
                setLoading(false)
              }).catch(error => {
                setLoading(false)
                setError(true)
              })
            } else {
              setShowAddProduct(true);
              const productsWithQuantities = products.map(prd => {
                return { ...prd, quantity: 0, unitPrice: prd.price };
              })
              setProductsWithQuantities(productsWithQuantities);
              setLoading(false);
            }
            //updateProducts(products);
          })
          .catch(error => {
            setLoading(false);
            setError(true);
          });
      }
    };

    useEffect(() => {
      updateTotal()
    }, [productsWithQuantities]);

    useEffect(() => {
      fetchCategoriesRequest().then(categories => {
        var tmpCategorie: Option[] = [
          { value: '0', label: 'Afficher toutes les catégories' },
        ];
        categories.map(categorie => {
          tmpCategorie.push({ value: categorie.id.toString(), label: categorie.name.charAt(0).toUpperCase() + categorie.name.slice(1).toLowerCase() })
        })
        setCategories(tmpCategorie)
      })
    }, [loadCatAndGam])

    useEffect(() => {
      fetchGammesRequest().then(gammes => {
        var tmpGamme: Option[] = [{ value: "0", label: "Afficher toutes les gammes" }]
        gammes.map(gamme => {
          tmpGamme.push({ value: gamme.id.toString(), label: gamme.name })
        })
        setGammes(tmpGamme)
        // console.log(gammes.toString())
      });
    }, [loadCatAndGam]);

    const onCategorieSelect = (option) => {
      // console.log(option)
      setCategorieSelected(Number(option.value));
    }

    const onGammeSelect = (option) => {
      // console.log(option)
      setGammeSelected(Number(option.value));
    }

    useEffect(() => {
      loadData(); // This is be executed when `loading` state changes
    }, []);

    const numberInOrder = (id) => {
      const found = data?.products.find(obj => {
        return obj.id === id;
      });
      return found?.quantity || 0;
    };

    const componentRef = useRef<HTMLDivElement>(null);

    return (
      <>
        {loading && (
          <Spinner>
            <CircularProgress color="primary" />
          </Spinner>
        )}

        {!loading &&
          <div>
            {orderId && (
              // <BtoBOrderContent total={total} data={data} productsWithQuantities={productsWithQuantities}  />
              <div ref={componentRef}>
                {active && (
                  <div>
                    <PrintButtonRow>
                      <ReactToPrint
                        trigger={() => (
                          <PrintButton
                            type="submit"
                            value="Imprimer le récapitulatif de commande"
                          />
                        )}
                        content={() => componentRef.current}
                      />
                    </PrintButtonRow>
                    <p>Institut : {institute.name}</p>
                    {data && (
                      <p>
                        Commande validée le :{' '}
                        {data.validatedAt.length > 0
                          ? Moment(data.validatedAt).format(
                            'DD MMMM yyyy à HH:mm',
                          )
                          : '-'}
                      </p>
                    )}
                  </div>
                )}

                <p>Total de la commande : {total.toFixed(2) || 0}€</p>
                <BtoBProductHeader>
                  <BtoBProductItem>Produit</BtoBProductItem>
                  <BtoBProductItemCentered>Quantité</BtoBProductItemCentered>
                  <BtoBProductItemCentered>Prix</BtoBProductItemCentered>
                  <BtoBProductItemCentered>Conditionnement</BtoBProductItemCentered>
                  <BtoBProductItemCentered>Montant</BtoBProductItemCentered>
                  {!active && (
                    <BtoBProductItemCentered> </BtoBProductItemCentered>
                  )}
                </BtoBProductHeader>
                {productsWithQuantities?.filter(prd => { return prd.quantity > 0 })
                  .map(prd => (
                    <BtoBProductWrapper>
                      <BtoBProductItem>{prd.language.title}</BtoBProductItem>
                      <BtoBProductItemCentered>{prd.quantity} </BtoBProductItemCentered>
                      <BtoBProductItemCentered>{prd.unitPrice}€</BtoBProductItemCentered>
                      <BtoBProductItemCentered>{prd.box}</BtoBProductItemCentered>
                      <BtoBProductItemCentered>{Number(prd.price * prd.quantity * prd.box).toFixed(2)}€</BtoBProductItemCentered>
                      {!active && (
                        <BtoBProductItemCentered>
                          <button onClick={() => setQuantity(0, prd.id)}>
                            <RiDeleteBin2Fill />
                          </button>
                        </BtoBProductItemCentered>
                      )}

                    </BtoBProductWrapper>
                  ))}
              </div>
            )}

            {!active && (
              <div>
                <form onSubmit={handleSubmit}>
                  {!orderId && (
                    <p>Total de la commande : {total.toFixed(2)}€</p>
                  )}
                  {!orderId && (
                    <BtoBButtons>
                      <BtoBButton
                        name="createUpdate"
                        type="submit"
                        value={
                          orderId
                            ? 'Mettre à jour la commande'
                            : 'Enregistrer la commande'
                        }
                      />
                      <BtoBButton name="validate" onClick={handleClickOpen} value="Valider la commande" />
                    </BtoBButtons>
                  )}

                  {orderId && (
                    <div>
                      { /* <p>Ajouter des produits: </p> */}
                      <br />
                      <br />
                      <br />
                      {/* <form onSubmit={handleShowAddProduct}> */}
                      <BtoBButtons><BtoBButton name="addProduct" type="submit" value={showAddProduct ? "Masquer la liste des produits" : "Ajouter ou Supprimer des produits"} /></BtoBButtons>
                      <br />
                      <br />
                      <BtoBButtons>
                        {/* {showAddProduct && } */}
                        <BtoBButton name="createUpdate" type="submit" value={orderId ? 'Mettre à jour la commande' : "Créer la commande"} />
                        <BtoBButton name="validate" onClick={handleClickOpen} value="Valider la commande" />
                      </BtoBButtons>

                      <br />
                      <br />
                      {/* </form> */}
                    </div>
                  )}
                  {
                    <Dialog
                      open={open}
                      onClose={handleDismiss}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {'Voulez vous vraiment valider la commande?'}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Si vous cliquez sur confirmer la commande sera envoyée directement au siège et ne pourra plus être modifiée. Cliquez sur annuler pour interrompre la validation.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleDismiss}>Annuler</Button>
                        <Button onClick={handleValidate} autoFocus>
                          Confirmer
                        </Button>
                      </DialogActions>
                    </Dialog>}

                  <Dialog
                    open={openError}
                    onClose={handleDismissError}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {'Enreur'}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Enregistrer la commande avant de la valider
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleDismissError} autoFocus>
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {showAddProduct &&
                    <div>
                      {orderId &&
                        <p>Pour supprimer des produits, veuillez mettre sa quantité à 0. Validez toutes vos modifications en cliquant sur le bouton "Mettre à jour la commande" en bas de la page.</p>
                      }
                      <DropdownWrapper>
                        <Dropdown className='dropdownPink' controlClassName='dropdownPink' menuClassName='dropdownPink' placeholderClassName='dropdownPink' options={categories || []} onChange={onCategorieSelect.bind(this)} placeholder='Sélectionnez une catégorie' />
                      </DropdownWrapper>

                      {/* <DropdownWrapper>
                  <Dropdown options={gammes || []} onChange={onGammeSelect.bind(this)} placeholder='Sélectionnez une gamme' />
                </DropdownWrapper> */}

                      <BtoBOrderHeader categorieSelected={categorieSelected} />

                      {productsWithQuantities?.sort((a, b) => customSort(a, b)).filter(prd => {
                        var currentCategory = false
                        var currentRange = false
                        if (categorieSelected == 0) {
                          currentCategory = true
                        } else {
                          let curCat = categories!.find(obj => {
                            return obj.value === String(categorieSelected);
                          });

                          if (prd.orderCategorie === curCat?.label.toLowerCase()) {
                            currentCategory = true
                          }
                        }
                        if (gammeSelected == 0) {
                          currentRange = true
                        } else {
                          let curRange = gammes!.find(obj => {
                            return obj.value === String(gammeSelected);
                          });

                          if (prd.range === curRange?.label) {
                            currentRange = true
                          }
                        }
                        if (prd.isCategory && categorieSelected != 21) {
                          return false;
                        }
                        return currentCategory && currentRange
                      }).map(({ id, language, range, ref, isCategory, price, box, quantity, unitPrice, franchisedPurchasePriceExclVATPerPCB, franchiseMarginPerPCB }) => {
                        return (isCategory && categorieSelected == 21 ?

                          <BtoBProductWrapper>
                            <BtoBProductItem ></BtoBProductItem>
                            <BtoBProductItemCentered><DivCategory>{language.title}</DivCategory></BtoBProductItemCentered>
                            <BtoBProductItemCentered></BtoBProductItemCentered>
                            <BtoBProductItemCentered></BtoBProductItemCentered>
                            {turnOnMargeFrancisePerPCBFeature && <BtoBProductItemCentered></BtoBProductItemCentered>}
                            <BtoBProductItemCentered></BtoBProductItemCentered>
                            <BtoBProductItemCentered></BtoBProductItemCentered>
                          </BtoBProductWrapper>
                          :
                          (

                            <BtoBProductWrapper>
                              <BtoBProductItem >{ref}</BtoBProductItem>
                              <BtoBProductItemCentered>{language.title}</BtoBProductItemCentered>
                              {/* <BtoBProductItemCentered>{category}/{range}</BtoBProductItemCentered> */}
                              <BtoBProductItemCentered>{box}</BtoBProductItemCentered>
                              <BtoBProductItemCentered>{(franchisedPurchasePriceExclVATPerPCB ?? (price * box)).toFixed(2)}€</BtoBProductItemCentered>
                              {turnOnMargeFrancisePerPCBFeature ?
                                categorieSelected !== 0 && categorieSelected !== 21 ? (<> </>) :
                                  franchiseMarginPerPCB === undefined ? (<BtoBProductItemCentered />)
                                    : (

                                      quantity > 0 ? (
                                        <BtoBProductItemCentered>
                                          {Number(
                                            (franchiseMarginPerPCB ?? 0) *
                                            (quantity === 0 ? 1 : quantity),
                                          ).toLocaleString('en-US', {
                                            maximumFractionDigits: 2,
                                            useGrouping: false,
                                          })}
                                          €
                                        </BtoBProductItemCentered>

                                      ) : (

                                        <BtoBProductItemCentered>
                                          <DivGray>
                                            {Number(
                                              franchiseMarginPerPCB ?? 0,
                                            ).toLocaleString('en-US', {
                                              maximumFractionDigits: 2,
                                              minimumFractionDigits: 2,
                                              useGrouping: false,
                                            })}
                                            €
                                          </DivGray>
                                        </BtoBProductItemCentered>
                                      )
                                    )
                                : (<> </>)}

                              <BtoBProductItemCentered>{active ? -1 : <input name="quantity" value={quantity} type="text" onChange={event => setQuantity(event.target.value, id)} />}</BtoBProductItemCentered>
                              <BtoBProductItemCentered>{Number((franchisedPurchasePriceExclVATPerPCB ?? (price * box)) * quantity).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: false })}€</BtoBProductItemCentered>
                            </BtoBProductWrapper>

                          ));
                      },
                      )}
                      <br />
                      <br />
                      <BtoBButtons>
                        {orderId && (
                          <BtoBButton
                            name="createUpdate"
                            type="submit"
                            value={
                              orderId
                                ? 'Mettre à jour la commande'
                                : 'Créer la commande'
                            }
                          />
                        )}
                        {orderId && (
                          <BtoBButton
                            name="validate"
                            type="submit"
                            value="Valider la commande"
                          />
                        )}
                      </BtoBButtons>
                    </div>
                  }

                  <br />
                  <br />
                  {/* <BtoBButtons>
                  {orderId &&
                    <BtoBButton name="validate" type="submit" value="Valider la commande" />
                  }
                </BtoBButtons> */}
                </form>
              </div>
            )}
          </div>
        }
      </>
    );
  };

  const BtoBOrderHeader = categorieSelected => {
    const shouldDisplayMArgeFrancisePerPCB =
      turnOnMargeFrancisePerPCBFeature &&
      (categorieSelected.categorieSelected == 0 ||
        categorieSelected.categorieSelected == 21);
    return (
      <div>
        <BtoBProductHeader>
          <BtoBProductItem>Référence</BtoBProductItem>
          <BtoBProductItemCentered>Produit</BtoBProductItemCentered>
          {/* <BtoBProductItemCentered>Catégorie/Gamme</BtoBProductItemCentered> */}
          <BtoBProductItemCentered>
            Conditionnement
            <br />
            PCB
          </BtoBProductItemCentered>
          <BtoBProductItemCentered>
            Prix Unitaire
            <br />
            HT/PCB
          </BtoBProductItemCentered>
          {shouldDisplayMArgeFrancisePerPCB && (
            <BtoBProductItemCentered>
              Marge franchisée
              <br />
              HT/PCB
            </BtoBProductItemCentered>
          )}
          <BtoBProductItemCentered>Quantité</BtoBProductItemCentered>
          <BtoBProductItemCentered>
            Montant
            <br />
            Commande HT
          </BtoBProductItemCentered>
        </BtoBProductHeader>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>
          {pageTitle} : {institute.name}
        </title>
      </Helmet>
      <TotalProvider>
        <PageWrapper
          header={
            <>
              <TopBlueBarre label={''} />
              <PageHeader
                extramargin={true}
                backBehaviour
                label={pageTitle + ' : ' + institute.name}
              />
            </>
          }
        >
          <OrderForm />
        </PageWrapper>
        <BottomBlueBarre />
      </TotalProvider>
    </>
  );
};

const createBtoBOrderRequest = async (data: BtoBProduct.CreatePayload) => {
  const order = await request<BtoBProduct.ResponsePayload>(`api/orders`, {
    method: 'POST',
    data,
  });
  return order;
};

const updateBtoBOrderRequest = async (
  data: BtoBProduct.UpdatePayload,
  orderId: number,
) => {
  const url = `api/orders/${orderId}`;
  const order = await request<BtoBProduct.ResponsePayload>(url, {
    method: 'PUT',
    data,
  });
  return order;
};

// const validateBtoBOrderRequest = async (orderId: number) => {
//   const url = `api/orders/${orderId}`;
//   const data = { 'active': true}
//   const order = request<BtoBProduct.ResponsePayload>(url, {
//     method: 'PUT',
//     data,
//   });
//   return order;
// };

export const fetchCategoriesRequest = () =>
  request<[Categorie.Order]>(`api/order/categories`, {
    method: 'GET',
  });

export const fetchGammesRequest = () =>
  request<[Gamme]>(`api/gammes`, {
    method: 'GET',
  });

export const fetchProductsV2FromJSON = () =>
  request<[BtoBProduct.ProductsV2SubcategoriesInterface]>(`api/gammes`, {
    method: 'GET',
  });


const BtoBProductWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  margin: 10px;
`;

const BtoBProductHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  margin: 10px;
  margin-bottom: 30px;
`;

const BtoBProductItem = styled.div`
  width: 16%;
`;

const BtoBProductItemCentered = styled.div`
  width: 16%;
  text-align: center;
`;

const BtoBButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;

const BtoBButton = styled.input`
  background: #e462a1;
  border: none;
  padding: 20px;
  border-radius: 20px;
  color: white;
  marginooo: 30px;
`;

const DropdownWrapper = style.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Spinner = style.div`
  display: flex;
  align-items: center;
  justify-content: center;  
  width: 100%;
  height: 100%;
`;

const PrintButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 95%;
`;

const PrintButton = styled.input`
  background: #e462a1;
  border: none;
  padding: 20px;
  border-radius: 20px;
  color: white;
`;

const DivGray = styled.div`
  color: gray;
`;

const DivCategory = styled.div`
  margin-block: 0;
  color1: ${p => p.theme.primary};
  font-family1: 'Gabriel Sans Cond';
  font-weight: 700;
  font-size: 1rem;
`;
