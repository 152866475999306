import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  receipts: () => _t(translations.menu.receipts),
  subscriptions: () => _t(translations.menu.subscriptions),
  users: () => _t(translations.menu.users),
  specialOffers: () => _t(translations.menu.special_offers),
  debits: () => _t(translations.menu.debits),
  institute: () => _t(translations.menu.institute),
  objectives: () => _t(translations.menu.objectives),
  orders: () => _t(translations.menu.orders),
  selloff: () => _t(translations.menu.selloff),
  stats: () => _t(translations.menu.stats),
  addInstitutes: () => _t(translations.menu.addInstitutes),
  tills: () => _t(translations.menu.tills),
  faq: () => _t(translations.menu.faq),
  manual: () => _t(translations.menu.manual),
  fiscalArchives: () => _t(translations.menu.fiscalArchives),
};
