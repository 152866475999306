import { useEffect } from 'react';
import { useMounted } from '..';
import { useModuleNavigator } from './useModuleNavigator';

interface InnerModuleNavigatorOptions<T> {
  // When this value changes, the module navigator pops to root.
  context: T;
  rootEndpoint: string;
}

export function useContextModuleNavigator<T>(
  options: InnerModuleNavigatorOptions<T>,
) {
  const { context, rootEndpoint } = options;

  const isMounted = useMounted();

  const { popToRoot } = useModuleNavigator({
    rootEndpoint: rootEndpoint,
  });

  useEffect(() => {
    if (isMounted) {
      popToRoot();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  return { popToRoot };
}
