import { useQuery } from 'app/hooks';
import { Optional } from 'types/misc';
import { genericQueryKey } from 'utils/cache/keys';
import { FetchMode } from 'app/services/discounts';
import { fetchCuresRequest } from 'app/services/cures';
import { fetchAllProductsRequest } from 'app/services/products';
import { fetchSellOffRequest } from 'app/services/selloffs';
import { useMemo } from 'react';

export const useQueries = ({
  instituteId,
}: {
  instituteId: number;
}) => {

  const productsParameters = { instituteId };

  const {
    data: products,
    error: error,
    isLoading: isLoading,
  } = useQuery(
    genericQueryKey({
      identifier: 'products',
      parameters: productsParameters,
    }),
    () => fetchAllProductsRequest(productsParameters),
  );

  return { error, isLoading, products };
};

export const useSellOffQueries = (parameters: { sellOffId: number })=> {
  const sellOffQueryState = useQuery(
    genericQueryKey({
      identifier: 'selloff',
      parameters,
    }),
    () => fetchSellOffRequest(parameters),
  );
  return {
    sellOffQueryState,
  };
}

// export const useQueries = (parameters: { instituteId: number }) => {
  
// };
