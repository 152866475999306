import { atom } from 'recoil';

import { Optional } from 'types/misc';

import { storageEffect } from '../effects/storage';
import { WeeklyObjectivesState } from './types';

export const weeklyObjectivesState = atom<Optional<WeeklyObjectivesState>>({
  default: undefined,
  effects_UNSTABLE: [storageEffect('weeklyObjectives')],
  key: 'weeklyObjectives',
});
