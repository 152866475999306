import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { TextInput } from 'app/components/Inputs/TextInput';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { SelectInput } from 'app/components/Inputs/SelectInput';
import { Option } from 'react-dropdown';

import { messages } from '../../../messages';
import { DatePicker } from 'app/components/Inputs/DatePicker';

interface Props {
  disabled: boolean;
  methods?: any;
  onContractChanged?: (arg: any) => void;
  isSKINorPJContract: boolean;
}

export const Contract = ({
  disabled,
  methods,
  onContractChanged,
  isSKINorPJContract,
}: Props) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const paymentDayValue = watch('subscription.paymentDay');

  const contractTypeValue = watch('subscription.type');

  return (
    <Grid container spacing={1}>
      <FormInputGrid item sm={12}>
        <FormLabel>{t(messages.cardCode.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name="subscription.cardCode"
          placeholder={t(messages.cardCode.label())}
          required
        />
      </FormInputGrid>
      <FormInputGrid item sm={12} container spacing={1}>
        <Grid item xs={!isSKINorPJContract ? 6 : 12}>
          {' '}
          <FormLabel>{t(messages.startDebitMonth.label())}</FormLabel>
          <DatePicker
            disabled={disabled}
            name="subscription.startDate"
            required
          />
        </Grid>
        <Grid item xs={6}>
          {!isSKINorPJContract && (
            <>
              <FormLabel>{t(messages.endDate.label())}</FormLabel>
              <DatePicker
                disabled={isSKINorPJContract}
                name="subscription.endDate"
                required={!isSKINorPJContract}
              />
            </>
          )}
        </Grid>
      </FormInputGrid>

      <FormInputGrid item sm={12}>
        <FormLabel>{t(messages.contract_type.label())}</FormLabel>
        <SelectInput
          name="subscription.type"
          options={[
            { id: '7', label: 'VIP' },
            { id: '6', label: 'CE' },
            { id: '8', label: 'Skin' },
            { id: '9', label: 'Antip Skin' },
            { id: '2', label: 'Prélèvement jeune' },
            { id: '5', label: ' Anticipation jeune' },
          ]}
          fullWidth
          placeholder={contractTypeValue}
          required
          methods={methods}
          onChange1={onContractChanged}
        />
      </FormInputGrid>
      {isSKINorPJContract && (
        <FormInputGrid item sm={12}>
          <FormLabel>{t(messages.dueDay.label())}</FormLabel>
          <SelectInput
            name="subscription.paymentDay"
            options={[
              { id: '5', label: '5' },
              { id: '20', label: '20' },
            ]}
            fullWidth
            placeholder={paymentDayValue}
            required={isSKINorPJContract}
          />
        </FormInputGrid>
      )}
    </Grid>
  );
};
