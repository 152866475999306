import { StatKind } from 'app/pages/Stats/StatsPage/types';
import { format } from 'date-fns';
import { Stats } from 'types/API/stats';
import { request } from 'utils/request';

export const fetchDummyStatsRequest = async ({ kind }: { kind: StatKind }) => {
	const module = await import(`./dummy/${kind}/response.json`);
	return module.default as Stats.Values;
};

export const fetchStatsRequest = async ({ caisseId }: { caisseId: number }) => {
	const response = request<Stats.Values>(`api/stat/caisse/${caisseId}`, {
		method: 'POST',
		data: { deleted: true },
	});
	return response;
};

export const fetchDailySummary = async ({
	caisseId,
	start,
	end,
}: {
	caisseId: number;
	start: Date;
	end: Date;
}) => {
	let oneOClockStartDate = new Date(start.setHours(7, 0, 0, 0));
	let elevenOClockEndDate = new Date(end.setHours(23, 0, 0, 0));
	const response = request<Stats.Values>(`api/stat/caisse/${caisseId}`, {
		method: 'POST',
		data: {
			caisseId: caisseId,
			startDate: oneOClockStartDate,
			endDate: elevenOClockEndDate,
		},
	});
	return response;
};

export const fetchAllDailySummary = async ({
	caisseId,
}: {
	caisseId: number;
}) => {
	const response = request<Stats.Values>(`api/stat/caisse/${caisseId}`, {
		method: 'POST',
		data: { caisseId: caisseId },
	});
	return response;
};

export const fetchBook = async ({ instituteId, start, end }: { instituteId: number, start: Date, end: Date }) => {
  let oneOClockStartDate = new Date(start.setHours(7, 0, 0, 0))
  let elevenOClockEndDate = new Date(end.setHours(23, 0, 0, 0))
  const response = request<Stats.Values>(`api/stat/ticket/book`, {
    method: 'POST',
        data: { "instituteId":instituteId, "startDate": oneOClockStartDate, "endDate": elevenOClockEndDate, cancel: false },
  });
  return response;
};

export const fetchTopServiceRequest = async ({
	instituteId,
	start,
	end,
}: {
	instituteId: number;
	start: Date;
	end: Date;
}) => {
	let oneOClockStartDate = new Date(start.setHours(7, 0, 0, 0));
	let elevenOClockEndDate = new Date(end.setHours(23, 0, 0, 0));
	const response = request<Stats.Values>(`api/stat/topservice`, {
		method: 'POST',
		data: {
			instituteId: instituteId,
			startDate: oneOClockStartDate,
			endDate: elevenOClockEndDate,
		},
	});
	return response;
};
