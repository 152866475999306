import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  count: () => _t(translations.receipt.summary.count),
  total: () => _t(translations.receipt.summary.total),
  categories: {
    vente: () => _t(translations.receipts.categories.vente),
    nc: () => _t(translations.receipts.categories.nc),
    epilation: () => _t(translations.receipts.categories.epilation),
    soin: () => _t(translations.receipts.categories.soin),
    nail: () => _t(translations.receipts.categories.nail),
  },
};
