
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { messages } from './messages';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { Institute } from 'types/API/institutes';
import { request } from 'utils/request';
import { useHistory } from 'react-router-dom';
import ToggleButton from 'react-toggle-button'
import { hasEntitlement } from 'utils/roles/hasEntitlement';
import { meState } from 'app/atoms';
import { useRecoilValue } from 'recoil';

export const TillPage = () => {

  const { t } = useTranslation();
  const pageTitle = t(messages.pageTitle());

  const { id, number, merchantId, printer, tpe, connected }: { id?: string, number?: string, merchantId?: string, printer?: string, tpe?: string, connected?: string } = useParams();

  const [tillNumber, setTillNumber] = useState(number)
  const [tillMerchantId, setTillMerchantId] = useState(merchantId)
  const [tillPrinter, setTillPrinter] = useState(printer)
  const [tillTpe, setTillTpe] = useState(tpe)

  const [state, setState] = useState({
    connectedOn: connected == "true" ? true : false
  });

  const history = useHistory();

  const me = useRecoilValue(meState);

  const handleSubmit = e => {

    e.preventDefault();

    const data = {
      id: Number(id),
      number: Number(tillNumber ),
      merchantId: tillMerchantId || "0",
      printer: tillPrinter ?? "",
      tpe: tillTpe ?? "",
      connected: state.connectedOn ?? false
    }
    updateTillRequest(data)
      .then(() => {
        // alert('La caisse a été mise à jour');
        history.goBack()
      }).catch(error => {
        alert(`Erreur lors de la mise à jour de la caisse ${error}`);
      })
  }

  const updateTillRequest = async (data: Institute.TillPayload) => {

    const url = `api/caisses/${id}`    
    const till = request<Institute.Till>(url, {
      method: 'PUT',
      data,
    });
    return till;
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageWrapper header={<PageHeader backBehaviour label={pageTitle} />}>
        {/* <SellOffBody sellOffId={sellOffId} /> */}
        <form onSubmit={handleSubmit}>
          <TillRow>
            <TillRowItem>
              Identifiant de la caisse :
            </TillRowItem>
            <TillRowItem>
              {id}
            </TillRowItem>
            <TillRowItem>Numéro de caisse :</TillRowItem>
            <TillRowItem>{tillNumber}</TillRowItem>
            <TillRowItem>MerchantId :</TillRowItem>
            <TillRowItem><input name="name" type="text" value={tillMerchantId} onChange={event => setTillMerchantId(event.target.value)} /></TillRowItem>
            <TillRowItem>Adresse IP Imprimante (ex: 192.168.0.0):</TillRowItem>
            <TillRowItem><input name="name" type="text" value={tillPrinter} onChange={event => setTillPrinter(event.target.value)} /></TillRowItem>
            <TillRowItem>Adresse IP Tpe (ex: 192.168.0.0):</TillRowItem>
            <TillRowItem><input name="name" type="text" value={tillTpe} onChange={event => setTillTpe(event.target.value)} /></TillRowItem>
            {
              hasEntitlement('super_admin', me) &&
              <>
                <TillRowItem>Caisse connectée:</TillRowItem>
                <ToggleButton

                  colors={{
                    activeThumb: {
                      base: 'rgb(228,98,161)',
                    },
                    inactiveThumb: {
                      base: 'rgb(62,130,247)',
                    },
                    active: {
                      base: 'rgb(228,98,161)',
                      hover: 'rgb(218, 42, 128)',
                    },
                    inactive: {
                      base: 'rgb(65,66,68)',
                      hover: 'rgb(95,96,98)',
                    }
                  }}
                  inactiveLabel="Non"
                  activeLabel="Oui"
                  value={state.connectedOn || false}
                  onToggle={(value) => {
                    setState({
                      connectedOn: !value,
                    })
                  }} />
              </>
              
            }
            
          </TillRow>

          <TillSaveButtons><TillSaveButton type="submit" value="Mettre à jour" /></TillSaveButtons>
        </form>
      </PageWrapper>
    </>
  )
}

const TillRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 95%;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  margin: 10px;
`;

const TillRowItem = styled.div`
  margin-bottom: 10px;
`;

const TillSaveButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 95%;
`;

const TillSaveButton = styled.input`
  background: #e462a1;
  border: none;
  padding: 20px;
  border-radius: 20px;
  color: white;
  margin: 30px;
`;
