import React, { PropsWithChildren, ReactNode } from 'react';

import styled from 'styled-components/macro';

type Props = PropsWithChildren<{
  disabled?: boolean;
  secondaryContent: ReactNode;
}>;

export const LandscapePageWrapper = ({
  children,
  disabled = false,
  secondaryContent,
}: Props) => (
  <Wrapper disabled={disabled}>
    <Content>
      <Left>{secondaryContent}</Left>
      <Right>
        <ContentWrapper>{children}</ContentWrapper>
      </Right>
    </Content>
  </Wrapper>
);

interface WrapperProps {
  disabled: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  margin: 0 auto;
  padding: 0;
  pointer-events: ${p => (p.disabled ? 'none' : 'inherited')};
`;

const Content = styled.div`
  align-items: stretch;
  display: flex;
  /* height: 100vh; */
  width: 100vw;
  background: ${p => p.theme.primary}; 
`;

const Left = styled.div``;

const Right = styled.div`
  background: ${p => p.theme.pageBackground};
  border-radius: 60px 0 0 60px;
  display: flex;
  flex-direction: column;

  padding: 2rem 2rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;
