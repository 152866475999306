import { useRecoilState } from 'recoil';
import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Error as RawErrorIcon } from '@material-ui/icons';
import { Status } from '../Status';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { resiliateOldSubscriptionRequest, resiliateSubscriptionRequest } from 'app/services/subscriptions';
import { useParams } from 'react-router-dom';
import {
  useQueries,
  useSelectors,
} from 'app/pages/Subscriptions/SubscriptionPage/hooks';
import { idabo, subscriptionId } from 'app/atoms/paymentDay';
import { useHistory } from 'react-router-dom';

interface Props {
  label?: string;
  terminate?: boolean;
}

export const ContentError = ({ label, terminate }: Props) => {
  const textsuccessresiliation = '\'opération de résiliation de prélèvement a été effectuée avec succès.Merci de patienter, le prélèvement sera résilié d\'ici une heure';
  const history = useHistory();
  const [idAbo, setIdAbo] = useRecoilState(idabo);
  const [subscriptionId1, setSubscriptionId1] = useRecoilState(subscriptionId);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { id, cardNumber }: { id: string; cardNumber: string; } = useParams();
  const customerId = parseInt(id);
  const { currentInstituteId: instituteId } = useSelectors();
  const { customer } = useQueries({
    customerId,
    cardNumber,
    instituteId,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleValidate = () => {
    let isOldCustomer = cardNumber.includes('old_');
    let subscriptionId = customer?.subscriptions[0].id ?? subscriptionId1;

    console.log(`handleValidate, isOldCustomer:${isOldCustomer}, instituteId:${instituteId}, subscriptionId:${subscriptionId}, idAbo:${idAbo} `);
    subscriptionId &&
      (async () => {
        isOldCustomer
          ? // This request is temporary and will be replaced with the actual request once available.
          await resiliateOldSubscriptionRequest({ subscriptionId: subscriptionId, instituteId: instituteId, idabo: idAbo }).
            then(
              () => {
                handleClose()
                popupforresiliationsuccess()
                //history.goBack();

              })
          : await resiliateSubscriptionRequest({
            subscriptionId: subscriptionId,
          }).then(() => {
            handleClose()
            popupforresiliationsuccess()
            //history.goBack();
          });
      })();
  };

  const popupforresiliationsuccess = () => {
    console.log(`popupforresiliationsuccess`);
    alert(textsuccessresiliation);
  };

  const handleClose = () => {
    console.log(`handleClose`);
    setOpen(false);
  };

  return (
    <Wrapper>
      <ErrorIcon className="icon" style={{ fontSize: '6rem' }} />
      <Text>{label}</Text>
      {terminate && (
        <>
          {' '}
          {
            <Status
              isActive={false}
              label={t('Résilier')}
              onClick={handleClickOpen}
            />
          }
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <b>ATTENTION :</b> Si vous procédez à la résiliation d’un
              abonnement en erreur pour le saisir en tant que nouvel abo propre
              dans le portail il faut au préalable :
              <DialogTitle id="alert-dialog-title">
                • Une fois la résiliation enregistrée, aller sur la fonction
                SAISIR NOUVEL ABO » dans la gestion abonnement pour recréer son
                son abonnement en vous munissant d’une NOUVELLE carte dont vous
                saisirez le numéro sur le portail et prévenir cette cliente que
                sa nouvelle carte l’attend à la caisse de votre institut.
              </DialogTitle>
              <DialogTitle id="alert-dialog-title">
                • Consulter la fiche abonnée dans l’onglet RECHERCHER de la
                caisse ou retrouver son contrat papier pour saisir : nom,
                prénom, 06, mail, adresse et surtout RIB.
              </DialogTitle>
              <b>Voulez vous vraiment résilier cet abonnement?</b>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Si vous cliquez sur confirmer, le prélèvement sera
                définitivement supprimé et les données associées ne pourront
                plus être récupérées si vous ne les avez pas notées.
              </DialogContentText>
              <DialogContentText id="alert-dialog-description">
                Cliquez sur annuler pour interrompre la validation.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Annuler</Button>
              <Button onClick={() => handleValidate()}>Confirmer</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
  > .icon {
    margin-bottom: 2rem;
  }
`;

const ErrorIcon = styled(RawErrorIcon)`
  color: ${p => p.theme.danger};
`;

const Text = styled(Typography)`
  text-align: center;
  color: ${p => p.theme.text.body};
`;