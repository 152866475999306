import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { TextInput } from 'app/components/Inputs/TextInput';
import { fieldName } from 'utils/form';

import { messages } from '../../../messages';
import { Address } from 'app/components/Domain/forms/Address';
import { Names } from './Names';

interface Props {
  disabled: boolean;
}

export const Customer = ({ disabled }: Props) => {
  const { t } = useTranslation();
  const namePrefix = 'customer';
  return (
    <Grid container spacing={1}>
      <Names disabled={disabled} namePrefix={namePrefix} />

      <FormInputGrid item sm={6}>
        <FormLabel>{t(messages.phone.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name={fieldName('phone', namePrefix)}
          placeholder={t(messages.phone.placeholder())}
          required
        />
      </FormInputGrid>
      <FormInputGrid item sm={6}>
        <FormLabel>{t(messages.email.label())}</FormLabel>
        <TextInput
          disabled={disabled}
          name={fieldName('email', namePrefix)}
          placeholder={t(messages.email.placeholder())}
          required
        />
      </FormInputGrid>
      <Address disabled={disabled} namePrefix={namePrefix} isRequired={false} />
    </Grid>
  );
};
