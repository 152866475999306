import { routes as auth } from './modules/auth';
import { routes as notFound } from './modules/notFound';
import { routes as receipts } from './modules/receipts';
import { routes as specialOffers } from './modules/specialOffers';
import { routes as subscriptions } from './modules/subscriptions';
import { routes as users } from './modules/users';
import { routes as institutes } from './modules/institutes';
import { routes as debits } from './modules/debits';
import { routes as objectives } from './modules/objectives';
import { routes as orders } from './modules/orders';
import { routes as sellOffs } from './modules/sellOffs';
import { routes as stats } from './modules/stats';
import { routes as addInstitutes } from './modules/addInstitutes';
import { routes as tills } from './modules/tills';
import { routes as faq } from './modules/faq';
import { routes as manual } from './modules/manual';
import { routes as fiscalArchives } from './modules/fiscalArchives';
export const routes = [
  // Order matters.
  ...auth,
  ...receipts,
  ...subscriptions,
  ...users,
  ...specialOffers,
  ...institutes,
  ...debits,
  ...objectives,
  ...orders,
  ...sellOffs,
  ...tills,
  ...stats,
  ...addInstitutes,
  ...faq,
  ...manual,
  ...fiscalArchives,
  ...notFound,
];
