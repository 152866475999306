import React from 'react';
import { Helmet } from 'react-helmet-async';
import { messages } from './messages';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { ReactComponent as AddIcon } from 'app/assets/plus.svg';
import { ActionPageHeader } from 'app/components/PageHeaders/ActionPageHeader';
import { generatePath, useHistory } from 'react-router-dom';
import { paths } from 'app/routes/paths';
import { LoadableContent } from 'app/components/LoadableContent';
import { List } from 'app/components/List';
import { EmptyState } from 'app/components/EmptyState';
import { useInstituteSelector } from 'app/atoms/selectors';
import { useQueries } from './hooks';
import { SellOffItem } from './components/SellOffItem';
import { SellOff } from 'types/API/selloffs';

export const SellOffsPage = () => {

  const { institute } = useInstituteSelector();

  const { t } = useTranslation();
  const history = useHistory();
  const pageTitle = t(messages.pageTitle());

  const onSellOffCreation = () => {
    history.push(paths.selloffs.create);
  };

  const onSellOffEdition = ({ sellOff }: { sellOff: SellOff.Normal }) => {
    const location = generatePath(paths.selloffs.update, { id: sellOff.id });
    history.push(location);
  };

  const { sellOffsQueryState } = useQueries({ instituteId: institute.id });
 
  return (
    <>
      <Helmet>
        <title>{pageTitle} : {institute.name}</title>
      </Helmet>

      <PageWrapper
        header={
          <ActionPageHeader
            button={{
              onClick: onSellOffCreation,
              label: t(messages.add()),
              icon: <AddIcon />,
            }}
            title={t(messages.pageTitle()) + " : " + institute.name}
          />

        }
      >
        <LoadableContent
          error={sellOffsQueryState.error}
          isLoading={sellOffsQueryState.isLoading}
          isEmpty={sellOffsQueryState.data?.length === 0}
          emptyNodeRenderer={() => (
            <EmptyState label={t(messages.emptyLabel())} />
          )}
        >
          <List>
            {sellOffsQueryState.data?.map(sellOff => (
               <SellOffItem
                key={sellOff.id}
                sellOff={sellOff}
                onClick={() => onSellOffEdition({ sellOff: sellOff })}
              />
            ))}
          </List>
        </LoadableContent>
      </PageWrapper>
    </>
  )
}