import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { LoadableContent } from 'app/components/LoadableContent';
import { request } from 'utils/request';
import { EmptyState } from 'app/components/EmptyState';
import { makeStyles } from '@material-ui/core/styles';

export interface Faq {
  id: number,
  question: string,
  response: string
}



export const FaqPage = () => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState<Faq[]>();

  const useStyles = makeStyles({
    cell: {
      fontSize: 14,
      padding: 5,
      background: 'white',
      borderRadius: 10,
      marginBottom: 10,
    },
    question: {
      fontWeight: 'bold',
      fontSize: 16,
    }
  });

  const classes = useStyles();

  const loadData = async () => {
    console.log(`loadData, loading:${loading}`);
    if (!loading) {
      setLoading(true)
      fetchFAQs().then(data => {
        setLoading(false)
        setData(data)
      }).catch(error => {
        setLoading(false)
        setError(error)
        console.log(error)
      })
    }
  }

  useEffect(() => {
    loadData();
    return () => { };
  }, []);

  return (
    <>
      <Helmet>
        <title>FAQ</title>
      </Helmet>
      <PageWrapper
        header={<PageHeader label={"FAQ"} />}>

        <LoadableContent isLoading={loading}
          emptyNodeRenderer={() => (
            <EmptyState label="Liste vide" />
          )}>
          {data &&
            <div>
              <p>Liste des questions fréquement posées:</p>
              <div>
                {data.map(faq => (
                  <div className={classes.cell}>
                    <div className={classes.question}>{faq.question}?</div>
                    <div>{faq.response}</div>
                  </div>
                ))
                }
              </div>
            </div>
          }
        </LoadableContent>
      </PageWrapper>
    </>
  );
}


export const fetchFAQs = async () => {
  const response = request<Faq[]>(`api/faqs`, {
    method: 'GET',
  });
  return response;
};