import { Alert as MuiAlert } from '@material-ui/lab';
import { Snackbar as MUISnackbar } from '@material-ui/core';
import { SyntheticEvent } from 'react';

import { Severity } from './types';

interface Props {
  label: string;
  onClose: (event: SyntheticEvent<Element>) => void;
  open: boolean;
  severity: Severity;
}

export const Snackbar = (props: Props) => {
  const { label, onClose, open, severity } = props;

  return (
    <MUISnackbar autoHideDuration={3000} onClose={onClose} open={open}>
      <MuiAlert
        elevation={6}
        onClose={onClose}
        severity={severity}
        variant="filled"
      >
        {label}
      </MuiAlert>
    </MUISnackbar>
  );
};
