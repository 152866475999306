import { FetchMode, fetchDiscountsRequest } from 'app/services/discounts';
import { fetchCuresRequest } from 'app/services/cures';
import { useQuery } from 'app/hooks';
import { genericQueryKey } from 'utils/cache/keys';

export const useQueries = (instituteId: number) => {
  const parameters = { instituteId, fetchMode: FetchMode.ALL };

  const {
    data: discounts,
    error: discountsError,
    isLoading: isLoadingDiscounts,
  } = useQuery(
    genericQueryKey({
      identifier: 'discounts',
      parameters,
    }),
    () => fetchDiscountsRequest(parameters),
  );

  const {
    data: cures,
    error: curesError,
    isLoading: isLoadingCures,
  } = useQuery(
    genericQueryKey({
      identifier: 'cures',
      parameters,
    }),
    () => fetchCuresRequest(parameters),
  );
  const error = discountsError ?? curesError;
  const isLoading = isLoadingDiscounts || isLoadingCures;
  return { cures, discounts, error, isLoading };
};
