import { useTranslation } from 'react-i18next';

import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { PeriodInputDescription } from 'app/pages/Stats/StatsPage/types/inputs';
import { DatePicker } from 'app/components/Inputs/DatePicker';
import { FormLabel } from 'app/components/Forms/FormLabel';

import { messages } from './messages';

interface Props {
  description: PeriodInputDescription;
  disabled: boolean;
}

export const Period = (props: Props) => {
  const { description, disabled } = props;

  const { t } = useTranslation();

  const { outputPropertyEnd, outputPropertyStart } = description;

  return (
    <>
      <FormInputGrid item sm={2}>
        <FormLabel>{t(messages.endDate.label())}</FormLabel>
        <DatePicker disabled={disabled} name={outputPropertyEnd} required />
      </FormInputGrid>
      <FormInputGrid item sm={2}>
        <FormLabel>{t(messages.startDate.label())}</FormLabel>
        <DatePicker disabled={disabled} name={outputPropertyStart} required />
      </FormInputGrid>
    </>
  );
};
