import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  pageTitle: () => _t(translations.subscription.create_page_title),

  firstname: {
    label: () => _t(translations.subscription.firstname.label),
    placeholder: () => _t(translations.subscription.firstname.placeholder),
  },
  lastname: {
    label: () => _t(translations.subscription.lastname.label),
    placeholder: () => _t(translations.subscription.lastname.placeholder),
  },
  phone: {
    label: () => _t(translations.subscription.phone.label),
    placeholder: () => _t(translations.subscription.phone.placeholder),
  },
  email: {
    label: () => _t(translations.subscription.email.label),
    placeholder: () => _t(translations.subscription.email.placeholder),
  },
  address: {
    label: () => _t(translations.subscription.address.label),
    placeholder: () => _t(translations.subscription.address.placeholder),
  },
  additionnal_address: {
    label: () => _t(translations.subscription.additionnal_address.label),
    placeholder: () =>
      _t(translations.subscription.additionnal_address.placeholder),
  },
  zipcode: {
    label: () => _t(translations.subscription.zipcode.label),
    placeholder: () => _t(translations.subscription.zipcode.placeholder),
  },
  city: {
    label: () => _t(translations.subscription.city.label),
    placeholder: () => _t(translations.subscription.city.placeholder),
  },

  rumCode: {
    label: () => _t(translations.subscription.rum_code.label),
  },
  cardCode: {
    label: () => _t(translations.subscription.card_code.label),
  },
  subscriptionType: {
    label: () => _t(translations.subscription.subscription_type.label),
  },
  startDate: {
    label: () => _t(translations.subscription.start_date.label),
  },
  startDebitMonth: {
    label: () => _t(translations.subscription.start_debit_month.label),
  },
  subscriptionStatus: {
    active: () => _t(translations.subscription.subscription_status.active),
    inactive: () => _t(translations.subscription.subscription_status.inactive),
  },
  endDate: {
    label: () => _t(translations.subscription.end_date.label),
  },
  dueDay: {
    label: () => _t(translations.subscription.due_day.label),
  },
  contract_type: {
    label: () => _t(translations.subscription.contarct_type.label),
  },
  iban: {
    label: () => _t(translations.subscription.iban.label),
    placeholder: () => _t(translations.subscription.iban.placeholder),
  },
  payerIsSubscriber: () => _t(translations.subscription.payer_is_subscriber),
  subscriptionComments: {
    label: () => _t(translations.subscription.subscription_comments.label),
    placeholder: () =>
      _t(translations.subscription.subscription_comments.placeholder),
  },
  instituteComments: {
    label: () => _t(translations.subscription.institute_comments.label),
    placeholder: () =>
      _t(translations.subscription.institute_comments.placeholder),
  },
  wrongAddress: () => _t(translations.subscription.wrong_address),
  wrongPhone: () => _t(translations.subscription.wrong_phone),
  wrongEmail: () => _t(translations.subscription.wrong_email),
  noMailing: () => _t(translations.subscription.no_mailing),
  noSMS: () => _t(translations.subscription.no_sms),
  noEmail: () => _t(translations.subscription.no_email),
  rejectedDebit: () => _t(translations.subscription.rejected_debit),
  rejectedDebitReason: {
    title: () => _t(translations.subscription.rejected_debit_reason.title),
    AC01: () => _t(translations.subscription.rejected_debit_reason.AC01),
    AC04: () => _t(translations.subscription.rejected_debit_reason.AC04),
    BE05: () => _t(translations.subscription.rejected_debit_reason.BE05),
    AM04: () => _t(translations.subscription.rejected_debit_reason.AM04),
    AC13: () => _t(translations.subscription.rejected_debit_reason['AC13 (3)']),
    MD01: () => _t(translations.subscription.rejected_debit_reason.MD01),
    AC06: () => _t(translations.subscription.rejected_debit_reason.AC06),
    MD07: () => _t(translations.subscription.rejected_debit_reason.MD07),
    AG02: () => _t(translations.subscription.rejected_debit_reason.AG02),
    FF01: () => _t(translations.subscription.rejected_debit_reason.FF01),
    MS02: () => _t(translations.subscription.rejected_debit_reason.MS02),
    MS03: () => _t(translations.subscription.rejected_debit_reason.MS03),
    RC01: () => _t(translations.subscription.rejected_debit_reason.RC01),
    RR01: () => _t(translations.subscription.rejected_debit_reason.RR01),
    SL01: () => _t(translations.subscription.rejected_debit_reason.SL01),
    AM05: () => _t(translations.subscription.rejected_debit_reason.AM05),
    MD02: () => _t(translations.subscription.rejected_debit_reason.MD02),
    MD06: () => _t(translations.subscription.rejected_debit_reason['MD06 (4)']),
    CNOR: () => _t(translations.subscription.rejected_debit_reason.CNOR),
    DNOR: () => _t(translations.subscription.rejected_debit_reason.DNOR),
    AG01: () => _t(translations.subscription.rejected_debit_reason.AG01),
    BE07: () => _t(translations.subscription.rejected_debit_reason.BE07)
  },
  sections: {
    bankAccount: () => _t(translations.subscription.sections.bank_account),
    bankAccountDisplay: () => _t(translations.subscription.sections.bank_account_display),
    contract: () => _t(translations.subscription.sections.contract),
    customer: () => _t(translations.subscription.sections.customer),
    institute: () => _t(translations.subscription.sections.institute),
  },
  submit: () => _t(translations.subscription.submit),
};
