import { useCallback, useMemo } from 'react';
import { differenceWith } from 'lodash';

import { useQuery } from 'app/hooks';
import {
  fetchCustomersRequest,
  fetchOldCustomersRequest,
  fetchPendingCustomersRequest,
  searchCustomersRequest,
} from 'app/services/customers';
import { genericQueryKey } from 'utils/cache/keys';
import { Customer } from 'types/API/customers';

import { SubscriptionTab } from '../types';

export const useQueries = ({
  instituteId,
  searchQuery,
  searchNotMigratedCustomers = true,
  tab,
}: {
  instituteId: number;
  searchQuery: string;
  searchNotMigratedCustomers?: boolean;
  tab: SubscriptionTab;
}) => {
  // ********************warning, warning, warning, warning:*************************========>
  //i disabled searchQuery because evrytime user writes a caracter, this variable changes and forces realoding view.
  const parameters = { instituteId/*, searchQuery*/ };

  const subscribersQueryState = useQuery(
    genericQueryKey({
      identifier: 'subscribers',
      parameters,
    }),
    () => fetchCustomersRequest(parameters),
    { enabled: tab === SubscriptionTab.SUBSCRIBERS },
  );

  /*
  const subscribersOldQueryState = useQuery(
    genericQueryKey({
      identifier: 'oldSubscribers',
      parameters,
    }),
    () => fetchOldCustomersRequest(parameters),
    { enabled: tab === SubscriptionTab.OLDDB },
  );*/

  const pendingSubscribersQueryState = useQuery(
    genericQueryKey({
      identifier: 'pendingSubscribers',
      parameters,
    }),
    () => fetchPendingCustomersRequest(parameters),
    { enabled: tab === SubscriptionTab.PENDING },
  );

  const searchParams = useMemo(
    () => ({ lastName: searchQuery, firstName: '' }),
    [searchQuery],
  );

  // This request search for customer in the old database. This is used to import old customers on demand.
  // This feature should be temporary while the migration is on going and will be removed in a near future.
  // Simply set `searchNotMigratedCustomers` to false if you want to disable it (or feel free to refactor :p).
  const searchExternalCustomers = useCallback(async () => {
    const customers = await searchCustomersRequest(searchParams);
    // Only keep the customers registered in the current institute and for which the subscription is still active.
    const instituteCustomers = customers.filter(
      customer => customer.institute.id === instituteId && customer.active,
    );
    // Remove duplicates with the customers already in the new database.
    const notMigratedCustomers = differenceWith(
      instituteCustomers,
      subscribersQueryState.data ?? [],
      (external: Customer.External, subscriber: Customer.Normal) => {
        return parseInt(external.id) === subscriber.id;
      },
    );
    return notMigratedCustomers.length > 0 ? notMigratedCustomers : undefined;
  }, [searchParams, instituteId, subscribersQueryState.data]);

  
  const externalSubscribersQueryState = useQuery(
    genericQueryKey({
      identifier: 'customersSearch',
      parameters: searchParams,
    }),
    searchExternalCustomers,
    {
      enabled:
        tab === SubscriptionTab.SUBSCRIBERS &&
        !!searchQuery &&
        // Only search in not migrated customers if the query has at least 3 chars (in order to avoid useless queries)
        searchQuery.length > 2 &&
        searchNotMigratedCustomers,
    },
  );

  return {
    externalSubscribersQueryState,
    pendingSubscribersQueryState,
//    subscribersOldQueryState,
    subscribersQueryState,
  };
};
