import { useMutation, useQueryClient } from 'react-query';

import { Auth } from 'types/API/auth';
import { User } from 'types/API/users';
import { addOrUpdateObjectToCaches } from 'utils/react-query/cache';
import { genericQueryKey } from 'utils/cache/keys';
import { registerUserRequest } from 'app/services/auth';
import { updateUserRequest } from 'app/services/users';
import { useMutationOptions } from 'app/hooks/modal/useMutationsOptions';

export const useMutations = (instituteId: number) => {
  const queryClient = useQueryClient();

  const parameters = { instituteId };

  const updateCache = (user: User.DetailFormat) => {
    const listQueryKey = genericQueryKey({
      identifier: 'users',
      parameters,
    });
    const detailQueryKey = genericQueryKey({
      identifier: 'user',
      parameters: { userId: user.id },
    });
    const queryKeys = [listQueryKey, detailQueryKey];

    addOrUpdateObjectToCaches({
      object: user,
      queryClient,
      queryKeys,
    });
  };

  const mutationOptions = useMutationOptions({
    successCallback: (response: User.DetailFormat, _) => {
      updateCache(response);
    },
  });

  const registerUser = (data: Auth.RegisterPayload) =>
    registerUserRequest(data);

  const { mutate: registerMutation, isLoading: isRegistering } = useMutation(
    registerUser,
    mutationOptions,
  );

  const updateUser = (params: { userId: number; data: User.UpdatePayload }) =>
    updateUserRequest(params);

  const { mutate: updateMutation, isLoading: isUpdating } = useMutation(
    updateUser,
    mutationOptions,
  );

  const isLoading = isUpdating || isRegistering;

  return {
    isLoading,
    registerMutation,
    updateMutation,
  };
};
