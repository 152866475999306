import { Snackbar } from 'app/components/Snackbar';

import { useSelectors } from './hooks';

export const SnackbarLoader = () => {
  const { resetSnackbar, snackbar } = useSelectors();

  const { label, open, severity } = snackbar;

  const handleClose = () => {
    resetSnackbar();
  };

  const renderAlert = () => (
    <Snackbar
      label={label}
      onClose={handleClose}
      open={open}
      severity={severity}
    />
  );

  return <>{open && renderAlert()}</>;
};
