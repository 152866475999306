import { useMutation } from 'react-query';

import { updateInstituteRequest } from 'app/services/institutes';
import { Institute } from 'types/API/institutes';

export const useMutations = () => {
  // const queryClient = useQueryClient();

  const updateCache = (institute: Institute.Normal) => {
    // const queryKey = genericQueryKey({
    //   identifier: 'users',
    // });
    // addOrUpdateObjectToCache({
    //   object: user,
    //   queryClient,
    //   queryKey,
    // });
  };

  const mutationOptions = {
    onSuccess: (response: Institute.Normal, _) => {
      updateCache(response);
    },
  };

  const updateInstitute = (params: {
    instituteId: number;
    data: Institute.Payload;
  }) => updateInstituteRequest(params);

  const { mutate: updateMutation, isLoading } = useMutation(
    updateInstitute,
    mutationOptions,
  );

  return {
    isLoading,
    updateMutation,
  };
};
