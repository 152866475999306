import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components/macro';
import { useFormContext } from 'react-hook-form';

import { Button } from 'app/components/Button';
import { SelectInputOption } from 'app/components/Inputs/SelectInput';
import { FormSection } from 'app/components/Forms/FormSection';

import { messages } from '../../messages';
import { General } from './sections/General';
import { Access } from './sections/Access';
import { first } from 'lodash';
import { User } from 'types/API/users';
import { rolePriority } from 'utils/entities/users';
import { Mode } from 'types/forms';
interface Props {
  canSubmit: boolean;
  isSubmitting: boolean;
  institutesOptions: SelectInputOption[];
  isInstituteEditionEnabled: boolean;
  isRoleEditionEnabled: boolean;
  rolesOptions: SelectInputOption[];
  mode: Mode;
}

export const Form = (props: Props) => {
  const {
    canSubmit,
    isSubmitting,
    institutesOptions,
    isInstituteEditionEnabled,
    isRoleEditionEnabled,
    rolesOptions,
    mode,
  } = props;
  const { t } = useTranslation();

  const displayAccessSection =
    isInstituteEditionEnabled || isRoleEditionEnabled;

  const { watch } = useFormContext();

  const roles = watch('roles');

  const role = first(roles) as User.Role;

  const isPasswordUpdateEnabled =
    rolePriority(role) >= rolePriority(User.Roles.ROLE_SUPER_USER);

  return (
    <Wrapper>
      <FormSection title={t(messages.sections.general())}>
        <General
          disabled={isSubmitting}
          isPasswordUpdateEnabled={isPasswordUpdateEnabled}
          isPasswordUpdateOptional={mode === Mode.Update}
        />
      </FormSection>

      {displayAccessSection && (
        <FormSection title={t(messages.sections.access())}>
          <Access
            disabled={isSubmitting}
            institutesOptions={institutesOptions}
            isInstituteEditionEnabled={isInstituteEditionEnabled}
            isRoleEditionEnabled={isRoleEditionEnabled}
            rolesOptions={rolesOptions}
          />
        </FormSection>
      )}
      <Button
        className="submit"
        disabled={!canSubmit}
        isSpinning={isSubmitting}
        type="submit"
      >
        {t(messages.submit())}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .submit {
    margin-top: 3rem;
    float: right;
  }
`;
