import { _t } from 'utils/messages';
import { translations } from 'locales/translations';

export const messages = {
  pageTitle: () => _t(translations.sellOffs.page_title),
  add: () => _t(translations.sellOffs.add),
  emptyLabel: () => _t(translations.sellOffs.empty_label),
  id: () => _t(translations.orders.id),
  startDate: () => _t(translations.sellOffs.start_date),
  endDate: () => _t(translations.sellOffs.end_date),
  dateFormat: () => _t(translations.orders.date_format),
  name: () => _t(translations.sellOffs.name),
};
