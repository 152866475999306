import { Receipt } from 'types/API/receipts';
import { request } from 'utils/request';

export const fetchPendingReceiptsRequest = ({
  instituteId,
}: {
  instituteId: number;
}) =>
  request<Receipt.Normal[]>(`api/tickets/pending/institute/${instituteId}`, {
    method: 'GET',
  });

export const fetchPaidReceiptsRequest = ({
  instituteId,
}: {
  instituteId: number;
}) =>
  request<Receipt.Normal[]>(`api/tickets/paid/institute/${instituteId}`, {
    method: 'GET',
  });

export const fetchReceiptRequest = ({ receiptId }: { receiptId: number }) =>
  request<Receipt.Detail>(`api/ticket/get/${receiptId}`, {
    method: 'GET',
  });
