import { Institute } from './institutes';

export namespace User {
  export interface Light {
    id: number;
    username: string;
    firstname: string;
    lastname: string;
  }

  export interface ListFormat extends Light {
    roles: Role[];
    institute: Institute.Light[];
  }

  export interface DetailFormat extends Light {
    roles: Role[];
    institute: string[];
    password: string;
  }

  export interface UpdatePayload {
    firstname: string;
    lastname: string;
    institute: string[];
    password?: string;
    roles: Role[];
  }

  export type Type = 'user' | 'till' | 'other';

  export const Types = {
    TYPE_USER: 'user' as Type,
    TYPE_TILL: 'till' as Type,
    TYPE_OTHER: 'other' as Type,
  };

  export type Role =
    | 'ROLE_TILL'
    | 'ROLE_USER'
    | 'ROLE_SUBSCRIPTIONS'
    | 'ROLE_SUPER_USER'
    | 'ROLE_ADMIN'
    | 'ROLE_ANIMATRICE'
    | 'ROLE_FORMATRICE'
    | 'ROLE_COMPTA'
    | 'ROLE_SUPER_ADMIN';

  export const Roles = {
    ROLE_TILL: 'ROLE_TILL' as Role,
    ROLE_USER: 'ROLE_USER' as Role,
    ROLE_SUBSCRIPTIONS: 'ROLE_SUBSCRIPTIONS' as Role,
    ROLE_SUPER_USER: 'ROLE_SUPER_USER' as Role,
    ROLE_ADMIN: 'ROLE_ADMIN' as Role,
    ROLE_ANIMATRICE: 'ROLE_ANIMATRICE' as Role,
    ROLE_FORMATRICE: 'ROLE_FORMATRICE' as Role,
    ROLE_COMPTA: 'ROLE_COMPTA' as Role,
    ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN' as Role,
  };

  export const RolesPriority = [
    Roles.ROLE_TILL,
    Roles.ROLE_USER,
    Roles.ROLE_SUBSCRIPTIONS,
    Roles.ROLE_SUPER_USER,
    Roles.ROLE_COMPTA,
    Roles.ROLE_ANIMATRICE,
    Roles.ROLE_FORMATRICE,
    Roles.ROLE_ADMIN,
    Roles.ROLE_SUPER_ADMIN,
  ];
}
