import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import moment from 'moment';
import 'moment/locale/fr';

import { convertLanguageJsonToObject } from './translations';
import en from './en/translation.json';
import fr from './fr/translation.json';

export const translationsJson = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

const defaultLocale = 'fr';

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(en);

const updateMomentLocale = async (locale: string) => {
  await import(`moment/locale/${locale}`);
  moment.locale(locale);
};

if (!i18next.isInitialized) {
  const instance = i18next
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(LanguageDetector);

  instance.on('languageChanged', async language => {
    // Sync `i18n` language with `moment` locale.
    await updateMomentLocale(language.substring(0,2));
  });

  instance
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      resources: translationsJson,
      fallbackLng: defaultLocale,
      debug:
        process.env.NODE_ENV !== 'production' &&
        process.env.NODE_ENV !== 'test',

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        format: function (value, format) {
          if (format === 'uppercase') return value.toUpperCase();
          if (value instanceof Date) return moment(value).format(format);
          return value;
        },
      },
    });
}
