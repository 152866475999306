import { OrderPage } from 'app/pages/Orders/OrderPage';
import { OrdersPage } from 'app/pages/Orders/OrdersPage';
import { Route } from 'types/route';

import { paths } from '../../paths';

const orders: Route = {
  authenticated: true,
  content: OrdersPage,
  routerPath: paths.orders.list,
};

const createOrder: Route = {
  authenticated: true,
  content: OrderPage,
  routerPath: paths.orders.create,
};

const updateOrder: Route = {
  authenticated: true,
  content: OrderPage,
  routerPath: paths.orders.update,
};

export const routes = [createOrder, updateOrder, orders];
