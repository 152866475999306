import { ManualKind } from 'app/pages/ManualPage/types';
import { ReceiptKind } from 'app/pages/Receipts/ReceiptsPage/types';
import { allStatKinds } from 'app/pages/Stats/StatsPage/types';
import { parameterFromStatKind } from 'app/pages/Stats/StatsPage/utils';
import { SubscriptionKind } from 'app/pages/Subscriptions/SubscriptionsPage/types';
import { first } from 'lodash';
import { Debit } from 'types/API/debits';

export const paths = {
	home: '/',
	receipts: {
		detail: '/receipt/:id',
		list: {
			pattern: '/receipts/:kind',
			cases: {
				pending: `/receipts/${ReceiptKind.PENDING}`,
				paid: `/receipts/${ReceiptKind.PAID}`,
				paidProducts: `/receipts/${ReceiptKind.PAID_PRODUCTS}`,
			},
		},
	},
	signin: '/signin',
	specialOffers: {
		list: '/special-offers',
		cures: {
			create: '/create-cure',
			update: '/cure/:id',
		},
		discounts: {
			create: '/create-discount',
			update: '/discount/:id',
		},
	},
	subscriptions: {
		list: {
			pattern: '/subscriptions/:kind',
			cases: {
				pending: `/subscriptions/${SubscriptionKind.PENDING}`,
				subscribers: `/subscriptions/${SubscriptionKind.SUBSCRIBERS}`,
				oldBd: `/subscriptions/${SubscriptionKind.OLDDB}`,
			},
		},
		create_subscription: `/subscription/${SubscriptionKind.CREATE_SUBSCRIPTION}`,
		update: '/subscription/:id',
		updateByCard: '/subscription/card/:cardNumber',
	},

	manual: {
		index: '/manual',
		list: {
			pattern: '/manual/:kind',
			cases: {
				portal: `/manual/${ManualKind.PORTAL}`,
				box: `/manual/${ManualKind.BOX}`,
			},
		},
	},
	users: {
		list: '/users',
		create: '/users/create',
		update: '/users/:id',
	},
	institutes: {
		update: '/institute',
	},
	debits: {
		list: {
			patterns: ['/debits/:kind', '/debits'],
			cases: {
				auto: '/debits',
				fifthOfMonth: `/debits/${Debit.PaymentDays.FIFTH_OF_MONTH}`,
				twentiethOfMonth: `/debits/${Debit.PaymentDays.TWENTIETH_OF_MONTH}`,
				history: '/debits/history',
			},
		},
	},
	objectives: {
		index: '/objectives',
		editObjectives: '/edit-objectives',
	},
	orders: {
		list: '/orders',
		create: '/orders/create',
		update: '/orders/:id',
	},
	selloffs: {
		list: '/selloffs',
		create: '/selloffs/create',
		update: '/selloffs/:id',
	},
	stats: {
		index: '/stats',
		pattern: '/stats/:kind',
	},
	addInstitutes: {
		index: '/add-institutes',
	},
	tills: {
		index: '/tills',
		update: '/tills/:id/:number/:merchantId/:printer/:tpe/:connected',
	},
	faq: {
		index: '/faq',
	},
	fiscalArchives: {
		index: '/fiscal-archives',
	},
};

export const entryPointPath = paths.receipts.list.cases.pending;
