import { useTranslation } from 'react-i18next';
import React from 'react';

import { messages } from './messages';
import { TextModal } from '../TextModal';

interface Props {
  cancelAction: () => void;
  cancelLabel?: string;
  confirmAction: () => void;
  confirmLabel?: string;

  description: string;
  isConfirmActionLoading?: boolean;
  title: string;
}

export const Confirmation = (props: Props) => {
  const { t } = useTranslation();

  const {
    cancelAction,
    cancelLabel,
    confirmAction,
    confirmLabel,
    description,
    isConfirmActionLoading,
    title,
  } = props;

  const primaryAction = {
    action: confirmAction,
    label: (confirmLabel ?? t(messages.confirm())) as string,
    isLoading: isConfirmActionLoading,
  };

  const secondaryAction = {
    action: cancelAction,
    label: (cancelLabel ?? t(messages.cancel())) as string,
  };

  return (
    <TextModal
      close={cancelAction}
      description={description}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      title={title}
    />
  );
};
