import { useQueryClient } from 'react-query';

import { deleteUserRequest } from 'app/services/users';
import { genericQueryKey } from 'utils/cache/keys';
import { removeObjectFromCache } from 'utils/react-query/cache';
import { useMutation } from 'app/hooks';

export const useMutations = (instituteId: number) => {
  const queryClient = useQueryClient();

  const parameters = { instituteId };

  const deleteUser = ({ userId }: { userId: number }) =>
    deleteUserRequest({ userId });

  const updateCache = ({ userId }: { userId: number }) => {
    const queryKey = genericQueryKey({
      identifier: 'users',
      parameters,
    });

    removeObjectFromCache({
      object: { id: userId },
      queryClient,
      queryKey,
    });
  };

  const { mutate: deleteMutation, isLoading: isDeleting } = useMutation(
    deleteUser,
    {
      onSuccess: (_, { userId }) => {
        updateCache({ userId });
      },
    },
  );

  return { deleteMutation, isDeleting };
};
