import { FormInputGrid } from 'app/components/Forms/FormInputGrid';
import { DropdownInputDescription } from 'app/pages/Stats/StatsPage/types/inputs';
import { FormLabel } from 'app/components/Forms/FormLabel';
import { SelectInput } from 'app/components/Inputs';

// import { messages } from './messages';

interface Props {
  description: DropdownInputDescription;
  disabled: boolean;
}

export const Dropdown = (props: Props) => {
  const { description, disabled } = props;

  const { label, outputProperty, placeholder } = description;
  return (
    <FormInputGrid item sm={2}>
      <FormLabel>{label}</FormLabel>
      <SelectInput
        disabled={disabled}
        name={outputProperty}
        options={[]}
        placeholder={placeholder}
        required
      />
    </FormInputGrid>
  );
};
