import { DialogTitle } from '@material-ui/core';
import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface ModalHeaderProps {
  accessoryNode?: ReactNode;
  title: string;
}

const StyledDialogTitle = styled(DialogTitle)`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  margin-block: 0;
  color: ${p => p.theme.primary};
  font-family: 'Gabriel Sans Cond';
  font-weight: 700;
  font-size: 2rem;
`;

export const Header = (props: ModalHeaderProps) => {
  const { accessoryNode, title } = props;

  return (
    <StyledDialogTitle disableTypography>
      <Title>{title}</Title>
      {accessoryNode}
    </StyledDialogTitle>
  );
};
