import { Helmet } from 'react-helmet-async';
import React, { useMemo, useState } from 'react';

import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { useInstituteSelector } from 'app/atoms/selectors';
import { LoadableContent } from 'app/components/LoadableContent';
import { Button } from 'app/components/Button';
import { EmptyState } from 'app/components/EmptyState';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { ReactComponent as AddIcon } from 'app/assets/plus.svg';
import { MonthSelector } from 'app/components/Calendar/MonthSelector';
import { Month } from 'types/month';

import { messages } from './messages';
import { useModals, useData } from './hooks';
import { ModalsProvider } from './hoc/ModalsProvider';
import { MonthTable } from './components/MonthTable';

export const ObjectivesEditionPage = () => {
  const { t } = useTranslation();

  const { institute } = useInstituteSelector();

  const [month, setMonth] = useState<Month>(Month.current);

  const { isLoading, rows, setWeeklyObjectives, updateDailyObjective } =
    useData({
      instituteId: institute.id,
      month: month,
    });

  const editable = useMemo(
    // Only current and future months are editable
    () => month.byAddingMonth().toDate() >= new Date(),
    [month],
  );

  const { weeklyObjectivesModal } = useModals();

  const pageTitle = t(messages.pageTitle());

  const onMonthChange = (newMonth: Month) => {
    setMonth(newMonth);
  };

  return (
    <ModalsProvider
      instituteId={institute.id}
      weeklyObjectivesModal={weeklyObjectivesModal}
      setWeeklyObjectives={setWeeklyObjectives}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageWrapper header={<PageHeader backBehaviour label={pageTitle} />}>
        {/* <MonthCalendar
              className="calendar"
              events={events}
              locale={locale}
              onEventChange={onEventChange}
            /> */}
        <MonthSelectorWrapper>
          <MonthSelector month={month} onChange={onMonthChange} />
        </MonthSelectorWrapper>
        <LoadableContent
          isLoading={isLoading}
          isEmpty={!rows}
          emptyNodeRenderer={() => (
            <EmptyState
              button={{
                onClick: () => {
                  weeklyObjectivesModal.toggleOpenState();
                },
                label: t(messages.add()),
                icon: <AddIcon />,
              }}
              label={t(messages.emptyLabel())}
            />
          )}
        >
          <Wrapper>
            <MonthTable
              editable={editable}
              rows={rows ?? []}
              onUpdate={updateDailyObjective}
            />
            <Button className="submit" color="secondary">
              {t(messages.submit())}
            </Button>
          </Wrapper>
        </LoadableContent>
      </PageWrapper>
    </ModalsProvider>
  );
};

const Wrapper = styled.div`
  .submit {
    margin-top: 1rem;
    float: right;
  }
  > .calendar {
    margin-top: 2rem;
  }
`;

const MonthSelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
