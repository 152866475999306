export enum MenuItem {
  RECEIPTS,
  SUBCRIPTIONS,
  DEBITS,
  DISCOUNTS,
  USERS,
  INSTITUTE,
  OBJECTIVES,
  ORDERS,
  SELLOFF,
  STATS,
  ADDINSTITUTES,
  TILLS,
  FAQ,
  MANUAL,
  FISCAL_ARCHIVES,
}
export interface MenuItemConfiguration {
  id: MenuItem;
  icon: (selected: boolean) => JSX.Element;
  path: string;
  title: string;
  selectionPredicate: (path: string) => boolean;
}
