import { Helmet } from 'react-helmet-async';
import { request } from 'utils/request';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { List } from 'app/components/List';
import { LoadableContent } from 'app/components/LoadableContent';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { EmptyState } from 'app/components/EmptyState';
import { useInstituteSelector } from 'app/atoms/selectors';
import { messages } from './messages';
import { DebitItem } from './components/DebitItem';
import { ListItem } from 'app/components/ListItem';
import { EntriesWrapper, InfoEntry } from 'app/components/ListItem/InfoEntry';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { hasEntitlement } from 'utils/roles/hasEntitlement';
import { debitsVersion, meState, paymentDay, idabo } from 'app/atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components/macro';
import ReactToPrint from 'react-to-print';
import { HeaderButton } from 'app/components/PageHeaders/HeaderButton';
import { CloudDownloadOutlined as DownloadIcon } from '@material-ui/icons';
import { DownloadState } from './types';
import { getDebitExportFileName } from 'utils/entities/debits';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {
  fetchDebitsFileContentRequest,
  DebitsResponse,
} from 'app/services/debits';
import { Summary } from './components/Summary';
import { Debit } from 'types/API/debits';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
//import { paymentMonth } from './utils';
import { DebitHistory } from 'types/API/debits';
import { CircularProgress } from '@material-ui/core';
import {
  SearchEvent,
  SearchInput,
} from 'app/components/PageHeaders/SearchInput';
import moment from 'moment';

import { FormControlLabel, Switch } from '@mui/material';
import { number } from 'yup';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Nombre de prélèvements au 5 et au 20 du mois',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

export const DebitsPage = () => {
  var monthName = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];
  const [selectedMonth, setSelectedMonth] = useState('');
  const { institute } = useInstituteSelector();
  const { t } = useTranslation();
  //  const history = useHistory();
  const me = useRecoilValue(meState);
  // const [tab, setTab] = useState(tabFromURLParam(kind));

  const componentRef = useRef<HTMLDivElement>(null);

  // const onTabChange = useCallback((_, newValue: DebitTab) => {
  //   setTab(newValue);
  // }, []);

  //  const [rejected, setRejected] = useState(false);

  // useEffect(() => {
  //   history.push(locationFromTab(tab));
  // }, [history, tab]);

  //  const month = useMemo(() => paymentMonth(), []);

  // const {
  //   debitsQueryState,
  //   historyQueryState,
  //   downloadExportFile,
  //   downloadExportTestFile,
  //   fileDownloadState,
  //   hasInvalidDebits,
  //   summary,
  //   historySummary,
  //   nbPrelevQueryState
  // } = useData({
  //   institute,
  //   day: paymentDayFromTab(tab),
  //   month,
  //   rejected
  // });

  const [historyData, setHistoryData] = useState<DebitHistory.Normal[]>();
  const [data, setData] = useState<DebitsResponse>();
  const [filteredCustomers, setfilteredCustomers] = useState<Debit.Normal[]>();

  // const [fifthData, setFifthData] = useState<DebitsResponse>();

  // const { displaySnackbar } = useSnackbar();

  // useEffect(() => {
  //   if (fileDownloadState.error) {
  //     displaySnackbar({
  //       label: fileDownloadState.error.message,
  //       severity: 'error',
  //     });
  //   }
  // }, [displaySnackbar, fileDownloadState.error]);

  const currency = institute.country.currency;
  const selectedDate = new Date();

  const calculateLastMonths = () => {
    var d = new Date();
    d.setDate(1);
    var lastMonths: string[] = [];
    for (var i = 0; i <= 11; i++) {
      // console.log(monthName[d.getMonth()]);
      let month = d.getMonth();
      lastMonths.push(monthName[month]);
      d.setMonth(d.getMonth() - 1);
    }
    return lastMonths.reverse();
  };

  function getMonthName(monthNumber: number) {
    const date = new Date();
    date.setMonth(monthNumber); // starts with 0, so 0 is January
    return date.toLocaleString('fr-FR', { month: 'long' });
  }

  const capitalizeFirstLowercaseRest = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const labels = calculateLastMonths();

  const [fiveValues, setFiveValues] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [twentyValues, setTwentyValues] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const [adultFiveValues, setAdultFiveValues] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [adultTwentyValues, setAdultTwentyValues] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [youngFiveValues, setYoungFiveValues] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [youngTwentyValues, setYoungTwentyValues] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [skinFiveValues, setSkinFiveValues] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [skinTwentyValues, setSkinTwentyValues] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  useEffect(() => {
    if (historyData !== undefined) {
      var fiveTempValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var twentyTempValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      var afiveTempValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var atwentyTempValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var yfiveTempValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var ytwentyTempValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var sfiveTempValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var stwentyTempValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      for (var i = 0; i < historyData.length; i++) {
        let history: DebitHistory.Normal = historyData[i];
        let date = new Date(history.created_at);
        let monthName = capitalizeFirstLowercaseRest(
          getMonthName(date.getMonth()),
        );
        let indexOfMonthInLabel = labels.indexOf(monthName);

        if (history.paymentDay === 5) {
          fiveTempValues[indexOfMonthInLabel] = history.ca;
          afiveTempValues[indexOfMonthInLabel] = history.adult;
          yfiveTempValues[indexOfMonthInLabel] = history.young;
          sfiveTempValues[indexOfMonthInLabel] = history.skin;
        }

        if (history.paymentDay === 20) {
          twentyTempValues[indexOfMonthInLabel] = history.ca;
          atwentyTempValues[indexOfMonthInLabel] = history.adult;
          ytwentyTempValues[indexOfMonthInLabel] = history.young;
          stwentyTempValues[indexOfMonthInLabel] = history.skin;
        }
      }
      setFiveValues(fiveTempValues);
      setTwentyValues(twentyTempValues);

      setAdultFiveValues(afiveTempValues);
      setAdultTwentyValues(atwentyTempValues);
      setYoungFiveValues(yfiveTempValues);
      setYoungTwentyValues(ytwentyTempValues);
      setSkinFiveValues(sfiveTempValues);
      setSkinTwentyValues(stwentyTempValues);
    }
    return () => { };
  }, [historyData]);

  var dataStacked = {
    labels,
    datasets: [
      {
        label: '5 du mois',
        data: fiveValues.map(value => value),
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        label: '20 du mois',
        data: twentyValues.map(value => value),
        backgroundColor: 'rgb(53, 162, 235)',
      },
    ],
  };

  var dataStackedAndGrouped = {
    labels,
    datasets: [
      {
        label: 'Adulte 5',
        data: adultFiveValues.map(value => value),
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0',
      },
      {
        label: 'Jeune 5',
        data: youngFiveValues.map(value => value),
        backgroundColor: 'rgb(53, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Skin 5',
        data: skinFiveValues.map(value => value),
        backgroundColor: 'rgb(75, 192, 192)',
        stack: 'Stack 0',
      },
      {
        label: 'Adulte 20',
        data: adultTwentyValues.map(value => value),
        backgroundColor: 'rgb(255, 206, 86)',
        stack: 'Stack 1',
      },
      {
        label: 'Jeune 20',
        data: youngTwentyValues.map(value => value),
        backgroundColor: 'rgba(153, 102, 255, 1)',
        stack: 'Stack 1',
      },
      {
        label: 'Skin 20',
        data: skinTwentyValues.map(value => value),
        backgroundColor: 'rgba(255, 159, 64, 1)',
        stack: 'Stack 1',
      },
    ],
  };

  var dataStackedAndGroupedAll = {
    labels,
    datasets: [
      {
        label: 'CA au 5 du mois',
        data: fiveValues.map(value => value),
        backgroundColor: 'rgb(61, 164, 53)',
        stack: 'Stack 2',
      },
      {
        label: 'CA 20 du mois',
        data: twentyValues.map(value => value),
        backgroundColor: 'rgb(35, 213, 29)',
        stack: 'Stack 2',
      },
      {
        label: 'Adulte 5',
        data: adultFiveValues.map(value => value),
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0',
      },
      {
        label: 'Jeune 5',
        data: youngFiveValues.map(value => value),
        backgroundColor: 'rgb(53, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Skin 5',
        data: skinFiveValues.map(value => value),
        backgroundColor: 'rgb(75, 192, 192)',
        stack: 'Stack 0',
      },
      {
        label: 'Adulte 20',
        data: adultTwentyValues.map(value => value),
        backgroundColor: 'rgb(255, 206, 86)',
        stack: 'Stack 1',
      },
      {
        label: 'Jeune 20',
        data: youngTwentyValues.map(value => value),
        backgroundColor: 'rgba(153, 102, 255, 1)',
        stack: 'Stack 1',
      },
      {
        label: 'Skin 20',
        data: skinTwentyValues.map(value => value),
        backgroundColor: 'rgba(255, 159, 64, 1)',
        stack: 'Stack 1',
      },
    ],
  };

  var startTime = '10:00:00';
  var endTime = '20:00:00';

  var currentDate = new Date();
  
  var restrainedStartDate = new Date(currentDate.getTime());
  restrainedStartDate.setHours(+startTime.split(':')[0]);
  restrainedStartDate.setMinutes(+startTime.split(':')[1]);
  restrainedStartDate.setSeconds(+startTime.split(':')[2]);
    
  var date = new Date();
  var currentDayOfWeek = date.getDay();
  let firstDayOfTheMonth = new Date(new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [startDate, setStartDate] = useState(firstDayOfTheMonth);
  const currentYear = new Date().getFullYear();
  const fiveYearsAgo = new Date();
  fiveYearsAgo.setFullYear(currentYear - 5);

  var dateNowPlus2months = new Date();
  dateNowPlus2months.setMonth(dateNowPlus2months.getMonth() + 2);
  dateNowPlus2months = moment(dateNowPlus2months).clone().endOf('month').toDate();

  var endDate = new Date(currentDate.getTime());
  endDate.setHours(+endTime.split(':')[0]);
  endDate.setMinutes(+endTime.split(':')[1]);
  endDate.setSeconds(+endTime.split(':')[2]);

  const [categories] = useState(['5', '20', 'Historique']);
  const [selectedCat, setSelectedCat] = useRecoilState(paymentDay);
  const [switchVersion, setSwitchVersion] = useRecoilState(debitsVersion);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [downloadingFile, setDownloadingFile] = useState(false);
  const isSuperAdmin = hasEntitlement('super_admin', me);
  const debitsClosed = (isSuperAdmin || currentDayOfWeek == 0 ?  false : (restrainedStartDate < currentDate && endDate > currentDate));

  // const handleChange = () => {
  //   setRejected(!rejected);
  //   // validateDates(startDate, endDate);
  // };

  const getCurrentMonthName = (date?: any) => {
    let currentMonth = date ? date?.getMonth() : new Date().getMonth();
    let name = monthName[currentMonth];
    return name;
  };

  const pullData = (cat: string, month: string) => {
    if (cat === '') {
      return;
    }

    var monthIndex = 0;
    if (month === '') {
      monthIndex = new Date().getMonth() + 1;
    } else {
      const selectedMonth = (element: string) => element === month;
      monthIndex = monthName.findIndex(selectedMonth) + 1;
    }
    var catNumber = Number(cat);

    console.log(
      `pullData for instituteId : ${institute.id}, cat: ${cat}, day : ${catNumber} and month: ${month}, monthNumber: ${monthIndex}, year: ${startDate?.getFullYear()}, only rejections : ${checked}`,
    );

    setLoading(true);
    if (!Number.isNaN(catNumber)) {
      fetchDebitsRequest({
        instituteId: institute.id,
        day: catNumber,
        month: monthIndex,
        rejected: checked,
        switchVersion,
        year: startDate?.getFullYear(),
      })
        .then(data => {
          setData(data);
          setfilteredCustomers(data.customer);
          setLoading(false);
          // setHistoryData(data);
        })
        .catch(error => {
          setLoading(false);
          setError(error);
          console.log(error);
        });
    } else {
      fetchDebitsHistoryRequest({ instituteId: institute.id })
        .then(data => {
          setLoading(false);
          setHistoryData(data);
        })
        .catch(error => {
          setLoading(false);
          setError(error);
          console.log(error);
        });
    }
  };

  const onCatOptionSelected = option => {
    let cat = option.value as string;
    setSelectedCat(option.value);
    if (selectedMonth === '') {
      setSelectedMonth(getCurrentMonthName);
    }
    console.log(`selectedCat: ${cat}, selectedMonth: ${selectedMonth}`);
    //pullData(option.value, selectedMonth);
    pullData(
      option.value,
      switchVersion ? getCurrentMonthName(startDate) : selectedMonth,
    );
  };

  useEffect(() => {
    setError(undefined);
  }, [switchVersion]);

  const onMonthOptionSelected = option => {
    setSelectedMonth(option.label);
    console.log(`selectedMonth:${option.label}, selectedCat:${selectedCat}`);
    pullData(selectedCat, option.value);
  };

  const [checked, setChecked] = useState(false);
  const [maxDate] = useState(new Date());

  useEffect(() => {
    console.log(
      `useEffect Start date before pullData RejectionValue : ${checked}`,
    );
    switchVersion
      ? pullData(selectedCat, getCurrentMonthName(startDate))
      : pullData(selectedCat, getCurrentMonthName());

  }, [startDate]);

  useEffect(() => {
    console.log(
      `useEffect checked before pullData RejectionValue : ${checked}`,
    );
    switchVersion
      ? pullData(selectedCat, getCurrentMonthName(startDate))
      : pullData(selectedCat, getCurrentMonthName());

  }, [checked]);


  const handleChange = () => {
    setChecked(!checked);
    console.log(
      `RejectionValue : ${checked}`,
    );
    // validateDates(startDate, endDate);
  };

  const [fileDownloadState, setFileDownloadState] = useState<DownloadState>({
    isLoading: false,
  });

  const downloadExportFile = async ({ institute, day, month, year, rejected }) => {
    setFileDownloadState({ isLoading: true });

    const exportFileName = getDebitExportFileName({
      instituteName: institute.name,
      day,
      month,
      year: startDate?.getFullYear(),
    });

    try {
      const data = await fetchDebitsFileContentRequest({
        instituteId: institute.id,
        day,
        month,
        year,
        rejected,
      });
      const content = atob(data);
      const blob = new Blob([content], {
        type: 'text/xml',
      });
      setTimeout(() => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = exportFileName;
        link.click();
        setFileDownloadState({ isLoading: false });
      }, 5000);
    } catch (error: any) {
      setFileDownloadState({ isLoading: false, error });
    }
  };

  const download = () => {
    setDownloadingFile(true);
    var day = 5;
    if (selectedCat === '20') {
      day = 20;
    }
    const selectedMonth1 = (element: string) => element === selectedMonth;
    const month: any = startDate.getMonth() + 1; //monthName.findIndex(selectedMonth1) + 1;
    const year: any = startDate.getFullYear();
    
    console.log(
      `Downloading for institute: ${institute}, day ${day} and month: ${month}, year: ${year}, only rejection:${checked}`,
    );

    downloadExportFile({ institute, day, month, year, rejected: checked }).then(() => {
      setTimeout(() => {
        setDownloadingFile(false); //dirty solution: once the file is loaded, it takes +-2 seconds to be downloaded by browser
      }, 4000);
    });
  };

  function onSearch(event: SearchEvent): void {
    let name = (event.target as HTMLInputElement).value;
    console.log(`SearchEvent name: ${name}`);

    let customers = data?.customer.filter(customer => {
      console.log(
        `firstname: ${customer.firstname.toLowerCase()}, lastname: ${customer.lastname.toLowerCase()}, name: ${name}`,
      );
      return (
        customer.lastname.toLowerCase().includes(name.toLowerCase()) ||
        customer.firstname.toLowerCase().includes(name.toLowerCase()) ||
        customer.iban.toLowerCase().includes(name.toLowerCase())
      );
    });
    console.log(`customers?.length:${customers?.length}`);
    setfilteredCustomers(customers);
  }

  return (
    <>
      <Helmet>
        <title>
          {t(messages.pageTitle())} : {institute.name}
        </title>
      </Helmet>

      {downloadingFile && (
        <Spinner>
          <CircularProgress color="primary" />
        </Spinner>
      )}

      {debitsClosed && (
        <PageWrapper
          header={
            <PageHeader
              label={t(messages.pageTitle()) + ' : ' + institute.name}
            />
          }
        >
          <WarningBlock>
            Pour des raisons technique, l'accès au prélèvements est désactivé de
            10h à 20h sauf le dimanche.
          </WarningBlock>
          {/* <WarningBlock>
            Momentanément indisponible en cours d’installation de la nouvelle version incluant les prélèvements à 12.90 €
          </WarningBlock> */}
        </PageWrapper>
      )}

      {!debitsClosed && (
        <PageWrapper
          header={
            <div>
              {/* <Header
                currency={currency}
                isDownloadDisabled={hasInvalidDebits}
                isDownloading={fileDownloadState.isLoading}
                isDownloadVisible={
                  debitsQueryState.isLoading || debitsQueryState.isError
                }
                month={month}
                onDownload={downloadExportFile}
                onTestDownload={downloadExportTestFile}
                onTabChange={onTabChange}
                summary={summary}
                tab={tab}.data != undefined ? nbPrelevQueryState.data.nbAdult : 0}
                nbJeune={nbPrelevQueryState.data != u
                nbAdult={nbPrelevQueryStatendefined ? nbPrelevQueryState.data.nbJeune : 0}
                nbSkin={nbPrelevQueryState.data != undefined ? nbPrelevQueryState.data.nbSkin : 0}
              /> */}
              <PageHeader
                label={t(messages.pageTitle()) + ' : ' + institute.name}
              />

              <Summary
                currency={currency}
                debitsAmount={
                  data?.totalPrelev != undefined ? data.totalPrelev : 0
                }
                debitsCount={data?.customer.length ?? 0}
                nbAdult={
                  data?.nbPrelev.nbAdult != undefined
                    ? data.nbPrelev.nbAdult
                    : 0
                }
                nbJeune={
                  data?.nbPrelev.nbJeune != undefined
                    ? data.nbPrelev.nbJeune
                    : 0
                }
                nbSkin={
                  data?.nbPrelev.nbSkin != undefined ? data.nbPrelev.nbSkin : 0
                }
              />

              {data && (
                <>
                  <HeaderButton
                    disabled={false}
                    icon={<DownloadIcon />}
                    isSpinning={false}
                    label={
                      selectedCat === '5' || selectedCat === '20'
                        ? 'Télécharger le fichier du ' +
                        selectedCat +
                        ' pour le mois de ' +
                        /*selectedMonth*/ (startDate.getMonth() + 1) + '/' + startDate.getFullYear()/*
                        ' ' +
                        new Date().getFullYear()*/
                        : "Télécharger l'" + selectedCat
                    }
                    onClick={download} // to re-enable it: onDownload
                  />

                  <PrintButtonRow>
                    <ReactToPrint
                      trigger={() => (
                        <PrintButton type="submit" value="Imprimer" />
                      )}
                      content={() => componentRef.current}
                    //pageStyle={pageStyle}
                    />
                  </PrintButtonRow>
                </>
              )}

              {/* <label>
                <input type="checkbox" checked={rejected} onChange={handleChange} />
                Integrer les clientes en rejet
              </label> */}
            </div>
          }
        >
          <label>
            <input type="checkbox" checked={checked} onChange={handleChange} />
            &nbsp;&nbsp;Aucun rejet / Seulement les rejets
          </label>
          <div ref={componentRef}>
            {isSuperAdmin && (
              <DropdownWrapper>
                {switchVersion ? (
                  <DatePicker
                    locale="fr"
                    minDate={fiveYearsAgo}
                    maxDate={dateNowPlus2months}
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date: any) => {
                      setStartDate(date);
                    }}
                  />
                ) : (
                  <Dropdown
                    options={monthName}
                    value={selectedMonth}
                    onChange={onMonthOptionSelected.bind(this)}
                    placeholder={getCurrentMonthName()} //"Sélectionnez un mois"
                  />
                )}
              </DropdownWrapper>
            )}

            <DropdownWrapper>
              <Dropdown
                options={categories}
                value={selectedCat}
                onChange={onCatOptionSelected.bind(this)}
                placeholder="Sélectionnez une date pour les prélèvements"
              />
            </DropdownWrapper>

            {data && (selectedCat == '5' || selectedCat == '20') && (
              <>
                <SearchInputWrapper>
                  <SearchInput
                    className="search-input"
                    disabled={false}
                    onSearch={onSearch}
                    placeholder={t(messages.searchPlaceholder())}
                  />
                </SearchInputWrapper>
              </>
            )}

            {/*  <div ref={componentRef}> */}
            {/* {tab != DebitTab.HISTORY &&

              <LoadableContent
                error={debitsQueryState.error}
                isLoading={debitsQueryState.isLoading}
                isEmpty={summary?.count === 0}
                emptyNodeRenderer={() => (
                  <EmptyState label={t(messages.emptyLabel())} />
                )}
              >
                <List>
                  {debitsQueryState.data?.sort(function (a, b) {
                    if (typeof a.firstname != "string") return -1;
                    if (typeof b.firstname != "string") return 1;
                    if (a.firstname.toLowerCase() < b.firstname.toLowerCase()) return -1;
                    if (a.firstname.toLowerCase() > b.firstname.toLowerCase()) return 1;
                    return 0;
                  })
                    .map(debit => (
                      <DebitItem
                        currency={currency}
                        debit={debit}
                        key={debit.subscriptionid}
                        onClick={() => onItemSelection(debit.cardNumber)}
                      />
                    ))}

                </List>
                <ScrollToTop />

              </LoadableContent>
            }  */}

            {/* {tab === DebitTab.HISTORY && */}

            {selectedCat != 'Historique' && (
              <LoadableContent
                error={error}
                isLoading={loading}
                isEmpty={data === undefined}
                emptyNodeRenderer={() => (
                  <EmptyState label={t(messages.emptyHistoryLabel())} />
                )}
              >
                <List>
                  <>
                    {filteredCustomers
                      ?.sort(function (a, b) {
                        if (typeof a.firstname != 'string') return -1;
                        if (typeof b.firstname != 'string') return 1;
                        if (
                          a.firstname.toLowerCase() < b.firstname.toLowerCase()
                        )
                          return -1;
                        if (
                          a.firstname.toLowerCase() > b.firstname.toLowerCase()
                        )
                          return 1;
                        return 0;
                      })
                      .map(debit => (
                        <DebitItem
                          selectedDate={selectedDate}
                          switchVersion={switchVersion}
                          startDate={startDate}
                          currency={currency}
                          debit={debit}
                          key={debit.subscriptionid}
                          setfilteredCustomers={setfilteredCustomers}
                        />
                      ))}
                  </>
                  <Bar options={options} data={dataStackedAndGrouped} />
                  <Bar options={options} data={dataStacked} />
                  <Bar options={options} data={dataStackedAndGroupedAll} />
                </List>
              </LoadableContent>
            )}

            {selectedCat == 'Historique' && (
              <LoadableContent
                error={error}
                isLoading={loading}
                isEmpty={historyData === undefined}
                emptyNodeRenderer={() => (
                  <EmptyState label={t(messages.emptyHistoryLabel())} />
                )}
              >
                <List>
                  {historyData?.map(history => (
                    <>
                      <ListItem>
                        <EntriesWrapper>
                          <InfoEntry
                            key="created_at"
                            label="Date de téléchargement"
                            value={isoToDateString(history.created_at)}
                          />
                        </EntriesWrapper>
                        <InfoEntry
                          key="nb"
                          label="Nombre"
                          value={String(history.nb)}
                        />
                        <InfoEntry
                          key="jp"
                          label="Jour de prélèvement"
                          value={String(history.paymentDay)}
                        />
                        <InfoEntry
                          key="mp"
                          label="Montant prélevé"
                          value={String(history.ca) + '€'}
                        />
                        <InfoEntry
                          key="nba"
                          label="Nb Adulte"
                          value={String(history.adult)}
                        />
                        <InfoEntry
                          key="nbj"
                          label="Nb Jeune"
                          value={String(history.young)}
                        />
                        <InfoEntry
                          key="nbs"
                          label="Nb Skin"
                          value={String(history.skin)}
                        />
                      </ListItem>
                    </>
                  ))}
                </List>
              </LoadableContent>
            )}
          </div>
          {/* } */}
          {/* </div> */}
        </PageWrapper>
      )}
    </>
  );
};

const fetchDebitsHistoryRequest = async ({
  instituteId,
}: {
  instituteId: number;
}) => {
  const response = await request<DebitHistory.Normal[]>(
    `api/prelev/history/${instituteId}`,
    {
      method: 'GET',
    },
  );
  // Cleanup the back-end response.
  return response;
};

const fetchDebitsRequest = async ({
  instituteId,
  day,
  month,
  rejected,
  switchVersion,
  year,
}: {
  instituteId: number;
  day: number;
  month: number;
  year?: number;
  rejected: boolean;
  switchVersion: boolean;
}) => {
  const response = await request<DebitsResponse>(
    `api/prelevement/${instituteId}/${day}/${month}${switchVersion ? `/${year}/` : '/'
    }xml/1/rejet/${rejected ? 1 : 0}`,
    {
      method: 'GET',
    },
  );
  console.log(
    '======>fetchDebitsRequest: ' +
    `api/prelevement/${instituteId}/${day}/${month}/xml/1/rejet/${rejected ? 1 : 0
    }`,
  );
  return response;
};

function isoToDateString(isoDate) {
  let date = new Date(isoDate);
  return date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear();
}

const WarningBlock = styled.div`
  color: #f00;
  border: 1px solid red;
  text-align: center;
  padding: 10px;
  margin: 20px;
  border-radius: 20px;
`;

const PrintButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 95%;
`;

const SearchInputWrapper = styled.div`
  > .search-input {
    width: 500px};
  }
`;

const PrintButton = styled.input`
  background: #e462a1;
  border: none;
  padding: 15px;
  margin-top: 20px;
  margin-right: -60px;
  width: 220px;
  border-radius: 30px;
  color: white;
`;

const DropdownWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const pageStyle = `
  @page {
    size: 50mm 80mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
const Spinner = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 100px;
  z-index: 10;
`;