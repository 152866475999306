import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { messages } from './messages';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { LoadableContent } from 'app/components/LoadableContent';
import { useInstituteSelector } from 'app/atoms/selectors';
import { Institute } from 'types/API/institutes';
import { request } from 'utils/request';
import { generatePath, useHistory } from 'react-router-dom';
import { paths } from 'app/routes/paths';
import { List } from 'app/components/List';
import styled from 'styled-components/macro';
import { TillsItem } from './components/TillsItem';
import JSONbigint from 'json-bigint'

export const TillsPage = () => {

  const { t } = useTranslation();
  const pageTitle = t(messages.pageTitle());
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { institute: cachedInstitute, instituteId } = useInstituteSelector();
  const [tills, setTills] = useState<[Institute.Till]>();

  const loadData = async () => {
    if (!loading) {
      setLoading(true)
      fetchInstituteRequest({ instituteId: instituteId }).then(data => {
        setLoading(false)
        
        if (data) {
          let valeur = JSONbigint.parse(data);
          setTills(valeur)
        }
      }).catch(error => {
        setLoading(false)
        setError(error)
        console.log(error)
      })
    }
  }

  useEffect(() => {
    loadData();
    return () => { };
  }, [instituteId]);

  const onTillEdition = ({ till }: { till: Institute.Till }) => {
    // /tills/: id /: number /: merchantId /: printer /: tpe /: connected
    let defaultMerchantId = "0"
    if (till.merchantId != null) {
      defaultMerchantId = till.merchantId.toString()
    }
    const location = generatePath(paths.tills.update, { id: till.id, number: till.number, merchantId: defaultMerchantId, printer: till.printer || "0", tpe: till.tpe || "0", connected: till.connected || false });
    history.push(location);
  };

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageWrapper
        header={<PageHeader label={pageTitle} />}>

        <LoadableContent
          error={error}
          isLoading={loading}
        >
          <div>Voici la liste des caisses rattachées à l'institut: {cachedInstitute.name}</div>
          <br />
          <List>
            {tills?.map(till => (
              <TillsItem
                key={till.id}
                till={till}
                onClick={() => onTillEdition({ till: till })}>
              </TillsItem>
            ))}
          </List>
          
        </LoadableContent>
      </PageWrapper>
    </div>
  )
}

export const fetchInstituteRequest = ({
  instituteId,
}: {
  instituteId: number;
  }) => {
  
  let res = request<[Institute.Till]>(`api/caisses?institute=${instituteId}`, {
    method: 'GET', transformResponse: [data => data]
  });

  return res
}
  


const TillsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin: 10px;
`;
