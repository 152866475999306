export enum SubscriptionTab {
  PENDING = 0,
  SUBSCRIBERS,
  CREATE_SUBSCRIPTION,
  OLDDB,
}

export enum SubscriptionKind {
  PENDING = 'pending',
  SUBSCRIBERS = 'subscribers',
  CREATE_SUBSCRIPTION = 'create-subscription',
  OLDDB = 'oldBd',
}
